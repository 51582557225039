
import RcDomainObject from "@/domain/common/RcDomainObject.js";
import Company from "@/domain/model/company/Company.js";
import Employee from "@/domain/model/employee/Employee.js";
import InspectionMap from "@/domain/model/inspection/InspectionMap.js";
import Signature from "@/domain/model/user/Signature.js";
import User from "@/domain/model/user/User.js";
import RcDate from '@/domain/session/time/RcDate.js';
import StringUtils from '@/utils/StringUtils.js';
import TimesheetEntryMap from "./TimesheetEntryMap.js";
import TimesheetMap from "./TimesheetMap.js";


export default class Timesheet extends RcDomainObject {
  
  static MODEL_NAME = "Timesheet";
  
  constructor(domain, data) {
    super(domain, data, Timesheet.MODEL_NAME);
  }
  
  static Fields = {
    COMPANY : Company.MODEL_NAME,
    EMPLOYEE : Employee.MODEL_NAME,
    ITEMS: TimesheetEntryMap.MODEL_NAME,
    INSPECTIONS: InspectionMap.MODEL_NAME,
    SIGNATURE: Signature.MODEL_NAME,
    YEAR: "year",
    MONTH: "month",
    DAY: "day",
    COMPLETED_DATE: "completedDate",
    CYCLE: "cycle",
    DATE: "date",
    TOTAL_OFF_DUTY: "offDuty",
    TOTAL_ON_DUTY: "onDuty",
    TOTAL_ON_DUTY_DRIVING: "totalOnDutyDriving",
    TOTAL_OFF_DUTY_AWAY: "totalOffDutyAway",
    TOTAL_OFF_DUTY_SLEEPER: "totalOffDutySleeper",
    TOTAL_ON_DUTY_NOT_DRIVING: "totalOnDutyNotDriving",
    LOCATION_START: "locationStart",
    LOCATION_END: "locationEnd",
    PDF_URL: "pdfUrl",
  }
  
  static Values = {
      Status: {
        MISSING: 0,
        INPROGRESS: 1,
        INCOMPLETE: 2,
        COMPLETED: 3,
        COMPLETED_OFF_DAY: 4,
      },
  }

  //On Duty Not Driving
  totalOnDutyNotDrivingQuarters() {
    return this.getInt(Timesheet.Fields.TOTAL_ON_DUTY_NOT_DRIVING);
  }

  totalNotDrivingHours() {
    return this.totalOnDutyNotDrivingQuarters()/4;
  }

  onDutyNotDrivingHoursDisplay() {
    return StringUtils.format("{0}", [ this.totalNotDrivingHours()]);
  }
  
  //Off Duty Away
  totalOffDutyAwayQuarters() {
    return this.getInt(Timesheet.Fields.TOTAL_OFF_DUTY_AWAY);
  }
  
  totalOffDutyAwayHours() {
    return this.totalOffDutyAwayQuarters()/4;
  }
  
  //On Duty Driving
  totalDrivingQuarters() {
    return this.getInt(Timesheet.Fields.TOTAL_ON_DUTY_DRIVING);
  }

  totalDrivingHours() {
    return this.totalDrivingQuarters()/4;
  }

  onDutyDrivingHoursDisplay() {
    return StringUtils.format("{0}", [ this.totalDrivingHours()]);
  }
  
  //On Duty both driving and working
  totalOnDutyQuarters() {
    return this.getInt(Timesheet.Fields.TOTAL_ON_DUTY);
  }

  totalOnDutyHours() {
    return this.totalOnDutyQuarters()/4;
  }
  
  onDutyHoursDisplay() {
    return StringUtils.format("{0}", [ this.totalOnDutyHours()]);
  }
  
  //Off Duty both away and sleeper
  totalOffDutyQuarters() {
    return this.getInt(Timesheet.Fields.TOTAL_OFF_DUTY);
  }
  
  totalOffDutyHours() {
    return this.totalOffDutyQuarters()/4;
  }

  offDutyHoursDisplay() {
    return StringUtils.format("{0}", [ this.totalOffDutyHours()]);
  }

  //Off and On Duty 
  totalHours() {
    var quarters = this.totalOnDutyQuarters() + this.totalOffDutyQuarters();
    return quarters/4;
  }
  
  totalQuarters() {
    var quarters = this.totalOnDutyQuarters() + this.totalOffDutyQuarters();
    return quarters;
  }
  
  totalHoursDisplay() {
    return StringUtils.format("{0}", [ this.totalHours()]);
  }
  
  fields() {
    return Timesheet.FIELDS;
  }
  
  employee() {
    var employeeData = this.data[Timesheet.Fields.EMPLOYEE];
    return new Employee(this.domain, employeeData);
  }
  
  signature() {
    var data = this.data[Timesheet.Fields.SIGNATURE];
    return new Signature(this.domain, data);
  }
  
  company() {
    var companyData = this.data[Timesheet.Fields.COMPANY];
    return new Company(this.domain, companyData);
  }
  
  entries() {
    var data = this.data[Timesheet.Fields.ITEMS];
    return new TimesheetEntryMap(this.domain, data);
  }
  
  inspections() {
    var data = this.data[Timesheet.Fields.INSPECTIONS];
    return new InspectionMap(this.domain, data);
  }
  
  withDate(rcDate) {
    this.put(Timesheet.Fields.YEAR, rcDate.year().value());
    this.put(Timesheet.Fields.MONTH, rcDate.month().value());
    this.put(Timesheet.Fields.DAY, rcDate.day().value());
    this.put(Timesheet.Fields.DATE, rcDate.day().firstHour().time());
  }
  
  year() {
    return this.getInt(Timesheet.Fields.YEAR);
  }
  
  month() {
    return this.getInt(Timesheet.Fields.MONTH);
  }
  
  day() {
    return this.getInt(Timesheet.Fields.DAY);
  }
  
  dateDisplay() {
    var theYear = StringUtils.toString(this.year());
    var theMonth = StringUtils.toString(this.month() + 1);
    var theDay = StringUtils.toString(this.day() + 1);
    if (StringUtils.isEmpty(theYear)) {
      return "";
    }
    return StringUtils.format("{0}-{1}-{2}", [theYear, theMonth, theDay]);
  }
  
  date() {
    var timeZone = this.timeZone();
    var rcDate = new RcDate(new Date(this.get(Timesheet.Fields.DATE)), timeZone);
    return rcDate;
  }
  
  completedDate() {
    return this.get(Timesheet.Fields.COMPLETED_DATE);
  }
  
  completedDateDisplay() {
    var completedDate = this.completedDate();
    if (!completedDate) {
      return "";
    }
    var timeZone = this.timeZone();
    var rcDate = new RcDate(new Date(completedDate), timeZone);
    return rcDate.displayAs_YYYY_MM_DD_HH_MM_SS_A();
  }
  
  cycle() {
    return this.get(Timesheet.Fields.CYCLE);
  }
  
  locationStart() {
    return this.getString(Timesheet.Fields.LOCATION_START);
  }
  
  hasStartLocation() {
    return StringUtils.isNotEmpty(this.locationStart());
  }

  locationEnd() {
    return this.getString(Timesheet.Fields.LOCATION_END);
  }
  
  hasEndLocation() {
    return StringUtils.isNotEmpty(this.locationEnd());
  }

  cycleDisplay() {
    if (this.cycle() == 2) {
      return "Cycle 2 (14 day)";
    }
    return "Cycle 1 (7 day)";
  }
  
  isToday() {
    var now = this.domain.session().time().now().day().firstHour().day();
    var rcDate = this.domain.session().time().day(this.year(), this.month(), this.day() + 1).firstHour().day();
    var result = rcDate.time() == now.time();
    return result;
  }
  
  statusDisplay() {
    if (this.isStatusInProgress()) {
      return "In Progress";
    }
    
    if (this.isStatusIncomplete()) {
      return "Incomplete";
    }
    
    if (this.isStatusComplete()) {
      return "Complete";
    }
    
    if (this.isStatusComplete() || this.isStatusCompleteOffDay()) {
      return "Complete";
    }
    
    return "Missing Hours";
  }

  status() {
    var quarters = this.totalQuarters();
    var date = this.completedDate();
    
    if (this.isToday() && quarters < 96) {
      return Timesheet.Values.Status.INPROGRESS;
    }
    
    if (date > 0 && quarters == 96) {
      let newSignature = this.signature().isNew();
      if (this.hasStartLocation() && this.hasEndLocation() && !newSignature) {
        return Timesheet.Values.Status.COMPLETED;
      }
      let totalOffDuty = this.totalOffDutyQuarters();
      if (totalOffDuty == 96) {
        if (!newSignature) {
          return Timesheet.Values.Status.COMPLETED_OFF_DAY;
        }
      }
      return Timesheet.Values.Status.INCOMPLETE;
    }
    
    if (quarters < 96) {
      return Timesheet.Values.Status.INCOMPLETE;
    }
    
    if (!date && quarters == 96) {
      return Timesheet.Values.Status.INCOMPLETE; 
    }
    
    return Timesheet.Values.Status.MISSING;
  }
  
  defectType() {
    if (this.isStatusComplete()) {
      return "Complete";
    }
    
    if (this.isStatusCompleteOffDay()) {
      return "CompleteOff";
    }
    
    if (this.isStatusInProgress()) {
      return "In Progress";
    }
    
    if (this.isStatusIncomplete()) {
      return "Incomplete";
    }

    return "Missing";
  }

  isStatusInProgress() {
    var status = this.status();
    return status == Timesheet.Values.Status.INPROGRESS;
  }
  
  isStatusComplete() {
    return this.status() == Timesheet.Values.Status.COMPLETED;
  }
 
  isStatusCompleteOffDay() {
    return this.status() == Timesheet.Values.Status.COMPLETED_OFF_DAY;
  }
  
  isStatusIncomplete() {
    return this.status() == Timesheet.Values.Status.INCOMPLETE;
  }
  
  isStatusMissing() {
    return this.status() == Timesheet.Values.Status.MISSING;
  }
 
  pdfUrl() {
    var host = this.domain.client().cdnHost;
    var url = host + this.getString(Timesheet.Fields.PDF_URL);
    return url;
  }
  
  hasPdfUrl() {
    return !StringUtils.isEmpty(this.getString(Timesheet.Fields.PDF_URL));
  }
  
  map() {
    var map = new TimesheetMap(this.domain);
    map.add(this);
    return map;
  }
  
  createdBy() {
    return new User(this.domain, this.get(RcDomainObject.DefaultFields.CREATED_BY));
  }

  modifiedBy() {
    return new User(this.domain, this.get(RcDomainObject.DefaultFields.MODIFIED_BY));
  }


}