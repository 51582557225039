
import ListViewUtils from "./ListViewUtils";

import ContentUtils from '@/utils/ContentUtils.js';
import StringUtils from '@/utils/StringUtils.js';
import RcTableViewModel from "../../../../shared/table/RcTableViewModel";
import CompanyMap from "@/domain/model/company/CompanyMap.js";
import CardMap from "@/domain/model/invoice/CardMap.js";
import CardListFilter from "@/domain/model/invoice/CardListFilter.js";

import InvoiceSettingsMap from "@/domain/model/invoice/InvoiceSettingsMap.js";
// import CompanyListFilter from "@/domain/model/company/CompanyListFilter.js";

// import TimesheetMap from '@/domain/model/timesheet/TimesheetMap.js';
// import Utils from '@/views/portal/inspections/Utils.js';
// import Employee from "@/domain/model/employee/Employee.js";
// import EmployeeUtils from '@/views/portal/employees/Utils.js';

export default class ListViewModel extends RcTableViewModel {
    
  constructor(panel) {
    super(panel);
    this._companyId = ContentUtils.baseDecode(panel.$route.params.operatorId);
    this._total = 0;
    this._companyList = [];
    this._cardMap = null;
    this._settingsMap = null;
    this._pages = [];
    this._searchText = panel.$route.query.search ? panel.$route.query.search : "";
  }

  start() {
    super.start();
  }

  isAdmin() {
    return super.isAdmin();
  }

  withSearchText(valueData) {
    this._searchText = valueData["value"]
    return this;
  }
  
  searchText() {
    return this._searchText;
  }

  withValue(valueData) {
    super.withValue(valueData, "card");
  }

  withButtonPressed(buttonData) {
    super.withButtonPressed(buttonData);
    if (!buttonData) {
      return;
    }
  }

  buildRowData(tableData) {
    var utils = new ListViewUtils(this, tableData);
    utils.start();
    return this;
  }

  basicListener(response, context) {
    var model = context.model;
    if (response.error()) {
      model.withError(response.error());
      model.panel().reloadPage();
    }
    model.panel().showWorking = false;
  }

  basicRefreshListener(response, context) {
    var model = context.model;
    model.basicListener(response, context);
    if (response.error()) {
      return;
    }
    model.panel().reloadPage();
  }

  loadItems() {
    super.loadItems();
    var query = this.panel().$route.query;
    this.panel().displayProgress("Loading Companies");
    var time = query["time"];
    var searchFor = query["search"];

    if (!isNaN(time)) {
      time = "(" + time + ")";
    } 
    if (StringUtils.isEmpty(time)) {
      time = "last30Days";
    }
        
    var context = {};
    context["model"] = this;
    context["pageName"] = "card";

    var filter = new CardListFilter();
      filter
      .withPagination(this.pageSize(query, "cards"), query["page"] ? (query["page"] - 1) : 0)
      .done();

    if (!StringUtils.isEmpty(searchFor)) {
      filter
        .withSearchText(searchFor)
        .done()
    }

    filter
          .withState("active")
          .done();

    if (!StringUtils.isEmpty(this._searchText)) {
      filter
        .withSearchText(this._searchText)
        .done()
    }

    var event = this.domain().events().companys().list(filter);
    if (this.isAdmin) {
      event.with("includeInvoiceSettings", true);
    }
    event.send(this.loadedItemsListener, context);
  }

  loadedItemsListener(responseEvent, context) {
    var model = context.model;
    if (responseEvent.error()) {
      model.withError(responseEvent.error());
    } else {
      var payload = responseEvent.payload();
      model._total = payload.count();
      var companyMap = new CompanyMap(model.domain(), payload.valuesFor(CompanyMap.MODEL_NAME));
      var cardMap = new CardMap(model.domain(), payload.valuesFor(CardMap.MODEL_NAME));
      var settingsMap = new InvoiceSettingsMap(model.domain(), payload.valuesFor(InvoiceSettingsMap.MODEL_NAME));
      model.update(model, companyMap, settingsMap, cardMap);
    }
    var query = model.panel().$route.query;
    model._pages = ContentUtils.PAGE_INDEXES(model._total, model.pageSize(query))
    model
      .withStateContentLoaded()
      .clearAndBuildTableData()
      .done();
    model.panel().showDetailsIfRequired();
  }

  update(model, companyMap, settingsMap, cardMap) {
    model._settingsMap = settingsMap;
    model._companyList = companyMap.sortByName();
    model._cardMap = cardMap;
  }

  company() {
    return this.domain().companys().findById(this._companyId);
  }

  findInvoiceSettings(settings) {
    return this._settingsMap.getSettings(settings.id());
  }

  findCard(card) {
    return this._cardMap.getCard(card.id());
  }

  hasFeatureSso() {
    return this.company().features().find().allowsSso();
  }
  
  companies() {
    return this._companyList;
  }

  totalItems() {
    return this._total;
  }

  pages() {
    return this._pages;
  }

}