<template>

    <Row cmpt="SpacerRow" :showBorder="showBorder">
      <Column class="rc-text-label" :class="alignment()">
        <br />
      </Column>
    </Row>

</template>

<script>

import { mapGetters } from 'vuex'

import Row from "@/portals/shared/library/box/Row.vue";
import Column from "@/portals/shared/library/box/Column.vue";

export default {
  name: 'portals-shared-library-spacer-spacer-row',
  components: {
    Row,
    Column,
  },
  props: {
    align: { type: String, default: "centre" },
    fontSize: { type: String, default: "medium" },
    cmpt: { type: String, default: "label" },
    showBorder: { type: Boolean, default: false },
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters([
                   'domain',
                 ]),
  },
  methods: {
    alignment: function() {
      let value = "";
      value = value + "rc-text-" + this.align;
      value = value + "rc-text-" + this.fontSize;
      return value;
    }
  },
}
</script>