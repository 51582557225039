
import RcTableViewRow from "@/portals/shared/cell/common/RcTableViewRow.js";

import Report from "@/domain/model/report/Report.js";

export default class ReportRow extends RcTableViewRow {

  static Id = "ReportRow";

  constructor() {
    super();
    this.put(RcTableViewRow.TypeId, ReportRow.Id);
  }

  withReport(report) {
    this.withObject(report.data);
    this.withId(report.id());
    this.withKey(report.modelName());
    return this;
  }

  report(domain) {
    return new Report(domain, this.object());
  }
}