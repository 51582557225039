
import BoxDisplayRow from "@/portals/shared/cell/box/BoxDisplayRow.js";

import ImageDisplayRow from "@/portals/shared/cell/image/ImageDisplayRow.js";
import StringDisplayRow from "@/portals/shared/cell/string/StringDisplayRow.js";
import StringUpdateRow from "@/portals/shared/cell/string/StringUpdateRow.js";
import Credentials from '@/domain/model/user/Credentials.js';


import RowDisplayRow from "@/portals/shared/cell/row/RowDisplayRow.js";
import SpacerDisplayRow from "@/portals/shared/cell/spacer/SpacerDisplayRow.js";

import ButtonDisplayRow from "@/portals/shared/cell/button/ButtonDisplayRow.js";

import MC from "@/domain/session/MC.js";
import WebLogger from "@/domain/logger/WebLogger";
import SignInRecoveryModel from "./SignInRecoveryModel.js";

export default class SignInRecoveryModelUtils {

  static buildRowData(domain, panel, tableData) {
    var au = new SignInRecoveryModelUtils(domain, panel, tableData);
    au.start();
  }

  constructor(panel, tableData) {
    this._panel = panel;
    this._tableData = tableData;
    this.MC = new MC();
    this._logger = new WebLogger(window.console, true);
  }

  panel() {
    return this._panel;
  }

  credentials() {
    var panel = this.panel();
    var model = panel.model();
    return model.credentials();
  }

  logger() {
    return this._logger;
  }

  start() {
    var model = this.panel().model();
    for (var spacers = 0; spacers < 10; spacers++) {
      this.addRow(new SpacerDisplayRow());
    }

    var signInOrWelcomeRow = new StringDisplayRow().withValue("Password Recovery").withAlignLeft().withStyle("rc-font-extra-large");

    var mainKids = [];
    var hint = model.error().hint;
    if (model.isStateSending()) {
      mainKids = [
        new SpacerDisplayRow(),
        new SpacerDisplayRow(),
        new StringDisplayRow().withValue(model.status()).withStyle("rc-font-large"),
      ];
    } else if (model.isStateDone()) {
      mainKids = [
        new SpacerDisplayRow(),
        new SpacerDisplayRow(),
        new StringDisplayRow()
          .withValue("If the email you specified is in our system you will receive a new password shortly.")
          .withStyle("rc-font-normal"),
        new RowDisplayRow()
          .withChildren([
            new BoxDisplayRow()
              .withWidth(5)
              .withChildren([
                new SpacerDisplayRow(),
              ]),
            new BoxDisplayRow()
              .withWidth(5)
              .withChildren([
                new ButtonDisplayRow()
                  .withWidth(5)
                  .withName("Back to Sign In")
                  .withField(SignInRecoveryModel.Fields.BackButton),
              ]),
            new BoxDisplayRow()
              .withWidth(5)
              .withChildren([
              ]),
          ]),
      ];
    
    } else {
      mainKids = [
        new StringDisplayRow().withValue("You will receive and email containing a temporary password. After signing in, you can navigate to the Gear tab and change it."),
        new StringUpdateRow().withName("Email").withMandatoryValue(true).withField(Credentials.FIELDS.Email).withDefaultValue(this.credentials().email()),
        new RowDisplayRow()
          .withChildren([
            new BoxDisplayRow()
              .withWidth(5)
              .withChildren([
                new ButtonDisplayRow().withWidth(5).withName("Back").withField(SignInRecoveryModel.Fields.BackButton),
              ]),
            new BoxDisplayRow()
              .withWidth(5)
              .withChildren([
                new SpacerDisplayRow(),
              ]),
            new BoxDisplayRow()
              .withWidth(5)
              .withChildren([
                new ButtonDisplayRow().withWidth(5).withName("Send").withBackgroundPrimaryAction().withField(SignInRecoveryModel.Fields.SendButton),                
              ]),
          ]),
      ];
    }

    this.addRow(new RowDisplayRow()
      .withChildren([

        new BoxDisplayRow()
          .withWidth(2)
          .withChildren([]),
        new BoxDisplayRow()
          .withWidth(11)
          .withPrimaryBackgroundColour()
          .withBorder(true)
          .withBorderStyleLarge()
          .withChildren([
            new SpacerDisplayRow(),
            new RowDisplayRow()
              .withChildren([
                new BoxDisplayRow()
                  .withWidth(1)
                  .withChildren([]),
                new BoxDisplayRow()
                  .withWidth(13)
                  .withChildren([
                    new StringDisplayRow().withValue(hint + " ").withStyleEven().withStyle("rc-title-error-left rc-font-regular-large"),
                  ]),
                new BoxDisplayRow()
                  .withWidth(1)
                  .withChildren([]),
              ]),
            new RowDisplayRow()
              .withChildren([
                new BoxDisplayRow()
                  .withWidth(1)
                  .withChildren([]),
                new BoxDisplayRow()
                  .withWidth(7)
                  .withChildren([
                    new ImageDisplayRow().withImageUrl("@/assets/2019/circlechek_logo.png"),
                    signInOrWelcomeRow,
                  ]),
                new BoxDisplayRow()
                  .withWidth(6)
                  .withChildren(
                    mainKids,
                  ),
                new BoxDisplayRow()
                  .withWidth(1)
                  .withChildren([]),
              ]),
            new SpacerDisplayRow(),
          ]),
        new BoxDisplayRow()
          .withWidth(2)
          .withChildren([]),
      ]));
  }

  addRow(row) {
    this._tableData.push(row);
  }
}