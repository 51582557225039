
import RcDomainMap from "../../common/RcDomainMap.js";
import Vehicle from './Vehicle.js';
import Utils from "./VehicleUtils.js";

export default class VehicleMap extends RcDomainMap {
  static MODEL_NAME = Vehicle.MODEL_NAME  + "Map";
  
  constructor(domain, data) {
    super(domain, data, VehicleMap.MODEL_NAME);
  }

  copy() {
    var data = this._copy();
    return new VehicleMap(this.domain, data);
  }
  
  find() {
    var keys = this.keys();
    var found = new VehicleMap(this.domain, {} );
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var data = this.domain.vehicles().findById(key);
      var item = new Vehicle(this.domain, data);
      if (!item.isNew()) {
        found.add(item);
      }
    }
    return found;
  }
  
  sortByName() {
    var list = this.list();
    return list.sort(Utils.SortByName);
  }
  
  list() {
    var values = [];
    var keys = this.keys();
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var item = this.getVehicle(key);
      values.push(item);
    }
    return values;
  }
  
  first() {
    var all = this.list();
    if (all.length > 0) {
      return all[0];
    }
    return new Vehicle(this.domain);
  }
  
  getVehicle(key) {
    var value = super.get(key);
    return new Vehicle(this.domain, value);
  }

  addVehicle(item) {
    this.add(item).done();
    return this;
  }
}