<template>

  <div :class="additional()" cmpt="Column" v-on:click="pressed">
    <slot />
  </div>

</template>

<script>

import { mapGetters } from 'vuex'

import StringUtils from "@/utils/StringUtils.js";

export default {
  name: 'portals-shared-library-box-column',
  props: {
    width:        { type: Number, default: 15 },
    borderStyle:  { type: String, default: "" },
    even:         { type: String, default: null },
  },
  data() {
    return {
    }
  },
  watch: {
  },
  computed: {
    ...mapGetters([
                 ]),
  },
  methods: {
    componentWidth: function() {
      const kids = this.$parent.$children;
      if (this.cmpt === "leftcolumn") {
        var aValue = "";
        if (aValue) {
          //
        }
      }
      if (!kids) {
        return "rc-box-col-" + this.width;
      }
      if (this.width != -1) {
        return "rc-box-col-" + this.width;
      }
      var number = kids.length;
      var index = kids.indexOf(this);
      var value = parseInt((15 / number).toFixed(0));
      if (number == 2 && index == 1) {
        value = 7;
      }
      if (number == 4 && index == (number - 2)) {
        value = 3;
      }
      if (number == 6 && index == 2) {
        value = 2;
      }
      if (number == 7 && index == (number - 2)) {
        value = 3;
      }
      if (number == 8 && index == (number - 2)) {
        value = 1;
      }
      if (number == 9 && index == 5) {
        value = 1;
      }
      if (number == 10 && index == 4) {
        value = 1;
      }
      if (number == 11 && index == (number - 2)) {
        value = 5;
      }
      if (number == 12 && index == (number - 2)) {
        value = 4;
      }
      if (number == 13 && index == (number - 2)) {
        value = 3;
      }
      if (number == 14 && index == (number - 2)) {
        value = 2;
      }
      return "rc-box-col-" + value + " ";
     },
     additional: function() {
      var value = this.componentWidth();
      if (this.even != null) {
        value += this.even == "true" ? " rc-border-thin-even " : " rc-border-thin ";
      } 
      if (!StringUtils.isEmpty(this.borderStyle)) {
        value += this.borderStyle + " ";
      }
      return value;
     },
    pressed: function() {
      this.$emit('click');
    },
  },
}
</script>