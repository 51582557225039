<template>
  <Box cmpt="Edit">
  
    <ErrorRow 
      :error="error" />
    <SuccessRow 
      :message="message" />

    <SavingDialog v-show="showSavingDialog"   @close="closeSavingDialog" />
    
    <Row v-if="!isLoading">
      <Column>
        <CompanyEdit
          :isAdmin="isAdmin"
          :companyData="company.data" 
          :isSaving="isSaving" 
          v-on:value="setValue"
          v-on:addressValue="setAddressValue"
          v-on:addressHqValue="setAddressHqValue"
          v-on:featureValue="setFeatureValue"
          />
      </Column>
    </Row>
    
    <SaveRow :left="5" :right="5" :disabled="false" v-on:value="save" v-if="!isLoading"/>
    
    <SpacerRow />
  </Box>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import ErrorRow   from '@/components/row/ErrorRow.vue';
import SuccessRow from '@/components/row/SuccessRow.vue';
import SaveRow    from '@/components/row/SaveRow.vue';

import ConstUtils   from '@/utils/ConstUtils.js';
import ContentUtils from '@/utils/ContentUtils.js';
import ConnectionUtils from '@/utils/ConnectionUtils.js';
import StringUtils  from '@/utils/StringUtils.js';

import MC from "@/domain/session/MC.js";

import CompanyEdit from '@/views/portal/companys/CompanyEdit.vue';

import Catelog from '@/domain/session/CanadianEnglish.js';
import Company from '@/domain/model/company/Company.js';

import Box from "@/portals/shared/library/box/Box.vue";
import Row from "@/portals/shared/library/box/Row.vue";
import Column from "@/portals/shared/library/box/Column.vue";

import SavingDialog from "@/portals/shared/views/saving/SavingDialog.vue";
import SpacerRow from "@/portals/shared/library/spacer/SpacerRow.vue";

export default {
  name: 'company-edit',
  props: {
    disabled: { type: Boolean, default: false },
    label:    { type: String,  default: 'Back' },
  },
  components: {
    Box, Row, Column,
    ErrorRow,
    SuccessRow, SpacerRow,
    CompanyEdit,
    SaveRow, SavingDialog,
  },
  data() {
    return {
      isSaving: false,
      error: null,
      message: null,
      kvps: {},
      addressKvps: null,
      addressHqKvps: null,
      featureKvps: null,
      showing: "details",
      catelog: Catelog.KEYS,

      isLoading: true,
      loadingMessage: null,
      showSavingDialog: false,
      
      MC: new MC(),
      Strings: StringUtils,
      Contents: ContentUtils,
      Consts: ConstUtils,
    }
  },
  computed: {
    ...mapGetters([
                   'auth_connected',
                   'auth_socket_status',
                   'signin_event',
                   'auth_client',
                   'domain',
                   ]),
    itemId: function() {
      return this.$route.params['id'];
    },
    data: function() {
      if (this.company && this.company.data) {
        return this.company.data;
      }
      return {};
    },
    company: function() {
      if (this.domain) {
        return this.domain.session().company();
      }
      return new Company(this.domain);
    },
    address: function() {
      return this.company.address().find();
    },
    addressHq: function() {
      return this.company.addressHq().find();
    },
    isAdmin() {
      var user = this.domain.session().user();
      var employees = this.domain.employees().findByUserId(user.id());
      var roles = employees.roles().find();
      return roles.isAdministrator();
    }
  },
  watch: {
    auth_connected() {
      ConnectionUtils.sendSignInEvent(this);
    },
    auth_socket_status() {
      ConnectionUtils.displayStatus(this);
    },
    signin_event() {
      ConnectionUtils.createDomainSession(this);
    }
  },
  mounted: function () {
    ConnectionUtils.ensureConnection(this);
  },
  methods: {
    ...mapActions([
                   'AuthStorage_updateCompany',
                   'addListenerAction',
                   'removeListenerAction',
                   'updateDomain',
                   ]),
    start: function() {
      this.isLoading = false;
      this.loadingMessage = this.MC.Status.Done.value();
    },
    callback: function(msg) {
      this.loadingMessage = msg;
    },
    setValue: function (kvp) {
      this.message = null;
      if (this.domain) {
        ContentUtils.set(this.kvps, kvp, this.domain.logger());
      }
    },
    setAddressValue: function(kvp) {
      this.message = null;
      if (!this.addressKvps) {
        this.addressKvps = {};
      }
      if (this.domain) {
        ContentUtils.set(this.addressKvps, kvp, this.domain.logger());
      }
    },
    setAddressHqValue: function(kvp) {
      this.message = null;
      if (!this.addressHqKvps) {
        this.addressHqKvps = {};
      }
      if (this.domain) {
        ContentUtils.set(this.addressHqKvps, kvp, this.domain.logger());
      }
    },
    setFeatureValue: function(kvp) {
      this.message = null;
      if (!this.featureKvps) {
        this.featureKvps = {};
      }
      if (this.domain) {
        ContentUtils.set(this.featureKvps, kvp, this.domain.logger());
      }
    },
    
    save: function () {
      this.isSaving = true;
      this.saving();
      ContentUtils.set(this.kvps, { 'key':ConstUtils.FIELDS.ID, 'value': this.company.id() })
      
      if (this.addressKvps) {
        this.addressKvps[ConstUtils.FIELDS.ID] = this.address.id();
        this.kvps[Company.FIELDS.ADDRESS] = this.addressKvps;
      }
      
      if (this.addressHqKvps) {
        this.addressHqKvps[ConstUtils.FIELDS.ID] = this.addressHq.id();
        this.kvps[Company.FIELDS.ADDRESS_HQ] = this.addressHqKvps;
      }
      
      if (this.featureKvps) {
        this.kvps[Company.FIELDS.FEATURES] = this.featureKvps[Company.FIELDS.FEATURES];
      }

      var event = this.domain.events().companySave(this.kvps);
      event.send(this.listener);
    },
    
    listener: function(event) {
      this.isSaving = false;
      this.showSavingDialog = false;
      if (event.error()) {
        this.error = event.error();
      } else {
        this.message = this.domain.lang().get(this.catelog.COMPANY.SAVE_SUCCESS);
        var payload = event.payload();
        var companyData = payload.valuesFor(Company.MODEL_NAME);
        var company = new Company(this.domain, companyData);
        if (company.id() === this.domain.session().company().id()) {
          let session = this.domain.session();
          session.with(company);
          session.companyName = company.name();
          this.AuthStorage_updateCompany(company);
        }
        this.$emit('companySaved', {'key': Company.MODEL_NAME, 'value': company.id()});
      }
    },
    
    saving: function() {
      this.showSavingDialog = true;
    },
    
    closeSavingDialog: function() {
     this.showSavingDialog = false;  
    },
  },
}
</script>
