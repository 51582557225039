import RcTableViewRow from "@/portals/shared/cell/common/RcTableViewRow.js";

import Employee from "@/domain/model/employee/Employee.js";
import User from "@/domain/model/user/User.js";

export default class EmployeeRow extends RcTableViewRow {
    
    static Id = "EmployeeRow";
    
    constructor() {
        super();
        this.put(RcTableViewRow.TypeId, EmployeeRow.Id);
    }
    
    withEmployee(employee, user) {
        this.withObject(employee.data);
        this.withId(employee.id());
        this.withField(employee.modelName());
        this.withKey(employee.modelName());
        this.put("user", user.data);
        return this;
    }

    withIsSelect(isSelect) {
        this.put("isSelect", isSelect);
        return this;
    }

    isSelect() {
        return this.getBoolean("isSelect");
    }
    
    employee(domain) {
        return new Employee(domain, this.object()); 
    }

    user(domain) {
        return new User(domain, this.get("user"));
    }
}