<template>

  <LabelRow v-if="ready" :labelWidth="row.labelWidth()" :fontSize="row.fontSize()" :align="row.align()">
    <template #space v-if="row.showLabel()"><br></template>
    <template #label>{{ Contents.decode(rowData.label()) }}</template>
    <template #hint>{{ Contents.decode(rowData.hint()) }}</template>
  </LabelRow>
  
</template>

<script>

import { mapGetters } from 'vuex'

import ContentUtils from '@/utils/ContentUtils.js';
import LabelRow from "@/portals/shared/library/label/LabelRow.vue";
import MC from "@/domain/session/MC.js";
import LabelDisplayRow from './LabelDisplayRow';

export default {
  name: 'label-display-cell',
  components: {
    LabelRow,
  },
  props: {
    css:    { type: String, default: "" },
    showMissingRequirements: { type: Boolean, default: false },
    data: null,
  },
  data() {
    return {
      ready: false,
      rowData: new LabelDisplayRow(),
      Contents: ContentUtils,
      MC: new MC(),
    }
  },
  computed: {
    ...mapGetters([
                   'domain',
                 ]),
    row: function() {
      return this.rowData;
    },
  },
  mounted: function () {
    this.rowData.data = this.data;
    this.ready = true;
  },
  methods: {
    withValue: function(kvp) {
      if (kvp) {
        kvp['id'] = this.row.id();
        kvp['object'] = this.row.object();
        kvp['field'] = this.row.field();
        kvp['key'] = this.row.key();
        this.$emit('withValue', kvp);
      }
    }
  },
}
</script>