<template>
  <ListView v-if="isReady" :adminDisplay="true" :isAdmin="true"></ListView>
</template>
<script>
import ListView from '../../../../customer/operator/views/checklist/list/ListView.vue';
import { mapActions, mapGetters } from 'vuex';
  
import ConnectionUtils from '@/utils/ConnectionUtils.js';
import ConstUtils from '@/utils/ConstUtils.js';
import ContentUtils from '@/utils/ContentUtils.js';
import StringUtils from '@/utils/StringUtils.js';
  
import MC from "@/domain/session/MC.js";

import Box from "@/portals/shared/library/box/Box.vue";
import Column from "@/portals/shared/library/box/Column.vue";
import Row from "@/portals/shared/library/box/Row.vue";
  
import Company from "@/domain/model/company/Company";

import CellFactory from "@/portals/shared/cell/CellFactory.vue";

export default {
  name: "portals-admin-views-schedule-list-view",
  components: {
    ListView,
    // CellFactory,
    // Box, Row, Column,
  },
  data() {
    return {
      redraw: 1,
      isAdmin: false,
      isReady: false,
      MC: MC,
      SU: StringUtils,
      CU: ContentUtils,
      CST: ConstUtils,
      CF: CellFactory,
      Box: Box,
      Row: Row,
      Column: Column,
    };
  },
  computed: {
    ...mapGetters([
                    'auth_connected',
                    'auth_socket_status',
                    'signin_event',
                    'auth_client',
                    'domain',
                    ]),
  },
  watch: {
    auth_connected() {
      ConnectionUtils.sendSignInEvent(this);
    },
    auth_socket_status() {
      ConnectionUtils.displayStatus(this);
    },
    signin_event() {
      ConnectionUtils.createDomainSession(this);
    },
  },
  mounted: function () {
    ConnectionUtils.ensureConnection(this);
  },
  methods: {
    ...mapActions([
                    'AuthStorage_updateCompany',
                    'addListenerAction',
                    'removeListenerAction',
                    'updateDomain',
                    ]),

    start: function() {
      let company = new Company(this.domain).withName("Administrator Portal");
      let session = this.domain.session();
      session.with(company);
      session.companyName = "Administrator Portal"
      this.AuthStorage_updateCompany(company);
      this.isReady = true;
    },
    displayProgress: function(msg) {
      this.callback(msg);
    },
    callback: function(msg) {
      if (msg) {
        //
      }
      this.paint();
    },
    paint: function() {
      this.redraw++;
    },

  }
}
</script>