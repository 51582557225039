
import RcTableViewRow from "@/portals/shared/cell/common/RcTableViewRow.js";

export default class LabelDisplayRow extends RcTableViewRow {

  static Id = "LabelDisplayRow";
  static FontSize = "fontSize";

  constructor() {
    super();
    this.put(RcTableViewRow.TypeId, LabelDisplayRow.Id);
    this.withFontSize("medium");
    this.withLabelWidth(15);
    this.withAlign("left");
    this.withShowLabel(false);
  }
  
  withAlignCentre() {
    return this.withAlign("centre");
  }
  
  withLabel(label) {
    this.withName(label).done();
    return this;
  }
  
  label() {
    return this.name();
  }
  
  fontSize() {
    return this.getString(LabelDisplayRow.FontSize);
  }

  withFontSize(fontSize) {
    this.put(LabelDisplayRow.FontSize, fontSize);
    return this;
  }

  withAlign(align) {
    this.put("align", align);
    return this;
  }
  
  align() {
    return this.getString("align");
  }

}