/*import Vue from 'vue'*/
import EventUtils from "@/utils/EventUtils.js";
import MapUtils from "@/utils/MapUtils.js";
import ConstUtils from "@/utils/ConstUtils.js";

const state = {
  list: [],
  active: [],
  inactive: [],
  map: {},
  total: 0,
  lastEvent: {}
};

const getters = {
  vehicles_found: (state) => state,
  vehicles_list: (state) => state.list,
  vehicles_active: (state) => state.active,
  vehicles_inactive: (state) => state.inactive,
  VehicleStorage_lastEvent: (state) => state.lastEvent,
};

const actions = {};

const mutations = {
  SOCKET_ONMESSAGE(state, event) {
    if (event.status == EventUtils.STATUS.COMPLETE) {
      if (event.statusCode == EventUtils.CODE.OK) {
        var inactiveList = [];
        var activeList = [];
        if (event.Payload && event.Payload.VehicleMap) {
          state.map = MapUtils.updateMap(event.Payload.VehicleMap, state.map);
          state.list = Object.values(state.map);
          state.list.forEach(function(item) {
            if (item.deleted == ConstUtils.VEHICLE.VALUES.DELETED_FALSE) {
              activeList.push(item);
            } else {
              inactiveList.push(item);
            }
          });
          if (event.Payload.count) {
            state.total = event.Payload.count;
          }
          state.inactive = inactiveList;
          state.active = activeList;
          state.lastEvent = event;
        }

        if (event.Payload && event.Payload.Vehicle) {
          let vehicle = event.Payload.Vehicle;
          state.map[vehicle[ConstUtils.FIELDS.ID]] = vehicle;
          state.list = Object.values(state.map);
          state.list.forEach(function(item) {
            if (item.deleted == ConstUtils.VEHICLE.VALUES.DELETED_FALSE) {
              activeList.push(item);
            } else {
              inactiveList.push(item);
            }
          });
          state.inactive = inactiveList;
          state.active = activeList;
          state.lastEvent = event;
        }
      }
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
