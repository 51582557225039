
import RcObject from "@/domain/common/RcObject.js";

export default class CardListFilter extends RcObject {
  
  static Fields = {
    PAGINATION: 'pagination',
    COMPONENTS: 'components',
    SEARCH_TEXT: 'searchText',
    SORT_BY: "sortBy",
    STATE_TYPE: 'state',
  }
  
  constructor() {
    super({});
  }
  
  withState(state) {
    this.put(CardListFilter.Fields.STATE_TYPE, state);
    return this;
  }

  withPagination(size, number) {
    this.put(CardListFilter.Fields.PAGINATION, { size, number });
    return this;
  }
  
  withSearchText(text) {
    this.put(CardListFilter.Fields.SEARCH_TEXT, { 'text': text });
    return this;
  }
  
  withSortBy(sortBy) {
    let direction = "asc";
    
    let newValue = sortBy.replace("Az", "");
    if (newValue.length < sortBy.length) {
      direction = "asc";
      this.put(CardListFilter.Fields.SORT_BY, { 'value': newValue, 'dir': direction });
    }
    
    newValue = sortBy.replace("Za", "");
    if (newValue.length < sortBy.length) {
      direction = "des";
      this.put(CardListFilter.Fields.SORT_BY, { 'value': newValue, 'dir': direction });
    }
    return this;
  }

  done() {
    //
  }
}