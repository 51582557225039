<template>
  <Row>
    <Column :width="15">
      <div id="detailsModal" class="rc-modal-dialog">
        <!-- Modal content -->
        <div class="rc-modal-content-dialog">
          <div class="rc-modal-header-dialog" :key="redraw">
            <Box>
              <WorkingDisplayRow v-if="this.showDialog == 'workingDialog'"></WorkingDisplayRow>
              <Row 
                v-for="(row, rowIndex) in viewHeaderData" 
                :key="rowIndex" >
                  <CellFactory 
                    :rowData="row.data"
                    :showMissingRequirements="showMissingRequirements"
                    v-on:withValue="withFieldValue"
                    v-on:withButtonPressed="withButtonPressed"
                    />
              </Row>
            </Box>
          </div>

          <div class="rc-modal-body-dialog" :key="redraw + 1">
            <div class="rc-modal-body-inner-dialog rc-border-thin">
              <Box>
                <Row 
                  v-for="(row, rowIndex) in viewBodyData" 
                  :key="rowIndex" >
                    <CellFactory 
                      :rowData="row.data"
                      :showMissingRequirements="showMissingRequirements"
                      v-on:withValue="withFieldValue"
                      v-on:withButtonPressed="withButtonPressed"
                      />
                </Row>
              </Box>
            </div>
          </div>

          <div class="rc-modal-footer-dialog" :key="redraw + 2">
            <Box>
              <Row 
                v-for="(row, rowIndex) in viewFooterData" 
                :key="rowIndex" >
                  <CellFactory 
                    :rowData="row.data"
                    :showMissingRequirements="showMissingRequirements"
                    v-on:withValue="withFieldValue"
                    v-on:withButtonPressed="withButtonPressed"
                    />
              </Row>
            </Box>
          </div>
        </div>
      </div>
    </Column>
  </Row>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';

import ConnectionUtils from '@/utils/ConnectionUtils.js';
import ConstUtils from '@/utils/ConstUtils.js';
import ContentUtils from '@/utils/ContentUtils.js';
import StringUtils from '@/utils/StringUtils.js';

import MC from "@/domain/session/MC.js";

import CellFactory from "@/portals/shared/cell/CellFactory.vue";
import Box from "@/portals/shared/library/box/Box.vue";
import Column from "@/portals/shared/library/box/Column.vue";
import Row from "@/portals/shared/library/box/Row.vue";

import BoxDisplayRow from "@/portals/shared/cell/box/BoxDisplayRow";
import ButtonDisplayRow from "@/portals/shared/cell/button/ButtonDisplayRow";
import ErrorDisplayRow from "@/portals/shared/cell/error/ErrorDisplayRow";
import RowDisplayRow from "@/portals/shared/cell/row/RowDisplayRow";
import SpacerDisplayRow from '@/portals/shared/cell/spacer/SpacerDisplayRow';
import StringDisplayRow from "@/portals/shared/cell/string/StringDisplayRow";
import StringUpdateRow from "@/portals/shared/cell/string/StringUpdateRow";
import TitleDisplayRow from "@/portals/shared/cell/title/TitleDisplayRow";
import TextAreaUpdateRow from '@/portals/shared/cell/textarea/TextAreaUpdateRow';
import DateUpdateRow from "@/portals/shared/cell/date/DateUpdateRow";

import ChecklistUpdateRow from "@/portals/shared/cell/checklist/update/ChecklistUpdateRow.js";
import PictureUpdateRow from "@/portals/shared/cell/picture/PictureUpdateRow.js";
import DefectTypeDisplayRow from "@/portals/shared/cell/defecttype/DefectTypeDisplayRow.js";
import StateDisplayRow from "@/portals/shared/cell/state/StateDisplayRow.js";

import Equipment from '@/domain/model/vehicle/Vehicle.js';
import ChecklistMap from '@/domain/model/checklist/CheckListMap.js';

export default {
  name: "equipment-list-add-view",
  components: {
    Column, Row, 
    Box,
    CellFactory,
  },
  props: {
    itemId: { type: String, default: "" },
  },
  data() {
    return {
      redraw: 1,
      redrawMain: 10000,

      MC: new MC(),
      Strings: StringUtils,
      Contents: ContentUtils,
      Consts: ConstUtils,

      showMissingRequirements: false,
      viewHeaderData: [],
      viewBodyData: [],
      viewFooterData: [],

      equipment: null,
      hasAddedEquipment: false,
      isUpdate: false,

      kvps: {},
      error: null,
      showDialog: null,
      StringUpdateRow: StringUpdateRow
    };
  },
  computed: {
    ...mapGetters([
                   'auth_connected',
                   'auth_socket_status',
                   'signin_event',
                   'auth_client',
                   'domain',
                   ]),
  },
  watch: {
    auth_connected() {
      ConnectionUtils.sendSignInEvent(this);
    },
    auth_socket_status() {
      ConnectionUtils.displayStatus(this);
    },
    signin_event() {
      ConnectionUtils.createDomainSession(this);
    }
  },
  mounted: function () {
    ConnectionUtils.ensureConnection(this);
  },
  methods: {
    ...mapActions([
                   'addListenerAction',
                   'removeListenerAction',
                   'updateDomain',
                   'AuthStorage_updateCompany',
                   ]),

    start: function() {
      this.equipment = new Equipment(this.domain);
      if (StringUtils.isNotEmpty(this.itemId)) {
        var id = ContentUtils.baseDecode(this.itemId);
        this.equipment = this.domain.vehicles().findById(id);
      }
      this.setup();
      this.mainScreen();
    },

    setup: function() {
      // window.onclick = this.onClick;
    },

    mainScreen() {
      this.viewHeaderData = [];
      this.viewBodyData = [];
      this.viewFooterData = [];

      if ( this.equipment.isNew() ) {
        this.viewHeaderData.push(new TitleDisplayRow().withTitle(StringUtils.format("Add Equipment")));
      } else {
        this.viewHeaderData.push(new TitleDisplayRow().withTitle(StringUtils.format("Update Equipment: {0}", [this.equipment.name()])));
        this.isUpdate = true;
      }

      let equipment = this.equipment;

      var errorBox = new BoxDisplayRow().withBorder(true)
                          .withChild(new RowDisplayRow()
                            .withChild(new SpacerDisplayRow().withWidth("1"))
                            .withChild(new ErrorDisplayRow().withWidth("13").withError(this.error))
                            .withChild(new SpacerDisplayRow().withWidth("1"))
                          );
      if (this.error) {
        this.viewBodyData.push(errorBox);
      }
      
      var image = equipment.images().find().first();
      let equipmentRow = new RowDisplayRow().withChild(new BoxDisplayRow()
                      .withWidth(10)
                      .withChild(new BoxDisplayRow().withBorder(true)
                            .withChild(new TitleDisplayRow().withSubtitle(true).withTitle("Details"))
                            .withChild(
                              new StringUpdateRow()
                              .withName("Name")
                              .withField(Equipment.FIELDS.NAME)
                              .withDefaultValue(equipment.name())
                              .withMandatoryValue(true)))
                      .withChild(new BoxDisplayRow()
                                      .withBorder(true)
                                      .withChild(
                                        new TitleDisplayRow()
                                          .withSubtitle(true)
                                          .withTitle("Checklist")
                                      )
                                      .withChild(
                                        new StringDisplayRow()
                                          .withValue("")
                                      )
                                      .withChild(
                                        new ChecklistUpdateRow()
                                          .withName("Checklists")
                                          .withField(Equipment.FIELDS.CHECKLIST_MAP)
                                          .withMandatoryValue(true)
                                          .withDefaultValue(equipment.checklists().find().first()),
                                      ))
                      .withChild(new BoxDisplayRow().withWidth(15).withBorder(true)
                                        .withChild(new TitleDisplayRow()
                                                        .withSubtitle(true)
                                                        .withTitle(this.MC.Vehicle.Add.NotesLabel.value()))
                                        .withChild(new StringDisplayRow()
                                                        .withAlignLeft()
                                                        .withValue("Add any additional information about this piece of equipment. This will not be displayed on the mobile app."))
                                        .withChild(new TextAreaUpdateRow()
                                                        .withName("Notes")
                                                        .withRows(4)
                                                        .withDefaultValue(equipment.notes())
                                                        .withField(Equipment.FIELDS.NOTES))))
                      .withChild(new BoxDisplayRow().withWidth(5)
                                        .withChild(new BoxDisplayRow()
                                                        .withBorder(true)
                                                        .withChild(new TitleDisplayRow().withSubtitle(true).withTitle("Picture"))
                                                        .withChild(new PictureUpdateRow()
                                                                        .withDefaultValue(image)
                                                                        .withField(Equipment.FIELDS.IMAGE_DATA)))
                                        .withChild(new BoxDisplayRow()
                                                      .withBorder(true)
                                                      .withChild(new TitleDisplayRow()
                                                                    .withTitle("State and Status")
                                                                    .withSubtitle(true))
                                                      .withChild(new DefectTypeDisplayRow()
                                                                    .withValue(equipment.defectType())
                                                                    .withLabel("Status"))
                                                      .withChild(new StateDisplayRow()
                                                                    .withValue(equipment.isNew() ? "active" : this.equipment.state())
                                                                    .withLabel("State"))));
      var startEndRow = new BoxDisplayRow()
        .withBorder(true)
        .withWidth(5)
        .withChildren([
          new TitleDisplayRow()
            .withSubtitle(true)
            .withTitle(this.MC.Vehicle.Add.ServiceStartAndEndTitle.value()),
          new DateUpdateRow()
            .withName(this.MC.Vehicle.Add.StartDateLabel.value())
            .withHint("The day the equipment started operations")
            .withDefaultValue(equipment.startDate())
            .withField(Equipment.FIELDS.START_DATE),
          new DateUpdateRow()
            .withName(this.MC.Vehicle.Add.EndDateLabel.value())
            .withHint("The day the equipment stopped operations")
            .withDefaultValue(equipment.endDate())
            .withField(Equipment.FIELDS.END_DATE),
        ]);

      var vinBox = new BoxDisplayRow()
        .withBorder(true)
        .withWidth(5)
        .withChild(new TitleDisplayRow()
                      .withSubtitle(true)
                      .withTitle("Serial Number and Last Safety Date"))
        .withChild(new DateUpdateRow()
                      .withName(this.MC.Vehicle.Add.Pmvi.value())
                      .withHint(this.MC.Vehicle.Add.PmviHint.value())
                      .withDefaultValue(equipment.annualDate())
                      .withField(Equipment.FIELDS.ANNUAL_DATE))
        .withChild(new StringUpdateRow()
                      .withName("Serial Number")
                      .withDefaultValue(equipment.vin())
                      .withField(Equipment.FIELDS.VIN));
      
      var odometerRow = new BoxDisplayRow()
        .withBorder(true)
        .withWidth(5)
        .withChildren([
          new TitleDisplayRow()
            .withSubtitle(true)
            .withTitle("Hours, Make, Model, Colour"),

          new StringUpdateRow()
            .withName("Hours")
            .withDefaultValue(equipment.odometer())
            .withField(Equipment.FIELDS.ODOMETER),

          new StringUpdateRow()
            .withName("Year")
            .withDefaultValue(equipment.year())
            .withField(Equipment.FIELDS.YEAR),

          new StringUpdateRow()
            .withName(this.MC.Vehicle.Add.Summary.value())
            .withHint("Common name for equipment")
            .withDefaultValue(equipment.summary())
            .withField(Equipment.FIELDS.SUMMARY),

          new StringUpdateRow()
            .withName(this.MC.Vehicle.Add.Make.value())
            .withHint("John Deere, Kubota, Honda etc")
            .withDefaultValue(equipment.make())
            .withField(Equipment.FIELDS.MAKE),

          new StringUpdateRow()
            .withName(this.MC.Vehicle.Add.Model.value())
            .withHint("S140, 2120, BX80 etc")
            .withDefaultValue(equipment.model())
            .withField(Equipment.FIELDS.MODEL),

          new StringUpdateRow()
            .withName(this.MC.Vehicle.Add.Colour.value())
            .withDefaultValue(equipment.colour())
            .withField(Equipment.FIELDS.COLOUR),

        ]);

      let bodyRow = new BoxDisplayRow().withBorder(true)
                      .withChild(equipmentRow)
                      .withChild(new RowDisplayRow()
                          .withChild(startEndRow)
                          .withChild(vinBox)
                          .withChild(odometerRow));
                          
      this.viewBodyData.push(bodyRow);

      let createSaveAnotherRow = new SpacerDisplayRow().withWidth("3");
      if (this.equipment.isNew()) {
        createSaveAnotherRow = new ButtonDisplayRow()
                                  .withWidth("3")
                                  .withName("Save and Add Another")
                                  .withField("SaveAdd")
                                  .withBackgroundPrimaryAction();
      }

      let footerRow = new BoxDisplayRow()
                  .withBorder(true)
                  .withChild(
                    new RowDisplayRow()
                      .withChild(new SpacerDisplayRow().withWidth("2"))
                      .withChild(new ButtonDisplayRow().withWidth("3").withName("Cancel").withField("Close"))
                      .withChild(new SpacerDisplayRow().withWidth("1"))
                      .withChild(createSaveAnotherRow)
                      .withChild(new SpacerDisplayRow().withWidth("1"))
                      .withChild(new ButtonDisplayRow().withWidth("3").withName("Save").withField("Save").withBackgroundPrimaryAction())
                      .withChild(new SpacerDisplayRow().withWidth("2"))
                  );
      this.viewFooterData.push(footerRow);
    },

    sendingScreen() {
      this.viewBodyData = [];
      this.viewFooterData = [];
      this.viewHeaderData = [];

      let bodyBox = new BoxDisplayRow().withChild(new RowDisplayRow()
                              .withChild(new SpacerDisplayRow().withWidth("2"))
                              .withChild(new BoxDisplayRow()
                                    .withWidth("11")
                                    .withChild(new StringDisplayRow()
                                                  .withValue("Sending"))
                                  )
                              .withChild(new SpacerDisplayRow().withWidth("2"))
                            );
      this.viewBodyData.push(bodyBox);
      this.paint();
    },

    doneScreen() {
      this.viewHeaderData = [];
      this.viewBodyData = [];
      this.viewFooterData = [];

      let bodyBox = new BoxDisplayRow().withChild(new RowDisplayRow()
                              .withChild(new SpacerDisplayRow().withWidth("2"))
                              .withChild(new BoxDisplayRow()
                                    .withWidth("11")
                                    .withChild(new StringDisplayRow()
                                                  .withMandatoryValue(true)
                                                  .withValue("Done!"))
                                  )
                              .withChild(new SpacerDisplayRow().withWidth("2"))
                            );

      this.viewBodyData.push(bodyBox);
      this.paint();
    },

    paint: function() {
      this.redraw++;
    },

    onClick: function(event) {
      var modal = document.getElementById("detailsModal");
      if (event.target == modal) {
        this.goBack();
      }
    },
    
    log() {
      return this.domain.logger();
    },

    resetMissingValues() {
      if (this.showMissingRequirements) {
        this.showMissingRequirements = false;
      }
    },

    withFieldValue: function(valueData) {
      this.resetMissingValues();
      
      let field = valueData["field"];
      let fieldValue = valueData["value"];

      if (field === Equipment.FIELDS.Checklists) {
        if (StringUtils.isNull(fieldValue)) {
          this.equipment.withChecklists(new ChecklistMap(this.domain));
        } else {
          var checklist = this.domain.checklists().findById(fieldValue);
          this.equipment.withChecklist(checklist.pointer()).done();
        }
      } else {
        this.equipment.with(valueData);
      }
      this.log().info("Dialog Value {0}", [JSON.stringify(this.equipment.data, null, 2)]);
    },
    
    withButtonPressed(buttonData) {
      if (!buttonData) {
        return;
      }
      if (buttonData["field"] === "Close") {
        buttonData["refresh"] = "false";
        if (this.hasAddedEquipment) {
          buttonData["refresh"] = "true";
        }
        this.$emit("withButtonPressed", buttonData);
      }

      if (buttonData["field"] === "Save") {
        if (!this.areRequirementsMet()) {
          this.showMissingRequirements = true;
        } else {
          this.doSendAction(false);
        }
      }

      if (buttonData["field"] === "SaveAdd") {
        if (!this.areRequirementsMet()) {
          this.showMissingRequirements = true;
        } else {
          this.doSendAction(true);
        }
      }
    },

    doSendAction(repeat) {
      this.resetMissingValues();
      if (!this.areRequirementsMet()) {
        this.showMissingRequirements = true;
        return;
      }
      this.sendingScreen();
      var context = {
        view: this
      };

      this.equipment.withCompany(this.domain.session().company().pointer());
      this.equipment.with_Category("equipment");
      this.equipment.withStateActive();
      
      var event = this.domain.events().vehicleSave(this.equipment.data)
      if (repeat) {
        event.send(this.savedAnotherListener, context);
      } else {
        event.send(this.savedListener, context);
      }
    },
    
    savedAnotherListener(notification, context) {
      this.doneScreen();
      if (!notification || !context) {
        return;
      }
      if (notification.error()) {
        this.error = notification.error();
        this.mainScreen();
        return;
      }
      this.hasAddedEquipment = true;
      this.equipment = new Equipment(this.domain);
      this.equipmentId = "";
      this.start();
    },

    savedListener(notification, context) {
      this.doneScreen();
      if (!notification || !context) {
        return;
      }
      if (notification.error()) {
        this.error = notification.error();
        this.mainScreen();
        return;
      }

      var buttonData = {};
      buttonData["field"] = "Close";

      if (!this.isUpdate) {
        buttonData["refresh"] = "true";
      }
      this.$emit("withButtonPressed", buttonData);
    },

    displayProgress: function(msg) {
      this.callback(msg);
    },

    callback: function(msg) {
      if (msg) {
        //
      }
    },

    goBack: function() {
      this.$router.back();
    },

    areRequirementsMet() {
      var met = true;
      var equipment = this.equipment;

      if (StringUtils.isEmpty(equipment.name())) {
        met = false;
      }

      if (met && equipment.checklists().isEmpty()) {
        met = false
      }
      return met;
    },

  },
}
</script>