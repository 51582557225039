<template>
    <Box>
  
      <Row v-if="isAdmin">
        <Column :width="15">
          <div class="rc-text-centre">
          Admin View - Compliance
          </div>
        </Column>
      </Row>
      
      <TimesheetDisplayRow v-if="this.showDialog == 'timesheetDetailsDialog'" :timesheetId="this.selectedId" v-on:withButtonPressed="withButtonPressed" v-on:withValue="withValue"></TimesheetDisplayRow>
      <InspectionDisplayRow v-if="this.showDialog == 'inspectionDetailsDialog'" :inspectionId="this.selectedId" v-on:withButtonPressed="withButtonPressed" v-on:withValue="withValue"></InspectionDisplayRow>
      <InspectionEmailRow v-if="this.showDialog == 'inspectionEmailDialog'" :inspectionId="this.selectedId" v-on:withButtonPressed="withButtonPressed" v-on:withValue="withValue"></InspectionEmailRow>
      <InspectionRemarksRow v-if="this.showDialog == 'inspectionRemarkDialog'" :inspectionId="this.selectedId" v-on:withButtonPressed="withButtonPressed" v-on:withValue="withValue"></InspectionRemarksRow>
      <UserUpdateRow v-if="this.showDialog == 'userUpdateDialog'" :userId="this.selectedId" v-on:withButtonPressed="withButtonPressed" v-on:withValue="withValue"></UserUpdateRow>
      <RemindDialogRow v-if="this.showDialog == 'userRemindDialog'" :userId="this.selectedId" :time="selectedTime" v-on:withButtonPressed="withButtonPressed" v-on:withValue="withValue"></RemindDialogRow>
      <HideConfirmDialogRow v-if="this.showDialog == 'userHideDialog'" :userId="this.selectedId" v-on:withButtonPressed="withButtonPressed" v-on:withValue="withValue"></HideConfirmDialogRow>
      <IncludeConfirmDialogRow v-if="this.showDialog == 'userIncludeDialog'" :userId="this.selectedId" v-on:withButtonPressed="withButtonPressed" v-on:withValue="withValue"></IncludeConfirmDialogRow>
      <WorkingDisplayRow v-if="this.showDialog == 'workingDialog'" :itemId="this.selectedId" v-on:withButtonPressed="withButtonPressed" v-on:withValue="withValue" :isAdmin="isAdmin"></WorkingDisplayRow>

      <Row>
        <Column :width="15">
          <Box class="rc-border-thin" :key="redraw + 1">
            <Row v-for="(row, rowIndex) in headerData" :key="rowIndex" >
              <CellFactory 
                :rowData="row.data"
                :showMissingRequirements="showMissingRequirements"
                v-on:withEnterPressed="withEnterPressed"
                v-on:withValue="withValue"
                v-on:withButtonPressed="withButtonPressed"
                  />
            </Row>
          </Box>
        </Column>
      </Row>

      <Row >
        <Column :width="15" >
          <Box :key="redraw" class="full-height-scrollable-div rc-border-thin">
            <Row v-for="(row, rowIndex) in viewData" :key="rowIndex" >
              <CellFactory 
                :rowData="row.data"
                :showMissingRequirements="showMissingRequirements"
                v-on:withEnterPressed="withEnterPressed"
                v-on:withValue="withValue"
                v-on:withButtonPressed="withButtonPressed"
                  />
            </Row>
          </Box>
        </Column>
      </Row>
  
      <Row>
        <Column :width="15">
          <Box class="rc-border-thin" :key="redraw + 2">
            <Row v-for="(row, rowIndex) in footerData" :key="rowIndex" >
              <CellFactory 
                :rowData="row.data"
                :showMissingRequirements="showMissingRequirements"
                v-on:withEnterPressed="withEnterPressed"
                v-on:withValue="withValue"
                v-on:withButtonPressed="withButtonPressed"
                  />
            </Row>
          </Box>
        </Column>
      </Row>
  
    </Box>
    
  </template>
  
  <script>
  
import { mapActions, mapGetters } from 'vuex';
  
import ConnectionUtils from '@/utils/ConnectionUtils.js';
import ConstUtils from '@/utils/ConstUtils.js';
import ContentUtils from '@/utils/ContentUtils.js';
import StringUtils from '@/utils/StringUtils.js';
  
import MC from "@/domain/session/MC.js";

import Box from "@/portals/shared/library/box/Box.vue";
import Column from "@/portals/shared/library/box/Column.vue";
import Row from "@/portals/shared/library/box/Row.vue";
  
import ListViewModel from "./ListViewModel.js";
import ListViewUtils from "./ListViewUtils.js";
  
import CellFactory from "@/portals/shared/cell/CellFactory.vue";

import TimesheetDisplayRow from './timesheet/details/DetailsDisplayRow.vue';
import InspectionDisplayRow from './inspection/details/DetailsDisplayRow.vue';
import UserUpdateRow from "./user/update/UserUpdateRow.vue";
import InspectionEmailRow from "./inspection/email/EmailDisplayRow.vue";
import InspectionRemarksRow from "./inspection/remark/RemarksDisplayRow.vue";
import WorkingDisplayRow from '@/portals/shared/views/working/WorkingDisplayRow.vue';
import HideConfirmDialogRow from './hide/HideConfirmDialogRow.vue';
import RemindDialogRow from './remind/RemindDialogRow.vue';
import IncludeConfirmDialogRow from "./include/IncludeConfirmDialogRow.vue";

export default {
    name: "compliance-list-view",
    components: {
      Column, Box, Row,
      CellFactory,
      UserUpdateRow,
      TimesheetDisplayRow,
      InspectionDisplayRow,
      InspectionEmailRow,
      InspectionRemarksRow,
      HideConfirmDialogRow,
      IncludeConfirmDialogRow,
      RemindDialogRow,
      WorkingDisplayRow,
    },
    props: {
    },
    data() {
      return {
        redraw: 1,
        isAdmin: false,
        
        MC: new MC(),
        Strings: StringUtils,
        Contents: ContentUtils,
        Consts: ConstUtils,
        
        CellFactory: CellFactory,
        
        viewModel: null,
        viewData: [],
        footerData: [],
        headerData: [],

        showMissingRequirements: false,
        selectedId: "",
        selectedTime: "",
        showDialog: "",

        ListViewModel: ListViewModel,
        ListViewUtils: ListViewUtils,
      };
    },
    computed: {
      ...mapGetters([
                     'auth_connected',
                     'auth_socket_status',
                     'signin_event',
                     'auth_client',
                     'domain',
                     ]),
    },
    watch: {
      auth_connected() {
        ConnectionUtils.sendSignInEvent(this);
      },
      auth_socket_status() {
        ConnectionUtils.displayStatus(this);
      },
      signin_event() {
        ConnectionUtils.createDomainSession(this);
      },
      $route (to, from){
        if (to && from) {
          if (to.query["action"] === "timesheetDetailsDialog") {
            this.showDialog = "timesheetDetailsDialog";
            this.selectedId = to.query["timesheetId"];

          } else if (to.query["action"] === "inspectionDetailsDialog") {
            this.showDialog = "inspectionDetailsDialog";
            this.selectedId = to.query["inspectionId"];

          } else if (to.query["action"] === "inspectionEmailDialog") {
            this.showDialog = "inspectionEmailDialog";
            this.selectedId = to.query["inspectionId"];

          } else if (to.query["action"] === "inspectionRemarkDialog") {
            this.showDialog = "inspectionRemarkDialog";
            this.selectedId = to.query["inspectionId"];

          } else if (to.query["action"] === "userUpdateDialog") {
            this.selectedId = to.query["userId"];
            this.showDialog = "userUpdateDialog";
            
          } else if (to.query["action"] === "userRemindDialog") {
            this.selectedId = to.query["userId"];
            this.selectedTime = to.query["startTime"];
            this.showDialog = "userRemindDialog";

          } else if (to.query["action"] === "userHideDialog") {
            this.selectedId = to.query["userId"];
            this.showDialog = "userHideDialog";

          } else if (to.query["action"] === "userIncludeDialog") {
            this.selectedId = to.query["userId"];
            this.showDialog = "userIncludeDialog";

          } else if (to.query["action"] === "displayDialog") {
            this.selectedId = to.query["checklistId"];
            this.showDialog = "displayDialog";

          } else if (to.query["action"] === "workingDialog") {
            this.showDialog = "workingDialog";

          } else {
            let refresh = false;

            var toQuery = Object.assign({}, to.query);
            var fromQuery = Object.assign({}, from.query);
            var action = fromQuery["action"];

            delete toQuery.action;
            delete toQuery.equipmentId;

            delete fromQuery.action;
            delete fromQuery.equipmentId;

            if (action == "userIncludeDialog") {
              refresh = true;
            }
            
            if (JSON.stringify(toQuery, null, 0) !== JSON.stringify(fromQuery, null, 0)) {
              refresh = true;
            }

            if (StringUtils.isNotEmpty(this.showDialog)) {
              this.showDialog = ""; 
            } 

            if (refresh) {
              this.reloadPage();
            }
          }
        }
      }
    },
    mounted: function () {
      this.viewModel = new ListViewModel(this);
      this.viewModel.start();
      ConnectionUtils.ensureConnection(this);
    },
    methods: {
      ...mapActions([
                     'AuthStorage_updateCompany',
                     'addListenerAction',
                     'removeListenerAction',
                     'updateDomain',
                     ]),
  
      start: function() {
        this.viewModel.withStateContentLoading();
        this.viewModel.clearAndBuildTableData();
        this.viewData = this.viewModel.viewData();
        this.paint();
        this.viewModel.loadItems();
      },
  
      displayProgress: function(msg) {
        this.callback(msg);
      },

      callback: function(msg) {
        var row = this.viewModel.findRowProgress();
        if (row != null) {
          row.withMessage(msg);
        }
        this.paint();
      },
      
      model: function() {
        return this.viewModel;
      },
      
      showDialogIfRequired: function() {
        var query = Object.assign({}, this.$route.query);
        if (query["action"]) {
          this.showDialog = query["action"];
          var id = query["checklistId"];
          if (StringUtils.isNotEmpty(id)) {
            this.selectedId = query["checklistId"];
          }
          id = query["timesheetId"];
          if (StringUtils.isNotEmpty(id)) {
            this.selectedId = query["timesheetId"];
          }
          id = query["inspectionId"];
          if (StringUtils.isNotEmpty(id)) {
            this.selectedId = query["inspectionId"];
          }
          id = query["userId"];
          if (StringUtils.isNotEmpty(id)) {
            this.selectedId = query["userId"];
          }
        } 
      },

      urlQueryParams() {
        var query = Object.assign({}, this.$route.query);
        if (query == null) {
          query = {};
        }
        return query;
      },

      addQueryParams(valueData, key) {
        var query = this.urlQueryParams();
        this.addQueryParamsToRouter(query, valueData, key);
      },

      addQueryParamsToRouter(query, valueData, key) {
        const value = valueData["value"];
        if (value && value != "null") {
          query[key] = value;
          if (key === "pageSize") {
            delete query.page
          }
        } else {
          delete query[key];
        }
        this.$router.push({ query });
      },

      withValue: function(valueData) {
        this.model().withValue(valueData);
        
        if (valueData["field"] === "PageSize") {
          this.model().withPageSize(valueData, "compliance");
          this.addQueryParams(valueData, "pageSize");
        }
        
        if (valueData["field"] === "PageIndex") {
          this.addQueryParams(valueData, "page");
        }
        
        if (valueData["field"] === "Search") {
          this.model().withSearchText(valueData);
        }
      },
      
      withEnterPressed(keyData) {
        if (keyData["title"]) {
          keyData["field"] = "Search"
          this.withButtonPressed(keyData);
        }
      },

      withButtonPressed: function(buttonData) {
        this.model().withButtonPressed(buttonData);

        if (buttonData["field"] === "Add") {
          const query = this.urlQueryParams();
          query["action"] = "addDialog";
          this.$router.push({ query });
        }
        
        if (buttonData["field"] === "Update") {
          const query = this.urlQueryParams();
          query["action"] = "updateDialog";
          query["checklistId"] = this.Contents.baseEncode(buttonData["id"]),
          this.$router.push({ query });
        }

        if (buttonData["field"] === "Details") {
          const query = this.urlQueryParams();
          query["action"] = "detailsDialog";
          query["checklistId"] = this.Contents.baseEncode(buttonData["id"]),
          this.$router.push({ query });
        }

        if (buttonData["field"] === "Refresh") {
          this.reloadPage();
        }

        if (buttonData["field"] === "NextPage") {
          const query = this.urlQueryParams();
          if (query.page) {
            query.page = (Number(query.page) + 1) + "";
          } else {
            query.page = "2";
          }
          if (query.page <= this.model().pages().length) {
            this.$router.push({ query });
          }
        }

        if (buttonData["field"] === "PreviousPage") {
          const query = this.urlQueryParams();
          if (query.page) {
            query.page = (Number(query.page) - 1) + "";
            if (query.page <= 0) {
              query.page = "1";
            }
            this.$router.push({ query });
          }
        }

        if (buttonData["field"] === "Close" || buttonData["field"] === "DetailsClose") {
          let query = this.urlQueryParams();
          query = this.deleteDetails(query);
          this.$router.push({ query });
          if (buttonData["removed"] || buttonData["refresh"]) {
            this.reloadPage();
          }
        }

        if (buttonData["field"] === "DisplayDialog") {
          if (buttonData["object"] === "Timesheet") {
            const query = this.urlQueryParams();
            query["action"] = "timesheetDetailsDialog";
            query["timesheetId"] = this.Contents.baseEncode(buttonData["id"]),
            this.$router.push({ query });
          }
          if (buttonData["object"] === "Inspection") {
            const query = this.urlQueryParams();
            query["action"] = "inspectionDetailsDialog";
            query["inspectionId"] = this.Contents.baseEncode(buttonData["id"]),
            this.$router.push({ query });
          }
          if (buttonData["object"] === "User") {
            const query = this.urlQueryParams();
            query["action"] = "userUpdateDialog";
            query["userId"] = this.Contents.baseEncode(buttonData["id"]),
            this.$router.push({ query });
          }
          if (buttonData["object"] === "Hide") {
            const query = this.urlQueryParams();
            query["action"] = "userHideDialog";
            query["userId"] = this.Contents.baseEncode(buttonData["id"]),
            this.$router.push({ query });
          }
          if (buttonData["object"] === "Include") {
            const query = this.urlQueryParams();
            query["action"] = "userIncludeDialog";
            query["userId"] = this.Contents.baseEncode(buttonData["id"]),
            this.$router.push({ query });
          }
          if (buttonData["object"] === "Remind") {
            const query = this.urlQueryParams();
            query["action"] = "userRemindDialog";
            query["userId"] = this.Contents.baseEncode(buttonData["id"]),
            query["startTime"] = this.viewModel.startTime().time();
            this.$router.push({ query });
          }
        }

        if (buttonData["field"] === "Email") {
          const query = this.urlQueryParams();
          query["action"] = "inspectionEmailDialog";
          query["inspectionId"] = this.Contents.baseEncode(buttonData["id"]),
          this.$router.push({ query });
        }

        if (buttonData["field"] === "Remarks") {
          const query = this.urlQueryParams();
          query["action"] = "inspectionRemarkDialog";
          query["inspectionId"] = this.Contents.baseEncode(buttonData["id"]),
          this.$router.push({ query });
        }

        if (buttonData["field"] === "Repair") {
          var repairParams = {
            id: buttonData["id"],
          };
          this.$router.push({
            name: ConstUtils.ROUTES.INSPECTION_REPAIRS,
            params: repairParams,
          });
        }

        if (buttonData["field"] === "Type") {
          this.addQueryParams(buttonData, "type");
        }

        if (buttonData["field"] === "Clear") {
          this.model().withSearchText("");
          buttonData["value"] = "";
          this.addQueryParams(buttonData, "search");
        }

        if (buttonData["field"] === "Search") {
          buttonData["value"] = this.model().searchText();
          const queryParams = this.urlQueryParams();
          delete queryParams["page"];
          this.addQueryParamsToRouter(queryParams, buttonData, "search");
        }

        
        if (buttonData["field"] === "Decrease") {
          let day = this.viewModel._startFilterDate.day().minus(14);
          buttonData["value"] = day.time();
          this.addQueryParams(buttonData, "time");
        }

        if (buttonData["field"] === "Increase") {
          let day = this.viewModel._startFilterDate.day().plus(14);
          buttonData["value"] = day.time();
          this.addQueryParams(buttonData, "time");
        }

        if (buttonData["field"] === "Reset") {
          const query = this.urlQueryParams();
          delete query.time;
          this.$router.push({ query });
        }

        if (buttonData["field"] === "Labels") {
          const query = this.urlQueryParams();
          if (query.labels) {
            buttonData["value"] = false;
          } else {
            buttonData["value"] = true;
          }
          this.addQueryParams(buttonData, "labels");
        }
      },
      
      deleteDetails(query) {
        delete query.action;
        delete query.vehicleId;
        delete query.timesheetId;
        delete query.inspectionId;
        delete query.userId;
        delete query.time;
        delete query.startTime;
        delete query.labels;
        return query;
      },

      reloadPage: function() {
        this.viewModel.withStateContentLoading();
        this.viewModel.clearAndBuildTableData();
        this.viewModel.loadItems();
      },

      paint: function() {
        this.redraw++;
      },

    },
  }
</script>