<template>
    <Row v-if="ready" borderStyle="rc-border-thin-even"> 
        <Column :width="15" :key="redraw">
            <Box :even="row.even()" :backgroundColour="row.even() ? 'rowEven' : 'rowOdd'">
                <Row>
                    <Column :width="8">
                        <LabelValueBox>
                            <template slot="label">
                                Date
                            </template>
                            <template slot="value">
                                {{ timesheet.dateDisplay() }}
                            </template>
                        </LabelValueBox>
                    </Column>
                    <Column :width="7">
                        <LabelValueBox :labelWidth="5">
                            <template slot="label">
                                On Duty Driving
                            </template>
                            <template slot="value">
                                {{ timesheet.onDutyDrivingHoursDisplay() }}
                            </template>
                        </LabelValueBox>
                    </Column>
                </Row>
                <Row>
                    <Column :width="8">
                        <LabelValueBox>
                            <template slot="label">
                                User
                            </template>
                            <template slot="value">
                                {{ timesheet.employee().find().user().find().fullName() }}
                            </template>
                        </LabelValueBox>
                    </Column>
                    <Column :width="7">
                        <LabelValueBox :labelWidth="5">
                            <template slot="label">
                                On Duty Not Driving
                            </template>
                            <template slot="value">
                                {{ timesheet.onDutyNotDrivingHoursDisplay() }}
                            </template>
                        </LabelValueBox>
                    </Column>
                </Row>
                <Row>
                    <Column :width="5">
                        <Box>
                            <StatusCell :data="timesheetStatusRow().data" />
                        </Box>
                    </Column>
                    <Column :width="10"></Column>
                </Row>
                <Row>
                    <Column :width="8">
                        <LabelValueBox>
                            <template slot="label">
                                Start Location
                            </template>
                            <template slot="value">
                                {{ this.Content.decodeText(timesheet.locationStart(), "") }}
                            </template>
                        </LabelValueBox>
                    </Column>
                    <Column :width="7">
                        <LabelValueBox :labelWidth="5">
                            <template slot="label">
                                On Duty Total
                            </template>
                            <template slot="value">
                                {{ timesheet.onDutyHoursDisplay() }}
                            </template>
                        </LabelValueBox>
                    </Column>
                </Row>
                <Row>
                    <Column :width="8">
                        <LabelValueBox>
                            <template slot="label">
                                End Location
                            </template>
                            <template slot="value">
                                {{ this.Content.decodeText(timesheet.locationStart()) }}
                            </template>
                        </LabelValueBox>
                    </Column>
                    <Column :width="7">
                        <LabelValueBox :labelWidth="5">
                            <template slot="label">
                                Off Duty Total
                            </template>
                            <template slot="value">
                                {{ timesheet.offDutyHoursDisplay() }}
                            </template>
                        </LabelValueBox>
                    </Column>
                </Row>
                <Row>
                    <Column :width="8">
                        <LabelValueBox>
                            <template slot="label">
                                Cycle
                            </template>
                            <template slot="value">
                                {{ timesheet.cycleDisplay() }}
                            </template>
                        </LabelValueBox>
                    </Column>
                    <Column :width="7">
                        <LabelValueBox :labelWidth="5">
                            <template slot="label">
                                Total Hours
                            </template>
                            <template slot="value">
                                {{ timesheet.totalHoursDisplay() }}
                            </template>
                        </LabelValueBox>
                    </Column>
                </Row>
                <Row>
                    <Column :width="8">
                        <LabelValueBox>
                            <template slot="label">
                                Time Zone
                            </template>
                            <template slot="value">
                                {{ timesheet.timeZone() }}
                            </template>
                        </LabelValueBox>
                    </Column>
                    <Column :width="7"></Column>
                </Row>
                <Row>
                    <Column :width="12"></Column>
                    <Column :width="1">
                        <Button v-on:click="withDetailsButtonPressed">Details</Button>
                    </Column>
                    <Column :width="2">
                        <Button v-on:click="withPrintButtonPressed">Print/Download</Button>
                    </Column>
                </Row>
                <!-- Admin section -->
                <Row v-if="row.isAdmin()">
                    <Column :width="15">
                        <Box :even="row.even()">
                            <Row>
                                <Column>
                                    <LabelValueBox :labelWidth="4">
                                    <template slot="label">
                                        Admin Section
                                    </template>
                                    <template slot="value">
                                        {{ "" }}
                                    </template>
                                    </LabelValueBox>
                                </Column>
                            </Row>
                        <Row>
                            <Column :width="5">
                                <LabelValueBox :labelWidth="5">
                                <template slot="label">
                                    Company Name
                                </template>
                                <template slot="value">
                                    {{ this.Content.decodeText(company.name(), "[Not Set]") }} {{ company.id() }}
                                </template>
                                </LabelValueBox>
                            </Column>
                        </Row>

                        <Row> <!-- Created and Modified By/On Row -->
                            <Column :width="5">
                                <LabelValueBox :labelWidth="4">
                                <template slot="label">
                                    Modified By/On
                                </template>
                                <template slot="value">
                                    {{ timesheet.modifiedBy().find().fullName() }}
                                    {{ timesheet.modifiedDateV2().displayAs_YYYY_MM_DD_HH_MM_SS_A() }}
                                </template>
                                </LabelValueBox>
                            </Column>
                            <Column :width="5">
                                <LabelValueBox :labelWidth="4">
                                <template slot="label">
                                    Created By/On
                                </template>
                                <template slot="value">
                                    {{ timesheet.createdBy().find().fullName() }}
                                    {{ timesheet.createdDateV2().displayAs_YYYY_MM_DD_HH_MM_SS_A() }}
                                </template>
                                </LabelValueBox>
                            </Column>
                            <Column :width="5">
                                <LabelValueBox :labelWidth="5">
                                <template slot="label">
                                    TImesheet Id
                                </template>
                                <template slot="value">
                                    {{ timesheet.id() }}
                                </template>
                                </LabelValueBox>
                            </Column>
                        </Row>
                        <Row>
                        </Row>

                        <Row> <!-- Button Row-->
                        <Column :width="9"></Column>
                        <Column :width="1">
                        </Column>
                        <Column :width="1">
                        </Column>
                        <Column :width="2">
                            <Button v-on:click="withUpdatePdfButtonPressed">Update PDF</Button>
                        </Column>
                        <Column :width="2">
                            <Button v-on:click="withSelectButtonPressed">Select</Button>
                        </Column>
                        </Row><!-- End Button Row-->
                    </Box>
                    </Column>
                </Row>
              <!-- Admin section -->
            </Box>
        </Column>
    </Row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import ConnectionUtils from '@/utils/ConnectionUtils.js';
import ConstUtils from '@/utils/ConstUtils.js';
import ContentUtils from '@/utils/ContentUtils.js';
import StringUtils from '@/utils/StringUtils.js';
  
import MC from "@/domain/session/MC.js";

import Box from "@/portals/shared/library/box/Box.vue";
import Column from "@/portals/shared/library/box/Column.vue";
import Row from "@/portals/shared/library/box/Row.vue";

import LabelValueBox from "@/portals/shared/library/labelvalue/LabelValue.vue";
import Button from "@/portals/shared/library/button/Button.vue";

import StatusCell from '@/portals/shared/cell/status/StatusDisplayCell';
import StatusDisplayRow from '@/portals/shared/cell/status/StatusDisplayRow';
import TimesheetRow from './TimesheetRow';

export default {
    name: 'timesheet-cell',
    components: {
        Box, Column, Row,
        LabelValueBox, Button,
        StatusCell,
    },
    props: {
        showMissingRequirements: { type: Boolean, default: false },
        isAdmin: { type: Boolean, default: true },
        data: { type: Object, default: null },
    },
    data() {
        return {
            MC: new MC(),
            Connections: ConnectionUtils,
            Consts: ConstUtils,
            Content: ContentUtils,
            Strings: StringUtils,

            row: new TimesheetRow(),
            timesheet: null,
            company: null,
            ready: false,
            redraw: 1,
        }
    },
    computed: {
    ...mapGetters([
                    'auth_connected',
                    'auth_socket_status',
                    'signin_event',
                    'auth_client',
                    'domain',
                    'TimesheetStorage_lastEvent',
                    ]),
    },
    watch: {
        EquipmentStorage_lastEvent() {
        let timesheet = this.domain.timesheets().findById(this.timesheet.id());
            if (this.timesheet.modifiedDate() < timesheet.modifiedDate()) {
                this.row.withTimesheet(timesheet);
                this.timesheet = this.row.timesheet(this.domain);
                this.company = this.timesheet.company().find();
                this.redraw++;
            }
        }
    },

    mounted: function () {
        this.row.data = this.data;
        this.timesheet = this.row.timesheet(this.domain);
        this.company = this.timesheet.company().find();
        this.ready = true;
    },

    methods: {
    ...mapActions([
                'AuthStorage_updateCompany',
                'addListenerAction',
                'removeListenerAction',
                'updateDomain',
                ]),

    withEnterPressed(keyData) {
        this.$emit('withEnterPressed', keyData);
    },

    withDetailsButtonPressed() {
        let data = {};
        data["field"] = "Details";
        this.withButtonPressed(data);
    },

    withPrintButtonPressed() {
        let data = {};
        data["field"] = "Print";
        this.withButtonPressed(data);
    },

    withUpdatePdfButtonPressed() {
        let data = {};
        data["field"] = "Rebuild";
        this.withButtonPressed(data);
    },

    withSelectButtonPressed() {
        let data = {};
        data["field"] = "Select";
        data["id"] = this.company.id();
        this.withButtonPressed(data);
    },

    withButtonPressed: function (buttonData) {
      if (StringUtils.isEmpty(buttonData["id"])) {
        buttonData["id"] = this.row.id();
      }
      buttonData["key"] = this.row.key();
      this.$emit('withButtonPressed', buttonData);
    },

    timesheetStatusRow() {
        let row = new StatusDisplayRow();
        let value = this.timesheet.defectType();
        row.withValueWidth(12);
        row.withLabelWidth(5);
        row.withLabel(this.MC.Inspection.Details.StatusLabel.value());
        row.withValue(value);
        row.withObject(value);
        return row;
    },

    },
};
</script>