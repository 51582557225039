
import RcTableViewRow from "@/portals/shared/cell/common/RcTableViewRow.js";

import StringUtils from "@/utils/StringUtils";

export default class ImageDisplayRow extends RcTableViewRow {

  static Id = "ImageDisplayRow";
  
  static Href = "href";
  static Url = "url";
  static Tooltip = "tooltip";

  constructor() {
    super();
    this.put(RcTableViewRow.TypeId, ImageDisplayRow.Id);
  }
  
  
  withLabel(label) {
    this.withName(label).done();
    return this;
  }
  
  label() {
    return this.name();
  }
  
  withHrefUrl(href) {
    this.put(ImageDisplayRow.Href, href);
    return this;
  }

  hrefUrl() {
    return this.getString(ImageDisplayRow.Href);
  }

  hasHrefUrl() {
    return this.has(ImageDisplayRow.Href);
  }
  
  withImageUrl(url) {
    this.put(ImageDisplayRow.Url, url);
    return this;
  }

  imageUrl() {
    return this.getString(ImageDisplayRow.Url);
  }

  hasImageUrl() {
    return this.has(ImageDisplayRow.Url);
  }

  withImageTransparent() {
    this.withValue("transparent");
    return this;
  }

  isImageTransparent() {
    return this.value() === "transparent";
  }

  withImageHosMissing() {
    this.withValue("hosMissing");
    return this;
  }
  
  isImageHosMissing() {
    return this.value() === "hosMissing";
  }

  withImageOffDutyDay() {
    this.withValue("hosOffDutyDay");
    return this;
  }
  
  isImageHosOffDutyDay() {
    return this.value() === "hosOffDutyDay";
  }

  withImageHosCompleteDay() {
    this.withValue("hosCompleteDay");
    return this;
  }
  
  isImageHosCompleteDay() {
    return this.value() === "hosCompleteDay";
  }

  withImageHosInProgressDay() {
    this.withValue("hosInProgressDay");
    return this;
  }
  
  isImageHosIncompleteDay() {
    return this.value() === "hosIncompleteDay";
  }

  withImageIncompleteDay() {
    this.withValue("hosIncompleteDay");
    return this;
  }
  
  isImageHosInProgressDay() {
    return this.value() === "hosInProgressDay";
  }

  withImageInspectionCompleteGood() {
    this.withValue("inspectionCompleteGood");
    return this;
  }
  
  isImageInspectionCompleteGood() {
    return this.value() === "inspectionCompleteGood";
  }

  withImageInspectionCompleteMinor() {
    this.withValue("inspectionCompleteMinor");
    return this;
  }
  
  isImageInspectionCompleteMinor() {
    return this.value() === "inspectionCompleteMinor";
  }

  withImageInspectionCompleteMajor() {
    this.withValue("inspectionCompleteMajor");
    return this;
  }
  
  isImageInspectionCompleteMajor() {
    return this.value() === "inspectionCompleteMajor";
  }

  isClickable() {
    return this.getBoolean("clickable");
  }
  
  withClickable(value) {
    this.put("clickable", value);
    return this;
  }

  withTooltip(tip) {
    this.put(ImageDisplayRow.Tooltip, tip);
    return this;
  }

  hasTooltip() {
    return StringUtils.isNotEmpty(this.tooltipText());
  }

  tooltipText() {
    return this.getString(ImageDisplayRow.Tooltip);
  }

}