<template>
    <Box>

      <InvoiceReadyEmailDisplayRow v-if="showDialog == 'invoiceReadyEmailDialog'" :invoiceId="selectedId" v-on:withValue="withFieldValue" v-on:withButtonPressed="withButtonPressed"></InvoiceReadyEmailDisplayRow>
      <InvoicePaidThankyouEmailDisplayRow v-if="showDialog == 'invoiceThankyouEmailDialog'" :invoiceId="selectedId" v-on:withValue="withFieldValue" v-on:withButtonPressed="withButtonPressed"></InvoicePaidThankyouEmailDisplayRow>
      <ChargeCardDisplayRow v-if="showDialog == 'chargeCardDialog'" :invoiceId="selectedId" v-on:withValue="withFieldValue" v-on:withButtonPressed="withButtonPressed"></ChargeCardDisplayRow>
      <RefreshCardDisplayRow v-if="showDialog == 'refreshChargeDialog'" :invoiceId="selectedId" v-on:withValue="withFieldValue" v-on:withButtonPressed="withButtonPressed"></RefreshCardDisplayRow>
      <UpdateInvoiceDisplayRow v-if="showDialog == 'updateInvoiceDialog'" :invoiceId="selectedId" v-on:withValue="withFieldValue" v-on:withButtonPressed="withButtonPressed"></UpdateInvoiceDisplayRow>
      <UpdateInvoicePdfDisplayView v-if="showDialog == 'updatePdfDialog'" :invoiceId="selectedId" v-on:withValue="withFieldValue" v-on:withButtonPressed="withButtonPressed"></UpdateInvoicePdfDisplayView>
      <UpdateInvoiceQbDisplayRow v-if="showDialog == 'updateQbDialog'" :invoiceId="selectedId" v-on:withValue="withFieldValue" v-on:withButtonPressed="withButtonPressed"></UpdateInvoiceQbDisplayRow>
      <PaidInvoiceDisplayRow v-if="showDialog == 'markPaidDialog'" :invoiceId="selectedId" v-on:withValue="withFieldValue" v-on:withButtonPressed="withButtonPressed"></PaidInvoiceDisplayRow>
      <NotPaidInvoiceDisplayRow v-if="showDialog == 'markNotPaidDialog'" :invoiceId="selectedId" v-on:withValue="withFieldValue" v-on:withButtonPressed="withButtonPressed"></NotPaidInvoiceDisplayRow>
      <WorkingDisplayRow v-if="this.showDialog == 'workingDialog'" :itemId="this.selectedId" v-on:withButtonPressed="withButtonPressed" v-on:withValue="withValue" :isAdmin="isAdmin"></WorkingDisplayRow>
      <InvoiceDisplayRow v-if="this.showDialog == 'detailsDialog'" :invoiceId="this.selectedId" v-on:withButtonPressed="withButtonPressed" v-on:withValue="withFieldValue"></InvoiceDisplayRow>
      <DisplayRow v-if="this.showDialog == 'detailsDialog'" :invoiceId="this.selectedId" v-on:withButtonPressed="withButtonPressed" v-on:withValue="withValue"></DisplayRow>
      <WorkingDisplayRow v-if="this.showDialog == 'workingDialog'" :itemId="this.selectedId" v-on:withButtonPressed="withButtonPressed" v-on:withValue="withValue" :isAdmin="isAdmin"></WorkingDisplayRow>

      <Row>
        <Column :width="15">
          <Box class="rc-border-thin" :key="redraw + 1">
            <Row v-for="(row, rowIndex) in headerData" :key="rowIndex" >
              <CellFactory 
                :rowData="row.data"
                :showMissingRequirements="showMissingRequirements"
                v-on:withEnterPressed="withEnterPressed"
                v-on:withValue="withValue"
                v-on:withButtonPressed="withButtonPressed"
                  />
            </Row>
          </Box>
        </Column>
      </Row>

      <Row >
        <Column :width="15" >
          <Box :key="redraw" class="full-height-scrollable-div rc-border-thin">
            <Row v-for="(row, rowIndex) in viewData" :key="rowIndex" >
              <CellFactory 
                :rowData="row.data"
                :showMissingRequirements="showMissingRequirements"
                v-on:withEnterPressed="withEnterPressed"
                v-on:withValue="withValue"
                v-on:withButtonPressed="withButtonPressed"
                  />
            </Row>
          </Box>
        </Column>
      </Row>
  
      <Row>
        <Column :width="15">
          <Box class="rc-border-thin">
            <Row v-for="(row, rowIndex) in footerData" :key="rowIndex" >
              <CellFactory 
                :rowData="row.data"
                :showMissingRequirements="showMissingRequirements"
                v-on:withEnterPressed="withEnterPressed"
                v-on:withValue="withValue"
                v-on:withButtonPressed="withButtonPressed"
                  />
            </Row>
          </Box>
        </Column>
      </Row>

    </Box>
    
  </template>
  
  <script>
  
import { mapActions, mapGetters } from 'vuex';
  
import ConnectionUtils from '@/utils/ConnectionUtils.js';
import ConstUtils from '@/utils/ConstUtils.js';
import ContentUtils from '@/utils/ContentUtils.js';
import StringUtils from '@/utils/StringUtils.js';
  
import MC from "@/domain/session/MC.js";

import Box from "@/portals/shared/library/box/Box.vue";
import Column from "@/portals/shared/library/box/Column.vue";
import Row from "@/portals/shared/library/box/Row.vue";
  
import ListViewModel from "./ListViewModel.js";
import ListViewUtils from "./ListViewUtils.js";
  
import CellFactory from "@/portals/shared/cell/CellFactory.vue";
import DisplayRow from './details/InvoiceDisplayRow.vue';

import WorkingDisplayRow from '../../../../../shared/views/working/WorkingDisplayRow.vue';
import InvoiceReadyEmailDisplayRow from './email/ready/InvoiceReadyEmailDisplayRow.vue';
import InvoicePaidThankyouEmailDisplayRow from './email/thankyou/InvoicePaidThankyouEmailDisplayRow.vue';
import ChargeCardDisplayRow from './charge/card/ChargeCardDisplayRow.vue';
import RefreshCardDisplayRow from './charge/refresh/RefreshCardDisplayRow.vue';
import UpdateInvoiceDisplayRow from './update/invoice/UpdateInvoiceDisplayRow.vue';
import UpdateInvoicePdfDisplayView from './update/pdf/UpdateInvoicePdfDisplayView.vue';
import UpdateInvoiceQbDisplayRow from './update/qb/UpdateInvoiceQbDisplayRow.vue';
import PaidInvoiceDisplayRow from './mark/paid/PaidInvoiceDisplayRow.vue';
import NotPaidInvoiceDisplayRow from './mark/notpaid/NotPaidInvoiceDisplayRow.vue';
import InvoiceDisplayRow from '@/portals/customer/operator/views/invoice/list/details/InvoiceDisplayRow.vue';

export default {
    name: "invoice-list-view",
    components: {
      Column, Box, Row,
      CellFactory,
      DisplayRow,
      WorkingDisplayRow,
      InvoiceReadyEmailDisplayRow,
      InvoicePaidThankyouEmailDisplayRow,
      ChargeCardDisplayRow,
      RefreshCardDisplayRow,
      UpdateInvoiceDisplayRow,
      UpdateInvoicePdfDisplayView,
      UpdateInvoiceQbDisplayRow,
      PaidInvoiceDisplayRow,
      NotPaidInvoiceDisplayRow,
      InvoiceDisplayRow,
    },
    props: {
      adminDisplay: { type: Boolean, default: false},
      isAdmin: {type: Boolean, default: false},
    },
    data() {
      return {
        redraw: 1,
          
        MC: new MC(),
        CU: ContentUtils,
        Strings: StringUtils,
        Contents: ContentUtils,
        Consts: ConstUtils,
        
        CellFactory: CellFactory,
        
        viewModel: null,
        viewData: [],
        headerData: [],
        footerData: [],

        showMissingRequirements: false,
        selectedId: "",
        showDialog: "",

        ListViewModel: ListViewModel,
        ListViewUtils: ListViewUtils,
      };
    },
    computed: {
      ...mapGetters([
                     'auth_connected',
                     'auth_socket_status',
                     'signin_event',
                     'auth_client',
                     'domain',
                     ]),
    },
    watch: {
      auth_connected() {
        ConnectionUtils.sendSignInEvent(this);
      },
      auth_socket_status() {
        ConnectionUtils.displayStatus(this);
      },
      signin_event() {
        ConnectionUtils.createDomainSession(this);
      },
      $route (to, from) {
        if (to && from) {
          if (to.query["action"] === "detailsDialog") {
            this.showDialog = "detailsDialog";
            this.selectedId = to.query["selectedId"];

          } else if (to.query["action"] === "invoiceReadyEmail") {
            this.showDialog = "invoiceReadyEmailDialog";
            this.selectedId = to.query["selectedId"];

          } else if (to.query["action"] === "invoiceThankyouEmail") {
            this.showDialog = "invoiceThankyouEmailDialog";
            this.selectedId = to.query["selectedId"];

          } else if (to.query["action"] === "chargeCard") {
            this.showDialog = "chargeCardDialog";
            this.selectedId = to.query["selectedId"];

          } else if (to.query["action"] === "refreshCharge") {
            this.showDialog = "refreshChargeDialog";
            this.selectedId = to.query["selectedId"];

          } else if (to.query["action"] === "updateInvoice") {
            this.showDialog = "updateInvoiceDialog";
            this.selectedId = to.query["selectedId"];

          } else if (to.query["action"] === "updatePdf") {
            this.showDialog = "updatePdfDialog";
            this.selectedId = to.query["selectedId"];

          } else if (to.query["action"] === "updateQb") {
            this.showDialog = "updateQbDialog";
            this.selectedId = to.query["selectedId"];

          } else if (to.query["action"] === "markPaid") {
            this.showDialog = "markPaidDialog";
            this.selectedId = to.query["selectedId"];

          } else if (to.query["action"] === "markNotPaid") {
            this.showDialog = "markNotPaidDialog";
            this.selectedId = to.query["selectedId"];

          } else if (to.query["action"] === "detailsDialog") {
            this.showDialog = "detailsDialog";
            this.selectedId = to.query["invoiceId"];

          } else {
            let refresh = false;

            var toQuery = Object.assign({}, to.query);
            var fromQuery = Object.assign({}, from.query);
            
            delete toQuery.action;
            delete toQuery.selectedId;

            delete fromQuery.action;
            delete fromQuery.selectedId;

            if (JSON.stringify(toQuery, null, 0) !== JSON.stringify(fromQuery, null, 0)) {
              refresh = true;
            }

            if (StringUtils.isNotEmpty(this.showDialog)) {
              this.showDialog = "";
            } 

            if (refresh) {
              this.reloadPage();
            }
          }
        }
      }
    },
    mounted: function () {
      this.viewModel = new ListViewModel(this);
      this.viewModel.withStatePageLoading();
      this.viewModel.clearAndBuildTableData();
      ConnectionUtils.ensureConnection(this);
    },
    methods: {
      ...mapActions([
                     'AuthStorage_updateCompany',
                     'addListenerAction',
                     'removeListenerAction',
                     'updateDomain',
                     ]),
  
      start: function() {
        this.viewModel.start();
        this.viewModel.withStateContentLoading();
        this.viewModel.clearAndBuildTableData();
        this.viewModel.loadItems();
      },
  
      displayProgress: function(msg) {
        this.callback(msg);
      },

      callback: function(msg) {
        var row = this.viewModel.findRowProgress();
        if (row) {
          row.withMessage(msg);
        }
        this.paint();
      },
      
      model: function() {
        return this.viewModel;
      },
      
      showDialogIfRequired: function() {
        var query = Object.assign({}, this.$route.query);
        if (query["action"]) {
          this.showDialog = query["action"];
          this.selectedId = query["invoiceId"];
        } 
      },

      urlQueryParams() {
        var query = Object.assign({}, this.$route.query);
        if (query == null) {
          query = {};
        }
        return query;
      },

      addQueryParams(valueData, key) {
        var query = this.urlQueryParams();
        this.addQueryParamsToRouter(query, valueData, key);
      },

      addQueryParamsToRouter(query, valueData, key) {
        const value = valueData["value"];
        if (value && value != "null") {
          query[key] = value;
          if (key === "pageSize") {
            delete query.page
          }
        } else {
          delete query[key];
        }
        this.$router.push({ query });
      },
      
      withEnterPressed(keyData) {
        if (keyData["title"]) {
          keyData["field"] = "Search"
          this.withButtonPressed(keyData);
        }
      },

      withFieldValue(valueData) {
        this.withValue(valueData);
      },

      withValue: function(valueData) {
        this.model().withValue(valueData);
        
        if (valueData["field"] === "PageSize") {
          this.model().withPageSize(valueData, "invoice");
          this.addQueryParams(valueData, "pageSize");

        } else if (valueData["field"] === "PageIndex") {
          this.addQueryParams(valueData, "page");

        } else if (valueData["field"] === "Month") {
          this.addQueryParams(valueData, "month");

        } else if (valueData["field"] === "chargeStatus") {
          this.addQueryParams(valueData, "chargeStatus");

        } else if (valueData["field"] === "autoCharge") {
          this.addQueryParams(valueData, "autoCharge");

        } else if (valueData["field"] === "autoEmail") {
          this.addQueryParams(valueData, "autoEmail");

        } else if (valueData["field"] === "PromoCode") {
          this.addQueryParams(valueData, "promo");

        } else if (valueData["field"] === "Amount") {
          this.addQueryParams(valueData, "amount");

        } else if (valueData["field"] === "Search") {
          this.model().withSearchText(valueData);
        } else {
          this.$emit("withValue", valueData);
        }
      },
      
      withButtonPressed: function(buttonData) {
        this.model().withButtonPressed(buttonData);
        let valueData = buttonData;

        if (buttonData["field"] === "Details") {
          const query = this.urlQueryParams();
          query["action"] = "detailsDialog";
          query["selectedId"] = this.Contents.baseEncode(buttonData["id"]),
          this.$router.push({ query });

        } else if (buttonData["field"] === "Refresh") {
          this.reloadPage();

        } else if (buttonData["field"] === "NextPage") {
          const query = this.urlQueryParams();
          if (query.page) {
            query.page = (Number(query.page) + 1) + "";
          } else {
            query.page = "2";
          }
          if (query.page <= this.model().pages().length) {
            this.$router.push({ query });
          }

        } else if (buttonData["field"] === "PreviousPage") {
          const query = this.urlQueryParams();
          if (query.page) {
            query.page = (Number(query.page) - 1) + "";
            if (query.page <= 0) {
              query.page = "1";
            }
            this.$router.push({ query });
          }

        } else if (buttonData["field"] === "Close") {
          let query = this.urlQueryParams();
          query = this.deleteDetails(query);
          this.$router.push({ query });
          if (buttonData["removed"] || buttonData["refresh"]) {
            this.reloadPage();
          }

        } else if (buttonData["field"] === "Clear") {
          this.model().withSearchText("");
          buttonData["value"] = "";
          this.addQueryParams(buttonData, "search");

        } else if (buttonData["field"] === "Search") {
          buttonData["value"] = this.model().searchText();
          const queryParams = this.urlQueryParams();
          delete queryParams["page"];
          this.addQueryParamsToRouter(queryParams, buttonData, "search");

        } else if (buttonData["field"] === "Full") {
          var fullParams = {
            id: buttonData["id"],
          };
          this.$router.push({
            name: ConstUtils.ROUTES.VEHICLE.DETAILS,
            params: fullParams,
          });

        } else if (buttonData["field"] === "Pdf") {
          this.printInvoice(buttonData);

        } else if (buttonData["field"] === "Select") {
          let company = this.domain.companys().findById(buttonData["id"]);
          let session = this.domain.session();
          session.with(company);
          session.companyName = company.name();
          this.AuthStorage_updateCompany(company);
          let listPath = StringUtils.format(ConstUtils.ROUTES.COMPANY.EDIT_PATH, [company.idEncoded()]);
          this.$router.push({
            path: listPath,
          });

        } else if (valueData["field"] == "InvoiceReady") {
          let query = Object.assign({}, this.$route.query);
          query["action"] = "invoiceReadyEmail";
          query["selectedId"] = this.CU.baseEncode(valueData["id"]);
          this.$router.push({ query });

        } else if (valueData["field"] == "InvoicePaid") {
          let query = Object.assign({}, this.$route.query);
          query["action"] = "invoiceThankyouEmail";
          query["selectedId"] = this.CU.baseEncode(valueData["id"]);
          this.$router.push({ query });

        } else if (valueData["field"] == "ChargeCard") {
          let query = Object.assign({}, this.$route.query);
          query["action"] = "chargeCard";
          query["selectedId"] = this.CU.baseEncode(valueData["id"]);
          this.$router.push({ query });

        } else if (valueData["field"] == "RefreshChargeDetails") {
          let query = Object.assign({}, this.$route.query);
          query["action"] = "refreshCharge";
          query["selectedId"] = this.CU.baseEncode(valueData["id"]);
          this.$router.push({ query });

        } else if (valueData["field"] == "UpdateInvoice") {
          let query = Object.assign({}, this.$route.query);
          query["action"] = "updateInvoice";
          query["selectedId"] = this.CU.baseEncode(valueData["id"]);
          this.$router.push({ query });

        } else if (valueData["field"] == "UpdatePdf") {
          let query = Object.assign({}, this.$route.query);
          query["action"] = "updatePdf";
          query["selectedId"] = this.CU.baseEncode(valueData["id"]);
          this.$router.push({ query });

        } else if (valueData["field"] == "UpdateQb") {
          let query = Object.assign({}, this.$route.query);
          query["action"] = "updateQb";
          query["selectedId"] = this.CU.baseEncode(valueData["id"]);
          this.$router.push({ query });

        } else if (valueData["field"] == "Pay") {
          let invoiceId = buttonData["id"];
          let invoice = this.domain.invoices().findById(invoiceId);
          let link = invoice.paymentUrl();
          var url = ContentUtils.escape(link);
          url = url.replace("#", "%23");
          window.open(url, '_blank');

        } else if (valueData["field"] == "MarkPaid") {
          let query = Object.assign({}, this.$route.query);
          query["action"] = "markPaid";
          query["selectedId"] = this.CU.baseEncode(valueData["id"]);
          this.$router.push({ query });
        
        } else if (valueData["field"] == "MarkNotPaid") {
          let query = Object.assign({}, this.$route.query);
          query["action"] = "markNotPaid";
          query["selectedId"] = this.CU.baseEncode(valueData["id"]);
          this.$router.push({ query });

        } else {
          this.$emit("withButtonPressed", buttonData);
        }
      },

      printInvoice(buttonData) {
        var invoiceId = buttonData["id"];
        var invoice = this.domain.invoices().findById(invoiceId);
        var url = ContentUtils.escape(invoice.pdfUrl());
        url = url.replace("#", "%23");
        window.open(url, '_blank');
      },
      
      deleteDetails(query) {
        delete query.action;
        delete query.vehicleId;
        return query;
      },

      reloadPage: function() {
        this.viewModel.withStateContentLoading();
        this.viewModel.clearAndBuildTableData();
        this.viewModel.loadItems();
      },

      paint: function() {
        this.redraw++;
      },

    },
  }
</script>