
import RcTableViewRow from "@/portals/shared/cell/common/RcTableViewRow.js";

export default class TitleDisplayRow extends RcTableViewRow {

  static Id = "TitleDisplayRow";
  
  static Title = "title";
  static Subtitle = "subtitle";
  static SavingTitle = "SavingTitle";
  
  constructor() {
    super();
    this.put(RcTableViewRow.TypeId, TitleDisplayRow.Id);
    this
      .withSubtitle(false)
      .withAlignLeft()
      .done();
  }

  withSavingTitle(sub) {
    this.put(TitleDisplayRow.SavingTitle, sub);
    return this;
  }
  
  savingTitle() {
    return this.getBoolean(TitleDisplayRow.SavingTitle)
  }
  
  withSubtitle(sub) {
    this.put(TitleDisplayRow.Subtitle, sub);
    return this;
  }
  
  subtitle() {
    return this.getBoolean(TitleDisplayRow.Subtitle)
  }
  
  withTitle(title) {
    this.put(TitleDisplayRow.Title, title);
    return this;
  }
  
  title() {
    return this.getString(TitleDisplayRow.Title);
  }
  
  withAlignLeft() {
    this.withAlign("left");
    return this;
  }
  
  withAlignRight() {
    this.withAlign("right");
    return this;
  }
  
  withAlign(align) {
    this.put("align", align);
    return this;
  }
  
  align() {
    return this.getString("align");
  }
}