
import RcDomainObject from "../../common/RcDomainObject.js";
import Vehicle from "../vehicle/Vehicle.js";
import Company from "../company/Company.js";
import Schedule from "../schedule/Schedule.js";
import Signature from "@/domain/model/user/Signature.js";
import VehicleMap from "../vehicle/VehicleMap.js";
import CommentMap from "../comment/CommentMap.js";
import ResultMap from "./result/ResultMap.js";
import Employee from "../employee/Employee.js";
import ChecklistResultMap from "../checklist/result/ChecklistResultMap.js";
import RepairResultMap from "./repair/result/RepairResultMap.js";

import StringUtils  from '@/utils/StringUtils.js';
import DateUtils  from '@/utils/DateUtils.js';

import Catalog from '@/domain/session/Catalog.js';
import RcDate from '@/domain/session/time/RcDate.js';
import User from "../user/User.js";

export default class Inspection extends RcDomainObject {
  
  static MODEL_NAME = Vehicle.MODEL_NAME + "Inspection";
  
  static Fields = {
      COMMENTS: CommentMap.MODEL_NAME,
      PDF_URL: "pdfUrl",
      CREATED_DATE: 'createdDate',
      DEFECT_TYPE: 'defectType',
      VEHICLE: Vehicle.MODEL_NAME,
      ODOMETER: 'odometer',
      TRAILERS: 'trailerMap',
      RESULTS: 'VehicleInspectionResultMap',
      REPAIR_RESULTS: 'VehicleInspectionRepairResultMap',
      PRE_TRIP_IOS: "pretrip",
      PRE_TRIP_ANDROID: "preTrip",
      TRIP: "trip",
      LOCATION: "location",
      SCHEDULE: "VehicleSchedule",
      COMPANY: "Company",
      
      DRIVER: "driver",
      DRIVER_DATE: "driverDate",
      DRIVER_SIGNATURE: "driverSignature",
      DRIVER_INSPECTION_DATE: "driverDate",
      
      INSPECTOR: "inspector",
      INITIAL_INSPECTOR_SIGNATURE: "inspectorSignature",
      INSPECTOR_INSPECTION_DATE: "initialDate",
      
      ENROUTE_INSPECTOR: 'enrouteInspector',
      ENROUTE_INSPECTION_DATE: 'enrouteInspectionDate',
      ENROUTE_INSPECTOR_SIGNATURE: "enrouteSignature",
      
      REPAIRED: "repaired",
      Action: "action",
      CHECKLIST_RESULTS: ChecklistResultMap.MODEL_NAME,
  }
  
  static Values = {
    Trip: {
      PRE: "pre",
      POST: "post",
      isPre: function(value) {
        return value.toLowerCase() === Inspection.Values.Trip.PRE;
      },
    },
    Action: {
      Repair: "actionRepair",
    },
  }
  
  constructor(domain, data) {
    super(domain, data, Inspection.MODEL_NAME);
    this.C = new Catalog();
  }

  find() {
    return this.domain.inspections().findById(this.id());
  }
  
  pdfUrl() {
    var host = this.domain.client().cdnHost;
    var url = host + this.getString(Inspection.Fields.PDF_URL);
    return url;
  }
  
  hasPdfUrl() {
    return !StringUtils.isEmpty(this.getString(Inspection.Fields.PDF_URL));
  }

  comments() {
    var obj = this.get(Inspection.Fields.COMMENTS);
    var comments = new CommentMap(this.domain, obj);
    return comments;
  }
  
  vehicle() {
    var obj = this.get(Inspection.Fields.VEHICLE);
    var item = new Vehicle(this.domain, obj);
    return item;
  }
  
  company() {
    var obj = this.get(Inspection.Fields.COMPANY);
    var item = new Company(this.domain, obj);
    return item;
  }
  
  trailers() {
    var obj = this.get(Inspection.Fields.TRAILERS);
    var item = new VehicleMap(this.domain, obj);
    return item;
  }
  
  vehicles() {
    var trailers = this.trailers();
    trailers.add(this.vehicle());
    return trailers;
  }
  
  results() {
    var obj = this.get(Inspection.Fields.RESULTS);
    var item = new ResultMap(this.domain, obj);
    return item;
  }
  
  withResults(results) {
    this.put(Inspection.Fields.RESULTS, results.data);
    return this;
  }
  
  resultsForVehicle() {
    var results = this.results();
    return results.vehicle();
  }
  
  resultsForTrailers() {
    var results = this.results();
    return results.trailers();
  }
  
  hasComments() {
    return this.comments().length() > 0;
  }
  
  hasBeenRepaired() {
    var beenRepaired = this.getBoolean(Inspection.Fields.REPAIRED);
    if (!beenRepaired) {
      beenRepaired = this.hasRepairs();
    }
    return beenRepaired;
  }
  
  withBeenRepaired() {
    this.put(Inspection.Fields.REPAIRED, true);
    return this;
  }
  
  copy() {
    var data = this._copy();
    return new Inspection(this.domain, data);
  }
  
  odometer() {
    return this.getString(Inspection.Fields.ODOMETER);
  }
  
  defectType() {
    return this.getString(Inspection.Fields.DEFECT_TYPE);
  }
  
  defectTypeDisplay() {
    var defectType = this.defectType();
    var display = "Good";
    if (defectType === "Major") {
      display = "Major";
    }
    if (defectType === "Minor") {
      display = "Minor";
    }
    return display  
  }
  
  isDefectTypeGood() {
    var defectType = this.defectType();
    if (defectType === "Major") {
      return false;
    }
    if (defectType === "Minor") {
      return false;
    }
    return true;
  }
  
  isDefectTypeMinor() {
    var defectType = this.defectType();
    if (defectType === "Minor") {
      return true;
    }
    return false;
  }

  isDefectTypeMajor() {
    var defectType = this.defectType();
    if (defectType === "Major") {
      return true;
    }
    return false;
  }
  
  withDefectType(defectType) {
    this.put(Inspection.Fields.DEFECT_TYPE, defectType);
    return this;
  }
  
  hasUnfixedDefect() {
    var defect = this.defectType();
    if (defect === "None" || StringUtils.isEmpty(defect)) {
      if (this.hasTrailers()) {
        return this.hasUnfixedDefects(true);
      }
      return false;
    }
    return true;
  }
  
  hasUnfixedDefects(includeDetachedTrailers) {
    var result = this.results().find().mostSevereUnfixedDefectType(includeDetachedTrailers);
    if (StringUtils.eic("None", result) || StringUtils.isEmpty(result)) {
      return false;
    }
    return true;
  }

  isPreTrip() {
    if (this.has(Inspection.Fields.TRIP)) {
      var value = this.getString(Inspection.Fields.TRIP);
      return Inspection.Values.Trip.isPre(value);
    }
    
    if (this.has(Inspection.Fields.PRE_TRIP_ANDROID)) {
      var android = this.getString(Inspection.Fields.PRE_TRIP_ANDROID);
      return android.toLowerCase() === "true";
    }
    
    if (this.has(Inspection.Fields.PRE_TRIP_IOS)) {
      var ios = this.getString(Inspection.Fields.PRE_TRIP_IOS);
      return ios.toLowerCase() === "true";
    }
    
    return true;
  }
  
  tripDisplay() {
    if (this.isPreTrip()) {
      return this.C.get(Catalog.Inspection.PRE_TRIP);
    }
    return this.C.get(Catalog.Inspection.POST_TRIP);
  }
  
  location() {
    return this.getString(Inspection.Fields.LOCATION);
  }
  
  createDate() {
    return this.get(Inspection.Fields.CREATED_DATE);
  }
  
  createDateDisplay() {
    var createdDate = this.createdDate();
    return DateUtils.toDateTime(createdDate);
  }
 
  createdDate() {
    var createdDate = this.getInt(Inspection.Fields.CREATED_DATE);
    var timeZone = this.timeZone();
    var rcDate = new RcDate(new Date(createdDate), timeZone);
    return rcDate;
  }
  
  createdDateDisplay() {
    var rcDate = this.createdDate();
    return rcDate.displayAs_YYYY_MM_DD_HH_MM_SS_A();
  }
  
  enrouteInspectionDate() {
    return this.getInt(Inspection.Fields.ENROUTE_INSPECTION_DATE);
  }
  
  enrouteInspectionDateDisplay() {
    var createdDate = this.enrouteInspectionDate();
    var timeZone = this.timeZone();
    var rcDate = new RcDate(new Date(createdDate), timeZone);
    return rcDate.displayAs_YYYY_MM_DD_HH_MM_SS_A();
  }
  
  enrouteSignature() {
    var data = this.get(Inspection.Fields.ENROUTE_INSPECTOR_SIGNATURE);
    return new Signature(this.domain, data);
  }
  
  enrouteInspector() {
    var data = this.get(Inspection.Fields.ENROUTE_INSPECTOR);
    var employee = new Employee(this.domain, data);
    return employee;
  }
  
  hasEnrouteInspector() {
    var inspector = this.enrouteInspector();
    return !inspector.isNew();
  }
  
  driver() {
    return this.driverInspector();
  }
  
  hasDriver() {
    var driver = this.driver();
    return !driver.isNew();
  }
  
  driverInspectionDate() {
    return this.getInt(Inspection.Fields.DRIVER_INSPECTION_DATE);
  }
  
  driverInspectionDateDisplay() {
    var createdDate = this.driverInspectionDate();
    var timeZone = this.timeZone();
    var rcDate = new RcDate(new Date(createdDate), timeZone);
    return rcDate.displayAs_YYYY_MM_DD_HH_MM_SS_A();
  }
  
  driverInspector() {
    var data = this.get(Inspection.Fields.DRIVER);
    var employee = new Employee(this.domain, data);
    return employee;
  }
  
  driverSignature() {
    var data = this.get(Inspection.Fields.DRIVER_SIGNATURE);
    return new Signature(this.domain, data);
  }
  
  hasVerifier() {
    return false;
  }
  
  repairResults() {
    var data = this.get(Inspection.Fields.REPAIR_RESULTS);
    var repairResults = new RepairResultMap(this.domain, data);
    return repairResults;
  }
  
  withRepairResults(repairResults) {
    this.put(Inspection.Fields.REPAIR_RESULTS, repairResults.data);
    return this;
  }
  
  hasRepairs() {
    return this.repairResults().size() > 0;
  }
  
  inspector() {
    var data = this.get(Inspection.Fields.INSPECTOR);
    var employee = new Employee(this.domain, data);
    return employee;
  }
  
  inspectorInspectionDate() {
    return this.get(Inspection.Fields.INSPECTOR_INSPECTION_DATE);
  }
  
  inspectorSignature() {
    var data = this.get(Inspection.Fields.INITIAL_INSPECTOR_SIGNATURE);
    return new Signature(this.domain, data);
  }
  
  inspectorInspectionDateDisplay() {
    var createdDate = this.inspectorInspectionDate();
    var timeZone = this.timeZone();
    var rcDate = new RcDate(new Date(createdDate), timeZone);
    return rcDate.displayAs_YYYY_MM_DD_HH_MM_SS_A();
  }
  
  schedule() {
    var scheduleData = this.get(Inspection.Fields.SCHEDULE);
    return new Schedule(this.domain, scheduleData);
  }
  
  hasTrailers() {
    var isEmpty = this.trailers().isEmpty();
    return !isEmpty;
  }
  
  addResults(results) {
    var keys = results.keys();
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var result = results.get(key);
      var existing = this.results();
      existing.add(result);
      this.withResults(existing);
    }
    return this;
  }
  
  withAction(action) {
    this.put(Inspection.Fields.Action, action);
    return this;
  }
  
  withActionRepair() {
    this.withAction(Inspection.Values.Action.Repair);
    return this;
  }

  checklistResults() {
    var object = this.get(Inspection.Fields.CHECKLIST_RESULTS);
    return new ChecklistResultMap(this.domain, object);
  }

  workOrderDisplay() {
    var results = this.repairResults().find();
    if (!results) {
      return "";
    }
    let list = results.list();
    let workOrders = "";
    for (let i = 0; i < list.length; i++) {
      let repairResult = list[i];
      let workOrder = repairResult.workOrders();
      workOrders += " " + workOrder;
    }

    return StringUtils.trim(workOrders);
  }

  modifiedBy() {
    return new User(this.domain, this.get(Employee.DefaultFields.MODIFIED_BY));
  }

  createdBy() {
    return new User(this.domain, this.get(Employee.DefaultFields.CREATED_BY));
  }

}