
import AddViewModel from "../add/AddViewModel";
import UpdateViewUtils from "./UpdateViewUtils";

export default class UpdateViewModel extends AddViewModel {
    
  constructor(panel, vehicle) {
    super(panel, vehicle);
    this._total = 0;
    this._error = null;
    this._inspections = [];
  }

  start() {
    super.start();
  }

  withValue(valueData) {
    super.withValue(valueData, "vehicle");
  }

  withButtonPressed(buttonData) {
    super.withButtonPressed(buttonData);
  }

  buildRowData(tableData) {
    var utils = new UpdateViewUtils(this, tableData);
    utils.start();
    return this;
  }

}