import RcDomainMap from "../../common/RcDomainMap.js";
import Report from "./Report.js";
import ReportUtils from "./ReportUtils.js";

export default class ReportMap extends RcDomainMap {
  
  static MODEL_NAME = "ReportMap";
  
  constructor(domain, data) {
    super(domain, data, ReportMap.MODEL_NAME);
  }

  copy() {
    var data = this._copy();
    return new ReportMap(this.domain, data);
  }

  find() {
    var keys = this.keys();
    var found = new ReportMap(this.domain, {} );
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var data = this.domain.report().findById(key);
      var item = new ReportMap(this.domain, data);
      if (!item.isNew()) {
        found.add(item);
      }
    }
    return found;
  }
  
  list() {
    var values = [];
    var keys = this.keys();
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var item = this.getReport(key);
      values.push(item);
    }
    return values;
  }
  
  sortByCreatedDate() {
    var list = this.list();
    return list.sort(ReportUtils.SortByCreatedDate);
  }
  
  sort() {
    return this.sortByName();
  }
  
  sortByName() {
    var list = this.list();
    return list.sort(ReportUtils.SortByName);
  }

  first() {
    var all = this.list();
    if (all.length > 0) {
      return all[0];
    }
    return new Report(this.domain);
  }
  
  getReport(key) {
    var value = super.get(key);
    return new Report(this.domain, value);
  }

  addReport(report) {
    this.add(report).done();
    return this;
  }
}