
import RcTableViewModel from "../../../../../../shared/table/RcTableViewModel";

import DetailsDisplayViewUtils from "./DetailsDisplayViewUtils";

import ContentUtils from '@/utils/ContentUtils.js';
import ConstUtils from "@/utils/ConstUtils.js";

import Timesheet from "@/domain/model/timesheet/Timesheet";
import TimesheetMap from "@/domain/model/timesheet/TimesheetMap";
import TimesheetListFilter from "@/domain/model/timesheet/TimesheetListFilter";

export default class DetailsDisplayViewModel extends RcTableViewModel {
    
  constructor(panel, timesheetId) {
    super(panel);
    this._companyId = ContentUtils.baseDecode(panel.$route.params.operatorId);
    this._total = 0;
    this._error = null;
    this._timesheetId = timesheetId;
    this._timesheet = null;
    this.CU = ContentUtils;
  }

  start() {
    super.start();
  }

  log() {
    return this.domain().logger();
  }

  isAdmin() {
    return super.isAdmin();
  }

  company() {
    return this.domain().companys().findById(this._companyId);
  }

  withValue(valueData) {
    super.withValue(valueData, "timesheet");
    if (!valueData) {
      return;
    }
  }

  withButtonPressed(buttonData) {
    super.withButtonPressed(buttonData);
    if (!buttonData) {
      return;
    }

    this.closeAction(buttonData);
    
    this.printAction(buttonData);

    this.fullScreenAction(buttonData);
    
    this.refreshAction(buttonData);

    this.log().info("{0}", [JSON.stringify(this._timesheet.data, null, 2)]);
  }

  closeAction(buttonData) {
    if (buttonData["field"] === "Close") {
      this.panel().$emit("withButtonPressed", buttonData);
    }
  }

  printAction(buttonData) {
    if (buttonData["field"] === "Print") {
      var timesheet = this.domain().timesheets().findById(buttonData["id"]);
      var url = ContentUtils.escape(timesheet.pdfUrl());
      url = url.replace("#", "%23");
      window.open(url, '_blank');
    }
  }

  fullScreenAction(buttonData) {
    if (buttonData["field"] === "Full") {
      var fullParams = {
        id: buttonData["id"],
      };
      this.panel().$router.push({
        name: ConstUtils.ROUTES.TIMESHEET.DETAILS,
        params: fullParams,
      });
    }
  }

  refreshAction(buttonData) {
    if (buttonData["field"] === "Refresh") {
      this.panel().reloadPage();
    }
  }

  error() {
    return this._error;
  }

  resetPage(model) {
    model._timesheet = new Timesheet(model.domain());
    model.withStateContentLoaded();
    model.panel().reloadPage();
  }

  closePage(model) {
    model.panel().close(model._atLeastOneSaved);
  }

  buildRowData(tableData) {
    var utils = new DetailsDisplayViewUtils(this, tableData);
    utils.start();
    return this;
  }

  loadItems() {
    super.loadItems();
    this
      .withStateContentLoading()
      .clearAndBuildTableData()
      .done();

    this.log().info("Loading view data");

    var context = {};
    context["model"] = this;

    var timesheet = new Timesheet(this.domain());
    timesheet.withId(this._timesheetId);

    var filter = new TimesheetListFilter();
    filter
      .withIds(timesheet.map())
      .withCompany(this._companyId)
      .done();

    var event = this.domain().events().timesheets().list(filter);
    event.send(this.loadedData, context);
  }

  loadedData(notification, context) {
    var model = context.model;
    model.log().info("Loaded view data");

    if (notification.error()) {
      model.withError(notification.error());
    } else {
      var payload = notification.payload();
      var payloadMap = payload.valuesFor(TimesheetMap.MODEL_NAME);
      var timesheetMap = new TimesheetMap(model.domain(), payloadMap);
      model._timesheet = timesheetMap.getTimesheet(model._timesheetId);
    }
    
    model
      .withStateContentLoaded()
      .clearAndBuildTableData()
      .done();

    model.panel().showDialogIfRequired();
  }

  timesheet() {
    return this._timesheet;
  }

  areRequirementsMet() {
    var met = true;

    return met;
  }
}