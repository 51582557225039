<template>
<Box>

  <ErrorRow :error="error" />

  <Row v-if="isAdmin">
    <Column :width="15">
      <div class="rc-text-centre">
      Admin View - CheckIns
      </div>
    </Column>
  </Row>
  
  <Row class="rc-text-label rc-text-medium">
    <Column :width="12" :showBorder="true">
      <Box >
        
        <Row>
          <Column :width="14">
            {{ C.get(Keys.FILTERS.TITLE) }}
          </Column>
          <Column :width="1">
            <Button 
              v-on:click="reset()" 
              :disabled="isLoading">
            Reset
            </Button>
          </Column>
          <Column :width="3" />
        </Row>
        
        <Row>
          <Column :width="1">
            {{ C.get(Keys.FILTERS.TIME) }}
          </Column>
          <Column :width="1">
            <Button 
              v-on:click="filterAll()"
              :disabled="isFilterAll()">
            All
            </Button>
          </Column>
          <Column :width="1">
            <Button 
              v-on:click="filterToday()"
              :disabled="isFilterToday()" >
            Today
            </Button>
          </Column>
          <Column :width="2">
            <Button 
              v-on:click="filterYesterday()"
              :disabled="isFilterYesterday()" >
            Yesterday
            </Button>
          </Column>
          <Column :width="2">
            <Button 
              v-on:click="filterLast2Weeks()" 
              :disabled="isFilterLast2Weeks()">
            Last 2 Weeks
            </Button>
          </Column>
          <Column :width="2">
            <Button
              v-on:click="filterThisMonth()"
              :disabled="isFilterThisMonth()">
            This Month
            </Button>
          </Column>
          <Column :width="2">
            <Button
              v-on:click="filterLast30Days()"
              :disabled="isFilterLast30Days()">
            Last 30 Days
            </Button>
          </Column>
          <Column :width="2">
            <Button
              v-on:click="filterLastMonth()"
              :disabled="isFilterLastMonth()">
            Last Month
            </Button>
          </Column>
          <Column :width="2">
            <Button
              v-on:click="filterLast2Years()"
              :disabled="isFilterLast2Years()">
            Last 2 Years
            </Button>
          </Column>
          <Column :width="1" />
        </Row>
        
      </Box>
    </Column>
    
    <Column :width="3" :showBorder="true">
      <Box>
        <Row>
          <div class="rc-box-col-10">
            Actions
          </div>
            
          <div class="rc-box-col-5">
            <Button
              v-on:click="refresh"
              :disabled="isLoading" >
            Refresh
            </Button>
          </div>
        </Row>
        
        <Row>
          <Column/>
        </Row>
        
      </Box>
    </Column>
    
  </Row>
  
  <TitleRow class="rc-text-small">
    <Column class="rc-text-centre">
     User
    </Column>
    <Column class="rc-text-centre">
      Date
    </Column>
    <Column class="rc-text-centre">
     Stats
    </Column>
  </TitleRow>
  
  <Row :showBorder="true">
    <Column>
      <Box>
        <Row v-for="(checkin, index) in checkinList" 
             v-bind:key="checkin['@rid']" 
             :class="isEven(index) ? 'rowEven' : 'rowOdd'" 
             style="padding: 8px">
          <CheckInRow :checkinData="checkin" :index="index" :isAdmin="isAdmin"/>
        </Row>
      </Box>  
    </Column>
  </Row>
  
  <Row :showBorder="true" v-if="checkinList.length == 0 && !isLoading">
    <Column :width="5"> </Column>
    <Column :width="5">
        <Spacer />
        <Box :showBorder="true">
          <TitleRow :subtitle="true"> No Results </TitleRow>
          <Value align="left">
            There are no results for your current filters. Try using a filter 
            that can show data from a larger time window like:<br>
            <Box>
              <Row>
                <Column :width="10">
                  Show Check-Ins for this month
                </Column>
                <Column :width="5">
                  <Button 
                    v-on:click="filterThisMonth()" 
                    :disabled="isFilterThisMonth()" >
                  This Month
                  </Button>
                </Column>
              </Row>
              <Row>
                <Column :width="10">
                  Show Check-Ins for the last 2 years
                </Column>
                <Column :width="5">
                  <Button 
                    v-on:click="filterLast2Years()" 
                    :disabled="isFilterLast2Years()" >
                  Last 2 Years
                  </Button>
                </Column>
              </Row>
            </Box>
          </Value>
        </Box>
        <Spacer />
    </Column>
    <Column :width="5"> </Column>
  </Row>
        
  <LoadingRow v-if="isLoading" :showBorder="true"> {{ loadingMessage }} </LoadingRow> 
 
  <Row :showBorder="true" v-if="!isLoading && checkinList.length > 0">
    <div class="rc-box-col-4" />
    <div class="rc-box-col-1">
      <Button v-if="canPreviousPage()" v-on:click="previousPage"> &lt; </Button>
    </div>
    
    <div class="rc-box-col-5 rc-text-centre rc-text-label">
      Showing {{ min }} to {{ max }}  of {{ count }}
    </div>
    
    <div class="rc-box-col-1">
      <Button v-if="canNextPage()" v-on:click="nextPage"> &gt; </Button>
    </div>
    <div class="rc-box-col-4" />
  </Row> <!-- page button row -->
  
</Box> <!-- container -->
</template>
<script>
import { mapGetters } from 'vuex';
import Catalog from "@/domain/session/Catalog.js";
import ErrorRow from '@/components/row/ErrorRow.vue';
import ConstUtils   from '@/utils/ConstUtils.js';
import CheckInMap from '@/domain/model/checkin/CheckInMap.js';
import CheckInUtils from "./CheckInUtils.js";
import StringUtils  from '@/utils/StringUtils.js';
import CheckInRow from "./CheckInRow.vue";
import CheckInListFilter from "@/domain/model/checkin/CheckInListFilter.js";

import MC from "@/domain/session/MC.js";

import Box from "@/portals/shared/library/box/Box.vue";
import Row from "@/portals/shared/library/box/Row.vue";
import Column from "@/portals/shared/library/box/Column.vue";

import TitleRow from "@/portals/shared/library/title/TitleRow.vue";
import LoadingRow from "@/portals/shared/library/loading/LoadingRow.vue";  

import Spacer from "@/portals/shared/library/spacer/SpacerRow.vue";

import Button from "@/portals/shared/library/button/Button.vue";
import Value from "@/portals/shared/library/value/ValueRow.vue";

export default {
  name: 'portal-operator-checkin-list',
  components: {
    Button,
    ErrorRow, LoadingRow,
    CheckInRow,
    TitleRow, Value, Spacer,
    Box, Row, Column,
  },
  props: {
  },
  data() {
    return {
      isAdmin: false,
      error: null,
      list: [],
      isLoading: false,
      loadingMessage: null,
      count: 0,
      pageSize: 40,
      pageNumber: 0,
      C: new Catalog(),
      Keys: Catalog.keys(),
      currentDateFilter: 'all',
      FILTER: {
        ALL: 'all',
        TODAY: 'today',
        YESTERDAY: 'yesterday',
        THIS_MONTH: 'thisMonth',
        LAST_30_DAYS: 'last30Days',
        LAST_2_WEEKS: 'last2Weeks',
        LAST_MONTH: 'lastMonth',
        TWO_MONTHS_AGO: '2MonthsAgo',
        LAST_2_YEARS: 'last2Years',
      },
      MC: new MC(),
    }
  },
  computed: {
    ...mapGetters([
        'auth_connected',
        'auth_client',
        'domain',
      ]),
    checkinList: function() {
      return this.list;
    },
    
    max: function() {
      var t = (this.pageNumber + 1) * this.pageSize;
      return this.count < t ? this.count : t;
    },
    min: function() {
      if (this.count == 0) {
        return 0;
      }
      return (this.pageNumber * this.pageSize) + 1;
    },
  },
  watch: {
  },
  mounted: function () {
    if (!this.auth_connected) {
      this.$router.replace({ name: this.$route.query.redirectTo || ConstUtils.ROUTES.SIGNIN })
    } else {
      this.currentDateFilter = this.FILTER.LAST_30_DAYS;
      this.loadItems();
    }
  },
  methods: {
    refresh: function() {
      this.loadItems();
    },
    loadItems: function() {
      this.isLoading = true;
      this.list = [];
      this.loadingMessage = this.MC.CheckIn.Status.Loading.value();
      var company = this.domain.session().company();
      var filter = new CheckInListFilter(this.domain);
      filter
        .withPagination(this.pageSize, this.pageNumber)
        .withDateRange(this.currentDateFilter)
        .withCompany(company.id())
        .done();
      var event = this.domain.events().checkins().list(filter);
      event.send(this.listener);
    },
    listener: function(event) {
      if (event.error()) {
        this.error = event.error();
      } else {
        var payload = event.payload();
        var map = payload.valuesFor(CheckInMap.MODEL_NAME);
        this.count = payload.count();
        this.list = Object.values(map.data).sort(CheckInUtils.SortByCreatedDate);
      }
      this.isLoading = false;
      this.loadingMessage = null;
    },
    previousPage: function() {
      if (this.canPreviousPage()) {
        this.pageNumber--;
        this.loadItems();
      }
    },
    canPreviousPage: function() {
      return this.pageNumber > 0;
    },
    canNextPage: function() {
      return this.count > (this.pageSize * this.pageNumber) + this.pageSize;
    },
    nextPage: function() {
      if (this.canNextPage()) {
        this.pageNumber++;
        this.loadItems();
      }
    },
    isEven: function (position) {
      return StringUtils.isEven(position);
    },
    
    //Today
    filterToday: function() {
      this.currentDateFilter = this.FILTER.TODAY;
      this.loadItems();
    },
    isFilterToday: function() {
      return this.currentDateFilter === this.FILTER.TODAY;
    },
    
    //Yesterday
    filterYesterday: function() {
      this.currentDateFilter = this.FILTER.YESTERDAY;
      this.loadItems();
    },
    isFilterYesterday: function() {
      return this.currentDateFilter === this.FILTER.YESTERDAY;
    },

    //Last 2 Weeks
    filterLast2Weeks: function() {
      this.currentDateFilter = this.FILTER.LAST_2_WEEKS;
      this.loadItems();
    },
    isFilterLast2Weeks: function() {
      return this.currentDateFilter === this.FILTER.LAST_2_WEEKS;
    },

    //This month
    filterThisMonth: function() {
      this.currentDateFilter = this.FILTER.THIS_MONTH;
      this.loadItems();
    },
    isFilterThisMonth: function() {
      return this.currentDateFilter === this.FILTER.THIS_MONTH;
    },
    
    //Last Month
    filterLastMonth: function() {
      this.currentDateFilter = this.FILTER.LAST_MONTH;
      this.loadItems();
    },
    isFilterLastMonth: function() {
      return this.currentDateFilter === this.FILTER.LAST_MONTH;
    },

    //Last 2 Month
    filterTwoMonthsAgo: function() {
      this.currentDateFilter = this.FILTER.TWO_MONTHS_AGO;
      this.loadItems();
    },
    isFilterTwoMonthsAgo: function() {
      return this.currentDateFilter === this.FILTER.TWO_MONTHS_AGO;
    },

    //Last 2 Years
    filterLast2Years: function() {
      this.currentDateFilter = this.FILTER.LAST_2_YEARS;
      this.loadItems();
    },
    isFilterLast2Years: function() {
      return this.currentDateFilter === this.FILTER.LAST_2_YEARS;
    },

    filterLast30Days: function() {
      this.currentDateFilter = this.FILTER.LAST_30_DAYS;
      this.loadItems();
    },
    isFilterLast30Days: function() {
      return this.currentDateFilter === this.FILTER.LAST_30_DAYS;
    },

    //All
    filterAll: function() {
      this.currentDateFilter = this.FILTER.ALL;
      this.loadItems();
    },
    isFilterAll: function() {
      return this.currentDateFilter === this.FILTER.ALL;
    },
    reset: function () {
      this.currentDateFilter = this.FILTER.TODAY;
      this.pageNumber = 0;
      this.loadItems();
    },

  },
}
</script>