
import RcTableViewModel from "../../../../../shared/table/RcTableViewModel";

import ContentUtils from '@/utils/ContentUtils.js';
import StringUtils from '@/utils/StringUtils.js';
import ListViewUtils from "./ListViewUtils";

import TimesheetListFilter from "@/domain/model/timesheet/TimesheetListFilter.js";
import TimesheetMap from '@/domain/model/timesheet/TimesheetMap.js';

import EmployeeListFilter from "@/domain/model/employee/EmployeeListFilter.js";
import EmployeeMap from "@/domain/model/employee/EmployeeMap.js";
import InspectionListFilter from "@/domain/model/inspection/InspectionListFilter.js";
import InspectionMap from "@/domain/model/inspection/InspectionMap.js";
import RcTime from "@/domain/session/time/RcTime.js";

export default class ListViewModel extends RcTableViewModel {

  constructor(panel) {
    super(panel);
    this._companyId = ContentUtils.baseDecode(panel.$route.params.operatorId);
    this._userId = null;
    this._total = 0;
    this._error = null;
    this._itemList = [];
    this._pages = [];
    this._employeeMap = null;
    this._timesheetMap = null;
    this._inspectionMap = null;
    this._startFilterDate = RcTime.time().now().day();
    this.SU = StringUtils;
    this.CU = ContentUtils;
  }

  start() {
    super.start();
  }

  company() {
    return this.domain().companys().findById(this._companyId);
  }

  isAdmin() {
    return super.isAdmin();
  }

  withValue(valueData) {
    super.withValue(valueData, "compliance");
  }

  withButtonPressed(buttonData) {
    super.withButtonPressed(buttonData);
    if (!buttonData) {
      return;
    }
  }

  buildRowData(tableData) {
    var utils = new ListViewUtils(this, tableData);
    utils.start();
    return this;
  }

  loadItems() {
    super.loadItems();
    this._itemList = [];
    this._employeeMap = new EmployeeMap(this.domain());

    this._timesheetMap = new TimesheetMap(this.domain());
    this._inspectionMap = new InspectionMap(this.domain());

    var context = {};
    context["model"] = this;

    var query = this.panel().$route.query;
    var time = query["time"] ? query["time"] : RcTime.time().now().time();
    this._startFilterDate = this.domain().time().at(time).day();
    this.panel().displayProgress("Loading Employees");

    var filter = new EmployeeListFilter();
    filter
      .withPagination(this.pageSize(query, "compliance"), query["page"] ? (query["page"] - 1) : 0)
      .withState('active')
      .withComplianceType(query["type"] ? query["type"] : "included")
      .done();

    let search = query["search"] ? query["search"] : "";
    if (StringUtils.isNotEmpty(search)) {
      filter
        .withSearchText(search)
        .done();
    }

    filter
      .withCompany(this._companyId)
      .done();

    var event = this.domain().events().employees().list(filter);
    event.with("signatures", false);
    context["startTime"] = new Date();
    context["pageName"] = "compliance";
    event.send(this.loadedItemsListener, context);
  }

  loadedItemsListener(notification, context) {
    context["loadTimeEmployee"] = new Date();
    var model = context.model;
    if (notification.error()) {
      model.withError(notification.error());
      model._total = 0;
      model.loadedItemsFinished();
    } else {
      var payload = notification.payload();
      model._total = payload.count();
      var values = payload.valuesFor(EmployeeMap.MODEL_NAME);
      model._employeeMap = new EmployeeMap(model.domain(), values);
      model._itemList = model._employeeMap.sortByUserFirst();
      context["sortTimeEmployee"] = new Date();
      var query = model.panel().$route.query;
      model._pages = ContentUtils.PAGE_INDEXES(model._total, model.pageSize(query, "compliance"));
      model.loadTimesheetsByFilter(context);
    }
  }

  loadTimesheetsByFilter(context) {
    let model = context.model;
    model._timesheetMap = new TimesheetMap(model.domain());
    if (!model.company().hasTimesheets()) {
      model.loadInspectionsByFilter(context);
      return;
    }
    var time = "last2Weeks(" + model._startFilterDate.time() + ")";
    model.panel().displayProgress("Loading Timesheets");

    var filter = new TimesheetListFilter();
    filter
      .withDateRange(time)
      .withPagination(-1, -1)
      .withEmployees(model._employeeMap.pointers())
      .withCompany(model.company().id())
      .done();

    var event = model.domain()
      .events()
      .timesheets().list(filter);
    event.with("users", false);

    event.send(model.loadedTimesheets, context);
  }

  loadedTimesheets(notification, context) {
    context["loadTimeTimesheets"] = new Date();
    var model = context.model;
    if (notification.error()) {
      model.withError(notification.error());
      model.loadedItemsFinished();
    } else {
      var payload = notification.payload();
      var values = payload.valuesFor(TimesheetMap.MODEL_NAME);
      model._timesheetMap = new TimesheetMap(model.domain(), values);
      model.log().info("Timesheets Found: {0}", [model._timesheetMap.size()]);
      model.loadInspectionsByFilter(context);
    }
  }

  loadInspectionsByFilter(context) {
    var model = context.model;
    model._inspectionMap = new InspectionMap(model.domain);
    if (!model.company().hasInspections()) {
      model.loadedItemsFinished(context);
      return;
    }

    var time = "last2Weeks(" + model._startFilterDate.time() + ")";
    model.panel().displayProgress("Loading Inspections");
    var filter = new InspectionListFilter();
    filter
      .withDateRange(time)
      .withPagination(-1, -1)
      .withEmployees(model._employeeMap.pointers())
      .done();

    filter
      .withCompany(model.company().id())
      .done();

    var event = model.domain()
      .events()
      .inspections().list(filter);
    event.with("populate", false);

    event.send(model.loadedInspections, context);
  }

  loadedInspections(notification, context) {
    context["loadTimeInspections"] = new Date();
    var model = context.model;
    if (notification.error()) {
      model.withError(notification.error());
      model.loadedItemsFinished();
    } else {
      var payload = notification.payload();
      var values = payload.valuesFor(InspectionMap.MODEL_NAME);
      model._inspectionMap = new InspectionMap(model.domain(), values);
      model.log().info("Inspections Found: {0}", [model._inspectionMap.size()]);
      model.loadedItemsFinished(context);
    }
  }

  loadedItemsFinished(context) {
    let model = context.model;
    this.panel().displayProgress("Rendering");
    model
      .withStateContentLoaded()
      .clearAndBuildTableData()
      .done();
    context["renderTime"] = new Date();
    delete context.model;
    model.log().info("Client Performance {0}", [JSON.stringify(context, null, 2)]);
    model.log().info("Done model loadedItemsListener");
    model.panel().showDialogIfRequired();
  }

  startTime() {
    return this._startFilterDate;
  }

  itemList() {
    return this._itemList;
  }

  totalItems() {
    return this._total;
  }

  pages() {
    return this._pages;
  }

  error() {
    return this._error;
  }

}