<template>

  <Row v-if="ready">
    <Column>
      <VehicleImage
        size="width:200px;"
        :defaultValue="row.valueAsObject()" />
    </Column>
  </Row>
</template>
  
<script>

import { mapGetters } from 'vuex'

import ContentUtils from '@/utils/ContentUtils.js';

import VehicleImageDisplayRow from "./VehicleImageDisplayRow.js";

import MC from "@/domain/session/MC.js";
//import Box from "@/portals/shared/library/box/Box.vue";
import Row from "@/portals/shared/library/box/Row.vue";
import Column from "@/portals/shared/library/box/Column.vue";
import VehicleImage from "@/views/portal/vehicles/VehicleImage.vue";

export default {
name: 'vehicle-image-display-cell',
components: {
    //Box,
    VehicleImage,
    Row, Column,
},
props: {
    css:    { type: String, default: "" },
    showMissingRequirements: { type: Boolean, default: false },
    data: {},
},
data() {
    return {
      ready: false,
      rowData: new VehicleImageDisplayRow(),
      Contents: ContentUtils,
      MC: new MC(),
    }
},
computed: {
    ...mapGetters([
                    'domain',
                ]),
    row: function() {
    return this.rowData;
    },
},
mounted: function () {
    this.rowData.data = this.data;
    this.ready = true;
},
methods: {
    withValue: function(kvp) {
    if (kvp) {
        kvp['id'] = this.row.id();
        kvp['object'] = this.row.object();
        this.$emit('withValue', kvp);
    }
    }
},
}
</script>