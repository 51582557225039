
import RcDomainObject from "@/domain/common/RcDomainObject.js";

import AnswerMap from "@/domain/model/answer/AnswerMap.js";
import Vehicle from "@/domain/model/vehicle/Vehicle.js";
import Schedule from "@/domain/model/schedule/Schedule.js";
import StringUtils  from '@/utils/StringUtils.js';

//import RcTime from '@/domain/session/time/RcTime.js';
import RcDate from '@/domain/session/time/RcDate.js';

export default class Result extends RcDomainObject {
  
  static MODEL_NAME = "VehicleInspectionResult";
  
  static Fields = {
    RESULT_TYPE: "hasResultType",
    DEFECT_UNFIXED_TYPE: "unfixedType",
    ODOMETER: "odometer",
    VEHICLE: Vehicle.MODEL_NAME,
    SCHEDULE: "VehicleSchedule",
    ANSWERS: "VehicleInspectionAnswerMap",
    DEFECT_TYPE: "defectType",
    ATTACHED_DATE: "attachedDate",
    DETACHED_DATE: "detachedDate",
    Attached: "attached",
  }
  
  static Values = {
      DefectType: {
        DEFECT_TYPE_NONE: "None",
        DEFECT_TYPE_MINOR: "Minor",
        DEFECT_TYPE_MAJOR: "Major",
      },
      VehicleType: {
        RESULT_TYPE_VEHICLE: "vehicle",
      },
      AttachedType: {
        RESULT_ATTACHED: "attached",
      },
  }
  
  constructor(domain, data) {
    super(domain, data, Result.MODEL_NAME);
  }
  
  fields() {
    return Result.Fields;
  }
  
  vehicle() {
    var obj = this.get(Result.Fields.VEHICLE);
    return new Vehicle(this.domain, obj);
  }
  
  odometer() {
    return this.get(Result.Fields.ODOMETER);
  }
  
  defectType() {
    return this.getString(Result.Fields.DEFECT_TYPE);
  }
  
  isDefectTypeGood() {
    var defectType = this.defectType();
    if (defectType === "Major") {
      return false;
    }
    if (defectType === "Minor") {
      return false;
    }
    return true;
  }
  
  isDefectTypeMinor() {
    var defectType = this.defectType();
    if (defectType === "Minor") {
      return true;
    }
    return false;
  }

  isDefectTypeMajor() {
    var defectType = this.defectType();
    if (defectType === "Major") {
      return true;
    }
    return false;
  }

  isInitial() {
    var resultType = this.getString(Result.Fields.RESULT_TYPE);
    return resultType.indexOf("Initial") != -1;
  }

  isEnroute() {
    var resultType = this.getString(Result.Fields.RESULT_TYPE);
    return resultType.indexOf("Enroute") != -1;
  }
  
  hasSchedule() {
    return this.schedule().isNotNew();
  }
  
  schedule() {
    var scheduleData = this.get(Result.Fields.SCHEDULE);
    return new Schedule(this.domain, scheduleData);
  }

  answers() {
    var data = this.get(Result.Fields.ANSWERS);
    return new AnswerMap(this.domain, data);
  }
  
  withAnswers(answers) {
    this.put(Result.Fields.ANSWERS, answers.data);
    return this;
  }
  
  withUnfixedDefectTypeMajor() {
    this.put(Result.Fields.DEFECT_UNFIXED_TYPE, 
        Result.Values.DefectType.DEFECT_TYPE_MAJOR);
    return this;
  }

  withUnfixedDefectTypeMinor() {
    this.put(Result.Fields.DEFECT_UNFIXED_TYPE, 
        Result.Values.DefectType.DEFECT_TYPE_MINOR);
    return this;
  }

  withUnfixedDefectTypeNone() {
    this.put(Result.Fields.DEFECT_UNFIXED_TYPE, 
        Result.Values.DefectType.DEFECT_TYPE_NONE);
    return this;
  }
  
  isDetached() {
    return !this.isAttached();
  }
  
  resultType() {
    return this.getString(Result.Fields.RESULT_TYPE);
  }
  
  hasResultType() {
    return !StringUtils.isEmpty(this.resultType());
  }
  
  attachedDate() {
    return this.getInt(Result.Fields.ATTACHED_DATE);
  }
  
  attachedDateV2() {
    var date = this.attachedDate();
    var timeZone = this.timeZone();
    var rcDate = new RcDate(new Date(date), timeZone);
    return rcDate;
  }

  attachedDateDisplay() {
    var date = this.attachedDateV2();
    return date.displayAs_YYYY_MM_DD_HH_MM_SS_A();
  }
  
  detachedDate() {
    return this.getInt(Result.Fields.DETACHED_DATE);
  }
  
  detachedDateV2() {
    var date = this.detachedDate();
    var timeZone = this.timeZone();
    var rcDate = new RcDate(new Date(date), timeZone);
    return rcDate;
  }
  
  detachedDateDisplay() {
    var date = this.detachedDateV2();
    return date.displayAs_YYYY_MM_DD_HH_MM_SS_A();
  }
  
  isVehicle() {
    return this.resultType().startsWith(Result.Values.VehicleType.RESULT_TYPE_VEHICLE);
  }
  
  isAttached() {
    if (this.isVehicle()) {
      return true;
    }
    return this.getBoolean(Result.Fields.Attached);
  }

  mostSevereUnfixedDefectType() {
    return this.getString(Result.Fields.DEFECT_UNFIXED_TYPE);
  }
  
  
}