
import RcObject from "./RcObject";

// import { uuid } from 'vue-uuid'

import CanadianEnglish from "@/domain/session/CanadianEnglish.js"
import DateUtils  from '@/utils/DateUtils.js';
import StringUtils  from '@/utils/StringUtils.js';
import RcDate from '@/domain/session/time/RcDate.js';

export default class RcDomainObject extends RcObject {

  static DefaultFields = {
    CREATED_BY: 'createdBy',
    CREATED_DATE: 'createdDate',
    
    MODIFIED_BY: 'modifiedBy',
    MODIFIED_DATE: 'modifiedDate',
    STATE: 'state',
    ACTIVE: 'active',
    MODEL_NAME: '@class',
    TIME_ZONE: 'timeZone',
    ID: '@rid',
  }
  
  static DefaultValues = {
    State: {
      ACTIVE: 'active',
      LOCKED: 'locked',
      RUNNING: 'active:run',
      INACTIVE: 'inactive',
      DELETED: 'deleted',
    },
  }
  
  constructor(domain, data, modelName) {
    super(data);
    if (modelName) {
      this.put(RcDomainObject.DefaultFields.MODEL_NAME, modelName);
    }
    this.domain = domain;
  }
  
  setTimeZone(domain) {
    if (domain != null && domain.time() == null) {
      return;
    }

    if (StringUtils.isEmpty(this.timeZone())) {
      let zone = domain.time().timeZone();
      if (StringUtils.isNotEmpty(zone)) {
        this.withTimeZone(zone);
      }
    }
  }

  withTimeZone(zone) {
    this.put(RcDomainObject.DefaultFields.TIME_ZONE, zone);
    return this;
  }

  logger() {
    return this.domain.logger();
  }
  
  session() {
    return this.domain.session();
  }
  
  withStateActive() {
    this.put(RcDomainObject.DefaultFields.STATE, RcDomainObject.DefaultValues.State.ACTIVE);
    return this;
  }
  
  withStateInactive() {
    this.put(RcDomainObject.DefaultFields.STATE, RcDomainObject.DefaultValues.State.INACTIVE);
    return this;
  }

  withStateDeleted() {
    this.put(RcDomainObject.DefaultFields.STATE, RcDomainObject.DefaultValues.State.DELETED);
    return this;
  }

  withStateLocked() {
    this.put(RcDomainObject.DefaultFields.STATE, RcDomainObject.DefaultValues.State.LOCKED);
    return this;
  }

  withStateRunning() {
    this.put(RcDomainObject.DefaultFields.STATE, RcDomainObject.DefaultValues.State.RUNNING);
    return this;
  }

  state() {
    var state = this.getString(RcDomainObject.DefaultFields.STATE);
    return state;
  }
  
  isStateLocked() {
    var state = this.state();
    return state === RcDomainObject.DefaultValues.State.LOCKED;
  }

  isStateRunning() {
    var state = this.state();
    return state === RcDomainObject.DefaultValues.State.RUNNING;
  }

  isStateActive() {
    var state = this.state();
    if (StringUtils.isEmpty(state)) {
      var active = this.get("active");
      if (active) {
        return true;
      }
      return false;
    }
    return StringUtils.startsWith(state, RcDomainObject.DefaultValues.State.ACTIVE);
  }
  
  isStateActiveOnly() {
    var state = this.state();
    if (StringUtils.isEmpty(state)) {
      var active = this.get("active");
      if (active) {
        return true;
      }
      return false;
    }
    return StringUtils.eic(state, RcDomainObject.DefaultValues.State.ACTIVE);
  }

  isStateInactive() {
    var state = this.state();
    if (StringUtils.isEmpty(state)) {
      return true;
    }
    return state === RcDomainObject.DefaultValues.State.INACTIVE;
  }
  
  isStateDeleted() {
    var state = this.state();
    if (StringUtils.isEmpty(state)) {
      var deleted = this.getString("deleted");
      if (deleted === "deleted") {
        return true;
      } else {
        return false;
      }
    }
    return state === RcDomainObject.DefaultValues.State.DELETED;
  }

  withCreatedBy(user) {
    this.put(RcDomainObject.DefaultFields.CREATED_BY, user.data);
    return this;
  }
  
  withCreatedDateNow() {
    this.put(RcDomainObject.DefaultFields.CREATED_DATE, DateUtils.now());
    return this;
  }
  
  createdDate() {
    return this.get(RcDomainObject.DefaultFields.CREATED_DATE);
  }
  
  createdDateV2() {
    var createdDate = this.createdDate();
    var timeZone = this.timeZone();
    var rcDate = new RcDate(new Date(createdDate), timeZone);
    return rcDate;
  }
  
  createdDateDisplay() {
    return DateUtils.toDate(this.createdDate());
  }
  
  modifiedDate() {
    return this.get(RcDomainObject.DefaultFields.MODIFIED_DATE);
  }

  modifiedDateV2() {
    var date = this.domain.session().time().at(this.modifiedDate(), this.timeZone());
    return date;
  }

  modifiedDateDisplay() {
    return DateUtils.toDate(this.modifiedDate());
  }
  
  modelName() {
    return this.get(RcDomainObject.DefaultFields.MODEL_NAME);
  }
  
  with(value) {
    if (value.data) {
      this.put(value.modelName(), value.data);
      
    } else if (value.field) {
      this.put(value.field, value.value);

    } else {
      this.put(value.modelName(), value);
    }
    return this;
  }
  
  timeZone() {
    var tz = this.getString(RcDomainObject.DefaultFields.TIME_ZONE);
    if (StringUtils.isEmpty(tz) || tz === "0") {
      tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
    return tz;
  }

  lang() {
    if (this.domain) {
      var session = this.domain.session();
      if (session) {
        return session.lang();
      }
    }
    return new CanadianEnglish();
  }
  
  isSystem() {
    return this.id() === "#13:0";
  }
  
  isPointer() {
    return this.length() == 2 && this.has(RcDomainObject.DefaultFields.MODEL_NAME) && this.has(RcDomainObject.DefaultFields.ID);
  }
}