<template>
<Box v-if="showOldMenu()">

  <SignOutDialog v-show="showSignOut"   @close="closeSignOutDialog" />
  
  <Row v-if="false">
    <Column :width="15">
      <AppMenu :isSmall="true" />
    </Column>
  </Row>

  <Row v-if="hasCompany">
    <Column v-if="isAdmin" :width="2" class="rc-text-centre">
      Administration Portal
    </Column>
    <Column v-else :width="2"></Column>
    
    <Column :width="11">
      <Box :showBorder="true"> 
        <Row>
          <Column :width="4" class="rc-text-left">
            <img src="@/assets/2019/circlechek_logo.png" height="40px">
          </Column>
          <Column :width="7" class="rc-text-centre">
            <div class="rc-text-xlarge">
              <router-link to="/portal/operator/companys" v-if="hasCompanys" class="" onclick="w3_close()" >
                {{ companyName }}
              </router-link>
              <div v-else>{{ companyName }} </div>
            </div>
          </Column>
          <Column :width="4" class="rc-text-right">
            <router-link v-if="isDec()" to="/portal/profile" class="rc-text-medium rc-text-right"><img width="35px" height="35px" src="@/assets/2024/profilesantahat.jpg"/></router-link>
            <router-link v-else to="/portal/profile" class="rc-text-medium rc-text-right"><img width="35px" height="35px" src="@/assets/2024/profile.png"/></router-link>
            <span class="rc-text-large">Hi {{ first() }}!&nbsp;&nbsp;&nbsp;</span><br>
            <span class="clickable rc-text-small" v-on:click="signout">Sign Out&nbsp;&nbsp;&nbsp;</span>
          </Column>
        </Row>
      </Box>
    </Column>

  </Row> <!-- row -->
  
  <Row v-if="hasCompany && !isNew">
    <Column :width="15">
        <AppPortalMenu />
    </Column>
  </Row>
  
</Box><!-- OldMenu Container -->
</template>

<script>

import { mapGetters } from 'vuex';
import AppPortalMenu from './AppPortalMenu.vue';
import ContentUtils from '@/utils/ContentUtils.js';
import Box from "@/portals/shared/library/box/Box.vue";
import Row from "@/portals/shared/library/box/Row.vue";
import Column from "@/portals/shared/library/box/Column.vue";
import SignOutDialog from "@/portals/customer/operator/views/sign/out/SignOutDialog.vue";
import RcTime from '@/domain/session/time/RcTime';

export default {
  name: 'app-header',
  components: {
    Box, Row, Column,
    // AppMenu,
    AppPortalMenu,
    SignOutDialog,
  },
  data() {
    return {
      connected: false,
      hasCompany: false,
      companyName: null,
      isAdmin: false,
      isProduction: true,
      showSignOut: false,
    };
  },
  computed: {
    ...mapGetters([
                   'user_roles',
                   'auth_client',
                   'auth_connected',
                   'auth_user',
                   'AuthStorage_Company',
                   'domain',
      ]),
    isConnected: function() {
      return this.connected;
    },
    hasCompanys: function() {
      var user = this.domain.session().user();
      var employees = this.domain.employees().findByUserId(user.id());
      return employees.length() > 1;
    },
  },
  watch: {
    AuthStorage_Company() {
      if (this.AuthStorage_Company) {
        this.companyName = this.AuthStorage_Company.name();
        this.hasCompany = true;
        this.isNew = this.AuthStorage_Company.isNew();
      } else {
        this.companyName = null;
        this.hasCompany = false;
      }
      this.isAdmin = this.domain.session().employee().find().roles().find().isAdministrator();
      this.isProduction = !this.auth_client.debug;
    },
  },
  mounted: function() {
    this.isProduction = !this.auth_client.debug;
  },
  methods: {
    isDec() {
      let rcTime = RcTime.time();
      let now = rcTime.now();
      if (now.month().value() == 11) {
        return true;
      }
      return false;
    },

    decode: function(value) {
      return ContentUtils.unescape(value);
    },
    first: function() {
      if (!this.domain || !this.domain.session()) {
        return "";
      }
      var name = this.domain.session().user().firstDisplay();
      return name;
    },
    email: function() {
      if (!this.domain || !this.domain.session()) {
        return "";
      }
      return this.domain.session().user().emailDisplay();
    },
    showOldMenu: function() {
      return true;
    },
    signout: function() {
      this.showSignOut = true;
    },
    closeSignOutDialog: function() {
     this.showSignOut = false;
    },
  },
}

</script>