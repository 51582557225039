
import RcTableViewRow from "@/portals/shared/cell/common/RcTableViewRow.js";

export default class ButtonDisplayRow extends RcTableViewRow {

  static Id = "ButtonDisplayRow";
  static Background = "background";
  
  constructor() {
    super();
    this.put(RcTableViewRow.TypeId, ButtonDisplayRow.Id);
    this.withShowLabel(false);
  }

  withBackgroundPrimaryAction() {
    this.put("background", "go");
    return this;
  }

  withBackgroundSecondaryAction() {
    this.put("background", "go2");
    return this;
  }

  withBackgroundMajorAction() {
    this.put("background", "major");
    return this;
  }
  
  withBackgroundNoAction() {
    this.put("background", "no");
    return this;
  }

  backgroundAction() {
    return this.get("background");
  }
  
  isBackgroundPrimaryAction() {
    if (this.isSelected()) {
      return true;
    }
    return this.backgroundAction() === "go";
  }

  isBackgroundSecondaryAction() {
    if (this.isSelected()) {
      return true;
    }
    return this.backgroundAction() === "go2";
  }

  isBackgroundMajorAction() {
    if (this.isSelected()) {
      return true;
    }
    return this.backgroundAction() === "major";
  }

  isBackgroundNoAction() {
    return this.backgroundAction() === "no";
  }

  withStyleMajor() {
    this.put("style", "major");
    return this;
  }
}