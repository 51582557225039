<template>
  <Row v-if="ready" cmpt="StringUpdateCell" :tagValue="row.key()" :refValue="row.key()">
    <Column :width="15" >
      <Box>
        <KeyValue :class="css"
          :title="row.field()" 
          :showLabel="row.showLabel()"
          :placeholder="row.name()" 
          :required="showMissingRequirements"
          :defaultValue="row.defaultValue()"
          :mandatory="row.mandatoryValue()"
          :hint="row.hint()"
          :hintUrl="row.hintUrl()"
          v-on:withEnterPressed="withEnterPressed"
          v-on:value="withValue" 
          />
      </Box>
    </Column>
  </Row>
</template>

<script>

import { mapGetters } from 'vuex'

import StringUpdateRow from "./StringUpdateRow.js";
import Box from "@/portals/shared/library/box/Box.vue";
import Row from "@/portals/shared/library/box/Row.vue";
import Column from "@/portals/shared/library/box/Column.vue";

import KeyValue from '@/components/input/KeyValue.vue';

export default {
  name: 'string-update-cell',
  components: {
    KeyValue,
    Box, Row, Column,
  },
  props: {
    css:    { type: String, default: "" },
    showMissingRequirements: { type: Boolean, default: false },
    data: null,
  },
  data() {
    return {
      ready: false,
      rowData: new StringUpdateRow(),
      kvps: null,
    }
  },
  computed: {
    ...mapGetters([
                   'domain',
                 ]),
    row: function() {
      return this.rowData;
    },
  },
  mounted: function () {
    this.rowData.data = this.data;
    this.ready = true;
  },
  methods: {
    withEnterPressed(title) {
      let kvp = {};
      kvp['id'] = this.row.id();
      kvp['object'] = this.row.object();
      kvp['field'] = this.row.field();
      kvp['key'] = this.row.key();
      kvp['title'] = title;
      this.$emit('withEnterPressed', kvp);
    },

    withValue: function(kvp) {
      if (kvp) {
        kvp['id'] = this.row.id();
        kvp['object'] = this.row.object();
        kvp['field'] = this.row.field();
        kvp['key'] = this.row.key();
        this.kvps = kvp;
        this.$emit('withValue', kvp);
      }
    }
  },
}
</script>