
import RcDomainObject from "../../common/RcDomainObject";
import Company from "../company/Company.js";
import CheckListItemMap from "./item/CheckListItemMap.js";
import CheckListMap from "./CheckListMap.js";
import StringUtils from '@/utils/StringUtils.js'
import User from "../user/User.js";

export default class CheckList extends RcDomainObject {
  
  static MODEL_NAME = 'CheckList';
  
  static Fields = {
      Name: "name",
      Description: "description",
      CREATED_DATE: "createdDate",
      COMPANY: "Company",
      CheckListItemMap: CheckListItemMap.MODEL_NAME,
  }
  
  constructor(domain, data) {
    super(domain, data, CheckList.MODEL_NAME);
  }

  fields() {
    return CheckList.FIELDS;
  }
  
  find() {
    var result = this.domain.checklists().findById(this.id());
    if (result.id() !== this.id()) {
      result = this;
    }
    
    return result;
  }

  name() {
    return this.getString(CheckList.Fields.Name);
  }
  
  withName(name) {
    this.put(CheckList.Fields.Name, name);
    return this;
  }
  
  description() {
    return this.getString(CheckList.Fields.Description);
  }
  
  withDescription(description) {
    this.put(CheckList.Fields.Description, description);
    return this;
  }
  
  hasDescription() {
    return !StringUtils.isEmpty(this.description());
  }
  
  items() {
    var data = this.get(CheckList.Fields.CheckListItemMap);
    var map = new CheckListItemMap(this.domain, data);
    return map;
  }
  
  withItems(items) {
    this.put(CheckList.Fields.CheckListItemMap, items);
    return this;
  }
  
  company() {
    var data = this.get(CheckList.Fields.COMPANY);
    var company = new Company(this.domain, data);
    return company;
  }
  
  map() {
    var map = new CheckListMap(this.domain);
    map.add(this);
    return map;
  }
 
  populate() {
    var items = this.items();
    items.populate()
    this.withItems(items);
    return this;
  }
  
  pointer() {
    var data = {'@rid': this.id()};
    var checklist = new CheckList(this.domain, data);
    return checklist;
  }
  
  copy() {
    var data = this._copy();
    return new CheckList(this.domain, data);
  }

  withCompany(company) {
    this.put(CheckList.Fields.COMPANY, company.pointer());
    return this;
  }

  createdBy() {
    return new User(this.domain, this.get(RcDomainObject.DefaultFields.CREATED_BY));
  }

  modifiedBy() {
    return new User(this.domain, this.get(RcDomainObject.DefaultFields.MODIFIED_BY));
  }
}