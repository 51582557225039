<template>
  <Row>
  <Column :width="15">
  <transition name="rc-modal-fade">
    <div class="rc-modal-backdrop">
      <div class="rc-modal">
      
        <Box>
          <TitleRow :left="4" :right="4"> {{ MC.Sign.Out.Title.value() }} </TitleRow>
          <ErrorRow :error="error" />
          <SpacerRow />
          <Row>
            <Column :width="3" />
            <Column :width="9">
              <Spacer />
              <Value align="centre" fontSize="large"> {{ MC.Sign.Out.Message.value() }} </Value>
              <Spacer />
            </Column>
            <Column :width="3" />
          </Row>
          
          <Row >
            <Column :width="3" />
            <Column :width="4">
              <Button v-on:click="back(false)" padding="5px">Back</Button>
            </Column>
            <Column :width="1" />
            <Column :width="4" >
              <Button :go="true" v-on:click="signOut"> {{ MC.Sign.Out.Confirm.value() }} </Button>
            </Column>
            <Column :width="3" />
          </Row>
          <SpacerRow />
        </Box>
      </div>
    </div>
      
  </transition>
  </Column>
  </Row>
</template>

<script>

import { mapGetters, mapActions, mapMutations } from 'vuex';
import MC from "@/domain/session/MC.js";

import ConstUtils   from '@/utils/ConstUtils.js';
import ContentUtils from '@/utils/ContentUtils.js';
import StringUtils  from '@/utils/StringUtils.js';
import UserEvents from '@/domain/model/user/UserEvents.js';

import Box from "@/portals/shared/library/box/Box.vue";
import Row from "@/portals/shared/library/box/Row.vue";
import Column from "@/portals/shared/library/box/Column.vue";

import Value from "@/portals/shared/library/value/Value.vue";
import Button from "@/portals/shared/library/button/Button.vue";
import Spacer from "@/portals/shared/library/spacer/Spacer.vue";

import SpacerRow from "@/portals/shared/library/spacer/SpacerRow.vue";

import TitleRow from "@/portals/shared/library/title/TitleRow.vue";
import ErrorRow    from '@/components/row/ErrorRow.vue';

export default {
  name: "sign-out-dialog",
  components: {
    Box, Row, Column,
    TitleRow, Spacer, SpacerRow,
    Button, Value,
    ErrorRow, 
  },
  props: {
    isAdmin: { type: Boolean, default: false },
    inspectionId: { type: String, default: "" },
  },
  data() {
    return {
      error: null,
      missing: false,
      
      isLoading: true,
      loadingMessage: null,
      MC: new MC(),
      Consts: ConstUtils,
      
      Strings: StringUtils,
      Contents: ContentUtils,
      
      Fields: {
        EMAIL: "email",
      },
      kvps: {},
      isSending: false,
    };
  },
  computed: {
    ...mapGetters([
      'auth_connected',
      'auth_socket_status',
      'signin_event',
      'auth_client',
      'domain',
    ]),
    itemId: function() {
      return this.$route.params['id'];
    },
  },
  watch: {
    
  },
  mounted: function () {
  },
  methods: {
    ...mapActions([
                   'AuthStorage_updateCompany',
                   'addListenerAction',
                   'removeListenerAction',
                   'updateDomain',
                   ]),
    ...mapMutations([
                   'authClear',
                   ]),

    start: function() {
      this.isLoading = false;
      this.loadingMessage = this.MC.Status.Loading.value();
    },
    
    callback: function(msg) {
      this.loadingMessage = msg;
    },
    back: function(good) {
      this.$emit("close", good);
    },
    signOut: function() {
      if (this.auth_connected) {
        var event = this.domain.events().userSignOut();
        event.send(this.listener);
        this.doneSignOut();
      } else {
        this.doneSignOut();
      }
    },
    
    listener: function(event) {
      if (event.is(UserEvents.SignOut.Names.Response)) {
        if (event.error()) {
          this.error = event.error();
        } else {
          this.doneSignOut()
        }
      }
    },
    
    doneSignOut: function() {
      this.authClear();
      this.$socket.close();
      var cookie = this.$cookie.get("readychek.ca");
      if (cookie) {
        cookie = JSON.parse(cookie);
        cookie.auto = false;
        cookie['Company'] = "";
        var cookieString = JSON.stringify(cookie);
        this.$cookie.set("readychek.ca", cookieString, 365);
        window.localStorage.readyChek = cookieString;
      }
      var states = this.$store.state
      if (states) {
        var keys = Object.keys(states)
        for (var keyIndex = 0 ; keyIndex < keys.length; keyIndex++) {
          var key = keys[keyIndex];
          var state = states[key];
          if (state.list) {
            state.list = [];
          }
          if (state.map) {
            state.map = {};
          }
        }
      }
      this.back(true);
      this.$router.replace({ name: this.$route.query.redirectTo || ConstUtils.ROUTES.SIGNIN });      
    }
   },
}
</script>