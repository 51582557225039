
import MC from "@/domain/session/MC.js";
import RcTableViewUtils from "../../../../../../shared/table/RcTableViewUtils";
//import StringDisplayRow from "@/portals/shared/cell//string/StringDisplayRow";
//import LabelValueDisplayRow from "@/portals/shared/cell//label/LabelValueDisplayRow";
// import TitleDisplayRow from "@/portals/shared/cell/title/TitleDisplayRow";
//import ButtonDisplayRow from "@/portals/shared/cell/button/ButtonDisplayRow";

import TitleDisplayRow from "@/portals/shared/cell/title/TitleDisplayRow";
import ButtonDisplayRow from "@/portals/shared/cell/button/ButtonDisplayRow";
import BoxDisplayRow from "@/portals/shared/cell/box/BoxDisplayRow";
import RowDisplayRow from "@/portals/shared/cell/row/RowDisplayRow";
import StringDisplayRow from "@/portals/shared/cell//string/StringDisplayRow";


export default class DetailsDisplayViewUtils extends RcTableViewUtils {

  constructor(model, tableData) {
    super(model, tableData);
    this.MC = new MC();
  }

  start() {
    if (this.model().isStatePageLoading()) {
      super.start("Loading Timesheet Properties");
      return;
    }

    if (this.model().isStateContentSaving()) {
      this.renderSaving();
      return;
    }

    if (this.model().isStateContentSaved()) {
      this.renderSaved();
      return;
    }


    if (this.model().isStateContentLoading()) {
      super.start("Loading Timesheet Details");
      return;
    }

    if (this.model().isStateContentLoaded()) {
      this.renderHeader();
      this.renderBody();
    }

    this.renderFooter();
  } 

  renderBody() {
    this._tableData._tableData = [];
  }

  renderHeader() {
    var timesheet = this.model().timesheet();
    var buttonRow = new RowDisplayRow()
                    .withChild(new StringDisplayRow().withWidth(9));

    buttonRow
            .withChild(new ButtonDisplayRow()
                            .withWidth("2")
                            .withName("Refresh")
                            .withField("Refresh")
                            .withId(timesheet.id()))
            .withChild(new ButtonDisplayRow()
                            .withWidth("2")
                            .withName("Print/Download")
                            .withField("Print")
                            .withId(timesheet.id()))
            .withChild(new ButtonDisplayRow()
                            .withWidth("2")
                            .withName("Full Screen")
                            .withField("Full")
                            .withId(timesheet.id()))

    this.addHeader(new BoxDisplayRow()
            .withBorder(true)
            .withChild(buttonRow)
          );
    this.addHeader(new TitleDisplayRow().withTitle("Hours of Service"));
  }

  renderFooter() {
    this.addFooter(new BoxDisplayRow()
                  .withBorder(true)
                  .withChild(
                    new RowDisplayRow()
                      .withChild(new ButtonDisplayRow().withWidth("2").withName("Close").withField("Close"))
                      .withChild(new StringDisplayRow().withWidth("13"))
                  )
              );
  }
}