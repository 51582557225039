<template>

  <ValueRow v-if="ready" :align="rowData.align()" :fontSize="rowData.fontSize()">
    {{ Contents.decode(rowData.value()) }}
  </ValueRow>
  
</template>

<script>

import { mapGetters } from 'vuex'

import ContentUtils from '@/utils/ContentUtils.js';
import ValueDisplayRow from "./ValueDisplayRow.js";

import ValueRow from "@/portals/shared/library/value/ValueRow.vue";
import MC from "@/domain/session/MC.js";

export default {
  name: 'value-display-cell',
  components: {
    ValueRow,
  },
  props: {
    css:    { type: String, default: "" },
    showMissingRequirements: { type: Boolean, default: false },
    data: null,
  },
  data() {
    return {
      ready: false,
      rowData: new ValueDisplayRow(),
      Contents: ContentUtils,
      MC: new MC(),
    }
  },
  computed: {
    ...mapGetters([
                   'domain',
                 ]),
    row: function() {
      return this.rowData;
    },
  },
  mounted: function () {
    this.rowData.data = this.data;
    this.ready = true;
  },
  methods: {
    withValue: function(kvp) {
      if (kvp) {
        kvp['id'] = this.row.id();
        kvp['object'] = this.row.object();
        this.$emit('withValue', kvp);
      }
    }
  },
}
</script>