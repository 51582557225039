import RcTableViewRow from "@/portals/shared/cell/common/RcTableViewRow.js";

import Schedule from "@/domain/model/schedule/Schedule.js";

export default class ScheduleRow extends RcTableViewRow {
    
    static Id = "ScheduleRow";
    
    constructor() {
        super();
        this.put(RcTableViewRow.TypeId, ScheduleRow.Id);
    }
    
    withSchedule(schedule) {
        this.withObject(schedule.data);
        this.withId(schedule.id());
        this.withField(schedule.modelName());
        this.withKey(schedule.modelName());
        return this;
    }
    
    schedule(domain) {
        return new Schedule(domain, this.object()); 
    }
}