
import RcDomainMap from "../../common/RcDomainMap.js";
import CheckList from "./CheckList.js";
import CheckListUtils from "./CheckListUtils.js";

export default class CheckListMap extends RcDomainMap {
  
  static MODEL_NAME = "CheckListMap";

  constructor(domain, data) {
    super(domain, data, CheckListMap.MODEL_NAME);
  }
  
  find() {
    var keys = this.keys();
    var found = new CheckListMap(this.domain, {} );
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var item = this.getCheckList(key);
      if (item.isNew()) {
        found.add(item);
      } else {
        var data = this.domain.checklists().findById(key);
        item = new CheckList(this.domain, data);
        if (!item.isNew()) {
          found.add(item);
        }
      }
    }
    
    return found;
  }
  
  list() {
    var values = [];
    var keys = this.keys();
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var item = this.getCheckList(key);
      values.push(item);
    }
    return values;
  }
  
  sort() {
    var list = this.list();
    return list.sort(CheckListUtils.SortByCreatedDate);
  }
  
  sortByName() {
    var list = this.list();
    var result = list.sort(CheckListUtils.SortByName);
    return result;
  }
  
  sortByNameDesc() {
    var list = this.list();
    return list.sort(CheckListUtils.SortByNameDesc);
  }
  
  first() {
    var all = this.list();
    if (all.length > 0) {
      return all[0];
    }
    return new CheckList(this.domain);
  }
  
  getCheckList(key) {
    var value = super.get(key);
    return new CheckList(this.domain, value);
  }
  
  copy() {
    var data = this._copy();
    return new CheckListMap(this.domain, data);
  }

  addChecklist(checklist) {
    this.add(checklist).done;
    return this;
  }
}