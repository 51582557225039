const state = {
  list: [],
  map: {},
  pages: {},
};

const getters = {
};

const actions = {
};

const mutations = {
};

export default {
  state,
  getters,
  actions,
  mutations,
};
