<template>
  <Row>
    <Column :width="15">
      <div id="detailsModal" class="rc-modal-dialog">
        <!-- Modal content -->
        <div class="rc-modal-content-dialog">
          <div class="rc-modal-header-dialog" :key="redraw">
            <Box>
              <WorkingDisplayRow v-if="this.showDialog === 'workingDialog'"></WorkingDisplayRow>
              <Row 
                v-for="(row, rowIndex) in this.headerData" 
                :key="rowIndex" >
                  <CellFactory 
                    :rowData="row.data"
                    :showMissingRequirements="showMissingRequirements"
                    v-on:withValue="withValue"
                    v-on:withButtonPressed="withButtonPressed"
                    />
              </Row>
            </Box>
          </div>

          <div class="rc-modal-body-dialog" :key="redraw + 1">
            <div class="rc-modal-body-inner-dialog rc-border-thin">
              <Box>
                <Row 
                  v-for="(row, rowIndex) in this.viewData" 
                  :key="rowIndex" >
                    <CellFactory 
                      :rowData="row.data"
                      :showMissingRequirements="showMissingRequirements"
                      v-on:withValue="withValue"
                      v-on:withButtonPressed="withButtonPressed"
                      />
                </Row>
              </Box>
            </div>
          </div>

          <div class="rc-modal-footer-dialog" :key="redraw + 2">
            <Box>
              <Row 
                v-for="(row, rowIndex) in this.footerData" 
                :key="rowIndex" >
                  <CellFactory 
                    :rowData="row.data"
                    :showMissingRequirements="showMissingRequirements"
                    v-on:withValue="withValue"
                    v-on:withButtonPressed="withButtonPressed"
                    />
              </Row>
            </Box>
          </div>
        </div>
      </div>
    </Column>
  </Row>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';

import ConnectionUtils from '@/utils/ConnectionUtils.js';
import ConstUtils from '@/utils/ConstUtils.js';
import ContentUtils from '@/utils/ContentUtils.js';
import StringUtils from '@/utils/StringUtils.js';

import MC from "@/domain/session/MC.js";

import Column from "@/portals/shared/library/box/Column.vue";
import Row from "@/portals/shared/library/box/Row.vue";
import Box from "@/portals/shared/library/box/Box.vue";
import CellFactory from "@/portals/shared/cell/CellFactory.vue";

import Employee from "@/domain/model/employee/Employee.js";
import User from "@/domain/model/user/User.js";

import WorkingDisplayRow from '@/portals/shared/views/working/WorkingDisplayRow.vue';
import AddViewModel from './AddViewModel.js';

export default {
  name: "employee-list-add-view",
  components: {
    Column, Row, 
    Box,
    CellFactory,
    WorkingDisplayRow,
  },
  props: {
    userId: { type: String, default: "" },
    employeeId: { type: String, default: "" },
    isAdmin:   { type: Boolean, default: false },
  },
  data() {
    return {
      redraw: 1,
      
      MC: new MC(),
      Strings: StringUtils,
      Contents: ContentUtils,
      Consts: ConstUtils,

      showMissingRequirements: false,
      headerData: [],
      viewData: [],
      footerData: [],

      error: null,
      viewModel: null,
      showDialog: null,
      isReady: false,

      AddViewModel: AddViewModel,
    };
  },
  computed: {
    ...mapGetters([
                   'auth_connected',
                   'auth_socket_status',
                   'signin_event',
                   'auth_client',
                   'domain',
                   ]),
  },
  watch: {
    auth_connected() {
      ConnectionUtils.sendSignInEvent(this);
    },
    auth_socket_status() {
      ConnectionUtils.displayStatus(this);
    },
    signin_event() {
      ConnectionUtils.createDomainSession(this);
    }
  },
  mounted: function () {
      this.viewModel = new this.AddViewModel(this, new User(this.domain), new Employee(this.domain));
      this.viewModel.withStatePageLoading();
      this.viewModel.start();
      ConnectionUtils.ensureConnection(this);
    },
  methods: {
    ...mapActions([
                   'AuthStorage_updateCompany',
                   'addListenerAction',
                   'removeListenerAction',
                   'updateDomain',
                   ]),

    start: function() {
      //window.onclick = this.onClick;
      this.viewModel.withStateContentLoading();
      this.viewModel.clearAndBuildTableData();
      this.paint();
      this.viewModel.loadData();
    },


    paint: function() {
      this.redraw++;
    },

    log() {
      return this.domain.logger();
    },

    onClick: function(event) {
      var modal = document.getElementById("detailsModal");
      if (event.target == modal) {
        this.close();
      }
    },
    
    close(refresh) {
      var data = {};
      data['id'] = this.inspectionId;
      data['field'] = "Close";
      data["refresh"] = refresh;
      this.$emit('click', data);
    },

    withValue: function(valueData) {
      this.viewModel.withValue(valueData);
    },

    withButtonPressed: function(buttonData) {
      this.viewModel.withButtonPressed(buttonData);
    },

    displayProgress: function(msg) {
      this.callback(msg);
    },

    callback: function(msg) {
      if (msg) {
        //
      }
    },

    showDialogIfRequired() {
      //
    },

    reloadPage: function() {
      this.viewModel.clearAndBuildTableData();
      this.paint();
    },
  },
}
</script>