
import RcTableViewRow from "@/portals/shared/cell/common/RcTableViewRow.js";

export default class LabelValueDisplayRow extends RcTableViewRow {

  static Id = "LabelValueDisplayRow";
  static LabelWidth = "labelWidth";

  constructor() {
    super();
    this.put(RcTableViewRow.TypeId, LabelValueDisplayRow.Id);
    this.withFontSize("medium");
  }
  
  withAlignLeft() {
    this.withAlign("left");
    return this;
  }
  
  withAlign(align) {
    this.put("align", align);
    return this;
  }
  
  align() {
    return this.getString("align");
  }
  
  withLabel(label) {
    this.withName(label).done();
    return this;
  }
  
  withLabelWidth(number) {
    super.withLabelWidth(number);
    return this;
  }

  label() {
    return this.name();
  }
  
}