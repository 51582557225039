
<template>
  <Box> <!-- root -->
    <ErrorRow :error="error" />
    <SuccessRow :message="message" /> 
    
    <Row v-if="isAdmin">
      <Column :width="15">
        <div class="rc-text-centre">
        Admin View - Home
        </div>
      </Column>
    </Row>

    <StatsRow v-if="!isLoading"/>
    
    <LoadingRow :showBorder="true" v-if="isLoading">{{loadingMessage}}</LoadingRow>

  </Box> <!-- root -->
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import ConstUtils   from '@/utils/ConstUtils.js';
import ContentUtils from '@/utils/ContentUtils.js';
import ConnectionUtils from '@/utils/ConnectionUtils.js';
import StringUtils  from '@/utils/StringUtils.js';
import ErrorRow    from '@/components/row/ErrorRow.vue';
import SuccessRow  from "@/components/row/SuccessRow.vue";

import MC from "@/domain/session/MC.js";

import StatsRow from "./HomeStatsRow.vue"

import Box from '@/portals/shared/library/box/Box.vue';
import LoadingRow from "@/portals/shared/library/loading/LoadingRow.vue"; 

export default {
  name: 'portal-operator-home',
  components: {
    ErrorRow, LoadingRow, SuccessRow,
    Box,
    StatsRow, 
  },
  data() {
    return {
      error: null,
      message: null,
      isAdmin: false,
      
      isLoading: true,
      loadingMessage: null,
      
      MC: new MC(),
      Strings: StringUtils,
      Contents: ContentUtils,
      Consts: ConstUtils,
      
      showSessionTimeoutDialog: false,
    }
  },
  computed: {
    ...mapGetters([
                  'auth_connected',
                  'auth_socket_status',
                  'signin_event',
                  'auth_client',
                  'domain',
                 ]),
    itemId: function() {
      return this.$route.params['id'];
    },
  },
  watch: {
    auth_connected() {
      ConnectionUtils.sendSignInEvent(this);
    },
    auth_socket_status() {
      ConnectionUtils.displayStatus(this);
    },
    signin_event() {
      ConnectionUtils.createDomainSession(this);
    }
  },
  mounted: function () {
    ConnectionUtils.ensureConnection(this);
  },
  methods: {
    ...mapActions([
                   'AuthStorage_updateCompany',
                   'addListenerAction',
                   'removeListenerAction',
                   'updateDomain',
                   ]),

    start: function() {
      this.isLoading = false;
      this.loadingMessage = this.MC.Status.Done.value();
    },
    callback: function(msg) {
      this.loadingMessage = msg;
    },
  },
}
</script>