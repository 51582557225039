
import RcTableViewModel from "../../../../../../shared/table/RcTableViewModel";
import DetailsDisplayViewUtils from "./DetailsDisplayViewUtils";

export default class DetailsDisplayViewModel extends RcTableViewModel {
    
  constructor(panel, scheduleId) {
    super(panel);
    this._total = 0;
    this.scheduleId = scheduleId;
    this._error = null;
  }

  start() {
    super.start();
  }

  isAdmin() {
    return super.isAdmin();
  }

  withValue(valueData) {
    super.withValue(valueData, "schedule");
  }

  withButtonPressed(buttonData) {
    super.withButtonPressed(buttonData);
    if (!buttonData) {
      return;
    }

  }

  buildRowData(tableData) {
    var utils = new DetailsDisplayViewUtils(this, tableData);
    utils.start();
    return this;
  }

  error() {
    return this._error;
  }

  loadItems() {
    this
      .withStateContentLoading()
      .clearAndBuildTableData()
      .done();
    this.loadedItems();
  }

  loadedItems() {
    this
      .withStateContentLoaded()
      .clearAndBuildTableData()
      .done();
  }


}