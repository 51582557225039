import RcTableViewUtils from "../../../../../shared/table/RcTableViewUtils";

import LabelValueDisplayRow from "@/portals/shared/cell//label/LabelValueDisplayRow";
import StringDisplayRow from "@/portals/shared/cell//string/StringDisplayRow";
import BoxDisplayRow from "@/portals/shared/cell/box/BoxDisplayRow";
import ButtonDisplayRow from "@/portals/shared/cell/button/ButtonDisplayRow";
import RowDisplayRow from "@/portals/shared/cell/row/RowDisplayRow";
import SpacerDisplayRow from "@/portals/shared/cell/spacer/SpacerDisplayRow";
import TitleDisplayRow from "@/portals/shared/cell/title/TitleDisplayRow";

import MC from "@/domain/session/MC.js";
import ContentUtils from '@/utils/ContentUtils.js';
import StringUtils from '@/utils/StringUtils.js';

export default class DisplayViewUtils extends RcTableViewUtils {

  constructor(model, tableData) {
    super(model, tableData);
    this.MC = new MC();
    this.CU = new ContentUtils();
    this.SU = new StringUtils();
  }

  start() {
    super.start();
    if (this.model().isStatePageLoading()) {
      return;
    }
    if (this.model().isStateContentLoading()) {
      return;
    }

    if (!this.model().isStateContentLoaded()) {
      return;
    }

    this.renderHeader();
    this.renderBody();
    this.renderFooter();
  }


  renderHeader() {
    // this.add(new TitleDisplayRow().withTitle("Billing"));
    //
  }

  renderBody() {
    var buttonBoxRow = new RowDisplayRow()
      .withChild(new SpacerDisplayRow().withWidth(11));

    if (this.model().isAdmin()) {
      buttonBoxRow
        .withChild(new ButtonDisplayRow()
          .withName("Remove")
          .withField("RemoveCcButton")
          .withKey("RemoveCcButton")
          .withWidth(2).withId(this.model().settings().card().id()))
    } else {
      buttonBoxRow
        .withChild(new SpacerDisplayRow().withWidth(2));
    }

    buttonBoxRow
      .withChild(new ButtonDisplayRow()
        .withName("Update")
        .withField("UpdateCcButton")
        .withKey("UpdateCcButton")
        .withWidth(2).withId(this.model().settings().card().id()));
    var buttonBox = new BoxDisplayRow().withChild(buttonBoxRow);

    this.add(new BoxDisplayRow().withChild(new RowDisplayRow()
      .withChild(new StringDisplayRow().withWidth("3"))
      .withChild(new BoxDisplayRow().withWidth("9").withBorder(true)
        .withChild(new TitleDisplayRow().withTitle("Payment"))
        .withChild(new BoxDisplayRow().withBorder(true)
          .withChild(new StringDisplayRow().withValue("Credit Cards will be charged between the 15th and 30th of the month"))
          .withChild(new StringDisplayRow().withValue("We can not accept American Express Cards"))
        )
        .withChild(new LabelValueDisplayRow().withLabel("Name").withValue(this.model().settings().card().find().name()))
        .withChild(new LabelValueDisplayRow().withLabel("Number").withValue(this.model().settings().card().find().number()))
        .withChild(new LabelValueDisplayRow().withLabel("Expiry").withValue(this.model().settings().card().find().expiry()))
        .withChild(new LabelValueDisplayRow().withLabel("Interval").withValue(this.model().settings().company().find().billingPeriod()))
        .withChild(buttonBox)
      )
      .withChild(new StringDisplayRow().withWidth("3"))));


    this.add(new BoxDisplayRow().withChild(new RowDisplayRow()
      .withChild(new StringDisplayRow().withWidth("3"))
      .withChild(new BoxDisplayRow().withWidth("9").withBorder(true)
        .withChild(new TitleDisplayRow().withTitle("Invoice Details"))
        .withChild(new StringDisplayRow().withValue("This information will appear on your invoice"))
        .withChild(new BoxDisplayRow().withBorder(true)
          .withChild(new TitleDisplayRow().withTitle("Billing Company").withSubtitle(true))
          .withChild(new LabelValueDisplayRow().withLabel("Name").withValue(this.model().settings().companyName()))
        )

        .withChild(new BoxDisplayRow().withBorder(true)
          .withChild(new TitleDisplayRow().withTitle("Billing Contact").withSubtitle(true))
          .withChild(new LabelValueDisplayRow().withLabel("Name").withValue(this.model().settings().contactName()))
          .withChild(new LabelValueDisplayRow().withLabel("Email").withValue(this.model().settings().contactEmail()))
          .withChild(new LabelValueDisplayRow().withLabel("Phone").withValue(this.model().settings().contactPhone()))
          .withChild(new LabelValueDisplayRow().withLabel("Address").withValue(this.model().settings().address().find().location()))
        )

        .withChild(new BoxDisplayRow().withBorder(true)
          .withChild(new TitleDisplayRow().withTitle("Invoice Notification Emails and Purchase Order #").withSubtitle(true))
          .withChild(new BoxDisplayRow().withBorder(true)
            .withChild(new StringDisplayRow().withValue("Invoices sent via email the first week of the month"))
          )
          .withChild(new LabelValueDisplayRow().withLabel("Email To").withValue(this.model().settings().emailTo()))
          .withChild(new LabelValueDisplayRow().withLabel("PO").withValue(this.model().settings().purchaseOrder()))
        )
        .withChild(new BoxDisplayRow().withChild(new RowDisplayRow()
          .withChild(new SpacerDisplayRow().withWidth(13))
          .withChild(new ButtonDisplayRow().withName("Update").withField("UpdateButton").withKey("UpdateButton")
            .withWidth(2).withId(this.model().settings().id()))
        )
        ))
      .withChild(new StringDisplayRow().withWidth("3")))
    );

    this.addPlanInformation();
  }

  addPlanInformation() {
    var rows = [];
    if (this.model().hasFeatureVehicles()) {
      rows.push(new LabelValueDisplayRow()
        .withLabel("Vehicle Storage Fee")
        .withValue("$" + this.model().priceVehicles() + " per vehicle or trailer per month"))
    }

    if (this.model().hasFeatureInspections()) {
      rows.push(new LabelValueDisplayRow()
        .withLabel("Vehicle Inspections")
        .withValue("$" + this.model().priceInspections() + " per new Vehicle Inspection"))
    }

    if (this.model().hasFeatureTimesheets()) {
      rows.push(new LabelValueDisplayRow()
        .withLabel("Hours of Service")
        .withValue("$" + this.model().priceTimesheets() + " per On Duty Day (No charge for days recorded as Off Duty all day)"))
    }

    if (this.model().hasFeatureEquipment()) {
      rows.push(new LabelValueDisplayRow()
        .withLabel("Equipment Storage Fee")
        .withValue("$" + this.model().priceEquipment() + " per piece of equipment per month"))
    }

    if (this.model().hasFeatureEquipmentInspections()) {
      rows.push(new LabelValueDisplayRow()
        .withLabel("Equipment Inspections")
        .withValue("$" + this.model().priceEquipmentInspections() + " per new Equipment Inspection"))
    }

    this.add(new BoxDisplayRow().withChild(new RowDisplayRow()
      .withChild(new StringDisplayRow().withWidth("3"))
      .withChild(new BoxDisplayRow().withWidth("9")
        .withChild(new TitleDisplayRow().withTitle("Plan Information"))
        .withChild(new BoxDisplayRow().withBorder(true)
          .withChildren(rows)
        ))
      .withChild(new StringDisplayRow().withWidth("3"))));

    this.add(new SpacerDisplayRow());
  }

  renderFooter() {
    //
  }
}