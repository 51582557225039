
import TimeUnit from "./TimeUnit.js";
import DateUtils from "@/utils/DateUtils.js";
import RcMonth from "./RcMonth.js";
import RcDay from "./RcDay.js";
import RcYear from "./RcYear.js";

export default class RcHour extends TimeUnit {
  
  constructor(date, timeZone) {
    super(date, timeZone);
  }
  
  day() {
    return new RcDay(this.date(), this.timeZone());
  }
  
  month() {
    return new RcMonth(this.date(), this.timeZone());
  }
  
  year() {
    return new RcYear(this.date(), this.timeZone());
  }
  
  value() {
    return this.date().getHours();
  }
  
  name() {
    return this.value();
  }
  
  previous() {
    return this.minus(1);
  }

  next() {
    return this.plus(1);
  }

  minus(hours) {
    let hoursToGoBack = DateUtils.hours(hours);
    let time = this.time();
    return new RcHour(new Date( time - hoursToGoBack), this.timeZone());
  }

  plus(hours) {
    let hoursToGAhead = DateUtils.hours(hours);
    let time = this.time();
    return new RcHour(new Date(time + hoursToGAhead), this.timeZone());
  }
}