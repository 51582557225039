<template>
  <Box cmpt="CompanyEdit">

    <Row>
      <Column :width="15">
    
        <Box :showBorder="true"> <!-- Company Edit -->
          <TitleRow align="left">Update Company</TitleRow>
      
          <ValueRow align="left" class="rc-text-small rc-text-value">
            It is <b>important</b> that this company information <b>matches</b> the
            information on your commercial motor vehicle registration.
            If you are operating as a CVOR company, this information <b>must</b>
            match your CVOR documentation.
          </ValueRow>
      
          <Row>
            <Column :width="7">
              <Box :showBorder="true">
                <TitleRow align="left" :subtitle="true">Commercially Registered Company Details</TitleRow>
                <CompanyNameEdit :companyData="data()" :disabled="isSaving" v-on:setValue="setValue" />
      
                <KeyValue :title="FIELDS.CVOR_NUMBER" placeholder="Registration/CVOR Number " :disabled="isSaving"
                  :defaultValue="data()['cvor']" v-on:value="setValue" />
      
                <KeyValue :title="FIELDS.PHONE" placeholder="Phone number (AAA-BBB-CCCC)" :disabled="isSaving"
                  :defaultValue="data()['phone']" v-on:value="setValue" />
      
                <KeyDate :title="FIELDS.RENEWAL_DATE" placeholder="Registration/CVOR Expiry Date (YYYY-MM-DD)"
                  :disabled="isSaving" :defaultValue="data()['cmvRegDate']" v-on:value="setValue" />
              </Box>
            </Column>
            <Column :width="8">
              <Box :showBorder="true">
                <TitleRow :subtitle="true" align="left">
                  Registered Address
                </TitleRow>
                <AddressRow :addressData="address.data" :showTitle="false" v-on:value="setAddressValue" />
              </Box>
            </Column>
          </Row>
          <Row>
            <Column>
              <Box>
                <TitleRow :subtitle="true" align="left">
                  App Settings - HQ Button Address and Profile Update
                </TitleRow>
                <Row>
                  <Column :width="10">
                  
                    <Box :showBorder="true">
                      <TitleRow :subtitle="true" align="left">HQ Button Address</TitleRow>
                      <ValueRow align="left">By setting this value your drivers will be able to complete the Inspection
                        quickly and accurately in the App. This value should represent the address
                        where the majority of the drivers will start their day.
                      </ValueRow>
                      <Row>
                        <Column :width="7">
                          <Box>
                            <Row>
                              <center><img src="@/assets/2023/HqButton.jpg" width="90%" /></center>
                            </Row>
                          </Box>
                        </Column>
                        <Column :width="8">
                          <Box>
                            <AddressRow :addressData="addressHq.data" :showTitle="false" v-on:value="setAddressHqValue" />
                          </Box>
                        </Column>
                      </Row>
                    </Box>
                      
                  </Column>
                  <Column :width="5">
                    <Box :showBorder="true">
                      <TitleRow :subtitle="true" align="left">Profile Update</TitleRow>
                      
                      <KeyCheck 
                        title="featureProfileUpdate" 
                        placeholder="Allow Commercial and Non Commercial users to update their profile"
                        hint="This allows a user to update their name, email, phone, address and drivers license"
                        :defaultValue="company.hasProfileUpdate()"
                        v-on:value="setFeatureValue" />

                    </Box>
                    <Spacer></Spacer>
                    <Box :showBorder="true">
                      <TitleRow :subtitle="true" align="left">API Key</TitleRow>
                      
                      <LabelValueRow>
                        <template #label>API Key</template>
                        <template #value>{{ company.apiKey() }}</template>
                      </LabelValueRow>

                      <ValueRow align="left">
                        This API key can be used to extract inspection information from ReadyChek.
                        An example API call to return all inspections created today: <br>
                        <span v-if="domain.client().isProduction()">
                          curl https://www.readychek.app/service/v1/api/rest/inspections?key={{ company.apiKey() }}&time=today&email={{ domain.session().user().email() }}
                        </span>
                        <span v-else>
                          curl http://localhost:8080/service/v1/api/rest/inspections?key={{ company.apiKey() }}&time=today&email={{ domain.session().user().email() }}
                        </span>
                        <br><br>
                        Or by clicking on this 
                          <a v-if="domain.client().isProduction()"
                            :href="'https://www.readychek.app/service/v1/api/rest/inspections?key=' + company.apiKey() + '&time=today&email=' + domain.session().user().email()" 
                            target="blank"><b>API Test link</b></a>
                          <a v-else
                            :href="'http://localhost:8080/service/v1/api/rest/inspections?key=' + company.apiKey() + '&time=today&email=' + domain.session().user().email()" 
                            target="blank"><b>API Test link</b></a>
                        <br><br>
                        <b>Please note:</b> The endpoint <b>/inspections</b> is the only one available at this time.
                      </ValueRow>
                    </Box>
                  </Column>
      
                </Row>
                    
              </Box>
            </Column>
          </Row>
          <Row>
            <Column>
              <Box>
                <TitleRow :subtitle="true" align="left">
                  Email Options
                </TitleRow>
      
                <Row>
                  <Column :width="7">
                    <Box>
                      <KeyValue 
                        :title="FIELDS.EMAIL" 
                        :isEmail="true" 
                        placeholder="Email All Inspections To:" 
                        :disabled="isSaving"
                        :defaultValue="company.email()" 
                        hint="A copy of all pre-trip inspection will be sent to each address (separate each email address with a comma)"
                        v-on:value="setValue" />
                    </Box>
                  </Column>
                    
                  <Column :width="8">
                     <Box>
                       <KeyValue 
                          :title="FIELDS.EMAIL_DEFECTS" 
                          :isEmail="true" placeholder="Email Inspections with Defects To:"
                          :disabled="isSaving" 
                          :defaultValue="company.emailDefects()"
                          hint="A copy of pre-trip inspection with Defects will be sent to each address (separate each email address with a comma)"
                          v-on:value="setValue" />
                     </Box>
                   </Column>
                </Row>
                
              </Box>
            </Column>
          </Row>
        </Box> <!-- Company Edit -->
                  
        <Spacer />
                  
        <Box v-if="isAdmin" :showBorder="true"> <!-- Admin Section -->
          <Row>
            <Column>
              <Title align="left">
                Admin Section
              </Title>
              <Box v-if="isAdmin" :showBorder="true">
      
                <TitleRow v-if="isAdmin" :subtitle="true" align="left">
                  Admin Working Name and Notes (Internal)
                </TitleRow>
                <KeyValue v-if="isAdmin" :title="FIELDS.ADMIN_SUMMARY" placeholder="Working Name (internal)"
                  hint="This will appear in the Company list" :defaultValue="company.adminSummary()" v-on:value="setValue" />
      
                <KeyTextArea v-if="isAdmin" :title="FIELDS.ADMIN_NOTES" placeholder="Notes" :defaultValue="company.adminNotes()"
                  v-on:value="setValue" :rows="5" />
      
                <TitleRow v-if="isAdmin" :subtitle="true" align="left">
                  Created Date, Email Invoices and Bay
                </TitleRow>
                <LabelValueRow>
                  <template #label>Created Date</template>
                  <template #value>{{ company.createdDateV2().displayAs_YYYY_MM_DD() }}</template>
                </LabelValueRow>
      
                <KeyCheck v-if="isAdmin" class="rowEven" :title="FIELDS.EMAIL_INVOICE" placeholder="Email Invoices"
                  :defaultValue="company.emailInvoices()" v-on:value="setValue" />

                <KeyCheck v-if="isAdmin" class="rowOdd" :title="FIELDS.CHARGE_INVOICE" placeholder="Charge Invoices"
                :defaultValue="company.chargeInvoices()" v-on:value="setValue" />

                <KeyList v-if="isAdmin" class="rowEven" :title="FIELDS.STATE" :options="STATES" :defaultValue="company.state()"
                  placeholder="State" v-on:value="setValue" />
      
                <KeyValue v-if="isAdmin" class="rowOdd" 
                  :title="FIELDS.BAY" 
                  placeholder="Bay" :defaultValue="company.bay()"
                  v-on:value="setValue" 
                  hint="An additional button for companies that have a second start location" />
      
                <TitleRow v-if="isAdmin" :subtitle="true" align="left">
                  Features
                </TitleRow>
      
                <KeyCheck v-if="isAdmin" class="rowEven" title="featureTimesheets" placeholder="Hours of Service"
                  :defaultValue="company.hasTimesheets()" v-on:value="setFeatureValue" />
                <KeyCheck v-if="isAdmin" title="featureInspections" placeholder="Vehicle Inspections"
                  :defaultValue="company.hasInspections()" v-on:value="setFeatureValue" />
                <KeyCheck v-if="isAdmin" class="rowEven" title="featureVehicles"
                  placeholder="Vehicles - allows billing for vehicles" :defaultValue="company.hasVehicles()"
                  v-on:value="setFeatureValue" />
                <KeyCheck v-if="isAdmin" title="featureChecklists" placeholder="Checklists"
                  :defaultValue="company.hasChecklists()" v-on:value="setFeatureValue" />
                <KeyCheck v-if="isAdmin" class="rowEven" title="featureCompliance" placeholder="Compliance" :defaultValue="hasCompliance"
                  v-on:value="setFeatureValue" />
                <KeyCheck v-if="isAdmin" title="featureSso" placeholder="Simple Sign-On (Employment ID)"
                  :defaultValue="company.hasSso()" v-on:value="setFeatureValue" />

                <TitleRow v-if="isAdmin" :subtitle="true" align="left">
                  Experimental Features
                </TitleRow>

                <KeyCheck v-if="isAdmin" title="featureEquipment" placeholder="Equipment" :defaultValue="hasEquipment"
                  v-on:value="setFeatureValue" />
                <KeyCheck v-if="isAdmin" title="featureEquipmentInspections" placeholder="Equipment Inspections" :defaultValue="hasEquipmentInspections"
                  v-on:value="setFeatureValue" />

                <!--
                <KeyCheck 
                  v-if="isAdmin"
                  title="featureUsers"
                  placeholder="Users - allows billing for users"
                  :defaultValue="company.hasUsers()"
                  v-on:value="setFeatureValue"
                  />
                <KeyCheck 
                  v-if="isAdmin"
                  class="rowEven"
                  title="featureMessages"
                  placeholder="Messages"
                  :defaultValue="company.hasMessages()"
                  v-on:value="setFeatureValue"
                  />
                <KeyCheck 
                  v-if="isAdmin"
                  title="featureHome"
                  placeholder="Home"
                  :defaultValue="company.hasHome()"
                  v-on:value="setFeatureValue"
                  />
                -->
      
      
      
                <TitleRow v-if="isAdmin" :subtitle="true" align="left">
                  Integration (QBooks)
                </TitleRow>
      
                <KeyValue v-if="isAdmin" :title="FIELDS.INTEGRATION_REFERENCE" placeholder="Integration Reference"
                  :defaultValue="company.integrationReference()" v-on:value="setValue" />
      
                <TitleRow v-if="isAdmin" :subtitle="true" align="left">
                  Promo Codes and Credit
                </TitleRow>
      
                <KeyValue v-if="isAdmin" :title="FIELDS.PROMO" placeholder="Promo" :defaultValue="company.promo()"
                  v-on:value="setValue" />
      
                <KeyValue v-if="isAdmin" :title="FIELDS.CREDIT" placeholder="Credit" :defaultValue="company.credit()"
                  v-on:value="setValue" />
      
                <TitleRow v-if="isAdmin" :subtitle="true" align="left">
                  Discount
                </TitleRow>
      
                <KeyValue v-if="isAdmin" :title="FIELDS.DISCOUNT" :defaultValue="company.discount()" v-on:value="setValue"
                  hint="An amount between 0 and 1 representing the percent discount on all ReadyChek features (0.5 == 50% off all products used)" />
      
                <TitleRow v-if="isAdmin" :subtitle="true" align="left">
                  Billing Start Date and Invoice Frequency
                </TitleRow>
      
                <KeyDate :title="FIELDS.CONTRACT_START" v-if="isAdmin" placeholder="Contract Start"
                  :defaultValue="company.contractStart()" v-on:value="setValue" />
      
                <KeyList v-if="isAdmin" :title="FIELDS.BILLING_PERIOD" :options="paymentFrequencys"
                  :defaultValue="company.billingPeriod()" placeholder="Billing Period" v-on:value="setValue" />
      
              </Box>
      
            </Column>
          </Row>
       </Box> <!-- Admin Section -->
              
      </Column>
    </Row>
  </Box>
</template>

<script>
import { mapGetters } from 'vuex'

import ContentUtils from '@/utils/ContentUtils';

import Company from '@/domain/model/company/Company.js';
import Address from '@/domain/model/address/Address.js';
import FeatureMap from '@/domain/model/feature/FeatureMap.js';

import KeyValue from '@/components/input/KeyValue.vue';
import KeyTextArea from '@/components/input/KeyTextArea.vue';
import KeyCheck from '@/components/input/KeyCheck.vue';
import KeyList from '@/components/input/KeyList.vue';
import KeyDate from '@/components/input/KeyDate.vue';

import CompanyNameEdit from './CompanyNameEdit.vue';
import AddressRow from "@/components/input/address/AddressRow.vue";

import Box from "@/portals/shared/library/box/Box.vue";
import Row from "@/portals/shared/library/box/Row.vue";
import Column from "@/portals/shared/library/box/Column.vue";
import TitleRow from "@/portals/shared/library/title/TitleRow.vue";
import Title from "@/portals/shared/library/title/Title.vue";
//import SpacerRow from "@/portals/shared/library/spacer/SpacerRow.vue";
import Spacer from "@/portals/shared/library/spacer/Spacer.vue";
import LabelValueRow from "@/portals/shared/library/labelvalue/LabelValueRow.vue";
import ValueRow from "@/portals/shared/library/value/ValueRow.vue";

export default {
  name: 'company-edit',
  props: {
    isSaving: { type: Boolean, default: false },
    isAdmin: { type: Boolean, default: false },
    companyData: { type: Object, default: () => { } },
  },
  components: {
    KeyValue, KeyTextArea,
    KeyDate,
    KeyList,
    CompanyNameEdit,
    AddressRow,
    Title, TitleRow,
    Spacer, //SpacerRow,
    KeyCheck,
    Box, Row, Column,
    LabelValueRow,
    ValueRow,
  },
  data() {
    return {
      FIELDS: Company.FIELDS,
      paymentFrequencys: ContentUtils.PAYMENT_FREQUENCYS(),
      STATES: ContentUtils.STATES(),
      featureMap: null,
    }
  },
  computed: {
    ...mapGetters([
      'domain',
    ]),
    company: function () {
      return new Company(this.domain, this.companyData);
    },
    address: function () {
      if (this.domain) {
        var address = this.company.address();
        var found = address.find();
        return found;
      }
      return new Address(this.domain);
    },
    addressHq: function () {
      if (this.domain) {
        var address = this.company.addressHq();
        var found = address.find();
        return found;
      }
      return new Address(this.domain);
    },
    features: function () {
      return this.company.features().find();
    },
    hasCompliance: function () {
      if (this.company.hasCompliance()) {
        return true;
      }
      if (this.featureMap) {
        return this.featureMap.allowsCompliance();
      }
      return false;
    },
    hasEquipment: function () {
      if (this.company.hasEquipment()) {
        return true;
      }
      if (this.featureMap) {
        return this.featureMap.allowsEquipment();
      }
      return false;
    },
    hasEquipmentInspections: function () {
      if (this.company.hasEquipmentInspections()) {
        return true;
      }
      if (this.featureMap) {
        return this.featureMap.allowsEquipmentInspections();
      }
      return false;
    }
  },
  watch: {
    domain() {
    }
  },
  mounted: function () {
    if (this.company.isNew()) {
      this.$emit('value', { key: 'state', value: 'active' });
      this.$emit('value', { 'key': this.FIELDS.DISCOUNT, 'value': 1 });
      this.$emit('value', { 'key': this.FIELDS.BILLING_PERIOD, 'value': 'monthly' });
      if (this.domain) {
        var value = this.domain.features().compliance();
        this.featureMap = new FeatureMap(this.domain);
        this.featureMap.add(value);
        this.$emit('featureValue', { 'key': FeatureMap.MODEL_NAME, 'value': this.featureMap.data });
      }
    } else {
      this.featureMap = this.features;
    }
  },
  methods: {
    setValue: function (kvp) {
      this.$emit('value', { 'key': kvp.key, 'value': kvp.value });
    },
    setAddressValue: function (kvp) {
      this.$emit('addressValue', { 'key': kvp.key, 'value': kvp.value });
    },
    setAddressHqValue: function (kvp) {
      this.$emit('addressHqValue', { 'key': kvp.key, 'value': kvp.value });
    },

    setFeatureValue: function (kvp) {
      var feature = null;
      if (kvp.key === 'featureInspections') {
        feature = this.domain.features().inspections();
      }
      if (kvp.key === 'featureProfileUpdate') {
        feature = this.domain.features().profileUpdate();
      }
      if (kvp.key === 'featureCheckIns') {
        feature = this.domain.features().checkins();
      }
      if (kvp.key === 'featureTimesheets') {
        feature = this.domain.features().timesheets();
      }
      if (kvp.key === 'featureVehicles') {
        feature = this.domain.features().vehicles();
      }
      if (kvp.key === 'featureSso') {
        feature = this.domain.features().sso();
      }
      if (kvp.key === 'featureMessages') {
        feature = this.domain.features().messages();
      }
      if (kvp.key === 'featureHome') {
        feature = this.domain.features().home();
      }
      if (kvp.key === 'featureUsers') {
        feature = this.domain.features().users();
      }
      if (kvp.key === 'featureCompliance') {
        feature = this.domain.features().compliance();
      }
      if (kvp.key === 'featureEquipment') {
        feature = this.domain.features().equipment();
      }
      if (kvp.key === 'featureEquipmentInspections') {
        feature = this.domain.features().equipmentInspections();
      }
      if (kvp.key === 'featureChecklists') {
        feature = this.domain.features().checklists();
      }

      if (kvp.value) {
        this.featureMap.add(feature.pointer());
      } else {
        this.featureMap.remove(feature);
      }

      var value = this.featureMap.data;
      this.$emit('featureValue', { 'key': FeatureMap.MODEL_NAME, 'value': value });
    },
    data: function () {
      if (this.company) {
        return this.company.data;
      }
      return {};
    }
  },
}
</script>
