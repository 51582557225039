
//import ConstUtils from '@/utils/ConstUtils.js';
//import Inspection from '@/domain/model/inspection/Inspection.js';

export default class ReportOperations {
  
  constructor(operationsDomain) {
    this.operationsDomain = operationsDomain;
    this.domain = this.operationsDomain.domain;
  }
  
  contains(name) {
    if (name) {
      //
    }
    return false;
  }
  
  list(params) {
    var event = ReportOperations.List.Operation(params);
    return this.operationsDomain.initRequest(event);
  }
  
  save(params) {
    var event = ReportOperations.Save.Operation(params);
    return this.operationsDomain.initRequest(event);
  }

  static Save = {
    Names: {
      Operation: 'ReportSaveRequestEvent',
      Notification: 'ReportSaveResponseEvent',
    },

    Errors: {
      NotFound: {
        id: 'NotFound',
        msg: 'NotFound',
        details: ''
      },
    },

    Operation: function(params) {
      var event = {
        handler: ReportOperations.Save.Names.Operation,
        eventName: ReportOperations.Save.Names.Operation,
      }

      event["Params"] = params.data ? params.data : params;

      return event;
    },
  }
  
  static List = {
    Names: {
      Operation: 'ReportListRequestEvent',
      Notification: 'ReportListResponseEvent',
    },

    Errors: {
      NotFound: {
        id: 'NotFound',
        msg: 'NotFound',
        details: ''
      },
    },

    Operation: function(params) {
      var event = {
        handler: ReportOperations.List.Names.Operation,
        eventName: ReportOperations.List.Names.Operation,
      }
      
      event["Params"] = params.data ? params.data : params;

      return event;
    },
  }
}