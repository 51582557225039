<template>
  <Row>
    <Column :width="15">
      <div id="detailsModal" class="rc-modal-dialog">
        <!-- Modal content -->
        <div class="rc-modal-content-dialog-wide">
          <div class="rc-modal-header-dialog" :key="redraw">
            <Box>
              <WorkingDisplayRow v-if="this.showWorking"></WorkingDisplayRow>
              <Row 
                v-for="(row, rowIndex) in this.headerData" 
                :key="rowIndex" >
                  <CellFactory 
                    :rowData="row.data"
                    :showMissingRequirements="showMissingRequirements"
                    v-on:withValue="withValue"
                    v-on:withButtonPressed="withButtonPressed"
                    />
              </Row>
            </Box>
          </div>

          <div class="rc-modal-body-dialog">
            <div class="rc-modal-body-inner-dialog rc-border-thin" :key="redraw + 1">
              <Box>
                <Row 
                  v-for="(row, rowIndex) in this.viewData" 
                  :key="rowIndex" >
                    <CellFactory 
                      :rowData="row.data"
                      :showMissingRequirements="showMissingRequirements"
                      v-on:withValue="withValue"
                      v-on:withButtonPressed="withButtonPressed"
                      />
                </Row>
              </Box>
            </div>
          </div>

          <div class="rc-modal-footer-dialog">
            <Box :key="redraw + 2">
              <Row 
                v-for="(row, rowIndex) in this.footerData" 
                :key="rowIndex" >
                  <CellFactory 
                    :rowData="row.data"
                    :showMissingRequirements="showMissingRequirements"
                    v-on:withValue="withValue"
                    v-on:withButtonPressed="withButtonPressed"
                    />
              </Row>
            </Box>
          </div>
        </div>
      </div>
    </Column>
  </Row>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';

import ConnectionUtils from '@/utils/ConnectionUtils.js';
import ConstUtils from '@/utils/ConstUtils.js';
import ContentUtils from '@/utils/ContentUtils.js';
import StringUtils from '@/utils/StringUtils.js';

import MC from "@/domain/session/MC.js";

import Column from "@/portals/shared/library/box/Column.vue";
import Row from "@/portals/shared/library/box/Row.vue";
import Box from "@/portals/shared/library/box/Box.vue";
import CellFactory from "@/portals/shared/cell/CellFactory.vue";

// import TitleDisplayRow from "@/portals/shared/cell/title/TitleDisplayRow";
// import ButtonDisplayRow from "@/portals/shared/cell/button/ButtonDisplayRow";
// import BoxDisplayRow from "@/portals/shared/cell/box/BoxDisplayRow";
// import RowDisplayRow from "@/portals/shared/cell/row/RowDisplayRow";
// import StringDisplayRow from "@/portals/shared/cell/string/StringDisplayRow";
// import SpacerDisplayRow from "@/portals/shared/cell/spacer/SpacerDisplayRow";

import Vehicle from "@/domain/model/vehicle/Vehicle.js";
import WorkingDisplayRow from '@/portals/shared/views/working/WorkingDisplayRow.vue';
import AddViewModel from './AddViewModel.js';

export default {
  name: "vehicle-list-add-view",
  components: {
    Column, Row, 
    Box,
    CellFactory,
    WorkingDisplayRow,
  },
  props: {
    vehicleId: { type: String, default: "" },
  },
  data() {
    return {
      redraw: 1,
      
      MC: new MC(),
      Strings: StringUtils,
      Contents: ContentUtils,
      Consts: ConstUtils,

      showMissingRequirements: false,
      headerData: [],
      viewData: [],
      footerData: [],

      viewModel: null,
      showWorking: false,
      
      AddViewModel: AddViewModel,
    };
  },
  computed: {
    ...mapGetters([
                   'auth_connected',
                   'auth_socket_status',
                   'signin_event',
                   'auth_client',
                   'domain',
                   ]),
  },
  watch: {
    auth_connected() {
      ConnectionUtils.sendSignInEvent(this);
    },
    auth_socket_status() {
      ConnectionUtils.displayStatus(this);
    },
    signin_event() {
      ConnectionUtils.createDomainSession(this);
    }
  },
  mounted: function () {
      this.viewModel = new this.AddViewModel(this, new Vehicle(this.domain));
      this.viewModel.withStatePageLoading();
      this.viewModel.clearAndBuildTableData();
      this.viewModel.start();
      ConnectionUtils.ensureConnection(this);
    },
  methods: {
    ...mapActions([
                   'AuthStorage_updateCompany',
                   'addListenerAction',
                   'removeListenerAction',
                   'updateDomain',
                   ]),

    start: function() {
      //window.onclick = this.onClick;
      this.viewModel.withStateContentLoading();
      this.viewModel.clearAndBuildTableData();
      this.viewModel.loadData();
    },

    onClick: function(event) {
      var modal = document.getElementById("detailsModal");
      if (event.target == modal) {
        this.close();
      }
    },
    
    close(refresh) {
      var data = {};
        data['id'] = this.inspectionId;
        data['field'] = "DetailsClose";
        data["refresh"] = refresh;
        this.$emit('click', data);
    },

    withButtonPressed: function(buttonData) {
      this.viewModel.withButtonPressed(buttonData);
    },

    withValue: function(valueData) {
      this.viewModel.withValue(valueData);
    },

    displayProgress: function(msg) {
      this.callback(msg);
    },

    callback: function(msg) {
      if (msg) {
        //
      }
    },

    reloadPage: function() {
      this.viewModel.clearAndBuildTableData();
      this.paint();
    },

    paint: function() {
      this.redraw++;
    },
    
  },
}
</script>