<template>
    <DialogView 
      :itemId="itemId" 
      @click="withButtonPressed" 
      @withButtonPressed="withButtonPressed" 
      @withValue="withValue"></DialogView>
  </template>
  
  <script>
  import DialogView from './RestoreView.vue';
  export default {
    name: "checklist-list-restore-row",
    components: {
      DialogView
    },
    props: {
      itemId: { type: String, default: "" },
    },
    methods: {
      withValue: function(event) {
        this.$emit('withValue', event);
      },
      withButtonPressed: function(event) {
        this.$emit('withButtonPressed', event);
      },
    }
  }
  </script>
  