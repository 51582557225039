
import RcTableViewRow from "@/portals/shared/cell/common/RcTableViewRow.js";

import Inspection from "@/domain/model/inspection/Inspection.js";

export default class InspectionRow extends RcTableViewRow {

  static Id = "InspectionRow";

  constructor() {
    super();
    this.put(RcTableViewRow.TypeId, InspectionRow.Id);
  }

  withInspection(inspection) {
    this.withObject(inspection);
    this.withId(inspection.id());
    this.withKey(inspection.modelName());
    this.withField(inspection.modelName());
    return this;
  }

  inspection(domain) {
    return new Inspection(domain, this.object());
    
  }
}