<template>
  <EmailDisplayView :inspectionId="inspectionId" @click="withButtonPressed" @withButtonPressed="withButtonPressed" @withValue="withValue"></EmailDisplayView>
</template>

<script>
import EmailDisplayView from '../../../../inspection/list/email/EmailDisplayRow.vue';

export default {
  name: 'compliance-list=inspection-email-display-view-row',
  components: {
    EmailDisplayView
  },
  props: {
    inspectionId: { type: String, default: "" },
  },
  methods: {
    withValue: function(event) {
      this.$emit('withValue', event);
    },
    withButtonPressed: function(event) {
      this.$emit('withButtonPressed', event);
    },
  }
}
</script>
