<template>
    <Box>
  
      <Row v-if="isAdmin">
        <Column :width="15">
          <div class="rc-text-centre">
          Admin View - Reports
          </div>
        </Column>
      </Row>

      <WorkingDisplayRow v-if="this.showDialog == 'workingDialog'" :itemId="this.selectedId" v-on:withButtonPressed="withButtonPressed" v-on:withValue="withValue" :isAdmin="isAdmin"></WorkingDisplayRow>
      <AddRow v-if="this.showDialog == 'addReportDialog'" :itemId="this.selectedId" v-on:withButtonPressed="withButtonPressed" v-on:withValue="withValue"></AddRow>
      <UpdateRow v-if="this.showDialog == 'updateReportDialog'" :itemId="this.selectedId" v-on:withButtonPressed="withButtonPressed" v-on:withValue="withValue"></UpdateRow>
      <DetailsRow v-if="this.showDialog == 'detailsReportDialog'" :itemId="this.selectedId" v-on:withButtonPressed="withButtonPressed" v-on:withValue="withValue"></DetailsRow>
      <RunRow v-if="this.showDialog == 'runReportDialog'" :itemId="this.selectedId" v-on:withButtonPressed="withButtonPressed" v-on:withValue="withValue"></RunRow>
      <RemoveRow v-if="this.showDialog == 'removeReportDialog'" :itemId="this.selectedId" v-on:withButtonPressed="withButtonPressed" v-on:withValue="withValue"></RemoveRow>
      <RestoreRow v-if="this.showDialog == 'restoreReportDialog'" :itemId="this.selectedId" v-on:withButtonPressed="withButtonPressed" v-on:withValue="withValue"></RestoreRow>
      
      <Row>
        <Column :width="15">
          <Box class="rc-border-thin" :key="redraw + 1">
            <Row v-for="(row, rowIndex) in headerData" :key="rowIndex" >
              <CellFactory 
                :rowData="row.data"
                :showMissingRequirements="showMissingRequirements"
                v-on:withEnterPressed="withEnterPressed"
                v-on:withValue="withValue"
                v-on:withButtonPressed="withButtonPressed"
                  />
            </Row>
          </Box>
        </Column>
      </Row>

      <Row >
        <Column :width="15" >
          <Box :key="redraw" class="full-height-scrollable-div rc-border-thin">
            <Row v-for="(row, rowIndex) in viewData" :key="rowIndex" >
              <CellFactory 
                :rowData="row.data"
                :showMissingRequirements="showMissingRequirements"
                v-on:withEnterPressed="withEnterPressed"
                v-on:withValue="withValue"
                v-on:withButtonPressed="withButtonPressed"
                  />
            </Row>
          </Box>
        </Column>
      </Row>
  
      <Row>
        <Column :width="15">
          <Box class="rc-border-thin" :key="redraw + 2">
            <Row v-for="(row, rowIndex) in footerData" :key="rowIndex" >
              <CellFactory 
                :rowData="row.data"
                :showMissingRequirements="showMissingRequirements"
                v-on:withEnterPressed="withEnterPressed"
                v-on:withValue="withValue"
                v-on:withButtonPressed="withButtonPressed"
                  />
            </Row>
          </Box>
        </Column>
      </Row>
  
    </Box>
    
  </template>
  
  <script>
  
import { mapActions, mapGetters } from 'vuex';
  
import ConnectionUtils from '@/utils/ConnectionUtils.js';
import ConstUtils from '@/utils/ConstUtils.js';
import ContentUtils from '@/utils/ContentUtils.js';
import StringUtils from '@/utils/StringUtils.js';
  
import MC from "@/domain/session/MC.js";

import Box from "@/portals/shared/library/box/Box.vue";
import Column from "@/portals/shared/library/box/Column.vue";
import Row from "@/portals/shared/library/box/Row.vue";
  
import ListViewModel from "./ListViewModel.js";
import ListViewUtils from "./ListViewUtils.js";
  
import CellFactory from "@/portals/shared/cell/CellFactory.vue";
import WorkingDisplayRow from '@/portals/shared/views/working/WorkingDisplayRow.vue';

import AddRow from './add/AddRow.vue';
import UpdateRow from './update/UpdateRow.vue';
import DetailsRow from './details/DetailsRow.vue';
import RunRow from './run/RunRow.vue';
import RemoveRow from './remove/RemoveRow.vue';
import RestoreRow from './restore/RestoreRow.vue';

export default {
    name: "report-list-view",
    components: {
      Column, Box, Row,
      CellFactory,
      WorkingDisplayRow,
      AddRow, DetailsRow, UpdateRow, RunRow, RemoveRow, RestoreRow
    },
    props: {
      adminDisplay: { type: Boolean, default: false},
      isAdmin: {type: Boolean, default: false},
    },
    data() {
      return {
        redraw: 1,
          
        MC: new MC(),
        Strings: StringUtils,
        Contents: ContentUtils,
        Consts: ConstUtils,
        
        CellFactory: CellFactory,
        
        viewModel: null,
        viewData: [],
        headerData: [],
        footerData: [],
        showMissingRequirements: false,
        selectedId: "",
        showDialog: "",

        ListViewModel: ListViewModel,
        ListViewUtils: ListViewUtils,
      };
    },
    computed: {
      ...mapGetters([
                     'auth_connected',
                     'auth_socket_status',
                     'signin_event',
                     'auth_client',
                     'domain',
                     'ReportStorage_lastEvent'
                     ]),
    },
    
    watch: {
      auth_connected() {
        ConnectionUtils.sendSignInEvent(this);
      },
      auth_socket_status() {
        ConnectionUtils.displayStatus(this);
      },
      signin_event() {
        ConnectionUtils.createDomainSession(this);
      },
      $route (to, from) {
        this.model().withToFrom(to, from);
      }
    },

    mounted: function () {
      this.viewModel = new ListViewModel(this);
      this.viewModel
      ConnectionUtils.ensureConnection(this);
    },

    methods: {
      ...mapActions([
                     'AuthStorage_updateCompany',
                     'addListenerAction',
                     'removeListenerAction',
                     'updateDomain',
                     ]),
  
      start: function() {
        this.model().loadItems();
      },
  
      displayProgress: function(msg) {
        this.callback(msg);
      },

      callback: function(msg) {
        var row = this.viewModel.findRowProgress();
        if (row) {
          row.withMessage(msg);
        }
        this.paint();
      },
      
      model: function() {
        if (this.viewModel == null) {
          this.viewModel = new ListViewModel(this);
        }
        return this.viewModel;
      },
      
      withEnterPressed: function(valueData) {
        this.model().withEnterPressed(valueData);
      },

      withValue: function(valueData) {
        this.model().withValue(valueData);
      },
      
      withButtonPressed: function(buttonData) {
        this.model().withButtonPressed(buttonData);
      },

      reloadPage: function() {
        this.model().reloadPage();
      },

      paint: function() {
        this.redraw++;
      },
    },
  }
</script>