
import RcTableViewRow from "@/portals/shared/cell/common/RcTableViewRow.js";
import Invoice from "@/domain/model/invoice/Invoice.js";

export default class InvoiceRow extends RcTableViewRow {

  static Id = "InvoiceRow";

  constructor() {
    super();
    this.put(RcTableViewRow.TypeId, InvoiceRow.Id);
  }

  withInvoice(item) {
    this.withObject(item.data);
    this.withId(item.id());
    this.withField(item.modelName());
    this.withKey(item.modelName());
    return this;
  }

  invoice(domain) {
    return new Invoice(domain, this.object());
  }
}