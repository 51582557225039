import RcTableViewUtils from "../../../../shared/table/RcTableViewUtils";

import StringDisplayRow from "@/portals/shared/cell//string/StringDisplayRow";
import BoxDisplayRow from "@/portals/shared/cell/box/BoxDisplayRow";
import ButtonDisplayRow from "@/portals/shared/cell/button/ButtonDisplayRow";
import LabelDisplayRow from "@/portals/shared/cell/label/LabelDisplayRow";
import LabelValueDisplayRow from "@/portals/shared/cell/label/LabelValueDisplayRow";
import RowDisplayRow from "@/portals/shared/cell/row/RowDisplayRow";
import SpacerDisplayRow from "@/portals/shared/cell/spacer/SpacerDisplayRow";
import StringListUpdateRow from "@/portals/shared/cell/string/list/StringListUpdateRow.js";
import StringUpdateRow from "@/portals/shared/cell/string/StringUpdateRow.js";
import TitleDisplayRow from "@/portals/shared/cell/title/TitleDisplayRow";

import MC from "@/domain/session/MC.js";
import ContentUtils from '@/utils/ContentUtils.js';
import StringUtils from '@/utils/StringUtils.js';
import CompanyRow from "../../../../shared/cell/company/CompanyRow";

export default class ListViewUtils extends RcTableViewUtils {

  static SORT_FIELDS() {
    return [
      { '@rid': 'nameAz', 'name': 'Name (A-Z)' },
      { '@rid': 'nameZa', 'name': 'Name (Z-A)' },
      { '@rid': 'createdDateAz', 'name': 'Created Date (0-9)' },
      { '@rid': 'createdDateZa', 'name': 'Created Date (9-0)' },
      { '@rid': 'contractStartAz', 'name': 'Contract Start Date (0-9)' },
      { '@rid': 'contractStartZa', 'name': 'Contract Start Date (9-0)' },
    ];
  }

  static STATUS() {
    return [
      { '@rid': 'all', 'name': 'All' },
      { '@rid': 'active', 'name': 'Active' },
      { '@rid': 'inactive', 'name': 'Inactive' },
      { '@rid': 'locked', 'name': 'Locked' },
      { '@rid': 'deleted', 'name': 'Deleted' },
    ];
  }

  static PAYING() {
    return [
      { '@rid': 'all', 'name': 'All' },
      { '@rid': 'paying', 'name': 'Paying' },
      { '@rid': 'notpaying', 'name': 'Not Paying' },
    ];
  }

  static CHARGE() {
    return [
      { '@rid': 'all', 'name': 'All' },
      { '@rid': 'true', 'name': 'Auto Charging' },
      { '@rid': 'false', 'name': 'Not Auto Charing' },
    ];
  }

  static EMAIL() {
    return [
      { '@rid': 'all', 'name': 'All' },
      { '@rid': 'true', 'name': 'Auto Emailing' },
      { '@rid': 'false', 'name': 'Not Auto Emailing' },
    ];
  }

  static QBCUSTOMERID() {
    return [
      { '@rid': 'all', 'name': 'All' },
      { '@rid': 'true', 'name': 'Set' },
      { '@rid': 'false', 'name': 'Not Set' },
    ];
  }

  static HAS_CARD() {
    return [
      { '@rid': 'all', 'name': 'All' },
      { '@rid': 'true', 'name': 'Yes' },
      { '@rid': 'false', 'name': 'No' },
    ];
  }

  static PROMOS() {
    return [
      { '@rid': 'all', 'name': 'All' },
      { '@rid': 'notempty', 'name': 'Not Empty' },
      { '@rid': 'TRI30', 'name': 'TRI 30' },
      { '@rid': 'DV30', 'name': 'DV 30' },
      { '@rid': 'SD30', 'name': 'SD 30' },
      { '@rid': 'SRS30', 'name': 'SRS 30' },
      { '@rid': 'ECC30', 'name': 'ECC 30' },
      { '@rid': 'KCI30', 'name': 'KCI 30' },
      { '@rid': 'WTS30', 'name': 'WTS 30' },
    ];
  }

  static FEATURES() {
    return [
      { '@rid': 'all', 'name': 'All' },
      { '@rid': 'inspections', 'name': 'Inspections' },
      { '@rid': 'hours', 'name': 'Hours of Service' },
      { '@rid': 'checklists', 'name': 'Checklists' },
      // { '@rid': 'equipment', 'name': 'Equipment'},
      { '@rid': 'sso', 'name': 'Simple Sign On' },
    ];
  }

  constructor(model, tableData) {
    super(model, tableData);
    this.MC = new MC();
    this.SU = new StringUtils();
    this.CU = new ContentUtils();
    this.pages = [];
  }

  start() {
    if (this.model().isStatePageLoading()) {
      super.start();
      return;
    }

    this.renderHeader(this.model().totalItems());

    if (this.model().isStateContentLoading()) {
      super.start();
      return;
    }

    if (this.model().isStateContentLoaded()) {
      this.renderBody();
    }

    this.renderFooter(this.model().totalItems());
  }

  renderBody() {
    let row = null;
    let even = true;
    let company = null;

    let list = this.model().items();
    for (let index = 0; index < list.length; index++) {
      company = list[index];
      row = new CompanyRow().withCompany(company);
      row
        .withIsAdmin(this.model().isAdmin())
        .withEven(even);
      this.addBody(row);
      even = !even;
    }
  }


  renderFooter(totalItems) {
    var query = this.model().panel().$route.query;
    this.pages = this.model().pages();
    this.addFooter(
      new BoxDisplayRow()
        .withWidth("15")
        .withChild(new RowDisplayRow()
          .withChild(new BoxDisplayRow()
            .withWidth("15")
            .withChild(new RowDisplayRow()
              .withChild(new LabelValueDisplayRow().withWidth("4").withLabelWidth("5").withLabel("Showing").withValue(this.createPageInfo(totalItems, query, "company")))
              .withChild(new StringDisplayRow().withWidth("7"))
              .withChild(new ButtonDisplayRow().withWidth("1").withName("<").withField("PreviousPage"))
              .withChild(new StringListUpdateRow().withWidth("2")
                .withShowLabel(false)
                .withName("Jump to Page")
                .withField("PageIndex")
                .withDefaultValue(this.model().pageNumber(query))
                .withOptions(this.pages))
              .withChild(new ButtonDisplayRow().withWidth("1").withName(">").withField("NextPage"))
            )
          )
        )
    );
  }

  renderHeader(totalItems) {
    var query = this.model().panel().$route.query;
    var buttons = new RowDisplayRow()
      .withChild(new LabelValueDisplayRow().withWidth("1").withLabel(""))
      .withChild(new SpacerDisplayRow().withWidth("12"));

    buttons
      .withChild(new ButtonDisplayRow()
        .withWidth("1").withName("New").withField("Add").withBackgroundPrimaryAction())
      .withChild(new ButtonDisplayRow()
        .withWidth("1").withName("Refresh").withField("Refresh"))
      ;

    this.addHeader(
      new RowDisplayRow()
        .withChild(
          new BoxDisplayRow()
            .withWidth("15")
            .withChild(buttons)
            .withChild(new RowDisplayRow()
              .withBorder()
              .withChild(
                new BoxDisplayRow()
                  .withWidth(15)
                  .withChild(new LabelDisplayRow().withLabel("Filters"))
                  .withChild(new RowDisplayRow()
                    .withChild(new StringListUpdateRow()
                      .withShowLabel(true)
                      .withField("Status")
                      .withName("Status")
                      .withWidth(2)
                      .withOptions(ListViewUtils.STATUS())
                      .withKey("status")
                      .withDefaultValue(query["status"] ? query["status"] : "active")
                      .withShowClear(true)
                    )
                    .withChild(new StringListUpdateRow()
                      .withShowLabel(true)
                      .withField("Paying")
                      .withName("Paying")
                      .withWidth(2)
                      .withOptions(ListViewUtils.PAYING())
                      .withKey("paying")
                      .withDefaultValue(query["paying"] ? query["paying"] : "all")
                      .withShowClear(true)
                    )
                    .withChild(new StringListUpdateRow()
                      .withShowLabel(true)
                      .withField("PromoCode")
                      .withName("Promo Codes")
                      .withWidth(2)
                      .withOptions(ListViewUtils.PROMOS())
                      .withKey("promo")
                      .withDefaultValue(query["promo"] ? query["promo"] : "all")
                      .withShowClear(true)
                    )
                    .withChild(new StringListUpdateRow()
                      .withShowLabel(true)
                      .withField("Feature")
                      .withName("Features")
                      .withWidth(2)
                      .withOptions(ListViewUtils.FEATURES())
                      .withKey("feature")
                      .withDefaultValue(query["feature"] ? query["feature"] : "all")
                      .withShowClear(true)
                    )
                    .withChild(new StringListUpdateRow()
                      .withShowLabel(true)
                      .withField("Charge")
                      .withName("Auto Charge")
                      .withWidth(2)
                      .withOptions(ListViewUtils.CHARGE())
                      .withKey("charge")
                      .withDefaultValue(query["charge"] ? query["charge"] : "all")
                      .withShowClear(true)
                    )
                    .withChild(new StringListUpdateRow()
                      .withShowLabel(true)
                      .withField("Email")
                      .withName("Email Invoice")
                      .withWidth(2)
                      .withOptions(ListViewUtils.EMAIL())
                      .withKey("email")
                      .withDefaultValue(query["email"] ? query["email"] : "all")
                      .withShowClear(true)
                    )
                    .withChild(new StringListUpdateRow()
                      .withShowLabel(true)
                      .withField("QBCustomerId")
                      .withName("QB Customer Id")
                      .withWidth(2)
                      .withOptions(ListViewUtils.QBCUSTOMERID())
                      .withKey("qbcustomerId")
                      .withDefaultValue(query["qbcustomerId"] ? query["qbcustomerId"] : "all")
                      .withShowClear(true)
                    )
                    .withChild(new ButtonDisplayRow().withShowLabel(true)
                      .withWidth("1").withName("Reset").withField("Reset"))
                  )
                  .withChild(new RowDisplayRow()
                    .withChild(new LabelValueDisplayRow().withWidth("1").withLabel("Search"))
                    .withChild(new StringUpdateRow().withWidth("5")
                      .withShowLabel(false).withField("Search")
                      .withKey("Search").withName("Search")
                      .withDefaultValue(query["search"] ? query["search"] : ""))
                    .withChild(new ButtonDisplayRow().withWidth("1").withName("Go").withKey("Search").withField("Search").withSelected(false))
                    .withChild(new ButtonDisplayRow().withWidth("1").withName("Clear").withKey("Search").withField("Clear").withSelected(false))
                    .withChild(new SpacerDisplayRow().withWidth("7"))
                  )
              )
            )
            .withChild(new RowDisplayRow()
              .withBorder(true)
              .withChild(new BoxDisplayRow()
                .withWidth("15")
                .withChild(new RowDisplayRow()
                  .withChild(new LabelValueDisplayRow().withWidth("3").withLabelWidth("5").withLabel("Showing").withValue(this.createPageInfo(totalItems, query, "company")))
                  .withChild(new StringDisplayRow().withWidth("1"))
                  .withChild(new LabelDisplayRow().withWidth("1").withLabel("Sort By"))
                  .withChild(new StringListUpdateRow().withWidth("3")
                    .withName("Sort By")
                    .withField("SortBy")
                    .withShowLabel(false)
                    .withDefaultValue(query["sortBy"] ? query["sortBy"] : "nameAz")
                    .withOptions(ListViewUtils.SORT_FIELDS()))
                  .withChild(new StringListUpdateRow().withWidth("3")
                    .withName("Items/Page")
                    .withField("PageSize")
                    .withShowLabel(false)
                    .withDefaultValue(this.model().pageSize(query, "company"))
                    .withOptions(ContentUtils.PAGE_SIZES()))
                  .withChild(new ButtonDisplayRow().withWidth("1").withName("<").withField("PreviousPage"))
                  .withChild(new StringListUpdateRow().withWidth("2")
                    .withShowLabel(false)
                    .withName("Jump to Page")
                    .withField("PageIndex")
                    .withDefaultValue(query["page"] ? query["page"] : "1")
                    .withOptions(ContentUtils.PAGE_INDEXES(totalItems, this.model().pageSize(query, "company"))))
                  .withChild(new ButtonDisplayRow().withWidth("1").withName(">").withField("NextPage"))
                )
              )
            )
        )
    );

    let box = new BoxDisplayRow()
      .withBorder(true);

    box
      .withChild(new RowDisplayRow().withFixedPosition(true)
        .withBackgroundTitle()
        .withChild(new TitleDisplayRow().withWidth("15").withTitle("Details"))
      );
    this.addHeader(box);
  }

}