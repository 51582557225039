
import RcDomainObject from "@/domain/common/RcDomainObject.js";
import PaymentMap
 from "./PaymentMap";
export default class Payment extends RcDomainObject {
  
  static MODEL_NAME = "Payment";
  
  constructor(domain, data) {
    super(domain, data, Payment.MODEL_NAME);
  }
  
  static Fields = {
    AMOUNT: "amount",
    TYPE: "type",
  }
  
  amount() {
    return this.getString(Payment.Fields.AMOUNT);
  }

  fields() {
    return Payment.FIELDS;
  }
  
  find() {
    var keys = this.keys();
    var found = new PaymentMap(this.domain, {} );
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var data = this.domain.invoices().payments().findById(key);
      var item = new Payment(this.domain, data);
      if (!item.isNew()) {
        found.add(item);
      }
    }
    return found.first();
  }
}