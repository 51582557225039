
import RcTableViewUtils from "../../../../../../shared/table/RcTableViewUtils";

import TitleDisplayRow from "@/portals/shared/cell/title/TitleDisplayRow";
import StringDisplayRow from "@/portals/shared/cell//string/StringDisplayRow";
// import StringListUpdateRow from "@/portals/shared/cell/string/list/StringListUpdateRow.js";
// import LabelValueDisplayRow from "@/portals/shared/cell//label/LabelValueDisplayRow";
// import LabelDisplayRow from "@/portals/shared/cell//label/LabelDisplayRow";
// import ValueDisplayRow from "@/portals/shared/cell//value/ValueDisplayRow";
// import ButtonDisplayRow from "@/portals/shared/cell/button/ButtonDisplayRow";

import MC from "@/domain/session/MC.js";

export default class DetailsDisplayViewUtils extends RcTableViewUtils {

  constructor(model, tableData) {
    super(model, tableData);
    this.MC = new MC();
  }

  start() {
    if (this.model().isStatePageLoading()) {
      super.start();
      return;
    }

    if (this.model().isStateContentLoading()) {
      super.start();
      return;
    }

    if (!this.model().isStateContentLoaded()) {
      return;
    }

    this.add(new TitleDisplayRow().withTitle("Inspection Details"));
    var schedule = this.model().domain().schedules().findById(this.model().scheduleId);
    if (schedule.isNew()) {
      return;
    }
    this.addBody(new StringDisplayRow().withValue(schedule.name()));
  } 
}