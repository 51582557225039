<style>
@import './assets/styles/w3.css';
@import './assets/styles/fontawesome.min.css';
@import './assets/styles/rc_main.css';
@import './assets/styles/rc_box.css';
@import './assets/styles/rc_text.css';
@import './assets/styles/rc_input.css';
@import './assets/styles/rc_font.css';
</style>
<template>
  <div id="app">
    <Box>
    
     <Row>
       <Column :width="15">
         <app-header />   
       </Column>
     </Row>
     <Row>
       
       <Column :width="2">
        <div v-if="isAdmin">
          &nbsp;&nbsp;&nbsp;&nbsp;<router-link to="/portal/admin/company/list" class="rc-text-small rc-padding-normal rc-border-round" onclick="w3_close()" >Companies</router-link><br>
          &nbsp;&nbsp;&nbsp;&nbsp;<router-link to="/portal/admin/invoice/list" class="rc-text-small rc-padding-normal rc-border-round" onclick="w3_close()" >Invoices</router-link><br>
          <!-- &nbsp;&nbsp;<router-link to="/portal/admin/card/list" class="rc-text-small rc-padding-normal rc-border-round" onclick="w3_close()" >Cards</router-link><br> -->
          &nbsp;&nbsp;&nbsp;&nbsp;<router-link to="/portal/admin/vehicle/list" class="rc-text-small rc-padding-normal rc-border-round" onclick="w3_close()" >Vehicles</router-link><br>
          &nbsp;&nbsp;&nbsp;&nbsp;<router-link to="/portal/admin/user/list" class="rc-text-small rc-padding-normal rc-border-round" onclick="w3_close()" >Users</router-link><br>
          &nbsp;&nbsp;&nbsp;&nbsp;<router-link to="/portal/admin/inspection/list?time=last2Weeks" class="rc-text-small rc-padding-normal rc-border-round" onclick="w3_close()" >Inspections</router-link><br>
          &nbsp;&nbsp;&nbsp;&nbsp;<router-link to="/portal/admin/hour/list?time=last2Weeks" class="rc-text-small rc-padding-normal rc-border-round" onclick="w3_close()" >Hours</router-link><br>
          &nbsp;&nbsp;&nbsp;&nbsp;<router-link to="/portal/admin/checklist/list" class="rc-text-small rc-padding-normal rc-border-round" onclick="w3_close()" >Checklists</router-link><br>
          &nbsp;&nbsp;&nbsp;&nbsp;<router-link to="/portal/admin/report/list" class="rc-text-small rc-padding-normal rc-border-round" onclick="w3_close()" >Reports</router-link><br>
          &nbsp;&nbsp;&nbsp;&nbsp;<router-link to="/portal/admin/schedule/list" class="rc-text-small rc-padding-normal rc-border-round" onclick="w3_close()" >Schedules</router-link><br>
          &nbsp;&nbsp;&nbsp;&nbsp;<router-link to="/portal/admin/equipment/list" class="rc-text-small rc-padding-normal rc-border-round" onclick="w3_close()" >Equipment</router-link><br>
          &nbsp;&nbsp;&nbsp;&nbsp;<router-link to="/portal/admin/dashboard" class="rc-text-small rc-padding-normal rc-border-round" onclick="w3_close()" >Dashboard</router-link><br>
          &nbsp;&nbsp;&nbsp;&nbsp;<router-link to="/portal/admin/whatsnew"  class="rc-text-small rc-padding-normal rc-border-round" onclick="w3_close()" >Whats New</router-link><br>
          &nbsp;&nbsp;&nbsp;&nbsp;<router-link to="/portal/admin/blank" class="rc-text-small rc-padding-normal rc-border-round" onclick="w3_close()" >Blank</router-link><br>
        </div>
       </Column>
       
       <Column :showBorder="false" :width="11" style="">
         <router-view />
       </Column>
       
     </Row>
     <Row>
       <Column :width="2">
       </Column>
       <Column :width="11">
         <app-footer />
       </Column>
       <Column :width="2">
       </Column>
     </Row>
     
    </Box>
    
    
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import AppHeader from '@/views/app/AppHeader.vue';
import AppFooter from '@/views/app/AppFooter.vue';
import Box from "@/portals/shared/library/box/Box.vue";
import Row from "@/portals/shared/library/box/Row.vue";
import Column from "@/portals/shared/library/box/Column.vue";

export default {
  name: 'app',
  components: {
    AppHeader,
    AppFooter,
    Box,Row,Column,
  },
  data() {
    return {
      error: null,
      isAdmin: false,
    };
  },
  computed: {
    ...mapGetters([
      'auth_user',
      'auth_client',
      'roles_found',
      'user_role',
      'AuthStorage_Company',
      'domain',
    ]),
  },
  watch: {
    AuthStorage_Company() {
      this.isAdmin = this.domain.session().employee().find().roles().find().isAdministrator();
    },
  },
  methods: {},
};
</script>
