<template>

<div>
  <LabelValueRow>
    <template #label>No. and Street</template>
    <template #value>{{ address.street() }}</template>
  </LabelValueRow>
  
  <LabelValueRow>
    <template #label>City</template>
    <template #value>{{ address.city() }}</template>
  </LabelValueRow>
  
  <LabelValueRow>
    <template #label>Province or State</template>
    <template #value>{{ address.province() }}</template>
  </LabelValueRow>
  
  <LabelValueRow>
    <template #label>Postal Code</template>
    <template #value>{{ address.postal() }}</template>
  </LabelValueRow>
  
  <LabelValueRow>
    <template #label>Country</template>
    <template #value>{{ address.country() }}</template>
  </LabelValueRow>
</div>

</template>

<script>
import { mapGetters } from 'vuex'
import MC from "@/domain/session/MC.js";
import Address from '@/domain/model/address/Address.js';
import LabelValueRow from "@/portals/shared/library/labelvalue/LabelValueRow.vue";

export default {
  name: 'address-row',
  components: {
    LabelValueRow,
  },
  props: {
    addressData: { type: Object, default: () => {} },
    left:  { type: Number, default : 0 },
    right: { type: Number, default : 0 },
  },
  data() {
    return {
      MC: new MC(),
    };
  },
  computed: {
    ...mapGetters([
                   'domain',
                 ]),
    address: function() {
      if (this.addressData) {
        return new Address(this.domain, this.addressData);
      }
      return new Address(this.domain, {});
    },
    
    leftPadding: function() {
      if (this.left > 0) {
        return "rc-box-col-" + this.left;
      }
      return "";
    },
    rightPadding: function() {
      if (this.right > 0) {
        return "rc-box-col-" + this.right;
      }
      return "";
    },
    middle: function() {
      var value = (15 - this.left - this.right);
      return "rc-box-col-" + value;
    },
  }
}
</script>