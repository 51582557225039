
import RcTableViewRow from "@/portals/shared/cell/common/RcTableViewRow.js";

import Company from "@/domain/model/company/Company.js";

export default class CompanyRow extends RcTableViewRow {

  static Id = "CompanyRow";

  constructor() {
    super();
    this.put(RcTableViewRow.TypeId, CompanyRow.Id);
  }

  withCompany(company) {
    this.withObject(company);
    this.withId(company.id());
    this.withKey(company.modelName());
    return this;
  }

  company(domain) {
    return new Company(domain, this.object());
  }
}