import StringUtils from "./StringUtils";

export default class BooleanUtils {

  static toBoolean(any) {
    if (any == null) {
      return false;
    }
    if (any == "undefined") {
      return false;
    }

    if (StringUtils.eic("0", any)) {
      return false;
    }
    if (StringUtils.eic("1", any)) {
      return true;
    }
    if (StringUtils.eic("true", any)) {
      return true;
    }
    if (StringUtils.eic("false", any)) {
      return false;
    }
    
    return false;
  }
}