import RcDomainObject from "@/domain/common/RcDomainObject";
import Report from "./Report";


export default class ReportsDomain extends RcDomainObject {

  constructor(domain, storage) {
    super(domain);
    this.storage = storage.ReportStorage;
  }

  list() {
    return this.storage.list;
  }

  findById(objId) {
    var objects = this.list();
    for (var i = 0; i < objects.length; i++) {
      var item = objects[i];
      if (super.toId(item) == objId) {
        return new Report(this.domain, item);
      }
    }

    return new Report(this.domain);
  }
}