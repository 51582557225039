<template>
    <Row>
      <Column :width="15" :key="redraw">
        <div id="detailsModal" class="rc-modal-dialog">
          <!-- Modal content -->
          <div class="rc-modal-content-dialog-wide">
            <div class="rc-modal-header-dialog">
              <Box >
                <Row 
                  v-for="(row, rowIndex) in viewHeaderData" 
                  :key="rowIndex" >
                    <CellFactory 
                      :rowData="row.data"
                      :showMissingRequirements="showMissingRequirements"
                      v-on:withValue="withFieldValue"
                      v-on:withButtonPressed="withButtonPressed"
                      />
                </Row>
              </Box>
            </div>
  
            <div class="rc-modal-body-dialog">
              <div class="rc-modal-body-inner-dialog rc-border-thin">
                <Box>
                  <Row 
                    v-for="(row, rowIndex) in viewBodyData" 
                    :key="rowIndex" >
                      <CellFactory 
                        :rowData="row.data"
                        :showMissingRequirements="showMissingRequirements"
                        v-on:withValue="withFieldValue"
                        v-on:withButtonPressed="withButtonPressed"
                        />
                  </Row>
                </Box>
              </div>
            </div>
  
            <div class="rc-modal-footer-dialog">
              <Box>
                <Row 
                  v-for="(row, rowIndex) in viewFooterData" 
                  :key="rowIndex" >
                    <CellFactory 
                      :rowData="row.data"
                      :showMissingRequirements="showMissingRequirements"
                      v-on:withValue="withFieldValue"
                      v-on:withButtonPressed="withButtonPressed"
                      />
                </Row>
              </Box>
            </div>
          </div>
        </div>
      </Column>
    </Row>
</template>
  
<script>
import { mapActions, mapGetters } from 'vuex';
  
import ConnectionUtils from '@/utils/ConnectionUtils.js';
import ConstUtils from '@/utils/ConstUtils.js';
import ContentUtils from '@/utils/ContentUtils.js';
import StringUtils from '@/utils/StringUtils.js';
    
import MC from "@/domain/session/MC.js";

import CellFactory from "@/portals/shared/cell/CellFactory.vue";
import Box from "@/portals/shared/library/box/Box.vue";
import Column from "@/portals/shared/library/box/Column.vue";
import Row from "@/portals/shared/library/box/Row.vue";
import BoxDisplayRow from "@/portals/shared/cell/box/BoxDisplayRow.js";
import RowDisplayRow from "@/portals/shared/cell/row/RowDisplayRow.js";
import SpacerDisplayRow from "@/portals/shared/cell/spacer/SpacerDisplayRow.js";

import TitleDisplayRow from "@/portals/shared/cell/title/TitleDisplayRow.js";
import ButtonDisplayRow from "@/portals/shared/cell/button/ButtonDisplayRow.js";
import StringDisplayRow from "@/portals/shared/cell/string/StringDisplayRow";

import LabelValueDisplayRow from "@/portals/shared/cell/label/LabelValueDisplayRow.js";
import Checklist from '@/domain/model/checklist/CheckList.js';


export default {
    name: "checklist-list-details-view",
    components: {
        Column, Row, 
        Box,
        CellFactory,
    },
    props: {
        itemId: { type: String, default: "" },
    },
    data() {
        return {
            redraw: 1,
            
            MC: new MC(),
            Strings: StringUtils,
            Contents: ContentUtils,
            Consts: ConstUtils,

            checklist: null,

            showMissingRequirements: false,
            viewHeaderData: [],
            viewBodyData: [],
            viewFooterData: [],

            kvps: {},
            error: null,
            };
    },
    computed: {
        ...mapGetters([
                    'auth_connected',
                    'auth_socket_status',
                    'signin_event',
                    'auth_client',
                    'domain',
                    ]),
    },
    watch: {
        auth_connected() {
        ConnectionUtils.sendSignInEvent(this);
        },
        auth_socket_status() {
        ConnectionUtils.displayStatus(this);
        },
        signin_event() {
        ConnectionUtils.createDomainSession(this);
        }
    },
    mounted: function () {
        ConnectionUtils.ensureConnection(this);
    },
    methods: {
        ...mapActions([
                    'addListenerAction',
                    'removeListenerAction',
                    'updateDomain',
                    ]),

        start: function() {
            let id = this.Contents.baseDecode(this.itemId);
            this.checklist = this.domain.checklists().findById(id).populate();
            this.setup();
            this.mainScreen();
        },

        setup: function() {
            window.onclick = this.onClick;
        },

        mainScreen() {
            console.log(this.checklist.name()); //eslint-disable-line no-console
            this.viewHeaderData = [];
            this.viewBodyData = [];
            this.viewFooterData = [];

            this.viewHeaderData.push(new TitleDisplayRow().withTitle("Checklist Details: " + this.checklist.name()))

            this.viewBodyData.push(new LabelValueDisplayRow()
                          .withWidth(5)
                          .withName(this.MC.CheckList.Add.NameLabel.value())
                          .withObject(Checklist.MODEL_NAME)
                          .withId(this.checklist.id())
                          .withField(Checklist.Fields.Name)
                          .withKey(Checklist.Fields.Name)
                          .withValue(this.checklist.name()));
            this.viewBodyData.push(new LabelValueDisplayRow()
                          .withWidth(10)
                          .withName(this.MC.CheckList.Add.DescriptionLabel.value())
                          .withObject(Checklist.MODEL_NAME)
                          .withValue(this.checklist.description())
                          .withId(this.checklist.id())
                          .withField(Checklist.Fields.Description)
                          .withKey(Checklist.Fields.Description));
            this.viewBodyData.push(new TitleDisplayRow()
                          .withTitle(this.MC.CheckList.Add.CheckListItemTitle.value())
                          .withSubtitle(true));
            this.createItemChildren();

            this.viewFooterData.push(new RowDisplayRow()
                        .withFixedPosition(true)
                        .withChildren([

                            new ButtonDisplayRow()
                            .withWidth(3)
                            .withName("Back")
                            .withField("Close"),

                            new SpacerDisplayRow()
                            .withWidth(12),
                        ]));
        },

        getChecklistItems() {
            return this.checklist.items();
        },
        
        createItemChildren() {
            var itemMap = this.getChecklistItems();
            var items = itemMap.sortByIndex();
            for (var i = 0 ; i < items.length; i++) {
            var item = items[i];
            
            this.log().info("{0} {1}", [item.index(), item.name()]);
            
            let label = StringUtils.format("{0}.", [item.index() + 1])
            this.viewBodyData.push(new LabelValueDisplayRow().withLabelWidth(1).withName(label).withValue(item.name()))
            this.createItemOptionChildren(item);
            }
        },
        
        getChecklistItemOptions(item) {
            return item.options();
        },
        
        createItemOptionChildren(item) {
            var optionMap = this.getChecklistItemOptions(item);
            var options = optionMap.sortByIndex();
            
            var boxRow = new BoxDisplayRow();
            for (var i = 0 ; i < options.length; i++) {
            var option = options[i];
            var labelRow = new LabelValueDisplayRow();
            let label = StringUtils.format("{0}.", [option.index() + 1])
            labelRow.withName(label).withLabelWidth(1)
            labelRow.withValue(option.name())
            boxRow.withChild(labelRow);
            }
            var row = new RowDisplayRow();
            row
            .withChild(new SpacerDisplayRow().withWidth(2))
            .withChild(boxRow.withWidth(10))
            this.viewBodyData.push(row);
        },

        sendingScreen() {
            this.viewBodyData = [];
            this.viewFooterData = [];
            this.viewHeaderData = [];

            let bodyBox = new BoxDisplayRow().withChild(new RowDisplayRow()
                                    .withChild(new SpacerDisplayRow().withWidth("2"))
                                    .withChild(new BoxDisplayRow()
                                            .withWidth("11")
                                            .withChild(new StringDisplayRow()
                                                        .withMandatoryValue(true)
                                                        .withValue("Sending"))
                                        )
                                    .withChild(new SpacerDisplayRow().withWidth("2"))
                                    );
            this.viewBodyData.push(bodyBox);
            this.paint();
            },

        doneScreen() {
            this.viewHeaderData = [];
            this.viewBodyData = [];
            this.viewFooterData = [];

            let bodyBox = new BoxDisplayRow().withChild(new RowDisplayRow()
                                    .withChild(new SpacerDisplayRow().withWidth("2"))
                                    .withChild(new BoxDisplayRow()
                                            .withWidth("11")
                                            .withChild(new StringDisplayRow()
                                                        .withMandatoryValue(true)
                                                        .withValue("Done!"))
                                        )
                                    .withChild(new SpacerDisplayRow().withWidth("2"))
                                    );

            this.viewBodyData.push(bodyBox);
            this.paint();
        },

        paint: function() {
            this.redraw++;
        },

        log() {
            return this.domain.logger();
        },

        onClick: function(event) {
            var modal = document.getElementById('detailsModal');
            if (event.target == modal) {
                this.goBack();
            }
        },

        withButtonPressed: function(buttonData) {
            if (buttonData.field == "Close") {
                this.goBack();
            }
        },

        doSendAction() {
            setTimeout(2000, this.doneSendAction);
        },

        doneSendAction(notification) {
            if (!notification) {
                return;
            }
        },

        withFieldValue: function(valueData) {
            if (valueData) {
                //
            }
            this.showMissingRequirements = false;
        },

        displayProgress: function(msg) {
            this.callback(msg);
        },

        callback: function(msg) {
            if (msg) {
                //
            }
        },

        areRequirementsMet: function() {
            var met = true;
            return met;
        },

        goBack: function() {
            this.$router.back();
        }
    }
}
</script>