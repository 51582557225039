export default class CompanyUtils {
  
  constructor() {
    //
  }
  
  static SortBy_name = function(a,b) {
    return CompanyUtils.SortBy_nameAz(a, b);
  }

  static SortBy_nameAz = function(a,b) {
    return CompanyUtils.SortBy("name", a, b);
  }

  static SortBy_nameZa = function(a,b) {
    return CompanyUtils.SortByDesc("name", a, b);
  }

  static SortBy_createDateAz = function(a,b) {
    return CompanyUtils.SortBy("createdDate", a, b);
  }

  static SortBy_createDateZa = function(a,b) {
    return CompanyUtils.SortByDesc("createdDate",a, b);
  }

  static SortBy_contractStartDateAz = function(a,b) {
    return CompanyUtils.SortBy("contractStart", a, b);
  }

  static SortBy_contractStartDateZa = function(a,b) {
    return CompanyUtils.SortByDesc("contractStart", a, b);
  }

  static SortByName = function(a,b) {
    if (a.name() > b.name()) {
      return 1;
    }
    if (a.name() < b.name()) {
      return -1;
    }
    return 0;
  }

  static SortByNameDesc = function(a,b) {
    if (a.name() < b.name()) {
      return 1;
    }
    if (a.name() > b.name()) {
      return -1;
    }
    return 0;
  }

  static SortBy = function(methodName, a, b) {
    if (a[methodName]() === b[methodName]()) {
      return 0;
    }

    if (a[methodName]() < b[methodName]()) {
      return -1;
    }
    return 1;
  }

  static SortByDesc = function(methodName, a, b) {
    if (a[methodName]() === b[methodName]()) {
      return 0;
    }

    if (a[methodName]() > b[methodName]()) {
      return -1;
    }
    return 1;
  }

}