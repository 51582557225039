
import RcTableViewModel from "../../../../../../shared/table/RcTableViewModel";
import AddViewUtils from "./AddViewUtils";

import Employee from "@/domain/model/employee/Employee.js";
import User from "@/domain/model/user/User.js";
// import RoleMap from "@/domain/model/role/RoleMap";

import ContentUtils from '@/utils/ContentUtils.js';
import StringUtils from '@/utils/StringUtils.js';

export default class AddViewModel extends RcTableViewModel {
  
  static Fields = {
      SaveButton: "save",
      SaveAnotherButton: "saveAnother",
      CancelButton: "cancel",
      CancelConfirm: "cancelConfirm",
  }

  constructor(panel, user, employee) {
    super(panel);
    this._companyId = ContentUtils.baseDecode(panel.$route.params.operatorId);
    this._total = 0;
    this._user = user;
    this._employee = employee;
    this._checklistDataLoaded = false;
    this._atLeastOneSaved = false;
  }

  start() {
    super.start();
  }

  log() {
    return this.domain().logger();
  }

  isAdmin() {
    return super.isAdmin();
  }

  company() {
    return this.domain().companys().findById(this._companyId);
  }

  withValue(valueData) {
    super.withValue(valueData, "employee");

    if (!valueData) {
      return;
    }

    var employee = this.employee();
    var user = this.user();

    this.panel().showMissingRequirements = false;

    let session = this.domain.session();
    let company = session.company();
    if (employee.isNew()) {
      employee.withCompany(company.pointer()).done();
    }
    
    if (valueData["field"] == "employmentId") {
      employee.withEmploymentId(valueData["value"]);

    } else if (valueData["field"] === "UserRoleMap") {
      let role = this.domain().roles().findById(valueData["value"]);
      employee.withRole(role);

    } else {
      user.put(valueData["field"], valueData["value"]);
    }

    this.log().info("{0}", [JSON.stringify(employee.data, null, 2)]);
    this.log().info("{0}", [JSON.stringify(user.data, null, 2)]);
  }

  withButtonPressed(buttonData) {
    super.withButtonPressed(buttonData);
    if (!buttonData) {
      return;
    }
    
    if (buttonData["field"] === "Save") {
      if (!this.areRequirementsMet()) {
        this.panel().showMissingRequirements = true;
        return;
      }
      this.withStateContentSaving();
      this.clearAndBuildTableData();
      let operation = this.domain().events().users().add(this.user(), this.employee());
      let context = { model: this };
      operation.send(this.savedListener, context);

    } else if (buttonData["field"] === "SaveAdd") {
      if (!this.areRequirementsMet()) {
        this.panel().showMissingRequirements = true;
        return;
      }
      this.withStateContentSaving();
      this.clearAndBuildTableData();
      let operation = this.domain().events().users().add(this.user(), this.employee());
      let context = { model: this };
      operation.send(this.savedAnotherListener, context);

    } else {
      this.closePage(this);
    }
  }

  savedAnotherListener(response, context) {
    if (!response) {
      return;
    }

    var model = context.model;
    model.withStateContentSaved();
    model.panel().reloadPage();
    model._atLeastOneSaved = true;
    model.log().info("User saved, adding another");
    setTimeout(function() {
      model.resetPage(model);
    }, 500);
  }

  savedListener(response, context) {
    if (!response) {
      return;
    }
    var model = context.model;
    if (response.hasError()) {
      model.withError(response.error());
      model.withStateContentLoaded();
      model.clearAndBuildTableData();
      return;
    }
    model._atLeastOneSaved = true;
    model.withStateContentSaved();
    model.panel().reloadPage();

    model.log().info("User saved, done");
    setTimeout(function() {
      model.closePage(model);
    }, 500);
  }

  resetPage(model) {
    model._employee = new Employee(model.domain());
    model._user = new User(model.domain());
    model.withStateContentLoaded();
    model.panel().reloadPage();
  }
  
  closePage(model) {
    model.panel().close(model._atLeastOneSaved);
  }

  buildRowData(tableData) {
    var utils = new AddViewUtils(this, tableData);
    utils.start();
    return this;
  }

  loadData() {
    super.loadItems();
    this
      .withStateContentLoading()
      .clearAndBuildTableData()
      .done();

    this.log().info("Loading view data");
    var context = {};
    context["model"] = this;

    var response = {};
    this.loadedData(response, context);
  }

  loadedData(response, context) {
    if (response || context) {
      //
    }
    var model = context.model;
    model.log().info("Loaded view data");

    model
      .withStateContentLoaded()
      .clearAndBuildTableData()
      .done();
  }

  employee() {
    return this._employee;
  }

  user() {
    return this._user;
  }

  areRequirementsMet() {
    var met = true;
    var user = this.user();
    var employee = this.employee();

    if (met && StringUtils.isEmpty(user.first())) {
      met = false;
    }

    if (met && StringUtils.isEmpty(user.last())) {
      met = false;
    }

    if (met && StringUtils.isEmpty(employee.employmentId())) {
      met = false;
    }

    if (met && employee.roles().isEmpty()) {
      met = false;
    }

    return met;
  }
}