import RcTableViewRow from "@/portals/shared/cell/common/RcTableViewRow.js";

import Employee from "@/domain/model/employee/Employee.js";
import User from "@/domain/model/user/User.js";

import TimesheetMap from "@/domain/model/timesheet/TimesheetMap.js";
import InspectionMap from "@/domain/model/inspection/InspectionMap.js";
// import RcDate from "@/domain/session/time/RcDate.js";

export default class ComplianceRow extends RcTableViewRow {
    
    static Id = "ComplianceRow";
    
    constructor() {
        super();
        this.put(RcTableViewRow.TypeId, ComplianceRow.Id);
    }
    
    withEmployee(employee, user) {
        this.withObject(employee.data);
        this.withId(employee.id());
        this.withField(employee.modelName());
        this.withKey(employee.modelName());
        this.put("user", user.data);
        return this;
    }

    withStartFilterDate(date) {
        this.put("startFilterDate", date);
        return this;
    }

    withTimesheetIds(timesheetIds) {
        this.put("timesheetIds", timesheetIds);
        return this;
    }

    withInspectionIds(inspectionIds) {
        this.put("inspectionIds", inspectionIds);
        return this;
    }

    startFilterDate() {
        let date = this.get("startFilterDate");
        return date
    }

    timesheetMap(domain) {
        let map = this.get("timesheetIds");
        let timesheetMap = new TimesheetMap(domain);
        for (var i = 0 ; i < map.length ; i++) {
            timesheetMap.add(domain.timesheets().findById(map[i]));
        }
        return timesheetMap
    }

    inspectionMap(domain) {
        let map = this.get("inspectionIds");
        let inspectionMap = new InspectionMap(domain);
        for (var i = 0 ; i < map.length ; i++) {
            inspectionMap.add(domain.inspections().findById(map[i]));
        }
        return inspectionMap
    }
    
    employee(domain) {
        return new Employee(domain, this.object()); 
    }
    
    user(domain) {
        return new User(domain, this.get("user"));
    }
}