
import RcDomainObject from '@/domain/common/RcDomainObject.js';

export default class RcError extends RcDomainObject {
  
  static Fields = {
      MessageId: "messageId",
      Hint: "hint",
  }
  
  static Values = {
      MessageIds: {
        InvoiceAlreadyPaid: "InvoiceAlreadyPaidId", 
      },
  }
  
  constructor(domain, data) {
    super(domain, data);
  }
  
  messageId() {
    return this.getString(RcError.Fields.MessageId);
  }

  withMessageId(messageId) {
    this.put(RcError.Fields.MessageId, messageId);
    return this;
  }

  hint() {
    return this.getString(RcError.Fields.Hint);
  }
  
  withHint(hint) {
    this.put(RcError.Fields.Hint, hint);
    return this;
  }
  
  isInvoiceAlreadyPaid() {
    var messageId = this.getString(RcError.Fields.MessageId);
    return RcError.Values.MessageIds.InvoiceAlreadyPaid == messageId; 
  }
}

