import RcTableViewUtils from "../../../../../shared/table/RcTableViewUtils";
import BoxDisplayRow from "@/portals/shared/cell/box/BoxDisplayRow";
import RowDisplayRow from "@/portals/shared/cell/row/RowDisplayRow";
import StringDisplayRow from "@/portals/shared/cell//string/StringDisplayRow";
// import StringUpdateRow from "@/portals/shared/cell/string/StringUpdateRow.js";
import StringListUpdateRow from "@/portals/shared/cell/string/list/StringListUpdateRow.js";
import LabelValueDisplayRow from "@/portals/shared/cell//label/LabelValueDisplayRow";
import LabelDisplayRow from "@/portals/shared/cell//label/LabelDisplayRow";
import TitleDisplayRow from "@/portals/shared/cell/title/TitleDisplayRow";
import ButtonDisplayRow from "@/portals/shared/cell/button/ButtonDisplayRow";
//import ErrorDisplayRow from "@/portals/shared/cell/error/ErrorDisplayRow";
import SpacerDisplayRow from "@/portals/shared/cell/spacer/SpacerDisplayRow";
// import StringUtils from '@/utils/StringUtils.js';
import StatusDisplayRow from "@/portals/shared/cell/status/StatusDisplayRow.js";
import DateUpdateRow from "@/portals/shared/cell/date/DateUpdateRow.js";

import MC from "@/domain/session/MC.js";
import ContentUtils from '@/utils/ContentUtils.js';
import StringUtils from '@/utils/StringUtils.js';
import InspectionRow from "@/portals/shared/cell/inspection/InspectionRow";

export default class ListViewUtils extends RcTableViewUtils {

  constructor(model, tableData) {
    super(model, tableData);
    this.MC = new MC();
    this.pages = [];
  }

  start() {
    if (this.model().hasError()) {
      this.renderError();  
    }

    if (this.model().isStatePageLoading()) {
      super.start();
      return;
    }

    this.renderHeader(this.model().totalItems());

    if (this.model().isStateContentLoading()) {
      super.start();
      return;
    }

    if (this.model().isStateContentLoaded()) {
      this.renderBody();
    }

    this.renderFooter(this.model().totalItems());
  }

  renderBody() {
    let row = null;
    let even = true;
    let item = null;
    let list = this.model().inspections();
    for (var index = 0; index < list.length; index++) {
      item = list[index];
      row = new InspectionRow().withInspection(item);
      row
        .withIsAdmin(this.model().isAdmin())
        .withEven(even);
      this.addBody(row);
      even = !even;
    }
  }

  renderInspections() {
    let boxContent = new BoxDisplayRow()
    let even = true;
    let adminBox = null;
    let adminRow = null;
    let boxChild = null;
    let modifiedBy = null;
    let modifiedOn = null;
    let createdBy = null;
    let createdOn = null;
    //let nameFormat = "{0} ({1})";
    let adminActionsRow = null;
    let list = this.model().inspections();
    for (let index = 0; index < list.length; index++) {
      let inspection = list[index];
      let inspector = inspection.inspector().find();
      let inspectorUser = inspector.user().find();
      let driver = inspection.driver().find();
      let driverUser = driver.user().find();
      let overallStatus = inspection.defectType();
      let detailsBox = new BoxDisplayRow();
      let vehicle = inspection.vehicle().find();
      let company = vehicle.company().find();
      detailsBox
        .withWidth("5")
        .withChild(new LabelValueDisplayRow().withLabel("Date").withValue(inspection.createdDateDisplay()))
        .withChild(new StatusDisplayRow()
                        .withLabel("Overall Status")
                        .withValue(overallStatus))
        .withChild(new LabelValueDisplayRow().withLabel("Inspected By").withValue(inspectorUser.fullName()));

      if (vehicle.isVehicle()) {
        detailsBox
          .withChild(new LabelValueDisplayRow().withLabel("Driven By").withValue(driverUser.fullName()));
      }

      detailsBox
        .withChild(new LabelValueDisplayRow().withLabel("Trip Type").withValue(inspection.tripDisplay()))
        .done();
      
      let results = inspection.results().find();
      let vehicleResults = results.forVehicle(vehicle);
      let vehicleDefect = vehicleResults.mostSevereUnfixedDefectType();
      let vehicleBox = new BoxDisplayRow();
      let vehicleName = vehicle.name() + " (" + vehicle.plate() + ")";

      let nameRow = new LabelValueDisplayRow().withLabel("Vehicle").withValue(vehicleName);
      if (vehicle.isEquipment()) {
        nameRow
          .withLabel("Equipment")
          .withValue(vehicle.name())
          .done();
      }
      vehicleBox
        .withWidth("5")
        .withChild(nameRow);
      
      if (vehicle.isEquipment()) {
        vehicleBox
          .withChild(new SpacerDisplayRow());
      } else {
        vehicleBox
          .withChild(new StatusDisplayRow()
                                .withLabel(this.MC.Inspection.Details.StatusLabel.value())
                                .withValue(vehicleDefect))
          .withChild(new LabelValueDisplayRow().withLabel("Odometer").withValue(inspection.odometer()));
      }
       
      vehicleBox
        .withChild(new SpacerDisplayRow())
        .withChild(new LabelValueDisplayRow().withLabel("Work Orders").withValue(inspection.workOrderDisplay()))
        .done();

      let trailerMap = inspection.trailers().find();
      let trailerList = trailerMap.sortByName();
      let trailersBox = new BoxDisplayRow();
      let trailersBoxRows = [];

      for (let trailerIndex = 0; trailerIndex < trailerList.length; trailerIndex++) {
        let trailerRows = [];
        let trailerBox = new BoxDisplayRow();
        let trailer = trailerList[trailerIndex];
        let trailerResults = results.forVehicle(trailer);
        let trailerDefect = trailerResults.mostSevereUnfixedDefectType(true);
        let trailerName = trailer.name() + " (" + trailer.plate() + ")";
        let trailerAttached = trailerResults.isAttached();
        trailerRows.push(new LabelValueDisplayRow().withLabel("Trailer").withValue(trailerName));
        trailerRows.push(new StatusDisplayRow()
                              .withLabel(this.MC.Inspection.Details.StatusLabel.value())
                              .withValue(trailerDefect)
                            );
        trailerRows.push(new LabelValueDisplayRow().withLabel("Attached").withValue(trailerAttached ? "Yes" : "No"));
        trailerBox.withChildren(trailerRows).done();
        trailersBoxRows.push(trailerBox);
      }
      
      trailersBox
        .withWidth("5")
        .withChildren(trailersBoxRows)
        .done()

      if (trailersBoxRows.length > 0) {
        trailersBox
          .withBorder(true)
          .withEven(even);
      }
      
      let unfixedDefects = inspection.hasUnfixedDefect();
      let width = unfixedDefects ? "8" : "9";
      let actionsRow = new RowDisplayRow()
        .withChild(new StringDisplayRow().withWidth(width))
        .withChild(new ButtonDisplayRow().withWidth("1").withName("Details").withId(inspection.id()).withField("Details"));

      if (unfixedDefects) {
        actionsRow
          .withChild(new ButtonDisplayRow().withWidth("1").withName("Repair").withId(inspection.id()).withField("Repair"));
      }

      actionsRow
        .withChild(new ButtonDisplayRow().withWidth("2").withName("Print/Download").withId(inspection.id()).withField("Print"))
        .withChild(new ButtonDisplayRow().withWidth("1").withName("Email").withId(inspection.id()).withField("Email"))
        .withChild(new ButtonDisplayRow().withWidth("2").withName("+ Remarks").withId(inspection.id()).withField("Remarks"));
      
      if (this.model().isAdmin()) {
        adminBox = new BoxDisplayRow().withBorder().withEven(even);
        adminBox.withChild(new LabelDisplayRow().withLabel("Admin Section"))
        adminBox.withChild(new LabelDisplayRow().withLabel(StringUtils.format("{0} ({1})", [this.valueOrNotSet(company.name()), company.id()])));

        adminRow = new RowDisplayRow();
        modifiedBy = inspection.modifiedBy().find();
        modifiedOn = inspection.modifiedDateV2().displayAs_YYYY_MM_DD_HH_MM_SS_A();
        createdBy = inspection.createdBy().find();
        createdOn = inspection.createdDate().displayAs_YYYY_MM_DD_HH_MM_SS_A();
        adminRow.withChild(new LabelValueDisplayRow().withWidth(4).withName("Modified By/On").withValue(StringUtils.format("{0}/{1}", [modifiedBy.fullName(), modifiedOn])));
        adminRow.withChild(new LabelValueDisplayRow().withWidth(4).withName("Created By/On").withValue(StringUtils.format("{0}/{1}",[createdBy.fullName(), createdOn])));
        adminRow.withChild(new LabelValueDisplayRow().withWidth(4).withLabelWidth(5).withName("InspectionId").withValue(inspection.id()));
        adminRow.withChild(new LabelValueDisplayRow().withWidth(3).withLabelWidth(5).withName("Time Zone").withValue(inspection.timeZone()))
        
        adminBox.addChild(adminRow);

        adminActionsRow = new RowDisplayRow();
        adminActionsRow
          .withChild(new SpacerDisplayRow().withWidth(8))
          .withChild(new SpacerDisplayRow().withWidth(1))
          .withChild(new ButtonDisplayRow().withWidth(1).withName("Delete").withId(inspection.id()).withField("Delete").withStyleMajor())
          .withChild(new SpacerDisplayRow().withWidth(2))
          .withChild(new ButtonDisplayRow().withWidth(2).withName("Rebuild Pdf").withId(inspection.id()).withField("Rebuild"))
          .withChild(new ButtonDisplayRow().withWidth(1).withName("Select").withField("Select").withId(company.id()).withObject("Company"));
        
        adminBox.withChild(adminActionsRow);
      }
      let buttonsBox = new BoxDisplayRow();
      buttonsBox.withChild(actionsRow);

      boxChild = new BoxDisplayRow()
        .withBorder(true)
        .withEven(even)
        .withChild(
          new RowDisplayRow()
            .withChild(detailsBox.withEven(even))
            .withChild(vehicleBox.withEven(even))
            .withChild(trailersBox.withEven(even)));
      
      if (actionsRow != null) {
        boxChild
          .withChild(buttonsBox);
      }
      if (adminBox != null) {
        boxChild.withChild(adminBox);
      }

      boxContent.withChild(boxChild);
      even = !even;
    }
    this.add(boxContent);
  }

  renderFooter(totalItems) {
    let query = this.model().panel().$route.query;
    this.pages = this.model().pages();
    this.addFooter(
        new BoxDisplayRow()
        .withWidth("15")
        .withChild(new RowDisplayRow()
          .withChild(new BoxDisplayRow()
            .withWidth("15")
            .withChild(new RowDisplayRow()
              .withChild(new LabelValueDisplayRow().withWidth("3").withLabelWidth("5").withLabel("Showing").withValue(this.createPageInfo(totalItems, query, "inspection")))
              .withChild(new StringDisplayRow().withWidth("8"))
              .withChild(new ButtonDisplayRow().withWidth("1").withName("<").withField("PreviousPage"))
              .withChild(new StringListUpdateRow().withWidth("2")
                                                  .withShowLabel(false)
                                                  .withName("Jump to Page")
                                                  .withField("PageIndex")
                                                  .withDefaultValue(query["page"] ? query["page"] : "1")
                                                  .withOptions(this.pages))
              .withChild(new ButtonDisplayRow().withWidth("1").withName(">").withField("NextPage"))
            )
          )
        )
    );
  }

  renderHeader(totalItems) {
    let query = this.model().panel().$route.query;
    let user = this.model().user();
    let userId = user.id();
    if (user.isNew()) {
      userId = "";
    }
    let vehicle = this.model().vehicle();
    let vehicleId = vehicle.id();
    if (vehicle.isNew()) {
      vehicleId = "";
    }
    if (vehicleId) {
      //
    }
    let filterBox = new BoxDisplayRow()
    let itemFilterRow = new RowDisplayRow()
    .withChild(new BoxDisplayRow().withWidth("7")
      .withChild(new StringListUpdateRow().withWidth("6").withName("User").withKey("pickUser").withField("User").withDefaultValue(userId).withOptions(this.userList()).withShowClear(true))
    )
    .withChild(new BoxDisplayRow().withWidth("8")
      .withChild(new StringListUpdateRow().withWidth("6").withName(this.inspectableTitle()).withKey("pickVehicle").withField("Vehicle").withDefaultValue(vehicleId).withOptions(this.vehicleList()).withShowClear(true))
    );

    filterBox
      .withWidth("15")
      .withBorder(true)
      .withChild(new LabelDisplayRow().withLabel("Filters"))
      .withChild(new RowDisplayRow()
        .withChild(new LabelDisplayRow().withWidth("1").withLabel("Status"))
        .withChild(new ButtonDisplayRow().withWidth("1").withName("All").withKey("allStatus").withField("Status").withDefaultValue("all").withSelected(query["status"] === "all" || !query["status"]))
        .withChild(new ButtonDisplayRow().withWidth("1").withName("Good").withKey("goodStatus").withField("Status").withDefaultValue("good").withSelected(query["status"] === "good"))
        .withChild(new ButtonDisplayRow().withWidth("1").withName("Minors").withKey("minorStatus").withField("Status").withDefaultValue("minor").withSelected(query["status"] === "minor"))
        .withChild(new ButtonDisplayRow().withWidth("2").withName("Majors").withKey("majorStatus").withField("Status").withDefaultValue("major").withSelected(query["status"] === "major"))
        .withChild(new ButtonDisplayRow().withWidth("2").withName("Any").withKey("anyStatus").withField("Status").withDefaultValue("any").withSelected(query["status"] === "any"))
        .withChild(new ButtonDisplayRow().withWidth("2").withName("Repaired").withKey("repairedStatus").withField("Status").withDefaultValue("repaired").withSelected(query["status"] === "repaired"))
      )
      .withChild(new RowDisplayRow()
        .withChild(new LabelDisplayRow().withShowLabel(true).withWidth("1").withLabel("Time"))
        .withChild(new ButtonDisplayRow().withShowLabel(true).withWidth("1").withName("All").withKey("allTime").withField("Time").withDefaultValue("all").withSelected(query["time"] === "all"))
        .withChild(new ButtonDisplayRow().withShowLabel(true).withWidth("1").withName("Today").withKey("todayTime").withField("Time").withDefaultValue("today").withSelected(query["time"] === "today"))
        .withChild(new ButtonDisplayRow().withShowLabel(true).withWidth("1").withName("Yesterday").withKey("yesterdayTime").withField("Time").withDefaultValue("yesterday").withSelected(query["time"] === "yesterday"))
        .withChild(new ButtonDisplayRow().withShowLabel(true).withWidth("2").withName("Last 2 Weeks").withKey("last2WeeksTime").withField("Time").withDefaultValue("last2Weeks").withSelected(query["time"] === "last2Weeks"))
        .withChild(new ButtonDisplayRow().withShowLabel(true).withWidth("2").withName("This Month").withKey("thisMonthTime").withField("Time").withDefaultValue("thisMonth").withSelected(query["time"] === "thisMonth"))
        .withChild(new ButtonDisplayRow().withShowLabel(true).withWidth("2").withName("Last Month").withKey("thisMonthTime").withField("Time").withDefaultValue("lastMonth").withSelected(query["time"] === "lastMonth"))
        .withChild(new ButtonDisplayRow().withShowLabel(true).withWidth("2").withName("Last 30 days").withKey("last30DaysTime").withField("Time").withDefaultValue("last30Days").withSelected(query["time"] === "last30Days"  || !query["time"]))
        .withChild(new DateUpdateRow().withWidth("3").withName("Show Inspections On or Before").withKey("pickTime").withField("Date").withDefaultValue(isNaN(query["time"]) ? "" : query["time"] ))
      );
    if (!this.model().isAdmin()) {
      filterBox
        .withChild(itemFilterRow);
    } else {
      let user = this.domain().users().findById(userId);
      let vehicle = this.domain().vehicles().findById(vehicleId);
      itemFilterRow = new RowDisplayRow()
        .withChild(new BoxDisplayRow().withWidth("7")
          .withChild(new LabelValueDisplayRow().withWidth(6).withLabel("User").withField("User").withValue(this.valueOrNotSet(user.fullName() + " " + user.email())))
        )
        .withChild(new BoxDisplayRow().withWidth("8")
          .withChild(new LabelValueDisplayRow().withWidth(6).withLabel("Vehicle").withField("Vehicle").withValue(this.valueOrNotSet(vehicle.plate() + " " + vehicle.name())))
        );
      filterBox
        .withChild(itemFilterRow);
    }

    this.addHeader(
      new RowDisplayRow()
      .withChild(
        new BoxDisplayRow()
        .withWidth("15")
        .withChild(new RowDisplayRow()
          .withChild(new LabelDisplayRow().withWidth("10").withLabel("Inspections"))
          .withChild(new SpacerDisplayRow().withWidth("4"))
          .withChild(new ButtonDisplayRow().withWidth("1").withName("Refresh").withField("Refresh"))
        )
        .withChild(new RowDisplayRow()
          .withChild(filterBox)
        )
        .withChild(new RowDisplayRow()
          .withChild(new BoxDisplayRow()
            .withWidth("15")
            .withChild(new RowDisplayRow()
              .withChild(new LabelValueDisplayRow().withWidth("4").withLabelWidth("5").withLabel("Showing").withValue(this.createPageInfo(totalItems, query, "inspection")))
              .withChild(new StringDisplayRow().withWidth("4"))
              .withChild(new StringListUpdateRow().withWidth("3")
                                                  .withName("Items/Page")
                                                  .withField("PageSize")
                                                  .withShowLabel(false)
                                                  .withDefaultValue(this.model().pageSize(query, "inspection"))
                                                  .withOptions(ContentUtils.PAGE_SIZES()))
              .withChild(new ButtonDisplayRow().withWidth("1").withName("<").withField("PreviousPage"))
              .withChild(new StringListUpdateRow().withWidth("2")
                                                  .withShowLabel(false)
                                                  .withName("Jump to Page")
                                                  .withField("PageIndex")
                                                  .withDefaultValue(query["page"] ? query["page"] : "1")
                                                  .withOptions(ContentUtils.PAGE_INDEXES(totalItems, this.model().pageSize(query, "inspection"))))
              .withChild(new ButtonDisplayRow().withWidth("1").withName(">").withField("NextPage"))
            )
          )
        )
      )
    );

    let box = new BoxDisplayRow();
    box
      .withChild(new RowDisplayRow()
        .withBackgroundTitle()
        .withChild(new RowDisplayRow().withWidth("10").withChildren([
          new TitleDisplayRow().withWidth("8").withTitle("Details"),
          new TitleDisplayRow().withWidth("7").withTitle("Inspected Item")
        ]))
        .withChild(new TitleDisplayRow().withWidth("5").withTitle("Trailers"))
      );

    this.addHeader(box);
  }

  inspectableTitle() {
    if (this.model().company().hasEquipment()) {
      return "Vehicle, Trailer or Equipment";
    }
    return "Vehicle or Trailer";
  }

}