/*import Vue from 'vue'*/
import EventUtils from '@/utils/EventUtils.js';
import MapUtils from '@/utils/MapUtils.js';
import ConstUtils from '@/utils/ConstUtils.js'
// import RcResponseEvent from '@/domain/model/events/RcResponseEvent.js';

// import CheckListMap from '@/domain/model/checklist/CheckListMap.js';
// import CheckList from '@/domain/model/checklist/CheckList.js';

const state = {
  list: [],
  map: {},
};

const getters = {
  CheckListStorage: state => state,
};

const actions = {
};

const mutations = {
  SOCKET_ONMESSAGE(state, event) {
    if (event.status == EventUtils.STATUS.COMPLETE && event.statusCode == EventUtils.CODE.OK) {
      if (event.Payload && event.Payload.CheckListMap) {
        var items = event.Payload.CheckListMap;
        state.map = MapUtils.updateMap(items, state.map);
        state.list = Object.values(state.map);
        state.lastEvent = event;
      }
      if (event.Payload && event.Payload.CheckList) {
        var obj = event.Payload.CheckList;
        state.map[obj[ConstUtils.FIELDS.ID]] = obj;
        state.list = Object.values(state.map);
        state.lastEvent = event;
      }
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};