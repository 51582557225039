<template>

  <div class="rc-row" :class="additional()" :cmpt="cmpt" :key="tagValue" :ref="refValue">
    <slot></slot>
  </div>

</template>

<script>

import { mapGetters } from 'vuex'

import Strings from "@/utils/StringUtils.js";

export default {
  name: 'portals-shared-library-box-row',
  props: {
    cmpt: { type: String, default: "row" },
    borderStyle: { type: String, default: "" },
    tagValue: { type: String, default: "" },
    refValue: { type: String, default: "" },
    even:     { type: String, default: null },
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters([
    ]),
  },
  created() {
    const slots = this.$slots.default;
    if (!slots) {
      return;
    }
    const columns = slots.filter(node => Strings.contains(node.tag, 'box-column'));
    if (columns) {
      var number = columns.length;
      if (number) {
        //
      }
    }
  },
  mounted() {
  },
  methods: {
    additional: function () {
      var value = "";
      if (this.even != null) {
        value += this.even == "true" ? " rc-border-thin-even " : " rc-border-thin ";
      } 
      if (!Strings.isEmpty(this.borderStyle)) {
        value = this.borderStyle + " ";
      }
      return value;
    },
  },
}
</script>