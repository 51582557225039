import EventUtils from '@/utils/EventUtils.js';
import MapUtils from '@/utils/MapUtils.js';
import ConstUtils from '@/utils/ConstUtils.js'

const state = {
  list: [],
  map: {},
  pages: {},
};

const getters = {
  EmployeeStorage_lastEvent: state => state.lastEvent,
};

const actions = {
};

const mutations = {
  SOCKET_ONMESSAGE(state, event) {
    if (event.status == EventUtils.STATUS.COMPLETE && event.statusCode == EventUtils.CODE.OK) {
      if (event.Payload && event.Payload.EmployeeMap) {
        var employeeItems = event.Payload.EmployeeMap;
        state.map = MapUtils.updateMap(employeeItems, state.map);
        state.list = Object.values(state.map);
        state.lastEvent = event;
      }
      if (event.Payload && event.Payload.Employee) {
        var employeeObj = event.Payload.Employee;
        state.map[employeeObj[ConstUtils.FIELDS.ID]] = employeeObj;
        state.list = Object.values(state.map);
        state.lastEvent = event;
      }
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
