
import RcObject from "@/domain/common/RcObject.js";

export default class CompanyListFilter extends RcObject {
  
  static Fields = {
    PAGINATION: 'pagination',
    COMPONENTS: 'components',
    SEARCH_TEXT: 'searchText',
    SORT_BY: "sortBy",
    STATE_TYPE: 'state',
    PAY_TYPE: 'paying',
    PROMO_CODE: 'promoCode',
    FEATURE_TYPE: 'feature',
    CHARGE_INVOICE: 'chargeInvoice',
    EMAIL_INVOICE: 'emailInvoice', 
    CARD_SET: 'cardSet', 
    QB_CUSTOMER_ID: 'qbCustomerId',
  }
  
  constructor() {
    super({});
  }
  
  withState(state) {
    this.put(CompanyListFilter.Fields.STATE_TYPE, state);
    return this;
  }

  withPaying(pay) {
    this.put(CompanyListFilter.Fields.PAY_TYPE, pay);
    return this;
  }

  withEmailInvoice(email) {
    this.put(CompanyListFilter.Fields.EMAIL_INVOICE, email);
    return this;
  }
  
  withChargeInvoice(charge) {
    this.put(CompanyListFilter.Fields.CHARGE_INVOICE, charge);
    return this;
  }
  
  withFeature(feature) {
    this.put(CompanyListFilter.Fields.FEATURE_TYPE, feature);
    return this;
  }

  withPagination(size, number) {
    this.put(CompanyListFilter.Fields.PAGINATION, { size, number });
    return this;
  }
  
  withSearchText(text) {
    this.put(CompanyListFilter.Fields.SEARCH_TEXT, { 'text': text });
    return this;
  }
  
  withSortBy(sortBy) {
    let direction = "asc";
    
    let newValue = sortBy.replace("Az", "");
    if (newValue.length < sortBy.length) {
      direction = "asc";
      this.put(CompanyListFilter.Fields.SORT_BY, { 'value': newValue, 'dir': direction });
    }
    
    newValue = sortBy.replace("Za", "");
    if (newValue.length < sortBy.length) {
      direction = "des";
      this.put(CompanyListFilter.Fields.SORT_BY, { 'value': newValue, 'dir': direction });
    }
    return this;
  }

  withComponents() {
    this.put(CompanyListFilter.Fields.COMPONENTS, { 'all': true } );
  }
  
  withPromoCode(value) {
    this.put(CompanyListFilter.Fields.PROMO_CODE, value);
    return this;
  }

  withQbCustomerId(value) {
    this.put(CompanyListFilter.Fields.QB_CUSTOMER_ID, value);
    return this;
  }

  withCardSet(value) {
    this.put(CompanyListFilter.Fields.CARD_SET, value);
    return this;
  }

  done() {
    //
  }
}