
import TimeUnit from "./TimeUnit.js";
import DateUtils from "@/utils/DateUtils.js";
import RcDate from "./RcDate.js";
import RcMonth from "./RcMonth.js";
import RcYear from "./RcYear.js";
import RcHour from "./RcHour.js";

export default class RcDay extends TimeUnit {
  
  constructor(date, timeZone) {
    super(date, timeZone);
  }
  
  firstHour() {
    var date = new Date(
        super.date().getFullYear(), 
        super.date().getMonth(),
        super.date().getDate(),
        0, 
        0, 
        0, 
        0 );
    
    return new RcHour(date, this.timeZone());
  }
  
  lastHour() {
    var year = super.date().getFullYear();
    var hour = super.date().getMonth();
    var day = super.date().getDate()
    var date = new Date(
        year,
        hour,
        day,
        23, 
        59, 
        59, 
        999 );
    
    return new RcHour(date, this.timeZone());
  }
  
  yesterday() {
    return this.previous();
  }
  
  tomorrow() {
    return this.next();
  }
  
  previous() {
    var time = this.time() - DateUtils.hours24();
    return new RcDay(new Date(time), this.timeZone());
  }
  
  next() {
    var time = this.time() + DateUtils.hours24();
    return new RcDay(new Date(time), this.timeZone());
  }
  
  weeksAgo(numberOfWeeks) {
    var time = this.time() - DateUtils.weeksAgo(numberOfWeeks);
    return new RcDay(new Date(time), this.timeZone());
  }
  
  minus(days) {
    var time = this.time() - DateUtils.days(days);
    return new RcDay(new Date(time), this.timeZone());
  }
  
  plus(days) {
    var time = this.time() + DateUtils.days(days);
    return new RcDay(new Date(time), this.timeZone());
  }
  
  
  month() {
    return new RcMonth(super.date(), this.timeZone());
  }
  
  year() {
    return new RcYear(super.date(), this.timeZone());
  }
  
  value() {
    return super.date().getDate();
  }
  
  name() {
    return super.date().getDay();
  }
  
  date() {
    return new RcDate(super.date(), this.timeZone());
  }

  day() {
    return this;
  }
}