
import RcDomainMap from "../../common/RcDomainMap.js";
import InvoiceItem from './InvoiceItem.js';
import InvoiceUtils from "./InvoiceUtils.js";

export default class InvoiceItemMap extends RcDomainMap {
  static MODEL_NAME = InvoiceItem.MODEL_NAME + "Map";

  constructor(domain, data) {
    super(domain, data, InvoiceItemMap.MODEL_NAME);
  }

  copy() {
    var data = this._copy();
    return new InvoiceItemMap(this.domain, data);
  }

  list() {
    var values = [];
    var keys = this.keys();
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var item = this.getItem(key);
      values.push(item);
    }
    return values;
  }

  find() {
    var keys = this.keys();
    var found = new InvoiceItemMap(this.domain, {});
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var data = this.domain.invoices().items().findById(key);
      var item = new InvoiceItem(this.domain, data);
      if (!item.isNew()) {
        found.add(item);
      }
    }
    return found;
  }

  getItem(key) {
    var value = super.get(key);
    return new InvoiceItem(this.domain, value);
  }

  sort() {
    var list = this.list();
    return list.sort(InvoiceUtils.SortByCreatedDate);
  }

  first() {
    var all = this.list();
    if (all.length > 0) {
      return all[0];
    }
    return new InvoiceItem(this.domain);
  }
}