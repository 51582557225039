
import VehicleAddUtils from "./VehicleAddUtils.js";

import StringUtils from '@/utils/StringUtils.js';
import ConstUtils from '@/utils/ConstUtils.js';

import Vehicle from "@/domain/model/vehicle/Vehicle.js";
import ChecklistListFilter from '@/domain/model/checklist/ChecklistListFilter.js';
import ChecklistMap from '@/domain/model/checklist/CheckListMap.js';
//import Image from "@/domain/model/image/Image.js";
import VehicleListFilter from "@/domain/model/vehicle/VehicleListFilter.js";
import ScheduleListFilter from "@/domain/model/schedule/ScheduleListFilter.js";


export default class VehicleAddModel {
  
  static Fields = {
      SaveButton: "save",
      SaveAnotherButton: "saveAnother",
      CancelButton: "cancel",
      CancelConfirm: "cancelConfirm",
  }
  
  static Values = {
      States: {
        Loading: "loading",
        Loaded: "loaded",
        Saving: "saving",
        Cancelling: "cancelling",
      }
  }
  
  constructor(panel, vehicle) {
    this._panel = panel;
    this._state = VehicleAddModel.Values.States.Loading;
    this._vehicle = vehicle;
    this._tableData = [];
    this._domain = this.panel().domain;
    
    this._error = null;
    this._timer = null;
    this._checklistDataLoaded = false;
    this._scheduleDataLoaded = false;
  }
  
  start() {
    this.populateData();
    this.loadData();
  }
  
  populateData() {
    this._tableData = [];
    var newData = []
    this.buildRowData(newData)
    this._tableData = newData;
    return this;
  }
  
  loadData() {
    this._checklistDataLoaded = false;
    this._scheduleDataLoaded = false;
    
    this.logger().info("Loading view data");
    
    var scheduleFilter = new ScheduleListFilter();
    scheduleFilter
      .withPagination(0, 20)
      .done();
    scheduleFilter
      .withCompany(this.domain().session().company().id())
      .done();

    var event = this.domain().events().schedules().list(scheduleFilter);
    event.send(this._loadData, this);
    
    var filter = new ChecklistListFilter();
    filter
      .withPagination(0, 10)
      .done();

    filter
      .withCompany(this.domain().session().company().id())
      .done();
    
    var checklistsEvent = this.domain().events().checklists().list(filter);
    checklistsEvent.send(this._loadChecklistData, this);
    
    var id = this.vehicle().id();
    
    var vehicleFilter = new VehicleListFilter();
    vehicleFilter
      .withPagination(20, 0)
      .withCompany(this.domain().session().company().id())
      .withIds([id])
      .done();
    
    var vehicleEvent = this.domain().events().vehicles().details(vehicleFilter);
    vehicleEvent.send(this._loadVehicleData, this);
  }
  
  _loadData(response, context) {
//    clearTimeout(this._timer);
//    this._timer = null;
    if (response) {
      //
    }
    var model = context;
    model.logger().info("Loaded schedule view data");

    if (model.vehicle().isNew()) {
      model._scheduleDataLoaded = true;
      if (model._checklistDataLoaded && model._scheduleDataLoaded) {
        model._state = VehicleAddModel.Values.States.Loaded;
        model.panel().populateData();
      }
    }
  }
  
  _loadChecklistData(response, context) {
  //  clearTimeout(this._timer);
  //  this._timer = null;
    if (response) {
      //
    }
    var model = context;
    model.logger().info("Loaded checklist view data");
    
    if (model.vehicle().isNew()) {
      model._checklistDataLoaded = true;
      if (model._checklistDataLoaded && model._scheduleDataLoaded) {
        model._state = VehicleAddModel.Values.States.Loaded;
        model.panel().populateData();
      }
    }
  }

  _loadVehicleData(response, context) {
    if (response) {
      //
    }
    var model = context;
    model.logger().info("Loaded vehicle view data");
    this._timer = setTimeout(function() {
      model._delayedShowVehicleData(model);
    }, 100);
  }
  
  _delayedShowVehicleData(model) {
    model._vehicle = model.domain().vehicles().findById(model.vehicle().id());
    model._state = VehicleAddModel.Values.States.Loaded;
    model.panel().populateData();
  }
  
  buildRowData(newData) {
    var utils = new VehicleAddUtils(this.domain(), this.panel(), newData);
    utils.start();
  }
  
  domain() {
    return this._domain;
  }
  
  panel() {
    return this._panel;
  }
  
  logger() {
    return this._domain.logger();
  }
  
  vehicle() {
    return this._vehicle;
  }
  
  withValue(kvp) {
    this.panel().showMissingRequirements = false;
    if (!kvp) {
      return;
    }
    this.domain().logger().info("Received: {0}", [JSON.stringify(kvp)]);
      
    var field = kvp['field'];
    var fieldValue = kvp['value'];
    var objectId = kvp['id'];
    if (!objectId) {
      return;
    }

    if (field === Vehicle.FIELDS.SCHEDULE) {
      var schedule = this.domain().schedules().findById(fieldValue);
      this._vehicle.withSchedule(schedule.pointer()).done();

    } else if (field === Vehicle.FIELDS.Checklists) {
      if (StringUtils.isNull(fieldValue)) {
        this._vehicle.withChecklists(new ChecklistMap(this.domain()));
      } else {
        var checklist = this.domain().checklists().findById(fieldValue);
        this._vehicle.withChecklist(checklist.pointer()).done();
      }

    } else if (field == Vehicle.FIELDS.ANNUAL_DATE) {
      if (StringUtils.isNull(fieldValue)) {
        this._vehicle.withAnnualDate(0);
      } else {
        this._vehicle.put(field, fieldValue);
      }

    } else if (field == "picture") {
      this._vehicle.withImageData(fieldValue);
      
    } else {
      this._vehicle.put(field, fieldValue);      
    }
    
    this.domain().logger().info("Vehicle: {0}", [JSON.stringify(this._vehicle.data, 1)]);
  }
  
  /**
   * Handle buttons being pressed.
   */
  withButtonPressed(buttonData) {
    if (!buttonData) {
      return;
    }

    this.domain().logger().info("Button: {0}", [JSON.stringify(buttonData)]);
    var field = buttonData['field'];
    if (!field) {
      return;
    }

    if (StringUtils.e(VehicleAddModel.Fields.SaveButton, field)) {
      if (!this.areRequirementsMet()) {
        this.domain().logger().info("Requirements not met!");
        this.panel().showMissingRequirements = true;
        return;
      }
      
      this._state = VehicleAddModel.Values.States.Saving;
      this.panel().populateData();
      
      this.event = this.domain().events().vehicleSave(this._vehicle.data);
      this.event.send(this.saveListener, this);
    }

    if (StringUtils.e(VehicleAddModel.Fields.SaveAnotherButton, field)) {
      if (!this.areRequirementsMet()) {
        this.domain().logger().info("Requirements not met!");
        this.panel().showMissingRequirements = true;
        return;
      }
      
      this._state = VehicleAddModel.Values.States.Saving;
      this.panel().populateData();
      let session = this.domain.session();
      if (this._vehicle.isNew()) {
        this._vehicle.withCompany(session.company());
      }
      this.event = this.domain().events().vehicleSave(this._vehicle.data);
      this.event.send(this.saveAnotherListener, this);
    }

    if (StringUtils.e(VehicleAddModel.Fields.CancelButton, field)) {
      this._state = VehicleAddModel.Values.States.Cancelling;
      this.panel().populateData();
    }
    
    if (StringUtils.e(VehicleAddModel.Fields.CancelConfirm, field)) {
      var value = buttonData['value'];
      if (value) {

        var roles = this.domain.session().employee().find().roles().find();
        if (roles.isAdministrator()) {
          this.panel().$router.replace({ 
            name: this.panel().$route.query.redirectTo || ConstUtils.ROUTES.VEHICLE.LIST 
          });
        } else {
          const path = StringUtils.format(ConstUtils.ROUTES.VEHICLE.LIST_PATH, [this.$route.params.operatorId]);
          this.$router.push({ path });
        }

      } else {
        this._state = VehicleAddModel.Values.Loaded;
        this.panel().populateData();
      }
    }
  }
  
  saveAnotherListener(response, context) {
    if (!response) {
      return;
    }
    
    context._vehicle = new Vehicle(context.domain());
    context.populateData();
    context.loadData();
  }
  
  saveListener(response, context) {
    if (!response) {
      return;
    }
    var panel = context.panel();
    panel.$router.replace(
        { name: panel.$route.query.redirectTo || ConstUtils.ROUTES.VEHICLES }
        );
  }
  
  tableData() {
    return this._tableData;
  }
  
  isSaving() {
    return StringUtils.e(this._state, VehicleAddModel.Values.States.Saving);
  }
  
  isLoading() {
    return StringUtils.e(this._state, VehicleAddModel.Values.States.Loading);
  }

  isCancelling() {
    return StringUtils.e(this._state, VehicleAddModel.Values.States.Cancelling);
  }

  areRequirementsMet() {
    var met = true;
    var vehicle = this._vehicle;
    if (StringUtils.isEmpty(vehicle.name())) {
      met = false;
    }
    if (met && StringUtils.isEmpty(vehicle._category())) {
      if (vehicle.category().isNew()) {
        met = false
      }
    }
    if (met && StringUtils.isEmpty(vehicle.brakeType())) {
      met = false
    }
    if (met && StringUtils.isEmpty(vehicle.odometerType())) {
      met = false
    }
    if (met && StringUtils.isEmpty(vehicle.year())) {
      met = false
    }
    if (met && StringUtils.isEmpty(vehicle.plate())) {
      met = false
    }
    if (met && StringUtils.isEmpty(vehicle.jurisdiction())) {
      met = false
    }
    if (met && vehicle.schedule().isNew()) {
      met = false
    }

    return met;
  }
  
}







