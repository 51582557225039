import RcTableViewRow from "@/portals/shared/cell/common/RcTableViewRow.js";

import Equipment from "@/domain/model/equipment/Equipment.js";

export default class EquipmentRow extends RcTableViewRow {
    
    static Id = "EquipmentRow";
    
    constructor() {
        super();
        this.put(RcTableViewRow.TypeId, EquipmentRow.Id);
    }
    
    withEquipment(equipment) {
        this.withObject(equipment.data);
        this.withId(equipment.id());
        this.withField(equipment.modelName());
        this.withKey(equipment.modelName());
        return this;
    }
    
    equipment(domain) {
        return new Equipment(domain, this.object()); 
    }
}