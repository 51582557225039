<template>
  <Box>
  
    <Row v-if="auth_user">
      <Column :width="2"> </Column>
      <div class="rc-box-col-11 rc-text-left rc-text-small">
        <router-link :to="'/portal/operator/' + companyId + '/home'" class="rc-padding-normal rc-border-round" onclick="w3_close()" >Home</router-link>
        <router-link :to="'/portal/operator/' + companyId + '/whatsnew'"  class="rc-padding-normal rc-border-round" onclick="w3_close()" >Whats New!</router-link>

        <router-link :to="'/portal/operator/' + companyId + '/compliance'" v-if="compliance" class="rc-padding-normal rc-border-round" onclick="w3_close()" >Compliance</router-link>
        
        <router-link :to="'/portal/operator/' + companyId + '/vehicles'" v-if="vehicles" class="rc-padding-normal rc-border-round" onclick="w3_close()" >Vehicles & Trailers</router-link>
        <router-link :to="'/portal/operator/' + companyId + '/equipment'" v-if="equipment" class="rc-padding-normal rc-border-round" onclick="w3_close()" >Equipment</router-link>

        <router-link :to="'/portal/operator/' + companyId + '/inspections'" v-if="inspections" class="rc-padding-normal rc-border-round" onclick="w3_close()" >Inspections</router-link>
        <router-link :to="'/portal/operator/' + companyId + '/hours'" v-if="timesheets" class="rc-padding-normal rc-border-round" onclick="w3_close()">Hours of Service</router-link>
        
        <!-- <router-link to="/portal/operator/checkins" v-if="checkins" class="rc-padding-normal rc-border-round" onclick="w3_close()">Check Ins</router-link> -->
        
        <router-link :to="'/portal/operator/' + companyId + '/users'" class="rc-padding-normal rc-border-round" onclick="w3_close()" >Users</router-link>
        <router-link :to="'/portal/operator/' + companyId + '/checklists'" v-if="checklists" class="rc-padding-normal rc-border-round" onclick="w3_close()" >Checklists</router-link>
        <router-link :to="'/portal/operator/' + companyId + '/reports'" class="rc-padding-normal rc-border-round" onclick="w3_close()" >Reports</router-link>

        <router-link :to="'/portal/operator/' + companyId + '/invoices'" v-if="!equipment" class="rc-padding-normal rc-border-round" onclick="w3_close()" >Invoices</router-link>
        <router-link :to="'/portal/operator/' + companyId + '/billing/display'" v-if="!equipment" class="rc-padding-normal rc-border-round" onclick="w3_close()" >Billing</router-link>
        <router-link :to="'/portal/operator/' + companyId + '/billing/list'" v-if="equipment" class="rc-padding-normal rc-border-round" onclick="w3_close()" >Billing & Invoices</router-link>
        
        <router-link :to="'/portal/operator/' + companyId + '/company/edit'" class="rc-padding-normal rc-border-round" onclick="w3_close()" >Company</router-link>
      </div>
      <Column :width="2"> </Column>
    </Row>

    <div v-else class="row">
    </div>
  </Box>
</template>

<script>
import { mapGetters } from 'vuex';
import ContentUtils from '@/utils/ContentUtils.js';

import Box from "@/portals/shared/library/box/Box.vue";
import Row from "@/portals/shared/library/box/Row.vue";
import Column from "@/portals/shared/library/box/Column.vue";

export default {
  name: 'app-menu',
  components: {
    Box, Row, Column,
  },
  props: {
    isSmall: { type: Boolean, default: false },
  },
  computed :{
    ...mapGetters([
                   'auth_user', 
                   'auth_client',
                   'auth_connected',
                   'domain',
                 ]),
    companyName: function() {
      if (!this.domain || !this.domain.session()) {
        return "";
      }
      var name = this.domain.session().company().name();
      return ContentUtils.unescape(name);
    },
    companyId: function() {
      if (!this.domain || !this.domain.session()) {
        return "";
      }
      var name = this.domain.session().company().id();
      return ContentUtils.baseEncode(name);
    },
    inspections: function() {
      if (!this.domain || !this.domain.session()) {
        return false;
      }
      var result = true;
      result = this.domain.session().company().features().find().allowsInspections();
      return result;
    },
    timesheets: function() {
      if (!this.domain || !this.domain.session()) {
        return false;
      }
      var result = true;
      result = this.domain.session().company().features().find().allowsTimesheets();
      return result;
    },
    checkins: function() {
      if (!this.domain || !this.domain.session()) {
        return false;
      }
      var result = true;
      result = this.domain.session().company().features().find().allowsCheckIns();
      return result;
    },
    checklists: function() {
      if (!this.domain || !this.domain.session()) {
        return false;
      }
      var result = true;
      result = this.domain.session().company().features().find().allowsCheckLists();
      return result;
    },
    vehicles: function() {
      if (!this.domain || !this.domain.session()) {
        return false;
      }
      var result = true;
      if (this.inspections) {
        return true;
      }
      result = this.domain.session().company().features().find().allowsVehicles();
      return result;
    },
    equipment: function() {
      if (!this.domain || !this.domain.session()) {
        return false;
      }
      var result = true;
      result = this.domain.session().company().features().find().allowsEquipment();
      return result;
    },
    schedules: function() {
      return true;
    },
    compliance: function() {
      if (!this.domain || !this.domain.session()) {
        return false;
      }
      var result = true;
      result = this.domain.session().company().features().find().allowsCompliance();
      return result;
    },
    hasCompanys: function() {
      var user = this.domain.session().user();
      var employees = this.domain.employees().findByUserId(user.id());
      return employees.length() > 1;
    },
    isAdmin() {
      var user = this.domain.session().user();
      var employees = this.domain.employees().findByUserId(user.id());
      var roles = employees.roles().find();
      return roles.isAdministrator();
    }
  },
  watch: {
  },
  methods: {
    decode: function(value) {
      return ContentUtils.unescape(value);
    },
    first: function(){
      if (!this.domain || !this.domain.session()) {
        return "";
      }
      var name = this.domain.session().user().firstDisplay();
      return name;
    },
    email: function() {
      if (!this.domain || !this.domain.session()) {
        return "";
      }
      return this.domain.session().user().emailDisplay();
    },
  },
}
</script>