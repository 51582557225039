<template>
  <Row v-if="ready">
    <div v-if="left" :class="leftPadding"> </div>
    <div :class="middle" ref="check">
      <Box>
        <Row v-if="showLabel">
          <Column :width="15">
            <div class="rc-font-small rc-text-label"> <br> </div>
          </Column>
        </Row>
        <Row>
          <Column :width="15">
            <Box>
              <Row>
                <Column :width="13">
                  <div v-if="isTitleShowing" class="rc-text-value rc-text-label">
                    {{ placeholderText() }}
                  </div>
                  <div v-else class="rc-font-small">
                    <div v-if="placeholder != null" > 
                      <div class="rc-font-small rc-text-label"> <br> </div>
                    </div>
                  </div>
                </Column>
                <Column :width="2">
                  <div class="rc-text-right rc-text-value" style="padding: 2px">
                    <input 
                      :placeholder="placeholderText()" 
                      :disable="disabled" 
                      type="checkbox" 
                      v-model="value"
                      v-on:keyup.enter="enter" 
                      v-on:input="withValue" 
                      style="transform: scale(1.75)" 
                      />
                  </div>
                </Column>
              </Row>
            </Box>
            <Box>
              <Row v-if="!shouldShowRequired && !Strings.isEmpty(hint)">
                <Column :width="15">
                  <div class="rc-font-small rc-text-value">
                    <a :href="hintUrl">{{ hint }}</a>  <br />
                  </div>
                </Column>
              </Row>
              <Row v-if="shouldShowRequired">
                <Column :width="15">
                  <div v-if="shouldShowRequired" class="rc-font-small rc-background-required">A value for {{ placeholderText() }} is required</div>
                </Column>
              </Row>
            </Box>
          </Column>
        </Row>
      </Box>
    </div>
    <div v-if="right" :class="rightPadding"> </div>
  </Row>
</template>

<script>

import MC from "@/domain/session/MC.js";
import StringUtils from '@/utils/StringUtils';

import Box from "@/portals/shared/library/box/Box.vue";
import Row from "@/portals/shared/library/box/Row.vue";
import Column from "@/portals/shared/library/box/Column.vue";

export default {
  name: 'key-date',
  components: {
    Box, Row, Column,
  },
  props: {
    componentName: { type: String, default: "" },
    title: String,
    hint: String,
    placeholder: String,
    left:  { type: Number, default: 0 },
    right: { type: Number, default: 0 },
    hintUrl:   { type: String, default: "" },
    disabled:  { type: Boolean, default: false },
    mandatory: { type: Boolean, default: false },
    required:  { type: Boolean, default: false },
    styling:   { type: String, default: "" },
    showLabel: { type: Boolean, default: true },
    defaultValue: { type: Boolean, default: false },
  },
  data() {
    return {
      value: this.defaultValue,
      isTitleShowing: true,
      MC: new MC(),
      Strings: StringUtils,
      ready: false,
    };
  },
  watch: {
    value() {
      this.validate();
      return this.value;
    },
    required() {
      if (this.shouldShowRequired) {
        this.$refs.check.scrollIntoView();
      }
    },
    defaultValue() {
      this.value = this.defaultValue;
    },
  },
  computed: {
    shouldShowRequired: function () {
      if (this.required) {
        if (this.mandatory) {
          if (this.value == false) {
            return true;
          }
        }
      }
      return false;
    },
    leftPadding: function () {
      if (this.left > 0) {
        return "rc-box-col-" + this.left;
      }
      return "";
    },
    rightPadding: function () {
      if (this.right > 0) {
        return "rc-box-col-" + this.right;
      }
      return "";
    },
    middle: function () {
      var value = (15 - this.left - this.right);
      return "rc-box-col-" + value + " " + this.styling;
    },
  },
  mounted: function () {
    this.validate();
    this.ready = true;
  },
  methods: {
    placeholderText: function () {
      if (this.mandatory) {
        return this.MC.get(this.placeholder) + "*";
      }
      return this.MC.get(this.placeholder);
    },
    withValue: function () {
      if (this.timer) {
        clearInterval(this.timer)
      }
      this.timer = setInterval(this.emit, 500);
    },
    emit: function () {
      clearInterval(this.timer);
      this.$emit('value', { 'key': this.title, 'value': this.value });
    },
    validate: function () {
    },
    enter: function () {
      this.$emit('enter', this.title);
    },
  },

}
</script>
