<template>
  <Row>
    <Column :width="15">
      <div id="detailsModal" class="rc-modal-dialog" :key="redraw">
        <!-- Modal content -->
        <div class="rc-modal-content-dialog">
          <div class="rc-modal-header-dialog">
            <Box>
              <WorkingDisplayRow v-if="this.showDialog === 'displayWorking'"></WorkingDisplayRow>

              <Row 
                v-for="(row, rowIndex) in headerData" 
                :key="rowIndex" >
                  <CellFactory 
                    :rowData="row.data"
                    :showMissingRequirements="showMissingRequirements"
                    v-on:withValue="withValue"
                    v-on:withButtonPressed="withButtonPressed"
                    />
              </Row>

            </Box>
          </div>

          <div class="rc-modal-body-dialog">
            <div class="rc-modal-body-inner-dialog rc-border-thin">
              <Box>
                <Row 
                  v-for="(row, rowIndex) in this.viewData" 
                  :key="rowIndex" >
                    <CellFactory 
                      :rowData="row.data"
                      :showMissingRequirements="showMissingRequirements"
                      v-on:withValue="withValue"
                      v-on:withButtonPressed="withButtonPressed"
                      />
                </Row>
              </Box>

            </div>

          </div>

          <div class="rc-modal-footer-dialog">
            <Box>
              <Row 
                v-for="(row, rowIndex) in footerData" 
                :key="rowIndex" >
                  <CellFactory 
                    :rowData="row.data"
                    :showMissingRequirements="showMissingRequirements"
                    v-on:withValue="withValue"
                    v-on:withButtonPressed="withButtonPressed"
                    />
              </Row>
            </Box>
          </div>
        </div>
      </div>
    </Column>
  </Row>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';

import ConnectionUtils from '@/utils/ConnectionUtils.js';
import ConstUtils from '@/utils/ConstUtils.js';
import ContentUtils from '@/utils/ContentUtils.js';
import StringUtils from '@/utils/StringUtils.js';

import MC from "@/domain/session/MC.js";

import Column from "@/portals/shared/library/box/Column.vue";
import Row from "@/portals/shared/library/box/Row.vue";
import Box from "@/portals/shared/library/box/Box.vue";
import CellFactory from "@/portals/shared/cell/CellFactory.vue";

import WorkingDisplayRow from '@/portals/shared/views/working/WorkingDisplayRow.vue';
import DetailsDisplayViewModel from "./DetailsDisplayViewModel";
import DetailsDisplayViewUtils from './DetailsDisplayViewUtils';

export default {
  name: "schedule-list-display-view",
  components: {
    Column, Row, 
    Box,
    CellFactory,
    WorkingDisplayRow,
  },
  props: {
    scheduleId: { type: String, default: "" },
    adminDisplay: { type: Boolean, default: false }
  },
  data() {
    return {
      redraw: 1,
      isAdmin: false,

      MC: new MC(),
      Strings: StringUtils,
      Contents: ContentUtils,
      Consts: ConstUtils,

      showMissingRequirements: false,

      headerData: [],
      viewData: [],
      footerData: [],

      showDialog: null,

      viewModel: null,

      DetailsDisplayViewModel: DetailsDisplayViewModel,
      DetailsDisplayViewUtils: DetailsDisplayViewUtils,
    };
  },
  computed: {
    ...mapGetters([
                   'auth_connected',
                   'auth_socket_status',
                   'signin_event',
                   'auth_client',
                   'domain',
                   ]),
  },
  watch: {
    auth_connected() {
      ConnectionUtils.sendSignInEvent(this);
    },
    auth_socket_status() {
      ConnectionUtils.displayStatus(this);
    },
    signin_event() {
      ConnectionUtils.createDomainSession(this);
    }
  },
  mounted: function () {
    this.viewModel = new DetailsDisplayViewModel(this, this.scheduleId);
    this.viewModel.withStatePageLoading();
    this.viewModel.clearAndBuildTableData();
    ConnectionUtils.ensureConnection(this);
  },
  methods: {
    ...mapActions([
                   'AuthStorage_updateCompany',
                   'addListenerAction',
                   'removeListenerAction',
                   'updateDomain',
                   ]),
    start: function() {
      window.onclick = this.onClick;
      this.viewModel.withStateContentLoading();
      this.viewModel.clearAndBuildTableData();
    },
    onClick: function(event) {
      var modal = document.getElementById("detailsModal");
      this.log().info("close window");
      if (event.target == modal) {
        this.goBack();
      }
    },
    log() {
      return this.domain.logger();
    },
    goBack() {
      this.$router.back();
    },
    model: function() {
      return this.viewModel;
    },
    withButtonPressed: function(buttonData) {
      if (buttonData["field"] === "DetailsClose") {
        this.$emit("withButtonPressed", buttonData);
      }
      
      if (buttonData["field"] === "Repair") {
        this.$emit("withButtonPressed", buttonData);
      }
      
      if (buttonData["field"] === "Print") {
        var inspection = this.domain.inspections().findById(buttonData["id"]);
        var url = ContentUtils.escape(inspection.pdfUrl());
        url = url.replace("#", "%23");
        window.open(url, '_blank');
      }

      if (buttonData["field"] === "Email") {
        this.$emit("withButtonPressed", buttonData);
      }

      if (buttonData["field"] === "Remarks") {
        this.$emit("withButtonPressed", buttonData);
      }

      if (buttonData["field"] === "Full") {
        var fullParams = {
          id: buttonData["id"],
        };
        this.$router.push({
          name: ConstUtils.ROUTES.INSPECTION_DETAILS,
          params: fullParams,
        });
      }
    },

    withValue: function(valueData) {
      this.$emit("withValue", valueData);
    },

    displayProgress: function(msg) {
      this.callback(msg);
    },

    callback: function(msg) {
      if (msg) {
        //
      }
    },
    deleteDetails(query) {
      delete query.action;
      return query;
    },
    reloadPage: function() {
      this.viewModel.withStateContentLoading();
      this.viewModel.clearAndBuildTableData();
      this.viewModel.loadItems();
    },
    paint: function() {
      this.redraw++;
    },
  },
}
</script>