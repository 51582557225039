
import ListViewUtils from "./ListViewUtils";

// import Utils from '@/views/portal/inspections/Utils.js';
import ContentUtils from '@/utils/ContentUtils.js';
import StringUtils from '@/utils/StringUtils.js';
import RcTableViewModel from "../../../../../shared/table/RcTableViewModel";
import TimesheetMap from '@/domain/model/timesheet/TimesheetMap.js';
// import EmployeeMap from "@/domain/model/employee/EmployeeMap.js";
// import Employee from "@/domain/model/employee/Employee.js";
import TimesheetListFilter from "@/domain/model/timesheet/TimesheetListFilter.js";


export default class ListViewModel extends RcTableViewModel {
    
  constructor(panel) {
    super(panel);
    this._companyId = ContentUtils.baseDecode(panel.$route.params.operatorId);
    this._userId = ContentUtils.baseDecode(this.panel().$route.query.userId);
    this._total = 0;
    this._error = null;
    this._timesheets = [];
    this._pages = [];
  }

  start() {
    super.start();
  }

  log() {
    return this.domain().logger();
  }

  company() {
    return this.domain().companys().findById(this._companyId);
  }
  
  isAdmin() {
    return super.isAdmin();
  }

  withValue(valueData) {
    super.withValue(valueData, "timesheet");
  }

  withButtonPressed(buttonData) {
    super.withButtonPressed(buttonData);
    if (!buttonData) {
      return;
    }
    if (buttonData["field"] == "Print") {
      var timesheetId = buttonData["id"];
      var timesheet = this.domain().timesheets().findById(timesheetId);
      this.printTimesheet(timesheet);
    }
  }

  buildRowData(tableData) {
    var utils = new ListViewUtils(this, tableData);
    utils.start();
    return this;
  }

  loadTimesheets() {
    super.loadItems();
    var query = this.panel().$route.query;
    this.panel().displayProgress("Loading Hours of Service");
    var time = query["time"];
    if (!isNaN(time)) {
      time = "(" + time + ")";
    } 
    if (StringUtils.isEmpty(time)) {
      time = "last30Days";
    }
    
    var filter = new TimesheetListFilter();
    filter
      .withDateRange(time)
      .withPagination(this.pageSize(query, "timesheet"), query["page"] ? (query["page"] - 1) : 0)
      .withStatusType(query["status"])
      .done();

    if (StringUtils.isNotEmpty(this._userId)) {
      var employeeMap = this.domain().employees().findByUserId(this._userId);
      var employee = employeeMap.findByCompanyId(this._companyId);
      if (this.isAdmin()) {
        employee = employeeMap.first();
      }
      if (!employee.isNew()) {
        filter
          .withEmployee(employee)
          .done();
      }
    }

    filter
      .withCompany(this._companyId)
      .done();
    
    var event = this.domain()
        .events()
        .timesheets().list(filter, true);
        
    var context = {};
    context["model"] = this;
    context["startTime"] = new Date();
    event.send(this.loadTimesheetsListener, context);
  }

  loadTimesheetsListener(responseEvent, context) {
    var model = context.model;
    context["loadTime"] = new Date();
    if (responseEvent.error()) {
      model.withError(responseEvent.error());
    } else {
      var payload = responseEvent.payload();
      model._total = payload.count();
      var payloadMap = payload.valuesFor(TimesheetMap.MODEL_NAME);
      var map = new TimesheetMap(model.domain(), payloadMap);
      model._timesheets = map.sort();
      context["sortTime"] = new Date();
    }
    var query = model.panel().$route.query;
    model._pages = ContentUtils.PAGE_INDEXES(model._total, model.pageSize(query, "timesheet"))
    model
      .withStateContentLoaded()
      .clearAndBuildTableData()
      .done();
    model.panel().showDetailsIfRequired();

    delete context.model;
    context["renderTime"] = new Date();
    model.log().info("Client Performance {0}", [JSON.stringify(context, null, 2)]);
  }

  timesheets() {
    return this._timesheets;
  }

  printTimesheet(timesheet) {
    var url = ContentUtils.escape(timesheet.pdfUrl());
    url = url.replace("#", "%23");
    window.open(url, '_blank');
  }
  
  user() {
    this._userId = ContentUtils.baseDecode(this.panel().$route.query.userId);
    var user = this.domain().users().findById(this._userId);
    return user;
  }

  totalItems() {
    return this._total;
  }

  pages() {
    return this._pages;
  }

  error() {
    return this._error;
  }

}