<template>
  <Row>
    <Column :width="15" :key="redraw">
      <div id="detailsModal" class="rc-modal-dialog">
        <!-- Modal content -->
        <div class="rc-modal-content-dialog-small">
          <div class="rc-modal-header-dialog">
            <Box >
              <Row 
                v-for="(row, rowIndex) in viewHeaderData" 
                :key="rowIndex" >
                  <CellFactory 
                    :rowData="row.data"
                    :showMissingRequirements="showMissingRequirements"
                    v-on:withValue="withFieldValue"
                    v-on:withButtonPressed="withButtonPressed"
                    />
              </Row>
            </Box>
          </div>

          <div class="rc-modal-body-dialog">
            <div class="rc-modal-body-inner-dialog rc-border-thin">
              <Box>
                <Row 
                  v-for="(row, rowIndex) in viewBodyData" 
                  :key="rowIndex" >
                    <CellFactory 
                      :rowData="row.data"
                      :showMissingRequirements="showMissingRequirements"
                      v-on:withValue="withFieldValue"
                      v-on:withButtonPressed="withButtonPressed"
                      />
                </Row>
              </Box>
            </div>
          </div>

          <div class="rc-modal-footer-dialog">
            <Box>
              <Row 
                v-for="(row, rowIndex) in viewFooterData" 
                :key="rowIndex" >
                  <CellFactory 
                    :rowData="row.data"
                    :showMissingRequirements="showMissingRequirements"
                    v-on:withValue="withFieldValue"
                    v-on:withButtonPressed="withButtonPressed"
                    />
              </Row>
            </Box>
          </div>
        </div>
      </div>
    </Column>
  </Row>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';

import ConnectionUtils from '@/utils/ConnectionUtils.js';
import ConstUtils from '@/utils/ConstUtils.js';
import ContentUtils from '@/utils/ContentUtils.js';
import StringUtils from '@/utils/StringUtils.js';

import MC from "@/domain/session/MC.js";

import CellFactory from "@/portals/shared/cell/CellFactory.vue";
import Box from "@/portals/shared/library/box/Box.vue";
import Column from "@/portals/shared/library/box/Column.vue";
import Row from "@/portals/shared/library/box/Row.vue";

import BoxDisplayRow from "@/portals/shared/cell/box/BoxDisplayRow";
import ButtonDisplayRow from "@/portals/shared/cell/button/ButtonDisplayRow";
import ErrorDisplayRow from "@/portals/shared/cell/error/ErrorDisplayRow";
import RowDisplayRow from "@/portals/shared/cell/row/RowDisplayRow";
import SpacerDisplayRow from '@/portals/shared/cell/spacer/SpacerDisplayRow';
import StringDisplayRow from "@/portals/shared/cell/string/StringDisplayRow";
import StringUpdateRow from "@/portals/shared/cell/string/StringUpdateRow";
import TitleDisplayRow from "@/portals/shared/cell/title/TitleDisplayRow";

export default {
  name: "equipment-list-remove-view",
  components: {
    Column, Row, 
    Box,
    CellFactory,
  },
  props: {
    itemId: { type: String, default: "" },
  },
  data() {
    return {
      redraw: 1,
      
      MC: new MC(),
      Strings: StringUtils,
      Contents: ContentUtils,
      Consts: ConstUtils,

      showMissingRequirements: false,
      viewHeaderData: [],
      viewBodyData: [],
      viewFooterData: [],

      kvps: {},
      error: null,
      StringUpdateRow: StringUpdateRow
    };
  },
  computed: {
    ...mapGetters([
                   'auth_connected',
                   'auth_socket_status',
                   'signin_event',
                   'auth_client',
                   'domain',
                   ]),
  },
  watch: {
    auth_connected() {
      ConnectionUtils.sendSignInEvent(this);
    },
    auth_socket_status() {
      ConnectionUtils.displayStatus(this);
    },
    signin_event() {
      ConnectionUtils.createDomainSession(this);
    }
  },
  mounted: function () {
    ConnectionUtils.ensureConnection(this);
  },
  methods: {
    ...mapActions([
                   'addListenerAction',
                   'removeListenerAction',
                   'updateDomain',
                   'AuthStorage_updateCompany'
                   ]),

    start: function() {
      this.setup();
      this.mainScreen();
    },

    setup: function() {
      window.onclick = this.onClick;
    },

    mainScreen() {
      this.viewHeaderData = [];
      this.viewBodyData = [];
      this.viewFooterData = [];

      this.viewHeaderData.push(new TitleDisplayRow().withTitle("Remove Equipment"));
      var equipment = this.domain.vehicles().findById(this.Contents.baseDecode(this.itemId));

      var errorBox = new BoxDisplayRow().withBorder(true)
                          .withChild(new RowDisplayRow()
                            .withChild(new SpacerDisplayRow().withWidth("1"))
                            .withChild(new ErrorDisplayRow().withWidth("13").withError(this.error))
                            .withChild(new SpacerDisplayRow().withWidth("1"))
                          );
      if (this.error) {
        this.viewBodyData.push(errorBox);
      }

      let bodyRow = new BoxDisplayRow().withChild(new RowDisplayRow()
                              .withChild(new SpacerDisplayRow().withWidth("1"))
                              .withChild(new BoxDisplayRow()
                                              .withWidth("13")
                                              .withChild(new StringDisplayRow().withValue("The remove action will make the equipment Inactive and the it will no longer appear in the App.").withFontSize("xlarge"))
                                              .withChild(new StringDisplayRow().withValue("This is recommended if the equipment no longer operated or owned by the company").withFontSize("small"))
                                              .withChild(new SpacerDisplayRow())
                                              .withChild(new StringDisplayRow().withValue(equipment.name() + " " + equipment.plate()).withFontSize("xxxlarge"))
                                              .withChild(new SpacerDisplayRow())
                                              .withChild(new StringDisplayRow().withValue("Are you sure you want to remove this equipment?").withFontSize("xlarge"))
                                            )
                              .withChild(new SpacerDisplayRow().withWidth("1"))
                            )
      this.viewBodyData.push(bodyRow);

      let footerRow = new BoxDisplayRow()
                  .withBorder(true)
                  .withChild(
                    new RowDisplayRow()
                      .withChild(new StringDisplayRow().withWidth("3"))
                      .withChild(new ButtonDisplayRow().withWidth("3").withName("Back").withField("Close"))
                      .withChild(new StringDisplayRow().withWidth("3"))
                      .withChild(new ButtonDisplayRow().withWidth("3").withName("Remove").withField("Remove").withBackgroundPrimaryAction())
                      .withChild(new StringDisplayRow().withWidth("3"))
                  );
      this.viewFooterData.push(footerRow);
    },

    sendingScreen() {
      this.viewBodyData = [];
      this.viewFooterData = [];
      this.viewHeaderData = [];

      let bodyBox = new BoxDisplayRow().withChild(new RowDisplayRow()
                              .withChild(new SpacerDisplayRow().withWidth("2"))
                              .withChild(new BoxDisplayRow()
                                    .withWidth("11")
                                    .withChild(new StringDisplayRow()
                                                  .withMandatoryValue(true)
                                                  .withValue("Removing Equipment"))
                                  )
                              .withChild(new SpacerDisplayRow().withWidth("2"))
                            );
      this.viewBodyData.push(bodyBox);
      this.paint();
    },

    doneScreen() {
      this.viewHeaderData = [];
      this.viewBodyData = [];
      this.viewFooterData = [];

      let bodyBox = new BoxDisplayRow().withChild(new RowDisplayRow()
                              .withChild(new SpacerDisplayRow().withWidth("2"))
                              .withChild(new BoxDisplayRow()
                                    .withWidth("11")
                                    .withChild(new StringDisplayRow()
                                                  .withMandatoryValue(true)
                                                  .withValue("Done!"))
                                  )
                              .withChild(new SpacerDisplayRow().withWidth("2"))
                            );

      this.viewBodyData.push(bodyBox);
      this.paint();
    },

    paint: function() {
      this.redraw++;
    },

    onClick: function(event) {
      var modal = document.getElementById("detailsModal");
      if (event.target == modal) {
        this.goBack();
      }
    },
    
    withButtonPressed: function(buttonData) {
      if (buttonData["field"] === "Close") {
        this.$emit("withButtonPressed", buttonData);
      }
      
      if (buttonData["field"] === "Remove") {
        this.send();
      }

    },

    send: function() {
      this.sendingScreen();
      var context = {
        view: this
      };

      let decodedId = this.Contents.baseDecode(this.itemId);
      var equipment = this.domain.vehicles().findById(decodedId);
      
      var event = this.domain.events().vehicles().remove(equipment)
      event.send(this.doneSendAction, context);
    },

    doneSendAction(notification, context) {
      this.doneScreen();
      if (!notification || !context) {
        return;
      }
      if (notification.error()) {
        this.error = notification.error();
        this.mainScreen();
        return;
      }
      var buttonData = {};
      buttonData["field"] = "Close";
      this.$emit("withButtonPressed", buttonData);
    },

    withFieldValue: function(valueData) {
      if (valueData) {
        //
      }
      this.showMissingRequirements = false;
    },

    displayProgress: function(msg) {
      this.callback(msg);
    },

    callback: function(msg) {
      if (msg) {
        //
      }
    },

    areRequirementsMet: function() {
      var met = true;
      return met;
    },

    goBack: function() {
      this.$router.back();
    }
  },
}
</script>