<template>
    <Row v-if="ready" borderStyle="rc-border-thin-even"> 
        <Column :width="15" :key="redraw">
            <Box :even="row.even()" :backgroundColour="row.even() ? 'rowEven' : 'rowOdd'">
                <Row>
                    <Column :width="5">
                        <LabelValueBox :labelWidth="5">
                            <template v-slot:label>
                                Name
                            </template>
                            <template v-slot:value>
                                {{ user.fullName() }}
                            </template>
                        </LabelValueBox>
                    </Column>
                    <Column :width="5">
                        <LabelValueBox :labelWidth="5">
                            <template v-slot:label>
                                Street
                            </template>
                            <template v-slot:value>
                                {{ user.address() }}
                            </template>
                        </LabelValueBox>
                    </Column>
                    <Column :width="5">
                        <LabelValueBox :labelWidth="5">
                            <template v-slot:label>
                                Class
                            </template>
                            <template v-slot:value>
                                {{ user.licenseClass() }}
                            </template>
                        </LabelValueBox>
                    </Column>
                </Row>
                <Row>
                    <Column :width="5">
                        <Box>
                            <StatusCell :data="this.employeeStateRow().data" />
                        </Box>
                    </Column>
                    <Column :width="5">
                        <LabelValueBox :labelWidth="5">
                            <template v-slot:label>
                                City
                            </template>
                            <template v-slot:value>
                                {{ user.city() }}
                            </template>
                        </LabelValueBox>
                    </Column>
                    <Column :width="5">
                        <Box v-if="user.hasLicenseExpired()">
                            <StatusCell :data="this.licenseExpiryStateRow('Expired', 'Expired').data" />
                        </Box>
                        <Box v-else-if="user.willLicenseExpireInWeeks(4)">
                            <StatusCell :data="this.licenseExpiryStateRow('ExpireSoon', 'Expiring Soon').data" />
                        </Box>
                        <Box v-else-if="user.willLicenseExpireInWeeks(1)">
                            <StatusCell :data="this.licenseExpiryStateRow('ExpireNext', 'Expiring this week').data" />
                        </Box>
                        <LabelValueBox v-else :labelWidth="5">
                            <template v-slot:label>
                                Expiry Date
                            </template>
                            <template v-slot:value>
                                {{ user.licenseExpiryDateDisplay() }}
                            </template>
                        </LabelValueBox>
                    </Column>
                </Row>
                <Row>
                    <Column :width="5">
                        <LabelValueBox :labelWidth="5">
                            <template v-slot:label>
                                Email
                            </template>
                            <template v-slot:value>
                                {{ user.emailDisplay() }}
                            </template>
                        </LabelValueBox>
                    </Column>
                    <Column :width="5">
                        <LabelValueBox :labelWidth="5">
                            <template v-slot:label>
                                Province
                            </template>
                            <template v-slot:value>
                                {{ user.jurisdiction() }}
                            </template>
                        </LabelValueBox>
                    </Column>
                    <Column :width="5">
                        <LabelValueBox :labelWidth="5">
                            <template v-slot:label>
                                Compliance
                            </template>
                            <template v-slot:value>
                                {{ employee.complianceType() }}
                            </template>
                        </LabelValueBox>
                    </Column>
                </Row>
                <Row>
                    <Column :width="5">
                        <LabelValueBox :labelWidth="5">
                            <template v-slot:label>
                                Phone
                            </template>
                            <template v-slot:value>
                                {{ user.phone() }}
                            </template>
                        </LabelValueBox>
                    </Column>
                    <Column :width="5">
                        <LabelValueBox :labelWidth="5">
                            <template v-slot:label>
                                Country
                            </template>
                            <template v-slot:value>
                                {{ user.country() }}
                            </template>
                        </LabelValueBox>
                    </Column>
                    <Column :width="5"></Column>
                </Row>
                <Row>
                    <Column :width="5">
                        <LabelValueBox :labelWidth="5">
                            <template v-slot:label>
                                Employee Id
                            </template>
                            <template v-slot:value>
                                {{ employee.employmentId() }}
                            </template>
                        </LabelValueBox>
                    </Column>
                    <Column :width="5">
                        <LabelValueBox :labelWidth="5">
                            <template v-slot:label>
                                Postal Code
                            </template>
                            <template v-slot:value>
                                {{ user.postal() }}
                            </template>
                        </LabelValueBox>
                    </Column>
                    <Column :width="5"></Column>
                </Row>
                <Row>
                    <Column :width="5">
                        <LabelValueBox :labelWidth="5">
                            <template v-slot:label>
                                Roles
                            </template>
                            <template v-slot:value>
                                {{ user.rolesDisplay() }}
                            </template>
                        </LabelValueBox>
                    </Column>
                    <Column :width="10"></Column>
                </Row>
                <Row>
                    <Column :width="5">
                        <LabelValueBox :labelWidth="5">
                            <template v-slot:label>
                                Last Sign In
                            </template>
                            <template v-slot:value>
                                {{ user.lastSignInV2().displayAs_YYYY_MM_DD_HH_MM_SS_A("[Has not signed in]") }}
                            </template>
                        </LabelValueBox>
                    </Column>
                    <Column :width="10"></Column>
                </Row>
                <Row v-if="row.isSelect()">
                    <Column :width="13"></Column>
                    <Column :width="2">
                        <Button v-on:click="withPickButtonPressed">Select</Button>
                    </Column>
                </Row>
                <Row v-else>
                    <Column :width="5"></Column>
                    <Column v-if="employee.isStateInactive()" :width="2"></Column>

                    <Column v-if="company.hasTimesheets() && !(employee.isStateNotInvited() || employee.isStateInvited())" :width="2">
                        <Button v-on:click="withHoursOfServiceButtonPressed">Hours of Service</Button>
                    </Column> 
                    <Column v-else :width="2"></Column>

                    <Column v-if="company.hasInspections() && !(employee.isStateNotInvited() || employee.isStateInvited())" :width="2">
                        <Button v-on:click="withInspectionsButtonPressed">Inspections</Button>
                    </Column> 
                    <Column v-else :width="2"></Column>

                    <Column v-if="employee.isStateActive()" :width="2">
                        <Button v-on:click="withRemoveButtonPressed">Remove</Button>
                    </Column>
                    <Column v-if="employee.isStateActive()" :width="2">
                        <Button v-on:click="withPasswordResetButtonPressed">Reset Password</Button>
                    </Column>
                    
                    <Column v-if="employee.isStateInvited()" :width="2">
                        <Button v-on:click="withRemoveButtonPressed">Remove</Button>
                    </Column>
                    <Column v-if="employee.isStateInvited()" :width="2">
                        <Button v-on:click="withResendInviteButtonPressed">Resend Invite</Button>
                    </Column>

                    <Column v-if="employee.isStateNotInvited()" :width="2">
                        <Button v-on:click="withRemoveButtonPressed">Remove</Button>
                    </Column>
                    <Column v-if="employee.isStateNotInvited()" :width="2">
                        <Button v-on:click="withSendButtonPressed">Send Invite</Button>
                    </Column>

                    <Column v-if="employee.isStateInactive()" :width="2">
                        <Button v-on:click="withRestoreButtonPressed">Restore</Button>
                    </Column>

                    <Column :width="2">
                        <Button v-on:click="withUpdateButtonPressed">Update</Button>
                    </Column>
                </Row>
                
                <!-- Admin section -->
                <Row v-if="row.isAdmin()">
                    <Column :width="15">
                        <Box :even="row.even()">
                            <Row>
                                <Column>
                                    <LabelValueBox :labelWidth="5">
                                    <template slot="label">
                                        Admin Section
                                    </template>
                                    <template slot="value">
                                        {{ "" }}
                                    </template>
                                    </LabelValueBox>
                                </Column>
                            </Row>
                            <Row>
                                <Column :width="5">
                                    <LabelValueBox :labelWidth="5">
                                    <template slot="label">
                                        Company Name
                                    </template>
                                    <template slot="value">
                                        {{ this.Content.decodeText(company.name(), "[Not Set]") }} {{ company.id() }}
                                    </template>
                                    </LabelValueBox>
                                </Column>
                            </Row>

                            <Row> <!-- Created and Modified By/On Row -->
                                <Column :width="5">
                                    <LabelValueBox :labelWidth="5">
                                    <template slot="label">
                                        Modified By/On
                                    </template>
                                    <template slot="value">
                                        {{ employee.modifiedBy().find().fullName() }}
                                        {{ "/" }}
                                        {{ employee.modifiedDateV2().displayAs_YYYY_MM_DD_HH_MM_SS_A() }}
                                    </template>
                                    </LabelValueBox>
                                </Column>
                                <Column :width="5">
                                    <LabelValueBox :labelWidth="5">
                                    <template slot="label">
                                        Created By/On
                                    </template>
                                    <template slot="value">
                                        {{ employee.createdBy().find().fullName() }}
                                        {{ "/" }}
                                        {{ employee.createdDateV2().displayAs_YYYY_MM_DD_HH_MM_SS_A() }}
                                    </template>
                                    </LabelValueBox>
                                </Column>
                                <Column :width="5">
                                    <LabelValueBox :labelWidth="5">
                                        <template slot="label">
                                        </template>
                                        <template slot="value">
                                        </template>
                                    </LabelValueBox>
                                </Column>
                            </Row>
                            <Row>
                                <Column :width="5">
                                    <LabelValueBox :labelWidth="5">
                                        <template slot="label">
                                            Make
                                        </template>
                                        <template slot="value">
                                            {{ user.clientMake() }}
                                        </template>
                                    </LabelValueBox>
                                </Column>
                                <Column :width="5">
                                    <LabelValueBox :labelWidth="5">
                                        <template slot="label">
                                            Client
                                        </template>
                                        <template slot="value">
                                            {{ user.clientName() }}
                                        </template>
                                    </LabelValueBox>
                                </Column>
                                <Column :width="5">
                                    <LabelValueBox :labelWidth="5">
                                        <template slot="label">
                                            Version
                                        </template>
                                        <template slot="value">
                                            {{ user.clientVersion() }}
                                        </template>
                                    </LabelValueBox>
                                </Column>
                            </Row>
                            <Row>
                                <Column :width="5">
                                    <LabelValueBox :labelWidth="5">
                                        <template slot="label">
                                            User Id
                                        </template>
                                        <template slot="value">
                                            {{ user.id() }}
                                        </template>
                                    </LabelValueBox>
                                </Column>
                                <Column :width="5">
                                    <LabelValueBox :labelWidth="5">
                                        <template slot="label">
                                            Employee Id
                                        </template>
                                        <template slot="value">
                                            {{ employee.id() }}
                                        </template>
                                    </LabelValueBox>
                                </Column>
                                <Column :width="5">
                                    <LabelValueBox :labelWidth="5">
                                        <template slot="label">
                                            Signature Id
                                        </template>
                                        <template slot="value">
                                            {{ user.signature().id() }}
                                        </template>
                                    </LabelValueBox>
                                </Column>
                            </Row>
                            <Row>
                                <Column :width="13"></Column>
                                <Column :width="2">
                                    <Button v-on:click="withSelectButtonPressed">Select</Button>
                                </Column>
                            </Row>
                        </Box>
                    </Column>
                </Row>
            </Box>
        </Column>
    </Row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import ConnectionUtils from '@/utils/ConnectionUtils.js';
import ConstUtils from '@/utils/ConstUtils.js';
import ContentUtils from '@/utils/ContentUtils.js';
import StringUtils from '@/utils/StringUtils.js';
  
import MC from "@/domain/session/MC.js";

import Box from "@/portals/shared/library/box/Box.vue";
import Column from "@/portals/shared/library/box/Column.vue";
import Row from "@/portals/shared/library/box/Row.vue";

import LabelValueBox from "@/portals/shared/library/labelvalue/LabelValue.vue";
import Button from "@/portals/shared/library/button/Button.vue";

import StatusCell from '@/portals/shared/cell/status/StatusDisplayCell';
import StatusDisplayRow from '@/portals/shared/cell/status/StatusDisplayRow';

import EmployeeRow from './EmployeeRow';

export default {
    name: "employee-cell",
    components: {
    Box, Row, Column,
    LabelValueBox,
    Button,
    StatusCell,
    },

    props: {
        showMissingRequirements: { type: Boolean, default: false },
        isAdmin: { type: Boolean, default: true },
        isSelect: { type: Boolean, default: false },
        data: { type: Object, default: null },
    },

    data() {
        return {
            MC: new MC(),
            Connections: ConnectionUtils,
            Consts: ConstUtils,
            Content: ContentUtils,
            Strings: StringUtils,

            row: new EmployeeRow(),
            employee: null,
            user: null,
            company: null,
            ready: false,
            redraw: 1,
        }
    },

    computed: {
    ...mapGetters([
                    'auth_connected',
                    'auth_socket_status',
                    'signin_event',
                    'auth_client',
                    'domain',
                    'EmployeeStorage_lastEvent',
                    ]),
    },

    watch: {
        EmployeeStorage_lastEvent() {
        let employee = this.domain.vehicles().findById(this.employee.id());
            if (this.employee.modifiedDate() < employee.modifiedDate()) {
                this.row.withEmployee(employee);
                this.employee = this.row.employee(this.domain);
                this.user = this.row.user(this.domain);
                this.company = this.employee.company().find();
                this.redraw++;
            }
        }
    },

    mounted: function () {
        this.row.data = this.data;
        this.employee = this.row.employee(this.domain);
        this.user = this.row.user(this.domain);
        this.company = this.employee.company().find();
        this.ready = true;
    },

    methods: {
    ...mapActions([
                    'AuthStorage_updateCompany',
                    'addListenerAction',
                    'removeListenerAction',
                    'updateDomain',
                    ]),
    
    withEnterPressed(keyData) {
      this.$emit("withEnterPressed", keyData);
    },

    withValue(valueData) {
      this.$emit('withValue', valueData);
    },

    withUpdateButtonPressed() {
      let data = {};
      data["field"] = "UserUpdate";
      this.withButtonPressed(data);
    },

    withRestoreButtonPressed() {
      let data = {};
      data["field"] = "Restore";
      this.withButtonPressed(data);
    },

    withRemoveButtonPressed() {
      let data = {};
      data["field"] = "UserRemoveConfirm";
      this.withButtonPressed(data);
    },

    withPasswordResetButtonPressed() {
      let data = {};
      data["field"] = "PasswordResetConfirm";
      this.withButtonPressed(data);
    },

    withSendButtonPressed() {
      let data = {};
      data["field"] = "Send";
      this.withButtonPressed(data);
    },

    withResendInviteButtonPressed() {
      let data = {};
      data["field"] = "Resend";
      this.withButtonPressed(data);
    },

    withInspectionsButtonPressed() {
      let data = {};
      data["field"] = "Inspections";
      data["id"] = this.user.id();
      this.withButtonPressed(data);
    },

    withHoursOfServiceButtonPressed() {
      let data = {};
      data["field"] = "Hours";
      data["id"] = this.user.id();
      this.withButtonPressed(data);
    },

    withPickButtonPressed() {
      let data = {};
      data["field"] = "Pick";
      this.withButtonPressed(data);
    },

    withSelectButtonPressed() {
      let data = {};
      data["field"] = "Select";
      data["id"] = this.company.id();
      this.withButtonPressed(data);
    },

    withButtonPressed: function (buttonData) {
      if (StringUtils.isEmpty(buttonData["id"])) {
        buttonData["id"] = this.row.id();
      }
      buttonData["key"] = this.row.key();
      this.$emit('withButtonPressed', buttonData);
    },

    employeeStateRow() {
        var hint = "";
        if (this.employee.isStateInvited()) {
            hint = "Has not signed in since receiving invite email";
        }
        if (this.employee.isStateInactive()) {
            hint = "Can no longer sign in";
        }
        if (this.employee.isStateNotInvited()) {
            hint = "Has not received an invite email";
        }

        let row = new StatusDisplayRow();
        let value = this.employee.state();
        row.withValueWidth(12);
        row.withLabelWidth(5);
        row.withName("State");
        row.withValue(value);
        row.withObject(StringUtils.titleCase(value));
        row.withHint(hint);
        return row;
    },

    licenseExpiryStateRow(value, hint) {
        let row = new StatusDisplayRow();
        row.withValueWidth(12);
        row.withLabelWidth(5);
        row.withName("Expiry Date");
        row.withValue(value);
        row.withObject(this.user.licenseExpiryDateDisplay());
        row.withHint(hint);
        return row;
    },
    },
}

</script>