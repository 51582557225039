<template>
    <Row v-if="ready" borderStyle="rc-border-thin-even"> 
        <Column :width="15" :key="redraw">
            <Box :even="row.even()" :backgroundColour="row.even() ? 'rowEven' : 'rowOdd'">
                <Row>
                    <Column :width="5">
                        <Box>
                            <StringDisplayCell v-on:click="withDetailsDialogButtonPressed()" :data="driverStringDisplayRow().data"></StringDisplayCell>
                        </Box>
                    </Column>
                    <Column :width="5">
                        <Box v-if="user.hasLicenseExpired()">
                            <StatusCell :data="this.licenseExpiryStateRow('Expired', 'Expired').data" />
                        </Box>
                        <Box v-else-if="user.willLicenseExpireInWeeks(4)">
                            <StatusCell :data="this.licenseExpiryStateRow('ExpireSoon', 'Expiring Soon').data" />
                        </Box>
                        <Box v-else-if="user.willLicenseExpireInWeeks(1)">
                            <StatusCell :data="this.licenseExpiryStateRow('ExpireNext', 'Expiring this week').data" />
                        </Box>
                        <LabelValueBox v-else :labelWidth="7">
                            <template v-slot:label>
                                License Expiry Date
                            </template>
                            <template v-slot:value>
                                {{ user.licenseExpiryDateDisplay() }}
                            </template>
                        </LabelValueBox>
                    </Column>
                </Row>
                <Row>
                    <Column :width="1"></Column>
                    <Column :width="14">
                        <Box>
                            <Row>
                                <Column v-for="dayIndex in 15" :key="dayIndex" :width="1">
                                    <Box>
                                        <StringDisplayCell :data="dateStringDisplayRow(dayIndex).data"></StringDisplayCell>
                                    </Box>
                                </Column>
                            </Row>
                        </Box>
                    </Column>
                </Row>
                <Row v-if="company.hasTimesheets()">
                    <Column :width="1">
                        <Box>
                            <StringDisplayCell :data="hoursOfServiceStringDisplayRow().data"></StringDisplayCell>
                        </Box>
                    </Column>
                    <Column :width="14">
                        <Box>
                            <Row>
                                <Column v-for="(timesheet, index) in timesheetRowMaker(15)" :key="index" :width="1">
                                    <Box v-if="timesheet.isEmpty()">
                                        <ImageDisplayCell 
                                        :data="timesheetEmptyImageDisplayRow(timesheet).data">
                                        </ImageDisplayCell>
                                        <StringDisplayCell v-if="$route.query['labels'] == true" :data="missingDisplayRow().data"></StringDisplayCell>
                                    </Box>
                                    <Box v-else>
                                        <ImageDisplayCell 
                                        v-on:click="withTimesheetDialogButtonPressed(timesheet.first().id())" 
                                        :data="timesheetImageDisplayRow(timesheet.first()).data">
                                        </ImageDisplayCell>
                                        <StringDisplayCell v-if="$route.query['labels'] == true" :data="timesheetStatusStringDisplayRow(timesheet.first()).data"></StringDisplayCell>
                                    </Box>
                                </Column>
                            </Row>
                        </Box>
                    </Column>
                </Row>
                <Row v-if="company.hasInspections()">
                    <Column :width="1">
                        <Box>
                            <StringDisplayCell :data="inspectionsStringDisplayRow().data"></StringDisplayCell>
                        </Box>
                    </Column>
                    <Column :width="14">
                        <Box>
                            <Row>
                                <Column v-for="(inspection, index) in inspectionRowMaker(14)" :key="index" :width="1">
                                    <Box v-if="inspection.isEmpty()">
                                        <Column :width="15"></Column>
                                    </Box>
                                    <Box v-else>
                                        <ImageDisplayCell 
                                        v-on:click="withInspectionDialogButtonPressed(inspection.first().id())" 
                                        :data="inspectionImageDisplayRow(inspection.first()).data">
                                        </ImageDisplayCell>
                                        <StringDisplayCell v-if="$route.query['labels'] == true" :data="inspectionStatusStringDisplayRow(inspection.first()).data"></StringDisplayCell>
                                    </Box>
                                </Column>
                            </Row>
                        </Box>
                    </Column>
                </Row>
                <Row v-if="employee.hasBeenIncluded()">
                    <Column :width="13"></Column>
                    <Column :width="1">
                        <Button v-on:click="withHideButtonPressed">Hide</Button>
                    </Column>
                    <Column :width="1">
                        <Button v-on:click="withRemindButtonPressed">Remind</Button>
                    </Column>
                </Row>
                <Row v-else>
                    <Column :width="14"></Column>
                    <Column :width="1">
                        <Button v-on:click="withIncludeButtonPressed">Include</Button>
                    </Column>
                </Row>
            </Box>
        </Column>
    </Row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import ConnectionUtils from '@/utils/ConnectionUtils.js';
import ConstUtils from '@/utils/ConstUtils.js';
import ContentUtils from '@/utils/ContentUtils.js';
import StringUtils from '@/utils/StringUtils.js';
  
import MC from "@/domain/session/MC.js";

import Box from "@/portals/shared/library/box/Box.vue";
import Column from "@/portals/shared/library/box/Column.vue";
import Row from "@/portals/shared/library/box/Row.vue";

import LabelValueBox from "@/portals/shared/library/labelvalue/LabelValue.vue";
import Button from "@/portals/shared/library/button/Button.vue";

import StatusCell from '@/portals/shared/cell/status/StatusDisplayCell';
import StatusDisplayRow from '@/portals/shared/cell/status/StatusDisplayRow';

import StringDisplayCell from '@/portals/shared/cell/string/StringDisplayCell';
import StringDisplayRow from '@/portals/shared/cell/string/StringDisplayRow';

import ImageDisplayCell from '@/portals/shared/cell/image/ImageDisplayCell';
import ImageDisplayRow from '@/portals/shared/cell/image/ImageDisplayRow';

import ComplianceRow from './ComplianceRow';

export default {
    name: 'compliance-cell',
    components: {
        Box, Column, Row,
        LabelValueBox, Button,
        StatusCell, StringDisplayCell, ImageDisplayCell,
    },

    props: {
        showMissingRequirements: { type: Boolean, default: false },
        isAdmin: { type: Boolean, default: true },
        data: { type: Object, default: null },
    },

    data() {
        return {
            MC: new MC(),
            Connections: ConnectionUtils,
            Consts: ConstUtils,
            Content: ContentUtils,
            Strings: StringUtils,

            row: new ComplianceRow(),
            employee: null,
            user: null,
            company: null,
            ready: false,
            redraw: 1,
        }
    },

    computed: {
    ...mapGetters([
                    'auth_connected',
                    'auth_socket_status',
                    'signin_event',
                    'auth_client',
                    'domain',
                    'ComplianceStorage_lastEvent',
                    ]),
    },

    watch: {
        ComplianceStorage_lastEvent() {
        let employee = this.domain.vehicles().findById(this.employee.id());
            if (this.employee.modifiedDate() < employee.modifiedDate()) {
                this.row.withEmployee(employee);
                this.employee = this.row.employee(this.domain);
                this.user = this.row.user(this.domain);
                this.company = this.employee.company().find();
                this.redraw++;
            }
        }
    },

    mounted: function () {
        this.row.data = this.data;
        this.employee = this.row.employee(this.domain);
        this.user = this.row.user(this.domain);
        this.company = this.employee.company().find();
        this.ready = true;
    },

    methods: {
    ...mapActions([
                    'AuthStorage_updateCompany',
                    'addListenerAction',
                    'removeListenerAction',
                    'updateDomain',
                    ]),
    
    withEnterPressed(keyData) {
      this.$emit("withEnterPressed", keyData);
    },

    withValue(valueData) {
      this.$emit('withValue', valueData);
    },

    withHideButtonPressed() {
        let data = {};
        data["field"] = "DisplayDialog";
        data["object"] = "Hide";
        this.withButtonPressed(data);
    },

    withRemindButtonPressed() {
        let data = {};
        data["field"] = "DisplayDialog";
        data["object"] = "Remind";
        this.withButtonPressed(data);
    },

    withIncludeButtonPressed() {
        let data = {};
        data["field"] = "DisplayDialog";
        data["object"] = "Include";
        this.withButtonPressed(data);
    },

    withDetailsDialogButtonPressed() {
        let data = {};
        data["id"] = this.user.id();
        data["object"] = "User";
        data["field"] = "DisplayDialog";
        data["key"] = "user";
        this.withButtonPressed(data);
    },

    withTimesheetDialogButtonPressed(id) {
        let data = {};
        data["id"] = id;
        data["object"] = "Timesheet";
        data["field"] = "DisplayDialog";
        this.withButtonPressed(data);
    },

    withInspectionDialogButtonPressed(id) {
        let data = {};
        data["id"] = id;
        data["object"] = "Inspection";
        data["field"] = "DisplayDialog";
        this.withButtonPressed(data);
    },

    withButtonPressed: function (buttonData) {
      if (StringUtils.isEmpty(buttonData["id"])) {
        buttonData["id"] = this.row.id();
      }
      buttonData["key"] = this.row.key();
      this.$emit('withButtonPressed', buttonData);
    },

    licenseExpiryStateRow(value, hint) {
        let row = new StatusDisplayRow();
        row.withValueWidth(12);
        row.withLabelWidth(7);
        row.withName("License Expiry Date");
        row.withValue(value);
        row.withObject(this.user.licenseExpiryDateDisplay());
        row.withHint(hint);
        return row;
    },

    driverStringDisplayRow() {
        let row = new StringDisplayRow();
        let roleDisplay = StringUtils.trim(this.employee.roles().find().display());
        row.withClickable(true);
        row.withAlignLeft();
        row.withWidth(4);
        row.withShowLabel(false);
        row.withValue(this.user.fullName() + " (" + roleDisplay + ")")
        return row;
    },

    dateStringDisplayRow(date) {
        let day = this.row.startFilterDate().minus(date - 1);
        let row = new StringDisplayRow();
        row.withFontSize("small");
        row.withWidth(1);
        row.withAlignLeft();
        row.withShowLabel(false);
        row.withValue(day.displayAs_MM_DD());
        return row;
    },

    timesheetRowMaker(numberOfDays) {
        let timesheetMap = this.row.timesheetMap(this.domain);
        let rows = [];

        for (let i = 0; i < numberOfDays; i++) {
            let day = this.row.startFilterDate().minus(i);
            let map = timesheetMap.findForDay(day, this.employee);
            rows.push(map);
        }
        return rows;
    },

    timesheetImageDisplayRow(timesheet) {
        let row = new ImageDisplayRow();
        row.withImageHosCompleteDay();

        if (timesheet.isStatusInProgress()) {
            row.withImageHosInProgressDay();
        }

        if (timesheet.isStatusCompleteOffDay()) {
            row.withImageOffDutyDay();
        }

        if (timesheet.isStatusIncomplete()) {
            row.withImageIncompleteDay();
        }
        row.withClickable(true);
        row.withId(timesheet.id());
        return row;
    },

    timesheetEmptyImageDisplayRow() {
        let imageRow = new ImageDisplayRow()
          .withImageHosMissing();
        imageRow.withTooltip("Missing");
        return imageRow;
    },

    hoursOfServiceStringDisplayRow() {
        let row = new StringDisplayRow();
        row.withWidth(1);
        row.withAlignLeft();
        row.withFontSize("small");
        row.withShowLabel(false);
        row.withValue("Hours of Service");
        return row;
    },

    missingDisplayRow() {
        let row = new StringDisplayRow();
        row.withWidth(1);
        row.withAlignLeft();
        row.withFontSize("xsmall");
        row.withShowLabel(false);
        row.withValue("Missing");
        return row;
    },

    timesheetStatusStringDisplayRow(timesheet) {
        let statusRow = new StringDisplayRow().withFontSize("xsmall").withAlignLeft().withWidth(1).withValue("Complete");

        if (timesheet.isStatusInProgress()) {
            statusRow.withValue("In Progress");
        }

        if (timesheet.isStatusCompleteOffDay()) {
            statusRow.withValue("Off Duty");
        }

        if (timesheet.isStatusIncomplete()) {
            statusRow.withValue("Incomplete");
        }
        
        return statusRow;
    },

    inspectionsStringDisplayRow() {
        let row = new StringDisplayRow();
        row.withWidth(1);
        row.withAlignLeft();
        row.withFontSize("small");
        row.withShowLabel(false);
        row.withValue("Inspections");
        return row;
    },

    inspectionRowMaker(numberOfDays) {
        let inspectionMap = this.row.inspectionMap(this.domain);
        let rows = [];

        for (let i = 0; i < numberOfDays; i++) {
            let day = this.row.startFilterDate().minus(i);
            let map = inspectionMap.findForDay(day, this.employee);
            rows.push(map);
        }
        return rows;
    },

    inspectionImageDisplayRow(inspection) {
        let imageRow = null;

        if (inspection.isDefectTypeGood()) {
            imageRow = new ImageDisplayRow()
              .withImageInspectionCompleteGood();
        }

        if (inspection.isDefectTypeMinor()) {
            imageRow = new ImageDisplayRow()
              .withImageInspectionCompleteMinor();
        }

        if (inspection.isDefectTypeMajor()) {
            imageRow = new ImageDisplayRow()
              .withImageInspectionCompleteMajor();
        }
        imageRow.withClickable(true);
        imageRow.withId(inspection.id());

        return imageRow;
    },

    inspectionStatusStringDisplayRow(inspection) {
        let statusRow = new StringDisplayRow().withFontSize("xsmall").withAlignLeft().withWidth(1);

        if (inspection.isDefectTypeGood()) {
            statusRow.withValue("Good");
        }

        if (inspection.isDefectTypeMinor()) {
            statusRow.withValue("Minor");
        }

        if (inspection.isDefectTypeMajor()) {
            statusRow.withValue("Major");
        }
        return statusRow;
    }

}
}


</script>