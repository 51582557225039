<template>
    <Row v-if="ready" borderStyle="rc-border-thin-even"> 
        <Column :width="15" :key="redraw">
            <Box :even="row.even()" :backgroundColour="row.even() ? 'rowEven' : 'rowOdd'">
                <Row>
                    <Column :width="5">
                        <ValueBox fontSize="medium">
                            {{ schedule.name() }}
                        </ValueBox>
                    </Column>
                    <Column :width="10">
                        <ValueBox fontSize="medium">
                            {{ schedule.description() }}
                        </ValueBox>
                    </Column>
                </Row>
                <Row v-if="!row.isAdmin()">
                    <Column :width="13"></Column>
                    <Column :width="2">
                        <Button v-on:click="withDetailsButtonPressed()">Details</Button>
                    </Column>
                </Row>  
                <Row v-if="row.isAdmin()">
                <Column :width="15">
                  <Box :even="row.even()">
                    <Row>
                      <Column :width="15">
                        <LabelValueBox :labelWidth="5">
                          <template slot="label">
                            Admin Section
                          </template>
                          <template slot="value">
                            {{ "" }}
                          </template>
                        </LabelValueBox>
                      </Column>
                    </Row>
                    <Row> <!-- Created and Modified By/On Row -->
                      <Column :width="5">
                        <LabelValueBox :labelWidth="5">
                          <template slot="label">
                            Modified By/On
                          </template>
                          <template slot="value">
                            {{ schedule.modifiedBy().find().fullName() }}
                            {{ schedule.modifiedDateV2().displayAs_YYYY_MM_DD_HH_MM_SS_A() }}
                          </template>
                        </LabelValueBox>
                      </Column>
                      <Column :width="5">
                        <LabelValueBox :labelWidth="5">
                          <template slot="label">
                            Created By/On
                          </template>
                          <template slot="value">
                            {{ schedule.createdBy().find().fullName() }}
                            {{ schedule.createdDateV2().displayAs_YYYY_MM_DD_HH_MM_SS_A() }}
                          </template>
                        </LabelValueBox>
                      </Column>
                    </Row>
                    <Row> <!-- Button Row-->
                      <Column :width="13"></Column>
                      <Column :width="2">
                        <Button v-on:click="withDetailsButtonPressed()">Details</Button>
                      </Column>
                    </Row><!-- End Button Row-->
                  </Box>
                </Column>
              </Row>
              <!-- Admin section -->  
            </Box>
        </Column>
    </Row>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';

import ConnectionUtils from '@/utils/ConnectionUtils.js';
import ConstUtils from '@/utils/ConstUtils.js';
import ContentUtils from '@/utils/ContentUtils.js';
import StringUtils from '@/utils/StringUtils.js';

import MC from "@/domain/session/MC.js";

import Box from "@/portals/shared/library/box/Box.vue";
import Column from "@/portals/shared/library/box/Column.vue";
import Row from "@/portals/shared/library/box/Row.vue";

import LabelValueBox from "@/portals/shared/library/labelvalue/LabelValue.vue";
import Button from "@/portals/shared/library/button/Button.vue";
import ValueBox from "@/portals/shared/library/value/Value.vue";

import ScheduleRow from './ScheduleRow';

export default {
    name: 'schedule-cell',
    components: {
        Box, Column, Row,
        LabelValueBox, Button, ValueBox,
    },

    props: {
        showMissingRequirements: { type: Boolean, default: false },
        isAdmin: { type: Boolean, default: true },
        data: { type: Object, default: null },
    },

    data() {
        return {
            MC: new MC(),
            Connections: ConnectionUtils,
            Consts: ConstUtils,
            Content: ContentUtils,
            Strings: StringUtils,

            row: new ScheduleRow(),
            schedule: null,
            company: null,

            ready: false,
            redraw: 1,
        };
    },

    computed: {
    ...mapGetters([
                    'auth_connected',
                    'auth_socket_status',
                    'signin_event',
                    'auth_client',
                    'domain',
                    'ScheduleStorage_lastEvent',
                    ]),
    },

    watch: {
        ScheduleStorage_lastEvent() {
            let schedule = this.domain.schedules().findById(this.schedule.id());
            if (this.schedule.modifiedDate() < schedule.modifiedDate()) {
                this.row.withChecklist(schedule);
                this.schedule = this.row.schedule(this.domain);
                this.company = this.schedule.company().find();
                this.redraw++;
            }
        }
    },

    mounted() {
        this.row.data = this.data;
        this.schedule = this.row.schedule(this.domain);
        this.company = this.schedule.company().find();
        this.ready = true;
    },

    methods: {
    ...mapActions([
                'AuthStorage_updateCompany',
                'addListenerAction',
                'removeListenerAction',
                'updateDomain',
                ]),

    withEnterPressed(keyData) {
      this.$emit("withEnterPressed", keyData);
    },

    withValue(valueData) {
      this.$emit('withValue', valueData);
    },

    withDetailsButtonPressed() {
      let data = {};
      data["field"] = "Details";
      data["id"] = this.schedule.id();
      this.withButtonPressed(data);
    },

    withButtonPressed: function (buttonData) {
      if (StringUtils.isEmpty(buttonData["id"])) {
        buttonData["id"] = this.row.id();
      }
      buttonData["key"] = this.row.key();
      this.$emit('withButtonPressed', buttonData);
    },
}
}

</script>