

import RcTableViewUtils from "../../../../../../shared/table/RcTableViewUtils";

import TextAreaUpdateRow from "@/portals/shared/cell/textarea/TextAreaUpdateRow.js";
import IntegerUpdateRow from "@/portals/shared/cell/integer/IntegerUpdateRow.js";

import ButtonDisplayRow from "@/portals/shared/cell/button/ButtonDisplayRow";
import BoxDisplayRow from "@/portals/shared/cell/box/BoxDisplayRow";
import RowDisplayRow from "@/portals/shared/cell/row/RowDisplayRow";
import StringDisplayRow from "@/portals/shared/cell//string/StringDisplayRow";
import StringUpdateRow from "@/portals/shared/cell/string/StringUpdateRow.js";
import StringListUpdateRow from "@/portals/shared/cell/string/list/StringListUpdateRow.js";
import TitleDisplayRow from "@/portals/shared/cell/title/TitleDisplayRow";
import SpacerDisplayRow from "@/portals/shared/cell/spacer/SpacerDisplayRow";
import DateUpdateRow from "@/portals/shared/cell/date/DateUpdateRow.js";
import VehicleTypeUpdateRow from "@/portals/shared/cell/vehicle/types/VehicleTypeUpdateRow.js";
import VehicleBrakeUpdateRow from "@/portals/shared/cell/vehicle/brakes/VehicleBrakeUpdateRow.js";
import VehicleUnitUpdateRow from "@/portals/shared/cell/vehicle/units/VehicleUnitUpdateRow.js";
import JurisdictionUpdateRow from "@/portals/shared/cell/vehicle/jurisdiction/JurisdictionUpdateRow.js";
import VehicleScheduleUpdateRow from "@/portals/shared/cell/vehicle/schedules/VehicleScheduleUpdateRow.js";
import DefectTypeDisplayRow from "@/portals/shared/cell/defecttype/DefectTypeDisplayRow.js";
import StateDisplayRow from "@/portals/shared/cell/state/StateDisplayRow.js";

import PictureUpdateRow from "@/portals/shared/cell/picture/PictureUpdateRow.js";

import MC from "@/domain/session/MC.js";
import Vehicle from "@/domain/model/vehicle/Vehicle.js";
import ChecklistUpdateRow from "@/portals/shared/cell/checklist/update/ChecklistUpdateRow.js";

export default class AddViewUtils extends RcTableViewUtils {

  static COMMERCIAL() {
    return [
      { '@rid': 'true', 'name': 'Yes' },
      { '@rid': 'false', 'name': 'No' },
    ];
  }

  constructor(model, tableData) {
    super(model, tableData);
    this.MC = new MC();
    this._vehicle = model.vehicle();
  }

  vehicle() {
    return this._vehicle;
  }

  start() {
    if (this.model().hasError()) {
      this.renderError();
    }

    if (this.model().isStatePageLoading()) {
      super.start("");
      return;
    }

    if (this.model().isStateContentSaving()) {
      this.renderSaving();
      return;
    }

    if (this.model().isStateContentSaved()) {
      this.renderSaved();
      return;
    }

    this.renderHeader();

    if (this.model().isStateContentLoading()) {
      super.start("Loading Vehicle Properties");
      return;
    }

    if (this.model().isStateContentLoaded()) {
      this.renderBody();
    }

    this.renderFooter();
  }

  renderSaving() {
    let box = new BoxDisplayRow()
      .withBorder(true)
      .withChild(new StringDisplayRow().withValue("Saving"));
    this.addBody(box);
  }

  renderSaved() {
    let box = new BoxDisplayRow()
      .withBorder(true)
      .withChild(new StringDisplayRow().withValue("Saved"));
    this.addBody(box);
  }

  renderBody() {
    var images = this.vehicle().images().find();
    var image = images.first();
    var summary = this.vehicle().summary();
    var kids = [];
    let schedule = this.vehicle().schedule();
    if (schedule.isNew()) {
      schedule = "";
    }
    let checklist = this.vehicle().checklists().find().first();
    if (checklist.isNew()) {
      checklist = "";
    }
    let category = this.vehicle().category();
    if (category.isNew()) {
      category = "";
    }
    let isCommercialRows = [
      new StringDisplayRow()
        .withAlignLeft()
        .withWidth(8)
        .withValue("Is this vehicle registered as a commercial vehicle?"),
      new StringListUpdateRow()
        .withName("")
        .withWidth(3)
        .withShowLabel(true)
        .withOptions(AddViewUtils.COMMERCIAL())
        .withDefaultValue(this.vehicle().isUsageTypeCommercial() + "")
        .withField("RequiresSchedule")
        .withId(this.vehicle().id())
    ];
    
    isCommercialRows = [];

    let newChecklistRow = [
      new ChecklistUpdateRow()
        .withName(this.MC.Vehicle.Add.Checklist.value())
        .withWidth(15)
        .withField(Vehicle.FIELDS.Checklists)
        .withDefaultValue(checklist)
        .withHint(this.MC.CheckList.DefinitionShort.value())
        .withShowClear(this.vehicle().isUsageTypeCommercial())
        .withMandatoryValue(!this.vehicle().isUsageTypeCommercial())
    ];

    newChecklistRow = [];

    kids.push(new RowDisplayRow()
      .withChildren([

        new BoxDisplayRow()
          .withBorder(true)
          .withWidth(10)
          .withChildren([
            new TitleDisplayRow()
              .withSubtitle(true)
              .withTitle(this.MC.Vehicle.Add.RequiredDetails.value()),

            new RowDisplayRow()
              .withChildren([
                new StringUpdateRow()
                  .withName(this.MC.Vehicle.Add.Name.value())
                  .withField(Vehicle.FIELDS.NAME)
                  .withWidth(10)
                  .withDefaultValue(this.vehicle().name())
                  .withMandatoryValue(true),

                new IntegerUpdateRow()
                  .withName(this.MC.Vehicle.Add.Year.value())
                  .withField(Vehicle.FIELDS.YEAR)
                  .withWidth(5)
                  .withDefaultValue(this.vehicle().year())
                  .withMandatoryValue(true),
              ]),

            new RowDisplayRow()
              .withChildren([
                new VehicleTypeUpdateRow()
                  .withName(this.MC.Vehicle.Add.VehicleType.value())
                  .withField(Vehicle.FIELDS._CATEGORY)
                  .withDefaultValue(category)
                  .withWidth(5)
                  .withMandatoryValue(true),

                new VehicleBrakeUpdateRow()
                  .withName(this.MC.Vehicle.Add.BrakeType.value())
                  .withField(Vehicle.FIELDS.BRAKE_TYPE)
                  .withDefaultValue(this.vehicle().brakeType())
                  .withWidth(5)
                  .withMandatoryValue(true),

                new VehicleUnitUpdateRow()
                  .withName(this.MC.Vehicle.Add.Units.value())
                  .withField(Vehicle.FIELDS.ODOMETER_TYPE)
                  .withWidth(5)
                  .withDefaultValue(this.vehicle().odometerType())
                  .withMandatoryValue(true),
              ]),
            new RowDisplayRow()
              .withChildren([
                new JurisdictionUpdateRow()
                  .withName(this.MC.Vehicle.Add.Jurisdiction.value())
                  .withField(Vehicle.FIELDS.JURISDICTION)
                  .withWidth(5)
                  .withDefaultValue(this.vehicle().jurisdiction())
                  .withMandatoryValue(true),

                new StringUpdateRow()
                  .withName(this.MC.Vehicle.Add.Plate.value())
                  .withField(Vehicle.FIELDS.PLATE)
                  .withWidth(5)
                  .withDefaultValue(this.vehicle().plate())
                  .withMandatoryValue(true),
                new DateUpdateRow()
                  .withName(this.MC.Vehicle.Add.PlateExpiryDateLabel.value())
                  .withHint(this.MC.Vehicle.Add.PlateExpiryDateHint.value())
                  .withWidth(5)
                  .withDefaultValue(this.vehicle().plateExpiryDate())
                  .withField(Vehicle.FIELDS.PLATE_EXPIRY_DATE),
              ]),
            new RowDisplayRow()
              .withChildren(isCommercialRows),
            new RowDisplayRow()
              .withChildren([
                new VehicleScheduleUpdateRow()
                  .withName(this.MC.Vehicle.Add.Schedule.value())
                  .withWidth(15)
                  .withField(Vehicle.FIELDS.SCHEDULE)
                  .withDefaultValue(schedule)
                  .withShowClear(!this.vehicle().isUsageTypeCommercial())
                  .withMandatoryValue(this.vehicle().isUsageTypeCommercial()),
                ...newChecklistRow,
              ]),
            new RowDisplayRow()
              .withChildren([
                new BoxDisplayRow()
                  .withWidth(15)
                  .withBorder(true)
                  .withChildren([
                    new TitleDisplayRow()
                      .withSubtitle(true)
                      .withTitle(this.MC.Vehicle.Add.NotesLabel.value()),
                    new StringDisplayRow()
                      .withAlignLeft()
                      .withValue("Add any additional information about this vehicle. This will not be displayed to a driver on the mobile app."),
                    new TextAreaUpdateRow()
                      .withName("Notes")
                      .withRows(4)
                      .withDefaultValue(this.vehicle().notes())
                      .withField(Vehicle.FIELDS.NOTES)
                  ]),
              ]),
          ]),

        new BoxDisplayRow()
          .withWidth(5)
          .withChildren([
            new RowDisplayRow()
              .withBorder(true)
              .withChildren([
                new TitleDisplayRow()
                  .withSubtitle(true)
                  .withWidth(15)
                  .withTitle(this.MC.Vehicle.Add.Picture.value()),
                new PictureUpdateRow()
                  .withWidth(15)
                  .withDefaultValue(image)
                  .withField(Vehicle.FIELDS.IMAGE_DATA),
              ]),
            new RowDisplayRow()
              .withBorder(true)
              .withChildren([
                new TitleDisplayRow()
                  .withTitle("State and Status")
                  .withWidth(15)
                  .withSubtitle(true),
                new DefectTypeDisplayRow()
                  .withValue(this.vehicle().defectType())
                  .withWidth(15)
                  .withLabel("Status"),
                new StateDisplayRow()
                  .withValue(this.vehicle().state())
                  .withWidth(15)
                  .withLabel("State"),
              ])
          ]),
      ]));

    var checklistRows = []
    if (this.allowsChecklists()) {
      checklistRows.push(
        new RowDisplayRow()
          .withBorder(true)
          .withChildren([
            new TitleDisplayRow()
              .withSubtitle(true)
              .withWidth(15)
              .withTitle(this.MC.Vehicle.Add.Checklist.value()),

            new StringDisplayRow()
              .withWidth(15)
              .withValue(this.MC.CheckList.DefinitionShort.value()),

            new ChecklistUpdateRow()
              .withName(this.MC.Vehicle.Add.Checklist.value())
              .withWidth(15)
              .withField(Vehicle.FIELDS.Checklists)
              .withDefaultValue(this.vehicle().checklists().find().first()),
          ])
      );
    }
    var startEndRow = new RowDisplayRow()
      .withBorder(true)
      .withChildren([
        new TitleDisplayRow()
          .withSubtitle(true)
          .withWidth(15)
          .withTitle(this.MC.Vehicle.Add.ServiceStartAndEndTitle.value()),
        new DateUpdateRow()
          .withName(this.MC.Vehicle.Add.StartDateLabel.value())
          .withHint(this.MC.Vehicle.Add.StartDateHint.value())
          .withDefaultValue(this.vehicle().startDate())
          .withWidth(15)
          .withField(Vehicle.FIELDS.START_DATE),
        new DateUpdateRow()
          .withName(this.MC.Vehicle.Add.EndDateLabel.value())
          .withHint(this.MC.Vehicle.Add.EndDateHint.value())
          .withDefaultValue(this.vehicle().endDate())
          .withWidth(15)
          .withField(Vehicle.FIELDS.END_DATE),
      ]);

    checklistRows.push(startEndRow);

    kids.push(new RowDisplayRow()
      .withChildren([
        new BoxDisplayRow()
          .withWidth(5)
          .withChildren(checklistRows),

        new BoxDisplayRow()
          .withWidth(5)
          .withChildren([
            new RowDisplayRow()
              .withBorder(true)
              .withChildren([
                new TitleDisplayRow()
                  .withSubtitle(true)
                  .withWidth(15)
                  .withTitle(this.MC.Vehicle.Add.SafetyTitle.value()),
                new DateUpdateRow()
                  .withName(this.MC.Vehicle.Add.Pmvi.value())
                  .withHint(this.MC.Vehicle.Add.PmviHint.value())
                  .withDefaultValue(this.vehicle().annualDate())
                  .withWidth(15)
                  .withField(Vehicle.FIELDS.ANNUAL_DATE),
                new StringUpdateRow()
                  .withName(this.MC.Vehicle.Add.PmviId.value())
                  .withDefaultValue(this.vehicle().annualNumber())
                  .withHint(this.MC.Vehicle.Add.PmviIdHint.value())
                  .withWidth(15)
                  .withField(Vehicle.FIELDS.ANNUAL_NUMBER),
              ]),
            new RowDisplayRow()
              .withBorder(true)
              .withChildren([
                new TitleDisplayRow()
                  .withSubtitle(true)
                  .withWidth(15)
                  .withTitle(this.MC.Vehicle.Add.VinWeightTitle.value()),

                new StringUpdateRow()
                  .withName(this.MC.Vehicle.Add.Vin.value())
                  .withDefaultValue(this.vehicle().vin())
                  .withWidth(15)
                  .withField(Vehicle.FIELDS.VIN),

                new IntegerUpdateRow()
                  .withName(this.vehicle().isTrailer() ? this.MC.Vehicle.Add.RegisteredGrossVehicleWeight.value() : this.MC.Vehicle.Add.Weight.value())
                  .withDefaultValue(this.vehicle().weight())
                  .withWidth(15)
                  .withField(Vehicle.FIELDS.WEIGHT),
              ]),
          ]),
        new BoxDisplayRow()
          .withWidth(5)
          .withBorder(true)
          .withChildren([
            new TitleDisplayRow()
              .withSubtitle(true)
              .withTitle(this.MC.Vehicle.Add.OdometerAndMoreTitle.value()),

            new StringUpdateRow()
              .withName(this.MC.Vehicle.Add.Odometer.value())
              .withWidth(3)
              .withDefaultValue(this.vehicle().odometer())
              .withField(Vehicle.FIELDS.ODOMETER),

            new StringUpdateRow()
              .withName(this.MC.Vehicle.Add.Summary.value())
              .withHint(this.MC.Vehicle.Add.SummaryHint.value())
              .withWidth(10)
              .withDefaultValue(summary)
              .withField(Vehicle.FIELDS.SUMMARY),

            new StringUpdateRow()
              .withName(this.MC.Vehicle.Add.Make.value())
              .withHint(this.MC.Vehicle.Add.MakeHint.value())
              .withDefaultValue(this.vehicle().make())
              .withField(Vehicle.FIELDS.MAKE),

            new StringUpdateRow()
              .withName(this.MC.Vehicle.Add.Model.value())
              .withHint(this.MC.Vehicle.Add.ModelHint.value())
              .withDefaultValue(this.vehicle().model())
              .withField(Vehicle.FIELDS.MODEL),

            new StringUpdateRow()
              .withName(this.MC.Vehicle.Add.Colour.value())
              .withDefaultValue(this.vehicle().colour())
              .withField(Vehicle.FIELDS.COLOUR),

          ]),
      ])
    );

    kids.push(new SpacerDisplayRow());

    this.addBody(new BoxDisplayRow()
      .withChildren(kids));

  }

  createSaveAnotherRow() {
    if (this.vehicle().isNew()) {
      return new ButtonDisplayRow()
        .withBackgroundPrimaryAction()
        .withName("Save and Add Another")
        .withField("SaveAdd")
        .withWidth(4);
    }
    return new SpacerDisplayRow().withWidth(4);
  }

  allowsChecklists() {
    var domain = this.model().domain();
    if (domain) {
      return domain.session().company().find().features().find().allowsCheckLists();
    }
    return false;
  }

  renderHeader() {
    this.addHeader(new TitleDisplayRow().withTitle("Add a Vehicle or Trailer"));
  }

  renderFooter() {

    this.addFooter(
      new RowDisplayRow()
        .withFixedPosition(true)
        .withChildren([
          new ButtonDisplayRow()
            .withWidth(3)
            .withName("Cancel")
            .withField("DetailsClose"),

          new SpacerDisplayRow()
            .withWidth(5),

          this.createSaveAnotherRow(),

          new ButtonDisplayRow()
            .withBackgroundPrimaryAction()
            .withName("Save")
            .withField("Save")
            .withWidth(3),

        ])
    );
  }
}