import Vue from 'vue'
import { uuid } from 'vue-uuid';

import EventUtils from '@/utils/EventUtils.js';
import StringUtils from '@/utils/StringUtils.js';

import UserEvents from '@/domain/model/user/UserEvents.js';

const state = {
  socket: {
    message: null,
    connection: null,
    isConnected: false,
    reconnectError: false,
    socket: null,
    count: 0,
    error: null,
  },
  // VUE_APP_RC_CDN defined in .env.development 
  client: {
    user: null,
    deviceId: uuid.v1(),
    version: process.env.VUE_APP_RC_VERSION,
    date: process.env.VUE_APP_RC_DATE,
    apiHost: process.env.VUE_APP_RC_API,
    cdnHost: process.env.VUE_APP_RC_CDN,
    debug: process.env.VUE_APP_RC_DEBUG == 'true',
    socket: null,
    connected: function() {
      if (this.socket) {
        return this.socket.isConnected;
      }
      return false;
    },
    isProduction() {
      if (StringUtils.contains(process.env.VUE_APP_RC_API, "localhost")) {
        return false;
      }
      return true;
    },
    cookie: null,
  },
  domain: null,
  userRoles: null,
  company: null,
};

const getters = {
    auth_client: state => state.client,
    auth_socket: state => state.socket,
    auth_connected: state => state.socket.isConnected,
    auth_socket_message: state => state.socket.message,
    auth_socket_status: state => state.socket.message ? state.socket.message.progressMessage : "",
    auth_user: state => state.client.user,
    user_roles: state => state.userRoles,
    AuthStore_socketError: state => state.socket.error,
    domain: state => state.domain,
    AuthStorage_Company: state => state.company,
    AuthStorage_Session: function() {
      var value = window.localStorage.readyChek;
      var cookieData = JSON.parse(value);
      if (cookieData) {
        //
      }
      return cookieData
    },
    authStorage_isConnected: function() {
      if (state.socket.isConnected) {
        return true;
      }
      var value = window.localStorage.readyChek;
      var cookieData = JSON.parse(value);
      if (cookieData) {
        //
        var time = cookieData['time'];
        if (time) {
          //
        }
      }
      return false;
    },
};

const actions = {
  updateUser({ commit }, user) {
    commit('setUser', user);
  },
  updateDomain({ commit }, domain) {
    commit('setDomain', domain);
  },
  authClear({ commit }) {
    commit('authClear');
  },
  AuthStorage_updateCompany({ commit }, company) {
    commit('setCompany', company);
  }
};

const mutations = {
  authClear(state) {
    state.client.user = null;
    state.socket.message = null;
    state.socket.isConnected = false;
    state.socket.connection = null;
    state.socket.message = null;
  },
  
  setUser(state, user) {
    state.client.user = user;
  },
  
  setDomain(state, domain) {
    state.domain = domain;
    state.userRoles = domain.session().employee().roles().find();
  },
  
  setCompany(state, company) {
    state.company = company;
  },
  
  SOCKET_ONOPEN (state, event)  {
    Vue.prototype.$socket = event.currentTarget;
    state.socket.connection = event.currentTarget;
    state.socket.isConnected = true;
    state.socket.message = event;
  },
  
  SOCKET_ONCLOSE (state, event)  {
    state.socket.isConnected = false;
    state.socket.message = event;
  },
 
  SOCKET_ONERROR (state, event)  {
    state.socket.message = event;
    state.socket.error = event;
  },
  
  SOCKET_ONMESSAGE (state, event)  {
    state.socket.message = event
    if (state.socket.message.status == EventUtils.STATUS.COMPLETE &&
        event.eventName == UserEvents.SignIn.Names.Response &&
        event.statusCode == EventUtils.CODE.OK) {
      state.client.user = state.socket.message.authUser;
      state.client.socket = state.socket;
    }
  },
  
  SOCKET_RECONNECT(state, count) {
    state.socket.count = count;
  },
  
  SOCKET_RECONNECT_ERROR(state) {
    state.socket.reconnectError = true;
  },
  
};

export default {
  state,
  getters,
  actions,
  mutations,
};