
import RcObject from "@/domain/common/RcObject.js";
import Company from "@/domain/model/company/Company.js";
import Employee from "@/domain/model/employee/Employee.js";
import EmployeeMap from "@/domain/model/employee/EmployeeMap.js";
import TimesheetMap from "@/domain/model/timesheet/TimesheetMap";

export default class TimesheetListFilter extends RcObject {
  
  static Fields = {
      CREATED_DATE_RANGE: 'createDateRange',
      PAGINATION: 'pagination',
      EMPLOYEE: Employee.MODEL_NAME,
      EMPLOYEES: EmployeeMap.MODEL_NAME,
      COMPANY: Company.MODEL_NAME,
      STATUS_TYPE: 'statusType',
      IDS: TimesheetMap.MODEL_NAME,
  }
  
  constructor() {
    super({});
  }
  
  withCompany(companyId) {
    this.put(TimesheetListFilter.Fields.COMPANY, companyId);
    return this;
  }
  
  withDateRange(range) {
    this.put(TimesheetListFilter.Fields.CREATED_DATE_RANGE, range);
    return this;
  }
  
  withStatusType(values) {
    this.put(TimesheetListFilter.Fields.STATUS_TYPE, values);
    return this;
  }
  
  withPagination(size, number) {
    this.put(TimesheetListFilter.Fields.PAGINATION, { size, number });
    return this;
  }
  
  withEmployee(employee) {
    if (!employee) {
      return this;
    }
    this.put(TimesheetListFilter.Fields.EMPLOYEE, employee);
    return this;
  }
  
  with(map) {
    this.put(map.modelName(), map.data);
    return this;
  }
  
  withEmployees(employees) {
    if (!employees) {
      return this;
    }
    this.put(TimesheetListFilter.Fields.EMPLOYEES, employees);
    return this;
  }
  
  withIds(timesheetMap) {
    this.put(TimesheetListFilter.Fields.IDS, timesheetMap);
    return this;
  }
  done() {
    //
  }
}