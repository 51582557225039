
import RcDomainMap from "../../common/RcDomainMap.js";
import Payment from './Payment.js';
import InvoiceUtils from "./InvoiceUtils.js";

export default class PaymentMap extends RcDomainMap {
  static MODEL_NAME = Payment.MODEL_NAME + "Map";

  constructor(domain, data) {
    super(domain, data, PaymentMap.MODEL_NAME);
  }

  copy() {
    var data = this._copy();
    return new PaymentMap(this.domain, data);
  }

  list() {
    var values = [];
    var keys = this.keys();
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var item = this.getPayment(key);
      values.push(item);
    }
    return values;
  }

  find() {
    var keys = this.keys();
    var found = new PaymentMap(this.domain, {});
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var data = this.domain.invoices().payments().findById(key);
      var item = new Payment(this.domain, data);
      if (!item.isNew()) {
        found.add(item);
      }
    }
    return found;
  }

  getPayment(key) {
    var value = super.get(key);
    return new Payment(this.domain, value);
  }

  sort() {
    var list = this.list();
    return list.sort(InvoiceUtils.SortByCreatedDate);
  }

  first() {
    var all = this.list();
    if (all.length > 0) {
      return all[0];
    }
    return new Payment(this.domain);
  }
}