
import CanadianEnglish from "./CanadianEnglish.js";

export default class Catalog extends CanadianEnglish {
  
  static MESSAGES = {};
  
  static Employee = {
    FILTERS_LABEL: Catalog.entry("Employee", "Filters"),
    FILTER_DETAILS_LABEL: Catalog.entry("Employee", "Details"),
    FILTER_INSPECTIONS_LABEL: Catalog.entry("Employee", "Inspections"),
    FILTER_HOURS_LABEL: Catalog.entry("Employee", "Hours of Service"),
  }
  
  static Timesheet = {
    LOCATION_START_TITLE: Catalog.entry("Timesheet", "Start Location"),
    LOCATION_START_LABEL: Catalog.entry("Timesheet", "Starting At"),

    LOCATION_END_TITLE: Catalog.entry("Timesheet", "End Location"),
    LOCATION_END_LABEL: Catalog.entry("Timesheet", "Ending At"),
      
    LOADING_LABEL: Catalog.entry("Timesheet", "Loading..."),
    MISSING_LABEL: Catalog.entry("Timesheet", "Missing Hours"),
    INPROGRESS_LABEL: Catalog.entry("Timesheet", "In Progress"),
    COMPLETE_LABEL: Catalog.entry("Timesheet", "Complete"),
    
    COMPANY_TITLE_LABEL: Catalog.entry("Timesheet", "Company"),
    COMPANY_NAME_LABEL: Catalog.entry("Timesheet", "Name"),
    COMPANY_ADDRESS_LABEL: Catalog.entry("Timesheet", "Address"),
    
    DETAILS_TITLE_LABEL: Catalog.entry("Timesheet", "Details"),
    DETAILS_DRIVER_LABEL: Catalog.entry("Timesheet", "Driver"),
    DETAILS_DATE_LABEL: Catalog.entry("Timesheet", "Date"),
    DETAILS_DAY_START_LABEL: Catalog.entry("Timesheet", "Day Start"),
    DETAILS_CYCLE_LABEL: Catalog.entry("Timesheet", "Cycle"),
    
    INSPECTIONS_TITLE_LABEL: Catalog.entry("Timesheet", "Inspections"),
    
    TOTALS_TITLE_LABEL: Catalog.entry("Timesheet", "Totals"),
    TOTALS_ONDUTY_NOTDRIVING_LABEL: Catalog.entry("Timesheet", "On Duty Not Driving"),
    TOTALS_ONDUTY_DRIVING_LABEL: Catalog.entry("Timesheet", "On Duty Driving"),
    TOTALS_ONDUTY_LABEL: Catalog.entry("Timesheet", "On Duty Total"),
    TOTALS_OFFDUTY_LABEL: Catalog.entry("Timesheet", "Off Duty"),
    
    GRAPH_TITLE_LABEL: Catalog.entry("Timesheet", "Graph"),
    
    STATUS_TITLE_LABEL: Catalog.entry("Timesheet", "Daily Duty Status"),
    
    SIGNATURE_TITLE_LABEL: Catalog.entry("Timesheet", "Signature"),
    SIGNATURE_COMPLETED_LABEL: Catalog.entry("Timesheet", "Date"),
    
    NO_INSPECTIONS_LABEL: Catalog.entry("Timesheet", "No inspections"),
    BLANK_LABEL: Catalog.entry("Timesheet", " "),
  }
  
  static Inspection = {
    PRE_TRIP: Catalog.entry("Inspection", "Pre-Trip"),
    POST_TRIP: Catalog.entry("Inspection", "Post-Trip"),
    DECLARATION_MSG: Catalog.entry("Inspection", "I declare that this vehicle has been inspected in accordance with {0}"),
    WORK_ORDERS: Catalog.entry("Inspection", "Work Ordes"),
  }
  
  static Invoice = {
    PURCHASE_ORDER: Catalog.entry("Invoice", "PO #"),
  }
  
  static entry(category, string) {
    var length = Object.values(Catalog.MESSAGES).length;
    var key = category + "." + length;
    Catalog.MESSAGES[key] = string; 
    return key;
  }

  
  constructor() {
    super();
  }
  
  timesheet() {
    return Catalog.Timesheet;
  }
  
  static keys() {
    return CanadianEnglish.KEYS;
  }
  
  get(id, hint) {
    var value = Catalog.MESSAGES[id];
    if (!value) {
      value = super.get(id, hint);
      if (!value) {
        value = id;
        if (hint) {
          value = hint;
        }
      }
    }
    if (!value) {
      value = id;
    }
    
    return value;
  }
}