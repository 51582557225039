<template>

    <div 
      :class="labelStyling" 
      v-on:click="emitClick()"
      cmpt="StringLabel" >
      {{ label }}
    </div>

</template>

<script>

import { mapGetters } from 'vuex'
import StringUtils  from '@/utils/StringUtils.js';

import MC from "@/domain/session/MC.js";

export default {
  name: 'string-label',
  components: {
  },
  props: {
    styling: { type: String, default : null },
    label: { type: String, default: null },
    labelWidth: { type: Number, default: 0 },
  },
  data() {
    return {
      MC: new MC(),
    }
  },
  computed: {
    ...mapGetters([
                   'domain',
                 ]),
    labelStyling: function() {
      return this.labelWidth + " rc-text-label " + StringUtils.toString(this.styling);
    },
  },
  methods: {
    emitClick: function() {
      this.$emit('click');
    }
  }
}
</script>