<template>
  <Box>
  
  <ErrorRow :error="error" />
  
  <Row>
    
    <Column :width="10" :showBorder="true">

      <div class="rc-container">
        <Row>
          <div class="rc-box-col-2">
            <Label>{{ MC.get(Keys.FILTERS.STATUS) }}</Label>
          </div>
          
          <div class="rc-box-col-1">
            <Button v-on:click="setFilterState('all')" 
              :disabled="isFilterState('all')">
              {{ MC.Filters.All.value() }}
            </Button>
          </div>
          <div class="rc-box-col-2">
            <Button v-on:click="setFilterState('active')" 
              :disabled="isFilterState('active')">
            {{ MC.Filters.Active.value() }}
            </Button>
          </div>
          <div class="rc-box-col-2">
            <Button v-on:click="setFilterState('inactive')" 
              :disabled="isFilterState('inactive')">
            {{ MC.Filters.Inactive.value() }}
            </Button>
          </div>
          <Column :width="1" />
          <Column :width="2" class=""><Label>{{ MC.Filters.Paying.value() }}</Label></Column>
          <Column :width="1">
            <Button v-on:click="setFilterPaying('all')" 
              :disabled="isFilterPaying('all')">
              {{ MC.Filters.All.value() }}
            </Button>
          </Column>
          <Column :width="1">
            <Button v-on:click="setFilterPaying('paying')" 
              :disabled="isFilterPaying('paying')">
              Yes
            </Button>
          </Column>
          <Column :width="1">
            <Button v-on:click="setFilterPaying('notpaying')" 
              :disabled="isFilterPaying('notpaying')">
              No
            </Button>
          </Column>
        </Row>
        
        <Row>
          <Column :width="2" class=""><Label>Auto Email Invoice</Label></Column>
          <Column :width="1">
            <Button v-on:click="setFilterAutoEmailInvoice('all')" 
              :disabled="isFilterAutoEmailInvoice('all')">
              {{ MC.Filters.All.value() }}
            </Button>
          </Column>
          <Column :width="1">
            <Button v-on:click="setFilterAutoEmailInvoice('true')" 
              :disabled="isFilterAutoEmailInvoice('true')">
              Yes
            </Button>
          </Column>
          <Column :width="1">
            <Button v-on:click="setFilterAutoEmailInvoice('false')" 
              :disabled="isFilterAutoEmailInvoice('false')">
              No
            </Button>
          </Column>
          <Column :width="3" />
          <Column :width="2" class=""><Label>Auto Charge Invoice</Label></Column>
          <Column :width="1">
            <Button v-on:click="setFilterAutoChargeInvoice('all')" 
              :disabled="isFilterAutoChargeInvoice('all')">
              {{ MC.Filters.All.value() }}
            </Button>
          </Column>
          <Column :width="1">
            <Button v-on:click="setFilterAutoChargeInvoice('true')" 
              :disabled="isFilterAutoChargeInvoice('true')">
              Yes
            </Button>
          </Column>
          <Column :width="1">
            <Button v-on:click="setFilterAutoChargeInvoice('false')" 
              :disabled="isFilterAutoChargeInvoice('false')">
              No
            </Button>
          </Column>
          <Column :width="0" />
        </Row>
        
        
        
        <Row>
          <div class="rc-box-col-2"><Label>Promo Code</Label></div>
          <div class="rc-box-col-1">
            <Button 
              v-on:click="setPromoCodeFilter('all')" 
              :disabled="isPromoCodeFilter('all')">
            All
            </Button>
          </div>
          <div class="rc-box-col-2">
            <Button 
              v-on:click="setPromoCodeFilter('notEmpty')" 
              :disabled="isPromoCodeFilter('notEmpty')">
            Not Empty
            </Button>
          </div>
          <div class="rc-box-col-1" v-for="(code, codeIndex) in promoCodes" :key="codeIndex + 'pc'">
            <Button 
              v-on:click="setPromoCodeFilter(code)" 
              :disabled="isPromoCodeFilter(code)">
            {{ code }}
            </Button>
          </div>
        </Row>
          
        <Row>
         <Column :width="2">
          <Label>{{ MC.Filters.Feature.value() }}</Label>
         </Column>
         
         <Column :width="2">
            <Button v-on:click="setFilterFeature('inspections')" 
              :disabled="isFilterFeature('inspections')">
              {{ MC.Filters.Inspections.value() }}
            </Button>
          </Column>
          
          <Column :width="2">
            <Button v-on:click="setFilterFeature('hours')" 
              :disabled="isFilterFeature('hours')">
              {{ MC.Filters.Hours.value() }}
            </Button>
          </Column>
          
          <Column :width="2">
            <Button v-on:click="setFilterFeature('checklists')" 
              :disabled="isFilterFeature('checklists')">
              {{ MC.Filters.Checklists.value() }}
            </Button>
          </Column>
          
           <Column :width="2">
            <Button v-on:click="setFilterFeature('sso')" 
              :disabled="isFilterFeature('sso')">
              {{ MC.Filters.Sso.value() }}
            </Button>
          </Column>
          
          <Column :width="7"> </Column>
          
        </Row>
        
        <Row>
          <div class="rc-box-col-2">
          <Label>{{ MC.Filters.Search.value() }}</Label></div>
          <div class="rc-box-col-5">
            <Box>
              <KeyValue 
                title="search"
                :clearValue="clear"
                v-on:enter="startSearch"
                v-on:value="searchValue"
                />
            </Box>
          </div>
          <div class="rc-box-col-1">
            <Button v-on:click="startSearch" 
              :disabled="isLoading">
            {{ MC.Filters.Go.value() }}
            </Button>
          </div>
  
          <div class="rc-box-col-2">
            <Button v-on:click="clearSearch" 
              :disabled="isLoading" >
            {{ MC.Filters.Clear.value() }}
            </Button>
          </div>
          <div class="rc-box-col-5" />
        </Row>
      </div>
      
    </Column>
    
    <Column :width="5" :showBorder="true">
      <Box>
        <Row>
          <div class="rc-box-col-10">
          </div>
          <div class="rc-box-col-5">
            <Button 
              v-on:click="refresh" 
              :disabled="isLoading">
              {{ MC.Actions.Refresh.value() }}
            </Button>
          </div>
        </Row>
        <Row><Column :width="15">&nbsp;</Column></Row>
        <Row><Column :width="15">&nbsp;</Column></Row>
        <Row><Column :width="15">&nbsp;</Column></Row>
        <Row><Column :width="15">&nbsp;</Column></Row>
        <Row><Column :width="15">&nbsp;</Column></Row>
        <Row>
         <Column :width="15" class=" rc-text-centre rc-text-label">
            Showing {{ min }} to {{ max }}  of {{ total }}
          </Column>
        </Row>
      </Box>
    </Column>
    
  </Row>
  
  <TitleRow>
    <div class="rc-box-col-2">
      ID
    </div>
    <div class="rc-box-col-4">
      Name
    </div>
    <div class="rc-box-col-2">
      Phone
    </div>
    <div class="rc-box-col-3">
      Dates
    </div>
    <div class="rc-box-col-1">
      Credit
    </div>
    <div class="rc-box-col-1">
      Discount
    </div>
    <div class="rc-box-col-2">
      Promo
    </div>
  </TitleRow>
  
  <Row :showBorder="true">
    <Column>
      <Box>
        <div v-for="(company, index) in companys" 
             v-bind:key="company['@rid']" 
             :class="isEven(index) ? 'rowEven rc-row' : 'rowOdd rc-row'" 
             style="padding: 8px">
          
          <div class="rc-box-col-15">
            <Box>
              <div v-if="isInContractPeriodWithDiscount(company)" 
               class="rc-row rc-font-bold rc-text-centre">
                <Column :width="15">
                  !! Discounted in contract period {{ company['name'] }} !!
                </Column>
              </div>
            
              <CompanyRow :companyData="company"/>
              <Row>
                <div class="rc-box-col-1">
                  <Button 
                    v-on:click="companyDetails(company)">
                    {{ MC.Actions.Details.value() }}
                  </Button>
                </div>
                <div class="rc-box-col-14" />
              </Row>
            </Box>
          </div>
          
        </div>
      </Box>
    </Column>
  </Row>

  <Row :showBorder="true" v-if="!isLoading" >
    <div class="rc-box-col-4" />
    <div class="rc-box-col-1">
      <Button 
        v-on:click="previousPage">
        &lt;
      </Button>
      
    </div>
    
    <div class="rc-box-col-5 rc-text-centre rc-text-label">
      Showing {{ min }} to {{ max }}  of {{ total }}
    </div>
  
    <div class="rc-box-col-1">
      <Button 
      v-on:click="nextPage">
      &gt;
      </Button>
    </div>
    <div class="rc-box-col-4" />
  </Row>

  <LoadingRow :showBorder="true" v-if="isLoading">{{ loadingMessage }} </LoadingRow>
  <SpacerRow />

  </Box>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ConnectionUtils from '@/utils/ConnectionUtils.js';
import MC from "@/domain/session/MC.js";
import ConstUtils  from '@/utils/ConstUtils.js';
import StringUtils from '@/utils/StringUtils.js';

import ErrorRow  from '@/components/row/ErrorRow.vue';
import KeyValue from '@/components/input/KeyValue.vue';

import CompanyRow from "../details/CompanyRow.vue";
import CompanyUtils from "./CompanyUtils.js";

import CompanyListFilter from "@/domain/model/company/CompanyListFilter.js";
import CompanyMap from "@/domain/model/company/CompanyMap.js";
import Company from "@/domain/model/company/Company.js";

import Box from "@/portals/shared/library/box/Box.vue";
import Row from "@/portals/shared/library/box/Row.vue";
import Column from "@/portals/shared/library/box/Column.vue";
import TitleRow from "@/portals/shared/library/title/TitleRow.vue";

import Label from "@/portals/shared/library/label/Label.vue";

//import Spacer from "@/portals/shared/library/spacer/Spacer.vue";
import SpacerRow from "@/portals/shared/library/spacer/SpacerRow.vue";
import LoadingRow from "@/portals/shared/library/loading/LoadingRow.vue";

import Button from "@/portals/shared/library/button/Button.vue";

export default {
  name: 'portals-admin-companys',
  components: {
    SpacerRow,
    ErrorRow,
    CompanyRow,
    KeyValue,
    Button, Label,
    Box, Row, Column,
    TitleRow, LoadingRow,
  },
  data() {
    return {
      error: null,
      list: [],
      isLoading: false,
      loadingMessage: null,
      MC: new MC(),
      
      total: 0,
      pageSize: 100,
      pageNumber: 0,
      stateFilter: 'active',
      payFilter: 'all',
      emailInvoiceFilter: 'all',
      chargeInvoiceFilter: 'all',
      featureFilter: 'all',
      promoCodeFilter: 'all',
      searchFor: null,
      clear: false,
      showSessionTimeoutDialog: false,
      promoCodes: ['TRI30', 'DV30', 'SD30', 'SRS30', 'ECC30', 'KCI30', 'WTS30'],
      };
  },
  computed: {
    ...mapGetters([
                   'auth_connected',
                   'auth_socket_status',
                   'signin_event',
                   'auth_client',
                   'domain',
               ]),
    companys: function() {
      return this.list;
    },
    max: function() {
      var t = (this.pageNumber + 1) * this.pageSize;
      return this.total < t ? this.total : t;
    },
    min: function() {
      if (this.total == 0) {
        return 0;
      }
      return (this.pageNumber * this.pageSize) + 1;
    },
  },
  watch: {
    auth_connected() {
      ConnectionUtils.sendSignInEvent(this);
    },
    auth_socket_status() {
      ConnectionUtils.displayStatus(this);
    },
    signin_event() {
      ConnectionUtils.createDomainSession(this);
    }
  },
  mounted: function () {
    ConnectionUtils.ensureConnection(this);
  },
  methods: {
    ...mapActions([
                   'AuthStorage_updateCompany',
                   'addListenerAction',
                   'removeListenerAction',
                   'updateDomain',
                   ]),
    start: function() {
      this.isLoading = false;
      this.loadingMessage = this.MC.Status.Loading.value();
      ConnectionUtils.withAdminCompany(this);
      this.loadByFilter();
    },
    callback: function(msg) {
      this.loadingMessage = msg;
    },
    isEven: function (position) {
      return StringUtils.isEven(position);
    },
    add: function() {
      this.$router.push({ name: ConstUtils.ROUTES.COMPANY_NEW });
    },
    refresh: function() {
      this.loadByFilter();
    },
    loadByFilter: function() {
      if (!this.domain || !this.domain.session()) {
        return;
      }
      this.isLoading = true;
      this.list = [];
      var filter = new CompanyListFilter();
      filter
        .withPagination(this.pageSize, this.pageNumber)
        .done();
      if (!StringUtils.isEmpty(this.searchFor)) {
        filter
          .withSearchText(this.searchFor)
          .done()
      }
      if (!StringUtils.isEmpty(this.stateFilter)) {
        filter
          .withState(this.stateFilter)
          .done();
      }
      if (!StringUtils.isEmpty(this.payFilter)) {
        filter
          .withPaying(this.payFilter)
          .done();
      }
      if (!StringUtils.isEmpty(this.emailInvoiceFilter)) {
        filter
          .withEmailInvoice(this.emailInvoiceFilter)
          .done();
      }
      if (!StringUtils.isEmpty(this.chargeInvoiceFilter)) {
        filter
          .withChargeInvoice(this.chargeInvoiceFilter)
          .done();
      }
      if (!StringUtils.isEmpty(this.featureFilter)) {
        filter
          .withFeature(this.featureFilter)
          .done();
      }
      if (!StringUtils.isEmpty(this.promoCodeFilter)) {
        filter
         .withPromoCode(this.promoCodeFilter)
         .done();
      }
      var request = this.domain.events().company().list(filter);
      request.send(this.listener);
    },
    
    listener: function(event) {
      if (event.error()) {
        this.error = event.error();
      } else {
        var payload = event.payload();
        var map = payload.valuesFor(CompanyMap.MODEL_NAME);
        this.total = payload.count();
        this.list = Object.values(map.data).sort(CompanyUtils.SortByName);
      }
      this.isLoading = false;
    },

    isFilterState(state) {
      return this.stateFilter === state;
    },
    
    setFilterState(state) {
      this.stateFilter = state;
      this.pageNumber = 0;
      this.loadByFilter();
    },

    isFilterPaying(paying) {
      return this.payFilter === paying;
    },
    setFilterPaying(paying) {
      this.payFilter = paying;
      this.pageNumber = 0;
      this.loadByFilter();
    },

    isFilterAutoEmailInvoice(value) {
      return this.emailInvoiceFilter === value;
    },
    
    setFilterAutoEmailInvoice(value) {
      this.emailInvoiceFilter = value;
      this.pageNumber = 0;
      this.loadByFilter();
    },
    
    isFilterAutoChargeInvoice(value) {
      return this.chargeInvoiceFilter === value;
    },
    
    setFilterAutoChargeInvoice(value) {
      this.chargeInvoiceFilter = value;
      this.pageNumber = 0;
      this.loadByFilter();
    },
    
    setPromoCodeFilter: function(filter) {
      this.promoCodeFilter = filter;
      this.refresh();
    },
    
    isPromoCodeFilter: function(filter) {
      return this.promoCodeFilter === filter;
    },

    isFilterFeature(feature) {
      return this.featureFilter === feature;
    },
    setFilterFeature(feature) {
      this.featureFilter = feature;
      this.pageNumber = 0;
      this.loadByFilter();
    },

    previousPage: function() {
      if (this.pageNumber > 0) {
        this.pageNumber--;
        this.loadByFilter();
      }
    },
    nextPage: function() {
      if (this.total > (this.pageSize * this.pageNumber) + this.pageSize) {
        this.pageNumber++;
        this.loadByFilter();
      }
    },
    companyDetails: function (companyData) {
      var company = new Company(this.domain, companyData);
      var params = {
          id: company.id(), 
      };
      this.$router.push( {
            name: ConstUtils.ROUTES.COMPANY.ADMIN.DETAILS,
            params: params,
          });
    },
    
    searchValue: function(value) {
      this.clear = false;
      this.searchFor = value['value'];
    },
    
    startSearch: function() {
      this.loadByFilter();
    },
    
    clearSearch: function() {
      this.searchFor = null;
      this.clear = true;
      this.loadByFilter();
    },
    
    isInContractPeriodWithDiscount: function(companyData) {
      var company = new Company(this.domain, companyData);
      var startDate = company.contractStartDate();
      if (company.discount() == 1) {
        var now = this.domain.session().time().now();
        if (now.time() > startDate.time()) {
          return true;
        }
      }
      return false;
    }
    
  },
}

</script>