<template>
    <Box>
      <Row v-if="isAdmin">
        <Column :width="15">
          <div class="rc-text-centre">
          Admin View - Companies
          </div>
        </Column>
      </Row>

      <Row>
        <Column :width="15">
          <Box class="rc-border-thin" :key="redraw + 1">
            <Row v-for="(row, rowIndex) in headerData" :key="rowIndex" >
              <CellFactory 
                :rowData="row.data"
                :showMissingRequirements="showMissingRequirements"
                v-on:withEnterPressed="withEnterPressed"
                v-on:withValue="withValue"
                v-on:withButtonPressed="withButtonPressed"
                  />
            </Row>
          </Box>
        </Column>
      </Row>

      <Row >
        <Column :width="15" >
          <Box :key="redraw" class="full-height-scrollable-div rc-border-thin">
            <Row v-for="(row, rowIndex) in viewData" :key="rowIndex" >
              <CellFactory 
                :rowData="row.data"
                :showMissingRequirements="showMissingRequirements"
                v-on:withEnterPressed="withEnterPressed"
                v-on:withValue="withValue"
                v-on:withButtonPressed="withButtonPressed"
                  />
            </Row>
          </Box>
        </Column>
      </Row>
  
      <Row>
        <Column :width="15">
          <Box class="rc-border-thin" :key="redraw + 2">
            <Row v-for="(row, rowIndex) in footerData" :key="rowIndex" >
              <CellFactory 
                :rowData="row.data"
                :showMissingRequirements="showMissingRequirements"
                v-on:withEnterPressed="withEnterPressed"
                v-on:withValue="withValue"
                v-on:withButtonPressed="withButtonPressed"
                  />
            </Row>
          </Box>
        </Column>
      </Row>
    
    </Box>
</template>
<script>
  import { mapActions, mapGetters } from 'vuex';
    
  import ConnectionUtils from '@/utils/ConnectionUtils.js';
  import ConstUtils from '@/utils/ConstUtils.js';
  import ContentUtils from '@/utils/ContentUtils.js';
  import StringUtils from '@/utils/StringUtils.js';
    
  import MC from "@/domain/session/MC.js";

  import Box from "@/portals/shared/library/box/Box.vue";
  import Column from "@/portals/shared/library/box/Column.vue";
  import Row from "@/portals/shared/library/box/Row.vue";
  
  import CellFactory from "@/portals/shared/cell/CellFactory.vue";
  
  import Company from "@/domain/model/company/Company";

  import ListViewModel from './ListViewModel';
  import ListViewUtils from './ListViewUtils';

  export default {
    name: "portals-admin-views-company-list-list-view",
    components: {
      Column, Box, Row,
      CellFactory,
    },
    props: {
      isAdmin: {type: Boolean, default: true},
    },
    data() {
      return {
        redraw: 1,
        
        MC: new MC(),
        Strings: StringUtils,
        Contents: ContentUtils,
        Consts: ConstUtils,
        
        CellFactory: CellFactory,
        
        viewModel: null,
        headerData: [],
        viewData: [],
        footerData: [],
        showMissingRequirements: false,
        selectedId: "",
        showDialog: null,

        ListViewModel: ListViewModel,
        ListViewUtils: ListViewUtils,
      };
    },
    computed: {
      ...mapGetters([
                      'auth_connected',
                      'auth_socket_status',
                      'signin_event',
                      'auth_client',
                      'domain',
                      ]),
    },
    watch: {
      auth_connected() {
        ConnectionUtils.sendSignInEvent(this);
      },
      auth_socket_status() {
        ConnectionUtils.displayStatus(this);
      },
      signin_event() {
        ConnectionUtils.createDomainSession(this);
      },
      $route (to, from) {
        if (to && from) {
          if (to.query["action"] === "edit") {
            this.showDialog = "editDialog";
            this.selectedId = to.query["selectedId"];

          } else if (to.query["action"] === "invoices") {
            this.showDialog = "invoicesDialog";

          } else {
            let refresh = false;

            var toQuery = Object.assign({}, to.query);
            var fromQuery = Object.assign({}, from.query);
            
            delete toQuery.action;
            delete toQuery.selectedId;

            delete fromQuery.action;
            delete fromQuery.selectedId;

            if (JSON.stringify(toQuery, null, 0) !== JSON.stringify(fromQuery, null, 0)) {
              refresh = true;
            }

            if (StringUtils.isNotEmpty(this.showDialog)) {
              this.showDialog = ""; 
            } 

            if (refresh) {
              this.reloadPage();
            }
          }
        }
      }
    },

    mounted: function () {
      this.viewModel = new ListViewModel(this);
      this.viewModel.start();
      this.viewModel.withStatePageLoading();
      this.viewModel.clearAndBuildTableData();
      ConnectionUtils.ensureConnection(this);
    },
    methods: {
      ...mapActions([
                      'AuthStorage_updateCompany',
                      'addListenerAction',
                      'removeListenerAction',
                      'updateDomain',
                      ]),
  
      start: function() {
        let company = new Company(this.domain);
        company.withName("Administration Portal");
        this.AuthStorage_updateCompany(company);
        
        this.viewModel.withStateContentLoading();
        this.viewModel.clearAndBuildTableData();
        this.viewModel.loadItems();
      },
  
      paint: function() {
        this.redraw++;
      },

      displayProgress: function(msg) {
        this.callback(msg);
      },

      showDialogIfRequired: function() {
        var query = Object.assign({}, this.$route.query);
        if (query["action"]) {
          this.selectedId = query["id"];
          this.showDialog = query["action"];
        } 
      },

      callback: function(msg) {
        var row = this.viewModel.findRowProgress();
        if (row) {
          row.withMessage(msg);
        }
        this.paint();
      },
      
      model: function() {
        return this.viewModel;
      },

      reloadPage: function() {
        this.viewModel.withStateContentLoading();
        this.viewModel.clearAndBuildTableData();
        this.viewModel.loadItems();
      },

      urlQueryParams() {
        var query = Object.assign({}, this.$route.query);
        if (query == null) {
          query = {};
        }
        return query;
      },

      addQueryParams(valueData, key) {
        var query = this.urlQueryParams();
        this.addQueryParamsToRouter(query, valueData, key);
      },

      addQueryParamsToRouter(query, valueData, key) {
        query[key] = valueData["value"];
        if (key === "pageSize") {
          delete query.page
        }
        if (query[key] === "null" || query[key] === "undefined" || 
            query[key] == undefined || query[key] == null) {
          delete query[key];
        }
        this.$router.push({ query });
      },
      
      delayedClearAndBuildTable: function() {
        setTimeout(this.clearAndBuildData, 500);
      },

      clearAndBuildData: function() {
        this.model()
          .withStateContentLoaded()
          .clearAndBuildTableData()
          .done();
        this.showDialogIfRequired();
      },
      
      deleteQueryParams(query) {
        delete query.action;
        delete query.promo;
        delete query.paying;
        delete query.charge;
        delete query.email;
        delete query.feature;
        delete query.search;
        delete query.status;
        return query;
      },

      withEnterPressed(keyData) {
        if (keyData["title"]) {
          keyData["field"] = "Search"
          this.withButtonPressed(keyData);
        }
      },

      withValue: function(valueData) {
        this.model().withValue(valueData);
        if (valueData["field"] === "Status") {
          this.addQueryParams(valueData, "status");
        }

        if (valueData["field"] === "Paying") {
          this.addQueryParams(valueData, "paying");
        }

        if (valueData["field"] === "PromoCode") {
          this.addQueryParams(valueData, "promo");
        }

        if (valueData["field"] === "Feature") {
          this.addQueryParams(valueData, "feature");
        }

        if (valueData["field"] === "Charge") {
          this.addQueryParams(valueData, "charge");
        }

        if (valueData["field"] === "Email") {
          this.addQueryParams(valueData, "email");
        }

        if (valueData["field"] === "PageSize") {
          this.model().withPageSize(valueData, "company");
          this.addQueryParams(valueData, "pageSize");
        }
        if (valueData["field"] === "PageIndex") {
          this.addQueryParams(valueData, "page");
        }
        
        if (valueData["field"] === "Search") {
          this.model().withSearchText(valueData);
        }

        if (valueData["field"] === "Date") {
          this.addQueryParams(valueData, "time")
        }

        if (valueData["field"] === "QBCustomerId") {
          this.addQueryParams(valueData, "qbcustomerId")
        }

        if (valueData["field"] === "SortBy") {
          this.addQueryParams(valueData, "sortBy")
        }
      },
      
      withButtonPressed: function(buttonData) {
        this.model().withButtonPressed(buttonData);

        if (buttonData["field"] === "Refresh") {
          this.reloadPage();
        }


        if (buttonData["field"] === "Add") {
          this.$router.push({ path: "/portal/admin/new",  query: { isAdmin: true } })
        }

        if (buttonData["field"] === "Reset") {
          let query = Object.assign({}, this.$route.query);
          query = this.deleteQueryParams(query);
          this.viewModel.withSearchText("");
          this.addQueryParamsToRouter(query, buttonData, "x");
        }

        if (buttonData["field"] === "Close" || buttonData["field"] === "DetailsClose") {
          let query = Object.assign({}, this.$route.query);
          query = this.deleteDetails(query);
          this.$router.push({ query });
          if (buttonData["refresh"]) {
            this.reloadPage();
          }
        }

        if (buttonData["field"] === "Time") {
          this.addQueryParams(buttonData, "time");
        }

        if (buttonData["field"] === "Status") {
          this.addQueryParams(buttonData, "status");
        }
        
        if (buttonData["field"] === "Select") {
          // portal/operator/RC-IzIwOjE=/company/edit
          let company = this.domain.companys().findById(buttonData["id"]);
          let session = this.domain.session();
          session.with(company);
          session.companyName = company.name();
          this.AuthStorage_updateCompany(company);
          let listPath = StringUtils.format(ConstUtils.ROUTES.COMPANY.EDIT_PATH, [company.idEncoded()]);
          this.$router.push({
            path: listPath,
          });
        }

        if (buttonData["field"] === "NextPage") {
          const query = Object.assign({}, this.$route.query);
          if (query.page) {
            query.page = (Number(query.page) + 1) + "";
          } else {
            query.page = "2";
          }
          if (query.page <= this.model().pages().length) {
            this.$router.push({ query });
          }
        }

        if (buttonData["field"] === "PreviousPage") {
          const query = Object.assign({}, this.$route.query);
          if (query.page) {
            query.page = (Number(query.page) - 1) + "";
            if (query.page <= 0) {
              query.page = "1";
            }
            this.$router.push({ query });
          }
        }
        
        if (buttonData["field"] === "Search") {
          buttonData["value"] = this.model().searchText();
          const queryParams = this.urlQueryParams();
          delete queryParams["page"];
          this.addQueryParamsToRouter(queryParams, buttonData, "search");
        }

        if (buttonData["field"] === "Clear") {
          this.model().withSearchText("");
          buttonData["value"] = "";
          this.addQueryParams(buttonData, "search");
        }
      },
      
    },
  }
</script>