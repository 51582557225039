<template>
<Box>
  <Row v-if="isAdmin">
    <Column :width="15">
      <div class="rc-text-centre">
      Admin View - Blank
      </div>
    </Column>
  </Row>

  <Row>
    <Column :width="15">
      <Box :key="redraw">
        <Row v-for="(row, rowIndex) in viewData" :key="rowIndex" >
          <CellFactory 
            :rowData="row.data"
            :showMissingRequirements="showMissingRequirements"
            v-on:withValue="withValue"
            v-on:withButtonPressed="withButtonPressed"
              />
        </Row>
      </Box>
    </Column>
  </Row>

</Box>
</template>
<script>

import { mapActions, mapGetters } from 'vuex';
  
import ConnectionUtils from '@/utils/ConnectionUtils.js';
import ConstUtils from '@/utils/ConstUtils.js';
import ContentUtils from '@/utils/ContentUtils.js';
import StringUtils from '@/utils/StringUtils.js';
  
import MC from "@/domain/session/MC.js";

import Box from "@/portals/shared/library/box/Box.vue";
import Column from "@/portals/shared/library/box/Column.vue";
import Row from "@/portals/shared/library/box/Row.vue";
import Company from "@/domain/model/company/Company.js";
import CellFactory from "@/portals/shared/cell/CellFactory.vue";

export default {
    name: "portals-admin-views-blank-view",
    components: {
      Column, Box, Row,
      CellFactory,
    },
    props: {
      adminDisplay: { type: Boolean, default: true },
      isAdmin: {type: Boolean, default: true},
    },
    data() {
      return {
        redraw: 1,
        
        MC: new MC(),
        Strings: StringUtils,
        Contents: ContentUtils,
        Consts: ConstUtils,
        
        CellFactory: CellFactory,
        
        viewModel: null,
        viewData: [],
        showMissingRequirements: false,
        selectedId: "",
        showDialog: null,

      };
    },
    computed: {
      ...mapGetters([
                     'auth_connected',
                     'auth_socket_status',
                     'signin_event',
                     'auth_client',
                     'domain',
                     ]),
    },
    watch: {
      auth_connected() {
        ConnectionUtils.sendSignInEvent(this);
      },
      auth_socket_status() {
        ConnectionUtils.displayStatus(this);
      },
      signin_event() {
        ConnectionUtils.createDomainSession(this);
      },
      $route (to, from){
        if (to && from) {
          if (to.query["action"] === "inspectionDetails") {
            this.selectedId = to.query["inspectionId"];
            this.showDialog = "detailsDialog";

          } else {
            if (StringUtils.isNotEmpty(this.showDialog)) {
              this.showDialog = "";

            } else {
              this.reloadPage();
            }
          }
        }
      }
    },
    mounted: function () {
      ConnectionUtils.ensureConnection(this);
    },
    methods: {
      ...mapActions([
                     'AuthStorage_updateCompany',
                     'addListenerAction',
                     'removeListenerAction',
                     'updateDomain',
                     ]),
  
      start: function() {
        let company = new Company(this.domain).withName("Administrator Portal");
        let session = this.domain.session();
        session.with(company);
        session.companyName = "Administrator Portal"
        this.AuthStorage_updateCompany(company);
      },
  
      displayProgress: function(msg) {
        this.callback(msg);
      },

      showDetailsIfRequired: function() {
        var query = Object.assign({}, this.$route.query);
        if (query["action"] === "inspectionDetails") {
          this.selectedId = query["inspectionId"];
          this.showDialog = "detailsDialog";
        } 
      },

      callback: function(msg) {
        if (msg) {
          //
        }
        this.paint();
      },
      
      
      withValue: function(valueData) {
        if (valueData) {
          //
        }
      },
      
      addQueryParams(valueData, key) {
        var query = Object.assign({}, this.$route.query);
        if (query == null) {
          query = {};
        }
        const value = valueData["value"];
        if (value && value != "null") {
          query[key] = value;
          if (key === "pageSize") {
            delete query.page
          }
        } else {
          delete query[key];
        }
        this.$router.push({ query });
      },

      withButtonPressed: function(buttonData) {
        if (buttonData) {
          //
        }
        //this.model().withButtonPressed(buttonData);
      },
      
      paint: function() {
        this.redraw++;
      },

    },
  }
</script>