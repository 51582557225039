
import RcTableViewRow from "@/portals/shared/cell/common/RcTableViewRow.js";

export default class SpacerDisplayRow extends RcTableViewRow {

  static Id = "SpacerDisplayRow";
  static FontSize = "fontSize";

  constructor() {
    super();
    this.put(RcTableViewRow.TypeId, SpacerDisplayRow.Id);
    this.withFontSize("small")
  }

  fontSize() {
    return this.getString(SpacerDisplayRow.FontSize);
  }

  withFontSize(fontSize) {
    this.put(SpacerDisplayRow.FontSize, fontSize);
    return this;
  }

  withAlign(align) {
    this.put("align", align);
    return this;
  }
  
  align() {
    return this.getString("align");
  }
}