import RcTableViewUtils from "../../../../../../shared/table/RcTableViewUtils";

import LabelDisplayRow from "@/portals/shared/cell/label/LabelDisplayRow";
// import ValueDisplayRow from "@/portals/shared/cell/value/ValueDisplayRow";
import ButtonDisplayRow from "@/portals/shared/cell/button/ButtonDisplayRow";
import CheckboxUpdateRow from "@/portals/shared/cell/checkbox/CheckboxUpdateRow.js";
import DateUpdateRow from "@/portals/shared/cell/date/DateUpdateRow.js";
import EmailUpdateRow from "@/portals/shared/cell/email/EmailUpdateRow.js";
import PhoneNumberUpdateRow from "@/portals/shared/cell/phonenumber/PhoneNumberUpdateRow.js";
import RowDisplayRow from "@/portals/shared/cell/row/RowDisplayRow";
import SpacerDisplayRow from "@/portals/shared/cell/spacer/SpacerDisplayRow";
import StringUpdateRow from "@/portals/shared/cell/string/StringUpdateRow.js";
import StringDisplayRow from "@/portals/shared/cell/string/StringDisplayRow.js";
import StringListUpdateRow from "@/portals/shared/cell/string/list/StringListUpdateRow.js";
import TitleDisplayRow from "@/portals/shared/cell/title/TitleDisplayRow";
import JurisdictionUpdateRow from "@/portals/shared/cell/vehicle/jurisdiction/JurisdictionUpdateRow.js";
import StatusDisplayRow from "@/portals/shared/cell/status/StatusDisplayRow.js";

import MC from "@/domain/session/MC.js";
import BoxDisplayRow from "../../../../../../shared/cell/box/BoxDisplayRow";

import ContentUtils from '@/utils/ContentUtils.js';
import StringUtils from '@/utils/StringUtils.js';
import RcError from "../../../../../../../domain/exceptions/RcError";

export default class InviteViewUtils extends RcTableViewUtils {

  constructor(model, tableData) {
    super(model, tableData);
    this.MC = new MC();
    this._user = model.user();
    this._employee = model.employee();
    this.SU = StringUtils;
    this.CU = ContentUtils;
  }

  employee() {
    return this._employee;
  }

  user() {
    return this._user;
  }

  start() {
    super.start();
    if (this.model().isStatePageLoading()) {
      super.start("");
      return;
    }

    if (this.model().isStateContentSaving()) {
      this.renderSaving();
      return;
    }

    if (this.model().isStateContentSaved()) {
      this.renderSaved();
      return;
    }

    this.renderHeader();

    if (this.model().isStateContentLoading()) {
      super.start("Loading User Properties");
      return;
    }

    if (this.model().isStateContentLoaded()) {
      this.renderBody();
    }

    this.renderFooter();
  }

  renderBody() {
    var body = new BoxDisplayRow();
    body.withChild(new RowDisplayRow()
      .withChild(new BoxDisplayRow().withBorder(true).withWidth(8)
        .withChild(new TitleDisplayRow().withTitle("User Details").withSubtitle(true))
        .withChild(new StringUpdateRow().withName("First Name").withField("first").withKey("first").withDefaultValue(this.user().first()).withMandatoryValue(true))
        .withChild(new StringUpdateRow().withName("Last Name").withField("last").withKey("last").withDefaultValue(this.user().first()).withMandatoryValue(true))
        .withChild(new EmailUpdateRow().withName("Email").withField("email").withKey("email").withDefaultValue(this.user().email()).withMandatoryValue(true))
        .withChild(new EmailUpdateRow()
          .withName("Verify Email")
          .withField("email2")
          .withKey("email2")
          .withDefaultValue(this.user().email())
          .withMandatoryValue(true)
          .withHint("Entering the email address twice helps elimitate typos"))
        .withChild(new PhoneNumberUpdateRow().withName("Phone No.").withField("phone").withKey("phone").withDefaultValue(this.user().phone()).withMandatoryValue(false))
      )
      .withChild(new BoxDisplayRow().withBorder(true).withWidth(7)
        .withChild(new TitleDisplayRow().withTitle("Role and Employee ID").withSubtitle(true))
        .withChild(new LabelDisplayRow().withFontSize("xsmall").withLabel("Every user must have a role, a manager or and owner have the most permissions, they can perform inspections as well as use this portal"))
        .withChild(new StringListUpdateRow().withMandatoryValue(true)
          .withOptions(ContentUtils.ROLES())
          .withName("Role")
          .withDefaultValue(this.employee().roles().find().first().id())
          .withField("UserRoleMap")
          .withKey("UserRoleMap"))
        .withChild(new StringUpdateRow()
          .withMandatoryValue(false)
          .withDefaultValue(this.employee().employmentId())
          .withHint("Employee ID is what will be used to sign into the shared devices (tablets or phones)")
          .withName("Employee ID")
          .withField("employmentId")
          .withKey("employmentId"))
      )
    );
    body.withChild(new RowDisplayRow()
      .withChild(new BoxDisplayRow().withBorder(true).withWidth(8)
        .withChild(new TitleDisplayRow().withTitle("Address Information").withSubtitle(true))
        .withChild(new LabelDisplayRow().withFontSize("xsmall").withLabel("If the user takes the vehicle home at night, complete this section. This will provide the user with an easy to use button in the app to set their address when completing inspections"))
        .withChild(new StringUpdateRow().withName("Street").withField("street").withKey("street").withDefaultValue(this.user().street()).withMandatoryValue(false))
        .withChild(new StringUpdateRow().withName("City").withField("city").withKey("city").withDefaultValue(this.user().city()).withMandatoryValue(false))
        .withChild(new JurisdictionUpdateRow()
          .withName(this.MC.Vehicle.Add.Jurisdiction.value())
          .withDefaultValue(this.user().jurisdiction())
          .withField("jurisdiction")
          .withKey("jurisdiction")
          .withMandatoryValue(false))
        .withChild(new StringListUpdateRow()
          .withOptions(ContentUtils.COUNTRYS())
          .withName("Country")
          .withDefaultValue(this.user().country())
          .withField("country")
          .withKey("country"))
        .withChild(new StringUpdateRow().withName("Postal Code").withField("postal").withKey("postal").withDefaultValue(this.user().postal()).withMandatoryValue(false))
      )
      .withChild(new BoxDisplayRow().withWidth(7)
        .withChild(new BoxDisplayRow().withBorder(true)
          .withChild(new TitleDisplayRow().withTitle("Drivers License Information").withSubtitle(true))
          .withChild(new LabelDisplayRow().withFontSize("xsmall").withLabel("With this information the portal will show you a message when the license is about to expire"))
          .withChild(new StringListUpdateRow()
            .withOptions(ContentUtils.LICENSES())
            .withName("Class")
            .withDefaultValue(this.user().licenseClass())
            .withField("licenseClass")
            .withKey("licenseClass"))
          .withChild(new DateUpdateRow()
            .withMandatoryValue(false)
            .withDefaultValue(this.user().licenseExpiryAsString())
            .withName("Expiry Date")
            .withField("licenseExpiry")
            .withKey("licenseExpiry")))
        .withChild(new SpacerDisplayRow())
        .withChild(new BoxDisplayRow().withBorder(true)
          .withChild(new TitleDisplayRow().withTitle("Employment Start and End Information").withSubtitle(true))
          .withChild(new LabelDisplayRow().withFontSize("xsmall").withLabel("When did this person start and stop working for your company"))
          .withChild(new DateUpdateRow()
            .withMandatoryValue(false)
            .withDefaultValue(this.employee().startDate())
            .withName("Start Date")
            .withField("startDate")
            .withKey("startDate"))
          .withChild(new DateUpdateRow()
            .withMandatoryValue(false)
            .withDefaultValue(this.employee().endDate())
            .withName("End Date")
            .withField("endDate")
            .withKey("endDate"))
        )
      )
    );
    this.addBody(body);
  }

  createSaveAnotherRow() {
    if (this.employee().isNew()) {
      return new ButtonDisplayRow()
        .withBackgroundPrimaryAction()
        .withName("Save and Invite Another")
        .withField("SaveAdd")
        .withWidth(4);
    }
    return new SpacerDisplayRow().withWidth(4);
  }

  renderHeader() {
    this.addHeader(new TitleDisplayRow().withTitle("Invite User"));
  }

  renderFooter() {
    this.addFooter(new BoxDisplayRow().withBorder(true).withChild(
      new LabelDisplayRow().withFontSize("xsmall")
        .withLabel("When you invite a user, by default an email will be sent to them with instructions on joining ReadyChek. If you'd prefer to add everyone first and send them an invite email later uncheck the 'Send invite email now' checkbox below. Filter the users list by 'Waiting to be invite' to see who hasn't yet received the invite email."))
    );
    this.addFooter(
      new RowDisplayRow()
        .withFixedPosition(true)
        .withChildren([
          new ButtonDisplayRow().withWidth(2)
            .withName("Cancel")
            .withField("Close"),

          new SpacerDisplayRow().withWidth(1),
          new CheckboxUpdateRow().withWidth(4)
            .withDefaultValue(this.model()._sendInviteEmail)
            .withShowLabel(false).withName("Send invite email now").withField("sendInviteEmail").withKey("sendInviteEmail"),
            
          new ButtonDisplayRow().withWidth(3)
            .withBackgroundPrimaryAction()
            .withName("Save")
            .withField("Save"),
            new SpacerDisplayRow().withWidth(1),
          this.createSaveAnotherRow(), // 4
        ])
    );
  }

  renderError() {
    let error = this.model().error();
    if (error == null) {
      return;
    }
    let rcError = new RcError(this.model().domain(), error);
    let messageId = rcError.messageId();
    if (messageId != "UserEmailAlreadyExistsError") {
      super.renderError();
    } else {
      var box = new BoxDisplayRow().withBorder(true)
      box
        .withChild(new StatusDisplayRow()
                        .withLabelWidth(0)
                        .withValueWidth(15)
                        .withValue("Major")
                        .withObject("There is a user with this email address already in your company."))
        .withChild(new StringDisplayRow().withValue("If this user has forgotten their password or lost the Welcome Email you can send it to them again, would you like to see the user?"))
        .withChild(new RowDisplayRow()
          .withChild(new SpacerDisplayRow().withWidth(13))
          .withChild(new ButtonDisplayRow().withWidth(2).withName("Yes").withField("ShowMe").withId(this.user().email()))
        );

      this.add(box);
    }
  }
}