<template>
  <Box>
    <Row :key="redraw">
      <Column :width="15">
        <ListView 
          v-if="isReady" 
          :adminDisplay="true" 
          :isAdmin="true"
          :showMissingRequirements="showMissingRequirements"
          v-on:withValue="withFieldValue"
          v-on:withButtonPressed="withButtonPressed">
        </ListView>
      </Column>
    </Row>
  </Box>
</template>
<script>
import ListView from '../../../../customer/operator/views/inspection/list/ListView.vue';
import { mapActions, mapGetters } from 'vuex';
  
import ConnectionUtils from '@/utils/ConnectionUtils.js';
import ConstUtils from '@/utils/ConstUtils.js';
import ContentUtils from '@/utils/ContentUtils.js';
import StringUtils from '@/utils/StringUtils.js';
  
import MC from "@/domain/session/MC.js";

import Box from "@/portals/shared/library/box/Box.vue";
import Column from "@/portals/shared/library/box/Column.vue";
import Row from "@/portals/shared/library/box/Row.vue";
  
import CellFactory from "@/portals/shared/cell/CellFactory.vue";

import Company from "@/domain/model/company/Company";

export default {
  name: "portals-admin-views-inspection-list-view",
  components: {
    ListView,
    // CellFactory,
    Box, Row, Column,
  },
  data() {
    return {
      redraw: 1,
      isAdmin: false,
      isReady: false,
      
      MC: new MC(),
      Strings: StringUtils,
      Contents: ContentUtils,
      Consts: ConstUtils,
      
      CF: CellFactory,
      Box: Box,
      Row: Row,
      Column: Column,

      showMissingRequirements: false,
      showDialog: null,
      selectedId: null,
    };
  },
  computed: {
    ...mapGetters([
                    'auth_connected',
                    'auth_socket_status',
                    'signin_event',
                    'auth_client',
                    'domain',
                    ]),
  },
  watch: {
    auth_connected() {
      ConnectionUtils.sendSignInEvent(this);
    },
    auth_socket_status() {
      ConnectionUtils.displayStatus(this);
    },
    signin_event() {
      ConnectionUtils.createDomainSession(this);
    },
  },
  mounted: function () {
    ConnectionUtils.ensureConnection(this);
  },
  methods: {
    ...mapActions([
                    'AuthStorage_updateCompany',
                    'addListenerAction',
                    'removeListenerAction',
                    'updateDomain',
                    ]),

    start: function() {
      let company = new Company(this.domain).withName("Administrator Portal");
      let session = this.domain.session();
      session.with(company);
      session.companyName = "Administrator Portal"
      this.AuthStorage_updateCompany(company);
      this.isReady = true;
    },

    displayProgress: function(msg) {
      this.callback(msg);
    },

    callback: function(msg) {
      if (msg) {
        //
      }
      this.paint();
    },

    paint: function() {
      this.redraw++;
    },

    log() {
      return this.domain.logger();
    },

    reloadPage: function() {
      this.paint()
    },

    deleteQuery(query) {
      delete query.action;
      delete query.selectedId;
      return query;
    },

    withFieldValue(valueData) {
      if (valueData) {
        //
      }
      this.log().info("Admin Value {0}", [JSON.stringify(valueData, null, 2)]);
    },

    withButtonPressed(valueData) {
      this.log().info("Admin Button: {0}", [JSON.stringify(valueData, null, 2)]);

      if (valueData["field"] === "Rebuild") {
        const query = Object.assign({}, this.$route.query);
        query["action"] = "updatePdf";
        query["inspectionId"] = this.Contents.baseEncode(valueData["id"]);
        this.$router.push({ query });

      } else if (valueData["field"] === "Delete") {
        const query = Object.assign({}, this.$route.query);
        query["action"] = "deleteInspection";
        query["inspectionId"] = this.Contents.baseEncode(valueData["id"]);
        this.$router.push({ query });

      } else if (valueData["field"] === "Select") {
        let company = this.domain.companys().findById(valueData["id"]);
        let session = this.domain.session();
        session.with(company);
        session.companyName = company.name();
        this.AuthStorage_updateCompany(company);
        let listPath = StringUtils.format(ConstUtils.ROUTES.COMPANY.EDIT_PATH, [company.idEncoded()]);
        this.$router.push({
          path: listPath,
        });
      }
    }
  }
}
</script>