<template>
  <div v-if="!auth_user" class="">
    <div class="container">
      <SpacerRow />
      <div class="row">
        <div class="rc-box-col-15 rc-text-centre">
          <a href="https://twitter.com/readychek" target="_blank">
            <i class="fa fa-twitter w3-hover-opacity" ></i></a>
          <a href="https://facebook.com/readychek" target="_blank">
            <i class="fa fa-facebook w3-hover-opacity"></i></a>
          <a href="https://www.linkedin.com/company/readychek">
            <i class="fa fa-linkedin w3-hover-opacity"></i></a>
          <a href="https://www.instagram.com/readychek" target="_blank">
            <i class="fa fa-instagram w3-hover-opacity"></i></a>
          <a href="https://www.readychek.com" target="_blank">
            <i class="fa fa-home w3-hover-opacity"></i></a>
        </div>
      </div>
      
      <div class="row">
        <div class="rc-box-col-15 rc-text-label rc-text-small rc-text-centre">
          Copyright &#169; ReadyChek Inc. 2019-2024
        </div>
      </div>
      
      <div class="row ">
        <div class="rc-box-col-15 rc-text-centre rc-text-label rc-text-small">
          {{ auth_client ? auth_client.version : "" }} 
          {{ auth_client ? auth_client.date : "" }}
        </div>
      </div>
      
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SpacerRow from "@/portals/shared/library/spacer/SpacerRow.vue";

export default {
  name: 'app-footer',
  components: {
    SpacerRow,
  },
  computed: {
    ...mapGetters([
        'auth_user', 
        'auth_client',
        'AuthStorage_Company',
      ]),
  },
  watch: {
  },
  methods: {
  },
}

</script>