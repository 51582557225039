import RcTableViewUtils from "../../../../../shared/table/RcTableViewUtils";
import BoxDisplayRow from "@/portals/shared/cell/box/BoxDisplayRow";
import RowDisplayRow from "@/portals/shared/cell/row/RowDisplayRow";
import StringDisplayRow from "@/portals/shared/cell//string/StringDisplayRow";
// import StringUpdateRow from "@/portals/shared/cell/string/StringUpdateRow.js";
import StringListUpdateRow from "@/portals/shared/cell/string/list/StringListUpdateRow.js";
import LabelValueDisplayRow from "@/portals/shared/cell//label/LabelValueDisplayRow";
import LabelDisplayRow from "@/portals/shared/cell//label/LabelDisplayRow";
import TitleDisplayRow from "@/portals/shared/cell/title/TitleDisplayRow";
import ButtonDisplayRow from "@/portals/shared/cell/button/ButtonDisplayRow";
import SpacerDisplayRow from "@/portals/shared/cell/spacer/SpacerDisplayRow";
// import StringUtils from '@/utils/StringUtils.js';
import DateUpdateRow from "@/portals/shared/cell/date/DateUpdateRow.js";

import MC from "@/domain/session/MC.js";
import ContentUtils from '@/utils/ContentUtils.js';
import StringUtils from '@/utils/StringUtils.js';

import TimesheetRow from "@/portals/shared/cell/timesheet/TimesheetRow.js";

export default class ListViewUtils extends RcTableViewUtils {

  constructor(model, tableData) {
    super(model, tableData);
    this.MC = new MC();
    this.pages = [];
    this.Strings = new StringUtils();
  }

  start() {
    if (this.model().hasError()) {
      this.renderError();  
    }

    if (this.model().isStatePageLoading()) {
      super.start();
      return;
    }

    this.renderHeader(this.model().totalItems());

    if (this.model().isStateContentLoading()) {
      super.start();
      return;
    }

    if (this.model().isStateContentLoaded()) {
      this.renderBody();
    }

    this.renderFooter(this.model().totalItems());
  }

  renderBody() {
    let row = null;
    let even = true;
    let item = null;
    let list = this.model().timesheets();
    for (var index = 0; index < list.length; index++) {
      item = list[index];
      row = new TimesheetRow().withTimesheet(item);
      row
        .withIsAdmin(this.model().isAdmin())
        .withEven(even);
      this.addBody(row);
      even = !even;
    }
  }

  renderFooter(totalItems) {
    let query = this.model().panel().$route.query;
    this.pages = this.model().pages();
    this.addFooter(
        new BoxDisplayRow()
        .withWidth("15")
        .withChild(new RowDisplayRow()
          .withChild(new BoxDisplayRow()
            .withWidth("15")
            .withChild(new RowDisplayRow()
              .withChild(new LabelValueDisplayRow().withWidth("4").withLabel("Showing").withValue(this.createPageInfo(totalItems, query, "timesheet")))
              .withChild(new StringDisplayRow().withWidth("7"))
              .withChild(new ButtonDisplayRow().withWidth("1").withName("<").withField("PreviousPage"))
              .withChild(new StringListUpdateRow().withWidth("2")
                                                  .withShowLabel(false)
                                                  .withName("Jump to Page")
                                                  .withField("PageIndex")
                                                  .withDefaultValue(query["page"] ? query["page"] : "1")
                                                  .withOptions(this.pages))
              .withChild(new ButtonDisplayRow().withWidth("1").withName(">").withField("NextPage"))
            )
          )
        )
    );
  }

  renderHeader(totalItems) {
    let query = this.model().panel().$route.query;
    let user = this.model().user();
    let userId = user.id();
    if (user.isNew()) {
      userId = "";
    }
    
    let filterRow = new StringListUpdateRow().withWidth(6).withName("User").withKey("pickUser").withField("User").withDefaultValue(userId).withOptions(this.userList()).withShowClear(true);
    if (this.model().isAdmin()) {
      let user = this.domain().users().findById(userId);
      filterRow = new LabelValueDisplayRow().withLabelWidth(3).withWidth(6).withLabel("User").withField("User").withValue(this.valueOrNotSet(user.fullName() + " " + user.email()));
    }

    this.addHeader(
      new RowDisplayRow()
      .withChild(
        new BoxDisplayRow()
        .withWidth("15")
        .withChild(new RowDisplayRow()
          .withChild(new LabelDisplayRow().withWidth(3).withLabel("Hours of Service"))
          .withChild(new SpacerDisplayRow().withWidth(11))
          .withChild(new ButtonDisplayRow().withWidth(1).withName("Refresh").withField("Refresh"))
        )
        .withChild(new RowDisplayRow()
          .withChild(
            new BoxDisplayRow()
            .withWidth("15")
            .withBorder(true)
            .withChild(new LabelDisplayRow().withLabel("Filters"))
            .withChild(new RowDisplayRow()
              .withChild(new LabelDisplayRow().withWidth("1").withLabel("Status"))
              .withChild(new ButtonDisplayRow().withWidth("1").withName("All").withKey("allStatus").withField("Status").withDefaultValue("all").withSelected(query["status"] === "all" || !query["status"]))
              .withChild(new ButtonDisplayRow().withWidth("1").withName("Complete").withKey("goodStatus").withField("Status").withDefaultValue("complete").withSelected(query["status"] === "complete"))
              .withChild(new ButtonDisplayRow().withWidth("2").withName("In Progress").withKey("minorStatus").withField("Status").withDefaultValue("inprogress").withSelected(query["status"] === "inprogress"))
              .withChild(new ButtonDisplayRow().withWidth("2").withName("Incomplete").withKey("majorStatus").withField("Status").withDefaultValue("incomplete").withSelected(query["status"] === "incomplete"))
              .withChild(new SpacerDisplayRow().withWidth("5"))
            )
            .withChild(new RowDisplayRow()
              .withChild(new LabelDisplayRow().withShowLabel(true).withWidth("1").withLabel("Time"))
              .withChild(new ButtonDisplayRow().withShowLabel(true).withWidth("1").withName("All").withKey("allTime").withField("Time").withDefaultValue("all").withSelected(query["time"] === "all"))
              .withChild(new ButtonDisplayRow().withShowLabel(true).withWidth("1").withName("Today").withKey("todayTime").withField("Time").withDefaultValue("today").withSelected(query["time"] === "today"))
              .withChild(new ButtonDisplayRow().withShowLabel(true).withWidth("2").withName("Yesterday").withKey("yesterdayTime").withField("Time").withDefaultValue("yesterday").withSelected(query["time"] === "yesterday"))
              .withChild(new ButtonDisplayRow().withShowLabel(true).withWidth("2").withName("Last 2 Weeks").withKey("last2WeeksTime").withField("Time").withDefaultValue("last2Weeks").withSelected(query["time"] === "last2Weeks"))
              .withChild(new ButtonDisplayRow().withShowLabel(true).withWidth("2").withName("This Month").withKey("thisMonthTime").withField("Time").withDefaultValue("thisMonth").withSelected(query["time"] === "thisMonth"))
              .withChild(new ButtonDisplayRow().withShowLabel(true).withWidth("2").withName("Last 30 days").withKey("last30DaysTime").withField("Time").withDefaultValue("last30Days").withSelected(query["time"] === "last30Days"  || !query["time"]))
              .withChild(new DateUpdateRow().withWidth("4").withName("Show Hours On or Before").withKey("pickTime").withField("Date").withDefaultValue(isNaN(query["time"]) ? "" : query["time"] ))
            )
            .withChild(new RowDisplayRow()
              .withChild(filterRow)
            )
          )
        )
        .withChild(new RowDisplayRow()
          .withChild(new BoxDisplayRow()
            .withWidth("15")
            .withBorder(true)
            .withChild(new RowDisplayRow()
              .withChild(new LabelValueDisplayRow().withWidth("4").withLabelWidth("5").withLabel("Showing").withValue(this.createPageInfo(totalItems, query, "timesheet")))
              .withChild(new StringDisplayRow().withWidth("4"))
              .withChild(new StringListUpdateRow().withWidth("3")
                                                  .withName("Items/Page")
                                                  .withField("PageSize")
                                                  .withShowLabel(false)
                                                  .withDefaultValue(this.model().pageSize(query, "timesheet"))
                                                  .withOptions(ContentUtils.PAGE_SIZES()))
              .withChild(new ButtonDisplayRow().withWidth("1").withName("<").withField("PreviousPage"))
              .withChild(new StringListUpdateRow().withWidth("2")
                                                  .withShowLabel(false)
                                                  .withName("Jump to Page")
                                                  .withField("PageIndex")
                                                  .withDefaultValue(query["page"] ? query["page"] : "1")
                                                  .withOptions(ContentUtils.PAGE_INDEXES(totalItems, this.model().pageSize(query, "timesheet"))))
              .withChild(new ButtonDisplayRow().withWidth("1").withName(">").withField("NextPage"))
            )
          )
        )
      )
    );

    let box = new BoxDisplayRow();
    box
      .withChild(new RowDisplayRow()
        .withBackgroundTitle()
        .withChild(new TitleDisplayRow().withWidth("8").withTitle("Details"))
        .withChild(new TitleDisplayRow().withWidth("7").withTitle("Hours"))
      );

    this.addHeader(box);
  }

}