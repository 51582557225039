
import BoxDisplayRow from "../cell/box/BoxDisplayRow";
import RowDisplayRow from "../cell/row/RowDisplayRow";

import LabelValueDisplayRow from "@/portals/shared/cell//label/LabelValueDisplayRow";
import StringDisplayRow from "@/portals/shared/cell//string/StringDisplayRow";
import ButtonDisplayRow from "@/portals/shared/cell/button/ButtonDisplayRow";
import StringListUpdateRow from "@/portals/shared/cell/string/list/StringListUpdateRow.js";
import SpacerDisplayRow from "../cell/spacer/SpacerDisplayRow";

import ErrorDisplayRow from "@/portals/shared/cell/error/ErrorDisplayRow";
import LoadingDisplayRow from "@/portals/shared/cell/loading/LoadingDisplayRow";

import MC from "@/domain/session/MC.js";
import ContentUtils from '@/utils/ContentUtils.js';
import StringUtils from '@/utils/StringUtils.js';

export default class RcTableViewUtils {

  constructor(model, tableData) {
    this._model = model;
    this._tableData = tableData;
    this.Contents = ContentUtils;
    this.Strings = StringUtils;
    this.Mc = MC;
  }

  isUserAdmin() {
    var user = this.domain().session().user();
    var employees = this.domain().employees().findByUserId(user.id());
    var roles = employees.roles().find();
    return roles.isAdministrator();
  }

  log() {
    return this.domain().logger();
  }

  domain() {
    return this.model().domain();
  }

  model() {
    return this._model;
  }

  addHeader(row) {
    this._tableData.addHeaderRow(row);
    return this;
  }

  addBody(row) {
    return this.add(row);
  }

  add(row) {
    this._tableData.addRow(row);
    return this;
  }

  addFooter(row) {
    this._tableData.addFooterRow(row);
    return this;
  }

  start(loadingMessage = "") {
    this.renderError();
    if (this.model()._useV2) {
      this.startV2(loadingMessage)
    } else {
      if (this.model().isStatePageLoading()) {
        this.addPageLoading(loadingMessage);
      }

      if (this.model().isStateContentLoading()) {
        this.addContentLoading(loadingMessage);
      }
    }
  }

  startV2(itemBeingLoaded = "Item") {
    if (this.model().hasError()) {
      this.renderError();
    }

    if (this.model().isStatePageLoading()) {
      this.addPageLoading("Loading " + itemBeingLoaded + " data...");
      return;
    }

    this.renderHeader();

    if (this.model().isStateContentLoading()) {
      this.addContentLoading("Loading " + itemBeingLoaded + " data...");
      return;
    }

    if (this.model().isStateContentLoaded()) {
      this.renderBody();
    }

    this.renderFooter();
  }

  renderBody() {
    //
  }

  renderHeader() {
    //
  }

  renderFooter() {
    this.setupFooterPagesRow();
  }

  setupFooterPagesRow(pageName = "all") {
    var query = this.model().urlQueryParams();
    let totalItems = this.model().itemCount();
    let pages = this.model().pages();
    let onPage = query["page"] ? query["page"] : "1";
    let pageDetails = this.createPageInfo(totalItems, query, pageName);

    let row = new RowDisplayRow()
      .withChild(new LabelValueDisplayRow()
        .withWidth("4")
        .withLabelWidth("5")
        .withLabel("Showing")
        .withValue(pageDetails))
      .withChild(new StringDisplayRow().withWidth("7"))
      .withChild(new ButtonDisplayRow().withWidth("1").withName("<").withField("PreviousPage"))
      .withChild(new StringListUpdateRow().withWidth("2")
        .withShowLabel(false)
        .withName("Jump to Page")
        .withField("PageIndex")
        .withDefaultValue(onPage)
        .withOptions(pages))
      .withChild(new ButtonDisplayRow().withWidth("1").withName(">").withField("NextPage"));

    this.addFooter(new BoxDisplayRow().withChild(row));
  }

  setupHeaderPagesRow(pageName = "all") {
    var query = this.model().urlQueryParams();
    let totalItems = this.model().itemCount();
    let pages = this.model().pages();
    let onPage = query["page"] ? query["page"] : "1";
    let pageSize = this.model().pageSize(query, pageName);
    let pageDetails = this.createPageInfo(totalItems, query, pageName);

    let row = new RowDisplayRow().withBorder()
      .withChild(new LabelValueDisplayRow()
        .withWidth("4")
        .withLabelWidth("5")
        .withLabel("Showing")
        .withValue(pageDetails))
      .withChild(new StringDisplayRow().withWidth("4"))
      .withChild(new StringListUpdateRow().withWidth("3")
        .withName("Items/Page")
        .withField("PageSize")
        .withShowLabel(false)
        .withDefaultValue(pageSize)
        .withOptions(ContentUtils.PAGE_SIZES()))
      .withChild(new ButtonDisplayRow().withWidth("1").withName("<").withField("PreviousPage"))
      .withChild(new StringListUpdateRow().withWidth("2")
        .withShowLabel(false)
        .withName("Jump to Page")
        .withField("PageIndex")
        .withDefaultValue(onPage)
        .withOptions(pages))
      .withChild(new ButtonDisplayRow().withWidth("1").withName(">").withField("NextPage"));
    return row;
  }

  addPageLoading(loadingMessage = "") {
    this.add(new BoxDisplayRow()
      .withChild(new RowDisplayRow()
        .withChild(new SpacerDisplayRow().withWidth("5"))
        .withChild(new BoxDisplayRow().withBorder(true).withWidth("5").withChild(new LoadingDisplayRow().withMessage(loadingMessage).withKey("progress").withId("progress")))
        .withChild(new SpacerDisplayRow().withWidth("5"))
      )
    );
  }

  createPageInfo(totalItems, query, pageName) {
    const pageSize = this.model().pageSize(query, pageName);
    var end = this.model().pageNumber(query) * pageSize;
    const start = end - pageSize + 1;
    if (end > totalItems) {
      end = totalItems;
    }
    var display = StringUtils.format("{0} to {1} of {2}", [start, end, totalItems]);
    if (totalItems == 0) {
      display = "0";
    }
    return display;
  }

  renderError() {
    let error = this.model().error();
    if (error == null) {
      return;
    }

    var box = new BoxDisplayRow().withBorder(true);
    box
      .withChild(new RowDisplayRow()
        .withChild(new SpacerDisplayRow().withWidth(2))
        .withChild(new ErrorDisplayRow().withWidth(11).withError(error))
        .withChild(new SpacerDisplayRow().withWidth(2))
      );
    this.add(box);
  }

  renderSaving() {
    let box = new BoxDisplayRow()
      .withBorder(true)
      .withChild(new StringDisplayRow().withValue("Saving"));
    this.addBody(box);
  }

  renderSaved() {
    let box = new BoxDisplayRow()
      .withBorder(true)
      .withChild(new StringDisplayRow().withValue("Saved"));
    this.addBody(box);
  }

  addContentLoading(loadingMessage = "") {
    this.addPageLoading(loadingMessage);
  }

  userList() {
    var company = this.model().company();
    var employees = company.employees();
    var userMap = employees.users().find();
    var sorted = userMap.sortByName();
    var list = [];
    for (var i = 0; i < sorted.length; i++) {
      var user = sorted[i];
      var userData = {};
      userData["@rid"] = user.id();
      userData["name"] = user.fullName() + " (" + user.email() + ")";
      if (StringUtils.startsWith(userData.name, "ReadyChek")) {
        continue;
      }
      if (StringUtils.isEmpty(user.fullName())) {
        continue;
      }
      list.push(userData)
    }
    return list;
  }

  vehicleList() {
    var company = this.model().company();
    var vehicles = company.vehicles();
    var sorted = vehicles.sortByName();
    var list = [];
    for (var i = 0; i < sorted.length; i++) {
      var item = sorted[i];
      var data = {};
      data["@rid"] = item.id();
      data["name"] = item.plate();
      if (StringUtils.isNotEmpty(item.name())) {
        data["name"] = data["name"] + " (" + item.name() + ")";
      }
      if (item.isTrailer()) {
        data["name"] = data["name"] + " [Trailer]";
      }
      if (item.isEquipment()) {
        if (company.hasEquipment()) {
          data["name"] = item.name() + " [Equipment]"
        } else {
          continue;
        }
      }

      list.push(data)
    }
    return list;
  }

  valueOrNotSet(value, trim) {
    if (value === false) {
      return "false";
    }

    if (StringUtils.isEmpty(value)) {
      return " ";
    }
    if (trim) {
      let size = 400;
      let isLong = value.length > size;
      value = value.substring(0, isLong ? size : value.length);
      if (isLong) {
        value = value + "..."
      }
    }
    return value;
  }
}