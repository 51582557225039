<template>
  <VueView 
    @click="withButtonPressed" 
    @withButtonPressed="withButtonPressed"
     @withValue="withValue"></VueView>
</template>

<script>
import VueView from './VehiclesView.vue';
export default {
  name: "report-list-add-vehicles-row",
  components: {
    VueView
  },
  props: {
  },
  methods: {
    withValue: function(event) {
      this.$emit('withValue', event);
    },
    withButtonPressed: function(event) {
      this.$emit('withButtonPressed', event);
    },
  }
}
</script>
