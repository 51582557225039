// import RcTableViewRow from "../cell/common/RcTableViewRow";
import ConstUtils from "@/utils/ConstUtils.js";
import ContentUtils from "@/utils/ContentUtils.js";
import StringUtils from '@/utils/StringUtils.js';
import RcTableViewData from "../table/RcTableViewData";

export default class RcTableViewModel {

  constructor(panel, useV2) {
    this._tablePanel = panel;
    this._useV2 = useV2;

    this.clearData();
    this.withStateLoading();
    this.panel().showMissingRequirements = false;

    this._error = null;

    this._itemList = [];
    this._itemCount = 0;
    this._itemMap = null;

    this._pages = [];

    this._searchText = "";
    this._valueData = {};
  }

  pushToRouter(query) {
    this.log().info("Router: {0}", [JSON.stringify(query, null, 2)]);
    this.panel().$router.push({ query });
  }

  withSearchText(valueData) {
    this._searchText = valueData["value"]
    return this;
  }

  searchText() {
    return this._searchText;
  }

  withStatePageLoading() {
    this.withStateLoading();
  }

  withStateLoading() {
    this.withState("pageLoading");
    return this;
  }

  isStatePageLoading() {
    return this.isState("pageLoading");
  }

  withStateContentLoading() {
    this.withState("contentLoading");
    return this;
  }

  isStateContentLoading() {
    return this.isState("contentLoading");
  }

  withStateContentLoaded() {
    this.withState("contentLoaded");
    return this;
  }

  withStateContentSaving() {
    this.withState("contentSaving");
  }

  isStateContentSaving() {
    return this.state() === "contentSaving";
  }

  withStateContentSaved() {
    this.withState("contentSaved");
  }

  isStateContentSaved() {
    return this.state() === "contentSaved";
  }

  loadItems() {
    this.withError(null);
    this.withStateContentLoading();
    this.clearAndBuildTableData();
    //this.log().info("{0}", [JSON.stringify(this.panel().$router.options.routes)]);
    return this;
  }

  loadedItemsListener(notification, context) {
    var model = context.model;
    var query = context.query;

    if (notification == null) {
      model.setupData(null, {})
      model.setupPages(query, context["pageName"]);
      model
        .withStateContentLoaded()
        .clearAndBuildTableData()
        .done();
      return false;
    }

    if (notification.error()) {
      model.setupData(null, {})
      model.setupPages(query, context["pageName"]);
      model
        .withError(notification.error())
        .withStateContentLoaded()
        .clearAndBuildTableData()
        .done()
      return false;
    }
    return true;
  }
  
  setupData(payload, map, sortMethod = "sort") {
    if (payload != null) {
      this._itemCount = payload.total();
    } else {
      this._itemCount = 0;
    }
    this._itemMap = map;
    if (this._itemMap[sortMethod]) {
      this._itemList = this._itemMap[sortMethod]();
    } else {
      this._itemList = [];
    }
  }

  showData() {
    this
      .withStateContentLoaded()
      .clearAndBuildTableData()
      .done();

    this.showDialogIfRequired();
  }

  pages() {
    return this._pages;
  }

  setupPages(query, pageName = "all") {
    this._pages = ContentUtils.PAGE_INDEXES(this._itemCount, this.pageSize(query, pageName))
  }

  isStateContentLoaded() {
    return this.isState("contentLoaded");
  }

  withState(state) {
    this._tableState = state;
    return this;
  }

  isState(state) {
    return StringUtils.contains(this._tableState, state);
  }

  panel() {
    return this._tablePanel;
  }

  domain() {
    return this._tablePanel.domain;
  }

  state() {
    return this._tableState;
  }

  log() {
    return this.domain().logger();
  }

  start() {
    this.withStatePageLoading();
    this.clearAndBuildTableData();
    return this;
  }

  showMissingRequirements() {
    return this.panel().showMissingRequirements;
  }

  urlQueryParams() {
    var query = Object.assign({}, this.panel().$route.query);
    if (query == null) {
      query = {};
    }
    return query;
  }

  deleteDetails(query) {
    delete query.action;
    delete query.itemId;
    delete query.search;
    return query;
  }

  reloadPage() {
    this.withStateContentLoading();
    this.clearAndBuildTableData();
    this.loadItems();
  }

  paint() {
    this.panel().redraw++;
  }

  addQueryParams(valueData, key) {
    var query = this.urlQueryParams();
    this.addQueryParamsToRouter(query, valueData, key);
  }

  addQueryParamsToRouter(query, valueData, key) {
    const value = valueData["value"];
    if (value && value != "null") {
      query[key] = value;
      if (key === "pageSize") {
        delete query.page
      }
    } else {
      delete query[key];
    }
    this.pushToRouter(query);
  }

  withToFrom(to, from) {
    this.log().info("Model To: {0} From: {1}", [to.path, from.path]);
  }

  showDialogIfRequired() {
    var query = this.urlQueryParams();
    if (query["action"]) {
      this.panel().showDialog = query["action"] + "Dialog";
      this.panel().itemId = query["selectedId"];
    }
  }

  withEnterPressed(valueData) {
    this.log().info("Model Enter {0}", [JSON.stringify(valueData, null, 2)]);
  }

  withValue(valueData, pageName = "all") {
    this.withFieldValue(valueData, pageName);
  }

  withFieldValue(valueData, pageName = "all") {
    this.panel().showMissingRequirements = false;
    this.withError(null);
    this._valueData[valueData["field"]] = valueData;
    this.log().info("Model Value {0}", [JSON.stringify(this._valueData, null, 2)]);

    if (!this._useV2) {
      return;
    }
    if (valueData["field"] === "PageSize") {
      this.withPageSize(valueData, pageName);
      this.addQueryParams(valueData, "pageSize");

    } else if (valueData["field"] === "PageIndex") {
      this.addQueryParams(valueData, "page");

    } else if (valueData["field"] === "Search") {
      this.withSearchText(valueData);

    } else {
      this.panel().$emit("withValue", valueData);
    }
  }

  withButtonPressed(buttonData) {
    this.log().info("Model Button {0}", [JSON.stringify(buttonData, null, 2)]);
    if (!this.panel().auth_connected) {
      this.panel().$connect();
    }
    if (!this._useV2) {
      return;
    }
    if (buttonData["field"] === "Refresh") {
      this.reloadPage();

    } else if (buttonData["field"] === "NextPage") {
      const query = this.urlQueryParams();
      if (query.page) {
        query.page = (Number(query.page) + 1) + "";
      } else {
        query.page = "2";
      }
      if (query.page <= this.pages().length) {
        this.pushToRouter(query);
      }

    } else if (buttonData["field"] === "PreviousPage") {
      const query = this.urlQueryParams();
      if (query.page) {
        query.page = (Number(query.page) - 1) + "";
        if (query.page <= 0) {
          query.page = "1";
        }
        this.pushToRouter(query);
      }

    } else if (buttonData["field"] === "Clear") {
      buttonData["value"] = "";
      this.withSearchText(buttonData);
      this.addQueryParams(buttonData, "search");

    } else if (buttonData["field"] === "Search") {
      buttonData["value"] = this.searchText();
      const queryParams = this.urlQueryParams();
      delete queryParams["page"];
      this.addQueryParamsToRouter(queryParams, buttonData, "search");

    } else if (buttonData["field"] === "Select") {
      let company = this.domain().companys().findById(buttonData["id"]);
      let session = this.domain().session();
      session.with(company);
      session.companyName = company.name();
      this.panel().AuthStorage_updateCompany(company);
      let listPath = StringUtils.format(ConstUtils.ROUTES.COMPANY.EDIT_PATH, [company.idEncoded()]);
      this.panel().$router.push({
        path: listPath,
      });

    } else if (buttonData["field"] === "DetailsClose" || buttonData["field"] === "Close" || buttonData["field"] === "DialogClose") {
      let query = this.urlQueryParams();
      query = this.deleteDetails(query);
      this.pushToRouter(query);
      
    } else {
      this.panel().$emit("withButtonPressed", buttonData);
    }
  }

  clearAndBuildTableData() {
    this
      .clearData()
      .rebuildTableData();
    return this;
  }

  clearData() {
    this._tableData = new RcTableViewData();
    return this;
  }

  rebuildTableData() {
    var tableViewData = new RcTableViewData();
    this
      .buildRowData(tableViewData)
      .endRowData(tableViewData)
      .postBuildRowData(tableViewData)
      .done();

    this._tableData = tableViewData;
    if (this.panel().headerData) {
      this.panel().headerData = this._tableData.header();
    }

    this.panel().viewData = this._tableData.body();

    if (this.panel().footerData) {
      this.panel().footerData = this._tableData.footer();
    }

    this.panel().paint();
  }

  buildRowData(tableData) {
    if (tableData) {
      //
    }
    return this;
  }

  endRowData(tableData) {
    if (tableData) {
      //
    }
    return this;
  }

  postBuildRowData(tableData) {
    if (tableData) {
      //
    }
    return this;
  }

  findRowProgress() {
    return this.findRowByKey("progress");
  }

  rowWithKey(key) {
    return this.findRowByKey(key);
  }

  findRowByKey(key) {
    const rows = this._tableData.rows();
    for (var index = 0; index < rows.length; index++) {
      var row = rows[index];
      if (row.key() === key) {
        return row;
      }
      if (row.hasChildren()) {
        row = this.findInChildren(row, key);
        if (row != null) {
          return row;
        }
      }
    }
    return null;
  }

  findInChildren(row, key) {
    if (!row.hasChildren()) {
      return null;
    }
    var children = row.children();
    for (var index = 0; index < children.length; index++) {
      var child = children[index];
      if (child.key() === key) {
        return child;
      }
      if (child.hasChildren()) {
        child = this.findInChildren(child, key);
        if (child != null) {
          return child;
        }
      }
    }
    return null;
  }

  findIndexByKey(key) {
    const rows = this._tableData.rows();
    for (var index = 0; index < rows.length; index++) {
      var row = rows[index];
      if (row.key() === key) {
        return index;
      }
    }
    return -1;
  }

  tableData() {
    return this._tableData;
  }

  viewData() {
    return this._tableData.body();
  }

  logJson(kvps) {
    var out = JSON.stringify(kvps, null, 2)
    this.log().info(out);
  }

  withPageSize(valueData, pageName = "all") {
    let preferences = {};
    if (window.localStorage.readyChek) {
      let string = window.localStorage.readyChek;
      preferences = JSON.parse(string);
    }
    let pages = preferences["pages"];
    if (pages == null) {
      pages = {};
    }
    pages[pageName] = valueData["value"];
    preferences["pages"] = pages;
    
    let string = JSON.stringify(preferences);
    window.localStorage.readyChek = string;
  }

  pageSize(query, pageName = "all") {
    let preferences = {};
    if (window.localStorage.readyChek) {
      let string = window.localStorage.readyChek;
      preferences = JSON.parse(string);
    }
    let pages = preferences["pages"];
    if (pages == null) {
      pages = {};
    }
    let defaultValue = "10";
    let value = pages[pageName];
    if (value == null) {
      value = defaultValue;
    }

    pages[pageName] = value;
    preferences["pages"] = pages;
    
    const size = query["pageSize"] ? query["pageSize"] : value;
    return size;
  }

  pageNumber(query) {
    return query["page"] ? query["page"] : "1";
  }

  error() {
    return this._error;
  }

  withError(error) {
    this._error = error;
    this.panel().error = this._error;
    return this;
  }

  hasError() {
    return this._error != null;
  }

  itemList() {
    return this._itemList;
  }

  itemCount() {
    return this._itemCount;
  }

  isAdmin() {
    let session = this.domain().session();
    return session.isAdmin();
  }

  isAdminDisplay() {
    this.panel().adminDisplay;
  }
  
  done() {

  }

}