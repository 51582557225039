
import RcTableViewRow from "@/portals/shared/cell/common/RcTableViewRow.js";

export default class ValueDisplayRow extends RcTableViewRow {

  static Id = "ValueDisplayRow";
  static FontSize = "fontSize";

  constructor() {
    super();
    this.put(RcTableViewRow.TypeId, ValueDisplayRow.Id);
    this.withFontSize("mediumn");
    this.withAlignLeft();
  }
  
  withAlignLeft() {
    this.withAlign("left");
    return this;
  }
  
  withAlignRight() {
    this.withAlign("right");
    return this;
  }

  withAlign(align) {
    this.put("align", align);
    return this;
  }
  
  align() {
    return this.getString("align");
  }

  withStyleEven() {
    this.withStyle("rowEven");
    return this;
  }
  
  withStyle(style) {
    this.put("style", style);
    return this;
  }
  
  style() {
    return this.getString("style");
  }

  fontSize() {
    return this.getString(ValueDisplayRow.FontSize);
  }

  withFontSize(fontSize) {
    this.put(ValueDisplayRow.FontSize, fontSize);
    return this;
  }
}