<template>
    <Row>
      <Column :width="15" :key="redraw">
        <div id="detailsModal" class="rc-modal-dialog">
          <!-- Modal content -->
          <div class="rc-modal-content-dialog-wide">
  
            <div class="rc-modal-header-dialog">
              <Box >
                <Row 
                  v-for="(row, rowIndex) in viewHeaderData" 
                  :key="rowIndex" >
                    <CellFactory 
                      :rowData="row.data"
                      :showMissingRequirements="showMissingRequirements"
                      v-on:withValue="withFieldValue"
                      v-on:withButtonPressed="withButtonPressed"
                      />
                </Row>
              </Box>
            </div>
  
            <div class="rc-modal-body-dialog">
              <div class="rc-modal-body-inner-dialog rc-border-thin">
                <Box :key="redrawMain">
                  <Row 
                    v-for="(row, rowIndex) in viewBodyData" 
                    :key="rowIndex" >
                      <CellFactory 
                        :rowData="row.data"
                        :showMissingRequirements="showMissingRequirements"
                        v-on:withValue="withFieldValue"
                        v-on:withButtonPressed="withButtonPressed"
                        />
                  </Row>
                </Box>
              </div>
            </div>
  
            <div class="rc-modal-footer-dialog">
              <Box>
                <Row 
                  v-for="(row, rowIndex) in viewFooterData" 
                  :key="rowIndex" >
                    <CellFactory 
                      :rowData="row.data"
                      :showMissingRequirements="showMissingRequirements"
                      v-on:withValue="withFieldValue"
                      v-on:withButtonPressed="withButtonPressed"
                      />
                </Row>
              </Box>
            </div>
  
          </div>
        </div>
      </Column>
    </Row>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';

import ConnectionUtils from '@/utils/ConnectionUtils.js';
import ConstUtils from '@/utils/ConstUtils.js';
import ContentUtils from '@/utils/ContentUtils.js';
import StringUtils from '@/utils/StringUtils.js';

import MC from "@/domain/session/MC.js";

import CellFactory from "@/portals/shared/cell/CellFactory.vue";
import Box from "@/portals/shared/library/box/Box.vue";
import Column from "@/portals/shared/library/box/Column.vue";
import Row from "@/portals/shared/library/box/Row.vue";

import BoxDisplayRow from "@/portals/shared/cell/box/BoxDisplayRow";
import ButtonDisplayRow from "@/portals/shared/cell/button/ButtonDisplayRow";
import ErrorDisplayRow from "@/portals/shared/cell/error/ErrorDisplayRow";
import RowDisplayRow from "@/portals/shared/cell/row/RowDisplayRow";
import SpacerDisplayRow from '@/portals/shared/cell/spacer/SpacerDisplayRow';
import StringDisplayRow from "@/portals/shared/cell/string/StringDisplayRow";
import StringUpdateRow from "@/portals/shared/cell/string/StringUpdateRow";
import TitleDisplayRow from "@/portals/shared/cell/title/TitleDisplayRow";
import TextAreaUpdateRow from '@/portals/shared/cell/textarea/TextAreaUpdateRow';

import Checklist from "@/domain/model/checklist/CheckList.js";
import ChecklistItem from '@/domain/model/checklist/item/CheckListItem.js';
import ChecklistItemOption from '@/domain/model/checklist/item/option/CheckListItemOption.js';
import ChecklistItemUpdateRow from "@/portals/shared/cell/checklist/item/ChecklistItemUpdateRow.js";
import ChecklistItemOptionUpdateRow from "@/portals/shared/cell/checklist/item/option/ChecklistItemOptionUpdateRow.js";
import ChecklistItemOptionMap from '@/domain/model/checklist/item/option/CheckListItemOptionMap.js';

export default {
  name: "checklist-list-add-view",
  components: {
    Column, Row, 
    Box,
    CellFactory,
  },
  props: {
    itemId: { type: String, default: "" },
  },
  data() {
    return {
      redraw: 1,
      redrawMain: 10000,

      MC: new MC(),
      Strings: StringUtils,
      Contents: ContentUtils,
      Consts: ConstUtils,

      showMissingRequirements: false,

      viewHeaderData: [],
      viewBodyData: [],
      viewFooterData: [],

      hasAddedChecklist: false,
      isUpdate: false,

      checklist: null,

      kvps: {},
      error: null,

      showDialog: null,
      StringUpdateRow: StringUpdateRow
    };
  },
  computed: {
    ...mapGetters([
                   'auth_connected',
                   'auth_socket_status',
                   'signin_event',
                   'auth_client',
                   'domain',
                   ]),
  },
  watch: {
    auth_connected() {
      ConnectionUtils.sendSignInEvent(this);
    },
    auth_socket_status() {
      ConnectionUtils.displayStatus(this);
    },
    signin_event() {
      ConnectionUtils.createDomainSession(this);
    }
  },
  mounted: function () {
    ConnectionUtils.ensureConnection(this);
  },

  methods: {
    ...mapActions([
                   'addListenerAction',
                   'removeListenerAction',
                   'updateDomain',
                   'AuthStorage_updateCompany',
                   ]),

    start: function() {
      this.checklist = new Checklist(this.domain);
      if (StringUtils.isNotEmpty(this.itemId)) {
        var id = ContentUtils.baseDecode(this.itemId);
        this.checklist = this.domain.checklists().findById(id).populate().copy();
      }
      this.setup();
      this.mainScreen();
    },

    setup: function() {
      //window.onclick = this.onClick;
    },

    mainScreen() {
      this.viewHeaderData = [];
      this.viewBodyData = [];
      this.viewFooterData = [];

      if (this.checklist.isNew()) {
        this.viewHeaderData.push(new TitleDisplayRow().withTitle(StringUtils.format("Add Checklist")));
      } else {
        this.viewHeaderData.push(new TitleDisplayRow().withTitle(StringUtils.format("Update Checklist: {0}", [this.checklist.name()])));
        this.isUpdate = true;
      }

      var errorBox = new BoxDisplayRow().withBorder(true)
                          .withChild(new RowDisplayRow()
                            .withChild(new SpacerDisplayRow().withWidth("1"))
                            .withChild(new ErrorDisplayRow().withWidth("13").withError(this.error))
                            .withChild(new SpacerDisplayRow().withWidth("1"))
                          );
      if (this.error) {
        this.viewBodyData.push(errorBox);
      }

      if (this.checklist.isNew()) {
        this.viewBodyData.push(new TitleDisplayRow().withTitle(this.MC.CheckList.Add.Title.value()).withSubtitle(true));
      } else {
        this.viewBodyData.push(new TitleDisplayRow().withTitle("Update Checklist").withSubtitle(true));
      }

      this.viewBodyData.push(new RowDisplayRow()
        .withBorder(true)
        .withChildren([
          new StringUpdateRow()
            .withWidth(5)
            .withName(this.MC.CheckList.Add.NameLabel.value())
            .withObject(Checklist.MODEL_NAME)
            .withId(this.checklist.id())
            .withField(Checklist.Fields.Name)
            .withKey(Checklist.Fields.Name)
            .withMandatoryValue(true)
            .withDefaultValue(this.checklist.name())
            .withHint(this.MC.CheckList.Add.NameHint.value()),

          new TextAreaUpdateRow()
            .withWidth(10)
            .withName(this.MC.CheckList.Add.DescriptionLabel.value())
            .withObject(Checklist.MODEL_NAME)
            .withDefaultValue(this.checklist.description())
            .withId(this.checklist.id())
            .withField(Checklist.Fields.Description)
            .withKey(Checklist.Fields.Description)
            .withHint(this.MC.CheckList.Add.DescriptionHint.value()),
        ]));

      this.viewBodyData.push(new TitleDisplayRow()
        .withTitle(this.MC.CheckList.Add.CheckListItemTitle.value()).withSubtitle(true));
      
      var childRows = [];
      childRows.push(new StringDisplayRow()
          .withAlignLeft()
          .withStyleEven()
          .withValue(this.MC.CheckList.QuestionDescription.value()));
      
      var itemChildren = this.createItemChildren();
      childRows.push(...itemChildren);

      this.viewBodyData.push(new BoxDisplayRow()
        .withField("addQuestionBox")
        .withBorder(true)
        .withChildren(childRows));

      this.viewFooterData.push(new RowDisplayRow()
      .withFixedPosition(true)
      .withChildren([
        new ButtonDisplayRow()
          .withWidth(3)
          .withName("Cancel")
          .withField("Close"),

        new SpacerDisplayRow()
          .withWidth(5),

        this.createSaveAnotherRow(),

        new ButtonDisplayRow()
          .withBackgroundPrimaryAction()
          .withName("Save")
          .withField("Save")
          .withWidth(3),
      ]))
    },

    getChecklistItems() {
      return this.checklist.items();
    },
    
    createItemChildren() {
      var kids = [];
      
      var itemMap = this.getChecklistItems();
      var items = itemMap.sortByIndex();
      for (var i = 0 ; i < items.length; i++) {
        var item = items[i];
        
        this.log().info("{0} {1}", [item.index(), item.name()]);
        
        var grandKids = this.createItemOptionChildren(item);
        
        var itemRows = [];
        
        itemRows.push(new ChecklistItemUpdateRow()
            .withItem(item)
            .withIndex(i)
            .withMandatoryValue(true)
            .withMaxIndex(items.length)
            .withChildren(grandKids));
            
        var itemRow = new BoxDisplayRow()
            .withBorder(true)
            .withChildren(itemRows)
        kids.push(itemRow);
      }
      var lastRow = new RowDisplayRow()
        .withChildren([
            new SpacerDisplayRow()
              .withWidth(13),
              
            new ButtonDisplayRow()
              .withWidth(2)
              .withName(this.MC.CheckList.AddQuestionButton.value())
              .withField("addQuestionButton")
              ]);
      
      kids.push(lastRow);
      return kids;
    },
    
    getChecklistItemOptions(item) {
      return item.options();
    },
    
    createItemOptionChildren(item) {
      var kids = [];
      var optionMap = this.getChecklistItemOptions(item);
      var options = optionMap.sortByIndex();
      
      for (var i = 0 ; i < options.length; i++) {
        var option = options[i];
        var row = new ChecklistItemOptionUpdateRow()
            .withMandatoryValue(true)
            .withIndex(i)
            .withMaxIndex(options.length)
            .withOption(option)
            .withRemove(!item.isTypeBool());
        kids.push(row);
      }
      
      if (item.isTypeList() && !item.isTypeBool()) {
        var lastRow = new RowDisplayRow()
            .withChildren([
                new SpacerDisplayRow()
                  .withWidth(13),
                  
                new ButtonDisplayRow()
                  .withWidth(2)
                  .withName(this.MC.CheckList.AddOptionButton.value())
                  .withField("addOptionButton")
                  ]);
        kids.push(lastRow);
      }
      
      return kids;
    },

    createSaveAnotherRow() {
      if (this.checklist.isNew()) {
        return new ButtonDisplayRow()
          .withBackgroundPrimaryAction()
          .withName("Save and Add Another")
          .withField("SaveAdd")
          .withWidth(4);
      }
      return new SpacerDisplayRow().withWidth(4);
    },
    
    sendingScreen() {
      this.viewBodyData = [];
      this.viewFooterData = [];
      this.viewHeaderData = [];

      let bodyBox = new BoxDisplayRow().withChild(new RowDisplayRow()
                              .withChild(new SpacerDisplayRow().withWidth("2"))
                              .withChild(new BoxDisplayRow()
                                    .withWidth("11")
                                    .withChild(new StringDisplayRow()
                                                  .withValue("Sending"))
                                  )
                              .withChild(new SpacerDisplayRow().withWidth("2"))
                            );
      this.viewBodyData.push(bodyBox);
      this.paint();
    },

    doneScreen() {
      this.viewHeaderData = [];
      this.viewBodyData = [];
      this.viewFooterData = [];

      let bodyBox = new BoxDisplayRow().withChild(new RowDisplayRow()
                              .withChild(new SpacerDisplayRow().withWidth("2"))
                              .withChild(new BoxDisplayRow()
                                    .withWidth("11")
                                    .withChild(new StringDisplayRow()
                                                  .withMandatoryValue(true)
                                                  .withValue("Done!"))
                                  )
                              .withChild(new SpacerDisplayRow().withWidth("2"))
                            );

      this.viewBodyData.push(bodyBox);
      this.paint();
    },

    paint: function() {
      this.redraw++;
    },

    paintMain: function() {
      this.redrawMain++;
    },

    onClick: function(event) {
      var modal = document.getElementById("detailsModal");
      if (event.target == modal) {
        this.goBack();
      }
    },

    log() {
      return this.domain.logger();
    },

    resetMissingValues() {
      if (this.showMissingRequirements) {
        this.showMissingRequirements = false;
      }
    },

    withFieldValue: function(valueData) {
      this.resetMissingValues();
      this.withValue(valueData);
      this.log().info("Dialog Value {0}", [JSON.stringify(this.checklist.data, null, 2)]);
    },

    withValue(kvp) {
      if (kvp) {
        this.domain.logger().info("Received: {0}", [JSON.stringify(kvp)]);
        
        var objectName = kvp['object'];
        var key = kvp['key'];
        var field = kvp['field'];
        var fieldValue = kvp['value'];
        var objectId = kvp['id'];
        
        let session = this.domain.session();
        let company = session.company();
        if (this.checklist.isNew()) {
          this.checklist.withCompany(company.pointer())
        }
        if (objectName === Checklist.MODEL_NAME) {
          if (field === Checklist.Fields.Name) {
            this.checklist.withName(fieldValue);
          }
          if (field === Checklist.Fields.Description) {
            this.checklist.withDescription(fieldValue);
          }
        }
        if (objectName === ChecklistItem.MODEL_NAME) {
          var item = this.checklist.items().getChecklistItem(objectId);
          if (field === ChecklistItem.Fields.Name) {
            item.withName(fieldValue);
          }
          
          if (key == ChecklistItem.Fields.Type) {
            item.withType(fieldValue);
            if (item.isTypeBool()) {
              this.doChecklistItemTypeBool(item, kvp);
            } else if (item.isTypeList()) {
              this.doChecklistItemTypeList(item, kvp);
            } else {
              var optionMap = new ChecklistItemOptionMap(this.domain);
              item.withOptions(optionMap);
              this.rebuildTableData();
            }
          }
          
          if (field == ChecklistItem.Fields.MultiSelect) {
            item.withMultiSelect(kvp.value);
          }
        }
      }
      if (objectName === ChecklistItemOption.MODEL_NAME) {
        this.doChecklistItemOptionName(kvp);
      }
      
    this.domain.logger().info("WithValue - Checklist: {0}", [this.checklist.toString()]);
    },
    
    doChecklistItemTypeBool(item, kvp) {
      if (!kvp) {
        return;
      }
      
      var optionMap = item.options();
      if (optionMap.isEmpty()) {
        var yesOption = new ChecklistItemOption(this.domain);
        yesOption
          .withIndex(optionMap.size())
          .withName("Yes")
          .withCategoryGood()
        optionMap.add(yesOption);

        var noOption = new ChecklistItemOption(this.domain);
        noOption
          .withIndex(optionMap.size())
          .withName("No")
          .withCategoryMinor()
          optionMap.add(noOption);
      }
      
      item.withOptions(optionMap);
      this.rebuildTableData();
    },
    
    doChecklistItemTypeList(item, kvp) {
      if (!kvp) {
        return;
      }
      var optionMap = item.options();
      if (optionMap.isEmpty()) {
        var option = new ChecklistItemOption(this.domain);
        option.withCategoryGood();
        option.withIndex(optionMap.size());
        optionMap.add(option);
      }
      item.withOptions(optionMap);
      this.rebuildTableData();
    },
    
    doChecklistItemOptionName(kvp) {
      var parentId = kvp['parentId'];
      var field = kvp['field'];
      var fieldValue = kvp['value'];
      var objectId = kvp['id'];
      var items = this.checklist.items();
      var checklistItem = items.getChecklistItem(parentId);
      var options = checklistItem.options();
      var itemOption = options.getCheckListItemOption(objectId);
      
      if (field === ChecklistItemOption.Fields.Name) {
        itemOption.withName(fieldValue);
      }
      if (field === ChecklistItemOption.Fields.Category) {
        itemOption.withCategory(fieldValue);
      }
      
    },

    withButtonPressed(buttonData) {
      this.resetMissingValues();
      if (!buttonData) {
        return;
      }
      this.domain.logger().info("Received: {0}", [JSON.stringify(buttonData)]);
      var field = buttonData['field'];
      
      if (field === "addQuestionButton") {
        this.doAddChecklistItemAction(buttonData);
      }
      if (field === "removeQuestionButton") {
        this.doRemoveChecklistItemAction(buttonData);
      }
      if (field === "upQuestionButton") {
        this.doUpChecklistItemAction(buttonData);
      }
      if (field === "downQuestionButton") {
        this.doDownChecklistItemAction(buttonData);
      }
      if (field === "upOptionButton") {
        this.doUpOptionAction(buttonData);
      }
      if (field === "downOptionButton") {
        this.doDownOptionAction(buttonData);
      }
      if (field === "addOptionButton") {
        this.doAddOptionAction(buttonData);
      }
      if (field === "removeOptionButton") {
        this.doRemoveOptionAction(buttonData);
      }
      if (buttonData["field"] === "Close") {
        if (this.hasAddedChecklist) {
          buttonData["refresh"] = "true";
        }
        this.$emit("withButtonPressed", buttonData);
      }

      if (buttonData["field"] === "Save") {
        if (!this.areRequirementsMet()) {
          this.showMissingRequirements = true;
        } else {
          this.doSendAction(false);
        }
      }

      if (buttonData["field"] === "SaveAdd") {
        if (!this.areRequirementsMet()) {
          this.showMissingRequirements = true;
        } else {
          this.doSendAction(true);
        }
      }
    },

    doUpChecklistItemAction(buttonData) {
      if (!buttonData) {
        return;
      }
      
      var objectId = buttonData['id'];
      var itemMap = this.checklist.items();
      var item = itemMap.getChecklistItem(objectId);
      var nextItem = itemMap.getChecklistItemByIndex(item.index() - 1);
      
      nextItem.withIndex(item.index());
      item.withIndex(item.index() - 1);
      
      itemMap.add(item);
      itemMap.add(nextItem);
      this.checklist.withItems(itemMap);
      this.rebuildTableData();
      
    },
    
    doDownChecklistItemAction(buttonData) {
      if (!buttonData) {
        return;
      }
      
      var objectId = buttonData['id'];
      var itemMap = this.checklist.items();

      var item = itemMap.getChecklistItem(objectId);
      var nextItem = itemMap.getChecklistItemByIndex(item.index() + 1);
      
      nextItem.withIndex(item.index());
      item.withIndex(item.index() + 1);
      
      itemMap.add(item);
      itemMap.add(nextItem);
      
      this.checklist.withItems(itemMap);
      this.rebuildTableData();
    },
    
    doAddChecklistItemAction(buttonData) {
      if (!buttonData) {
        return;
      }
      var itemMap = this.checklist.items();
      var item = new ChecklistItem(this.domain);
      item
        .withIndex(itemMap.size())
        .withTypeString()
        .done();
      itemMap.add(item);
      this.checklist.withItems(itemMap);
      this.rebuildTableData();
    },
    
    doRemoveChecklistItemAction(buttonData) {
      if (!buttonData) {
        return;
      }
      var objectId = buttonData['id'];
      var itemMap = this.checklist.items();
      var item = itemMap.getChecklistItem(objectId);
      itemMap.remove(item);
      
      var sorted = itemMap.sortByIndex(); 
      for (var i = 0; i < sorted.length; i++) {
        item = sorted[i];
        item.withIndex(i);
        itemMap.add(item);
      }
      
      this.checklist.withItems(itemMap);
      this.rebuildTableData();
    },
    
    doUpOptionAction(buttonData) {
      if (!buttonData) {
        return;
      }
      
    },
    
    doDownOptionAction(buttonData) {
      if (!buttonData) {
        return;
      }
    },
    
    doAddOptionAction(buttonData) {
      var itemId = buttonData['parentId'];
      var items = this.checklist.items();
      var item = items.getChecklistItem(itemId).find();
      var optionMap = item.options();
      var option = new ChecklistItemOption(this.domain);
      option
        .withIndex(optionMap.size())
        .withCategoryGood();
      optionMap.add(option);
      item.withOptions(optionMap);
      items.add(item)
      this.checklist.withItems(items);
      this.rebuildTableData();
    },
    
    doRemoveOptionAction(buttonData) {
      if (!buttonData) {
        return;
      }
        
      var itemId = buttonData['parentId'];
      var itemMap = this.checklist.items();
      var item = itemMap.getChecklistItem(itemId)
      var optionMap = item.options();
      
      var optionId = buttonData['id'];
      var option = optionMap.getCheckListItemOption(optionId);
      optionMap.remove(option);
      
      var sorted = optionMap.sortByIndex(); 
      for (var i = 0; i < sorted.length; i++) {
        option = sorted[i];
        option.withIndex(i);
        optionMap.add(option);
      }
      
      item.withOptions(optionMap);
      this.rebuildTableData();
    },

    doSendAction(repeat) {
      this.resetMissingValues();
      if (!this.areRequirementsMet()) {
        this.showMissingRequirements = true;
        return;
      }
      this.sendingScreen();
      var context = {
        view: this
      };
      
      var event = this.domain.events().checklists().save(this.checklist);
      if (repeat) {
        event.send(this.savedAnotherListener, context);
      } else {
        event.send(this.savedListener, context);
      }
    },

    savedAnotherListener(notification, context) {
      this.doneScreen();
      if (!notification || !context) {
        return;
      }
      if (notification.error()) {
        this.error = notification.error();
        this.mainScreen();
        return;
      }
      this.hasAddedChecklist = true;
      this.checklist = new Checklist(this.domain);
      this.itemId = "";
      this.start();
    },

    savedListener(notification, context) {
      this.doneScreen();
      if (!notification || !context) {
        return;
      }
      if (notification.error()) {
        this.error = notification.error();
        this.mainScreen();
        return;
      }

      var buttonData = {};
      buttonData["field"] = "Close";

      if (!this.isUpdate) {
        buttonData["refresh"] = "true";
      }
      this.$emit("withButtonPressed", buttonData);
    },

    displayProgress: function(msg) {
      this.callback(msg);
    },

    callback: function(msg) {
      if (msg) {
        //
      }
    },

    goBack: function() {
      this.$router.back();
    },

    areRequirementsMet() {
      var met = true;
      var checklist = this.checklist;
      if (StringUtils.isEmpty(checklist.name())) {
        met = false;
      }
      var items = checklist.items();
      var itemKeys = items.keys();
      for (var i = 0; i < itemKeys.length; i++) {
        var item = items.getChecklistItem(itemKeys[i]);
        if (StringUtils.isEmpty(item.name())) {
          met = false;
          break;
        }
        var options = item.options();
        var optionKeys = options.keys();
        for (var j = 0; j < optionKeys.length; j++) {
          var option = options.getCheckListItemOption(optionKeys[j]);
          if (StringUtils.isEmpty(option.name())) {
            met = false;
            break;
          }
        }
      }
      return met;
    },

    rebuildTableData() {
      this.mainScreen();
      this.paintMain();
    },
  }
}

</script>