import RcTableViewRow from "@/portals/shared/cell/common/RcTableViewRow.js";

import Timesheet from "@/domain/model/timesheet/Timesheet.js";

export default class TimesheetRow extends RcTableViewRow {

  static Id = "TimesheetRow";

  constructor() {
    super();
    this.put(RcTableViewRow.TypeId, TimesheetRow.Id);
  }

  withTimesheet(timesheet) {
    this.withObject(timesheet.data);
    this.withId(timesheet.id());
    this.withKey(timesheet.modelName());
    this.withField(timesheet.modelName());
    return this;
  }

  timesheet(domain) {
    return new Timesheet(domain, this.object());
  }
}