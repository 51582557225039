<template>
  <div class="row">
    <div v-if="left" :class="leftPadding"> </div>

    <div :class="middle">
      <ProgressRow :display="disabled" :title="title" :message="message" />
      <GreenButton 
        v-on:click="save" 
        :disabled="disabled"
        :label="MC.get(label)" />
    </div>
  
    <div v-if="right" :class="rightPadding"> </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'
import MC from "@/domain/session/MC.js";
import ProgressRow from '@/components/row/ProgressRow.vue';
import GreenButton from '@/components/button/GreenButton.vue';

export default {
  name: 'title-row',
  components: {
    ProgressRow,
    GreenButton,
  },
  props: {
    label:    { type: String, default : 'ACTION_SAVE' },
    styling:  { type: String, default : ""},
    disabled: { type: Boolean, default: true },
    left:     { type: Number, default : 0 },
    right:    { type: Number, default : 0 },
    message: { type: String, default: "Please wait..." },
    title:   { type: String, default: "Saving" },

  },
  data() {
    return {
      MC: new MC(),
    }
  },
  computed: {
    ...mapGetters([
                   'domain',
                 ]),
    leftPadding: function() {
      if (this.left > 0) {
        return "rc-box-col-" + this.left;
      }
      return "";
    },
    rightPadding: function() {
      if (this.right > 0) {
        return "rc-box-col-" + this.right;
      }
      return "";
    },
    middle: function() {
      var value = (15 - this.left - this.right);
      return "rc-box-col-" + value;
    },
  },
  methods: {
    save: function() {
      this.$emit('value', 'save');
      this.$emit('buttonPressed', 'save');
    },
  }
}
</script>