<template>
  <Dialog 
    :userId="userId" 
    :time="time"
    @click="withButtonPressed" 
    @withButtonPressed="withButtonPressed" 
    @withValue="withValue" 
    :isAdmin="isAdmin"></Dialog>
</template>

<script>
import Dialog from './RemindDialogView.vue';
export default {
  name: 'compliance-list-remind-dialog-row',
  components: {
    Dialog
  },
  props: {
    userId: { type: String, default: "" },
    isAdmin: { type: Boolean, default: false },
    time: { type: Number, default: 0 },
  },
  methods: {
    withValue: function(event) {
      this.$emit('withValue', event);
    },
    withButtonPressed: function(event) {
      this.$emit('withButtonPressed', event);
    },
  }
}
</script>
