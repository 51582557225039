import EventUtils from '@/utils/EventUtils.js';
import MapUtils from '@/utils/MapUtils.js';
import ConstUtils from '@/utils/ConstUtils.js'

const state = {
  list: [],
  map: {},
  pages: {},
  lastEvent: {},
};

const getters = {
  TimesheetStorage_lastEvent: state => state.lastEvent,
};

const actions = {
};

const mutations = {
  SOCKET_ONMESSAGE(state, event) {
    if (event.status == EventUtils.STATUS.COMPLETE && event.statusCode == EventUtils.CODE.OK) {
      if (event.Payload && event.Payload.TimesheetMap) {
        var items = event.Payload.TimesheetMap;
        state.map = MapUtils.updateMap(items, state.map);
        state.list = Object.values(state.map);
        state.lastEvent = event;
      }
      if (event.Payload && event.Payload.Timesheet) {
        var obj = event.Payload.Timesheet;
        state.map[obj[ConstUtils.FIELDS.ID]] = obj;
        state.list = Object.values(state.map);
        state.lastEvent = event;
      }
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
