import RcTableViewRow from "@/portals/shared/cell/common/RcTableViewRow.js";

import Invoice from "@/domain/model/invoice/Invoice.js";

export default class BillingRow extends RcTableViewRow {
    
    static Id = "BillingRow";
    
    constructor() {
        super();
        this.put(RcTableViewRow.TypeId, BillingRow.Id);
    }
    
    withInvoice(invoice) {
        this.withObject(invoice.data);
        this.withId(invoice.id());
        this.withField(invoice.modelName());
        this.withKey(invoice.modelName());
        return this;
    }
    
    invoice(domain) {
        return new Invoice(domain, this.object()); 
    }
}