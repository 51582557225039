<template>

<div class="rc-container rc-background-back" cmpt="AdminRegisterNew"> <!-- main -->

  <DetailsRow 
    v-if="isReady"
    :companyId="companyId"
    :isAdmin="true"
    v-on:companySaved="companySaved"
    />
  <SpacerRow />

</div><!-- main -->

</template>

<script>

import { mapGetters, mapActions } from 'vuex'

import Catalog    from "@/domain/session/Catalog.js";
import ConstUtils from '@/utils/ConstUtils.js';
import StringUtils from '@/utils/StringUtils.js';
import Company from "@/domain/model/company/Company.js";

import SpacerRow from '@/components/row/SpacerRow.vue'

import DetailsRow from "../details/DetailsRow.vue";


export default {
  name: "admin-company-new",
  components: {
    SpacerRow,
    DetailsRow,
  },
  props: {
  },
  data() {
    return {
      WC: new Catalog(),
      isAdmin: false,
      isReady: false,
    };
  },
  computed: {
    
    ...mapGetters([
                   'auth_connected',
                   'domain',
                ]),
    companyId: function() {
      var company = new Company(this.domain);
      company
        .withStateActive()
        .withFullDiscount()
        .withBillingPeriodMonthly()
        .done();
      if (this.domain) {
        this.domain.session().with(company);
      }
      return company.id();
    },
  },
  watch: {
    
  },
  created: function() {
  },
  mounted: function () {
    if (this.domain) {
      this.domain.session().companyName = null;
    }
    
    this.isReady = true;

    if (!this.auth_connected) {
      this.$router.replace({ name: this.$route.query.redirectTo || ConstUtils.ROUTES.SIGNIN })
    } else {
      this.start = true;
    }
  },
  methods: {
    ...mapActions([
                     'AuthStorage_updateCompany',
                     'addListenerAction',
                     'removeListenerAction',
                     'updateDomain',
                     ]),
    companySaved: function(value) {
      if (value) {
        var company = this.domain.companys().findById(value['value']);
        let session = this.domain.session();
        session.with(company);
        session.companyName = company.name();
        this.AuthStorage_updateCompany(company);
        let listPath = StringUtils.format(ConstUtils.ROUTES.COMPANY.EDIT_PATH, [company.idEncoded()]);
        this.$router.push({
          path: listPath,
        });
      }
    },

  }
}
</script>