<template>
  <Box>
    <Row>
      <Column>
        <Box>
          <DialogView 
            @click="withButtonPressed" 
            @withButtonPressed="withButtonPressed"
             @withValue="withValue"></DialogView>
        </Box>
      </Column>
    </Row>
  </Box>
</template>

<script>
import DialogView from './AddView.vue';
import Box from "@/portals/shared/library/box/Box.vue";
import Column from "@/portals/shared/library/box/Column.vue";
import Row from "@/portals/shared/library/box/Row.vue";


export default {
  name: "equipment-list-add-row",
  components: {
    Box, Row, Column,
    DialogView,
  },
  
  data() {
    return {
      showDialog: null
    };
  },

  props: {
    itemId: { type: String, default: "" },
  },

  methods: {
    withValue: function(event) {
      this.$emit('withValue', event);
    },
    withButtonPressed: function(event) {
      this.$emit('withButtonPressed', event);
    },
  }
}
</script>
