
import RcDomainObject from "@/domain/common/RcDomainObject.js";
import Asset from "@/domain/model/asset/Asset.js";
import Company from "@/domain/model/company/Company.js";
import InvoiceItemMap from "./InvoiceItemMap";

export default class InvoiceItem extends RcDomainObject {
  
  static MODEL_NAME = "InvoiceItem";
  
  constructor(domain, data) {
    super(domain, data, InvoiceItem.MODEL_NAME);
  }
  
  static Fields = {
    COMPANY: Company.MODEL_NAME,
    ASSET: Asset.MODEL_NAME,
    BASE_PRICE: "basePrice",
    PRICE: "price",
    DISCOUNT_PERCENTAGE: "discountPercentage",
    PRICE_PER_ITEM: "pricePerItem",
    SUBTOTAL_FOR_ITEMS: "subtotalForItems",
    DESCRIPTION: "description",
    PRICE_PER_ITEM_DISPLAY: "pricePerItemDisplay",
    TOTAL_DISPLAY: "totalDisplay",
    NUMBER_OF_ITEMS: "numberOfItems",
  }
  
  fields() {
    return InvoiceItem.FIELDS;
  }
  
  asset() {
    return new Asset(this.domain, this.get(InvoiceItem.Fields.ASSET));
  }

  pricePerItemDisplay() {
    return this.getString(InvoiceItem.Fields.PRICE_PER_ITEM_DISPLAY);
  }

  quantity() {
    return this.getString(InvoiceItem.Fields.NUMBER_OF_ITEMS);
  }

  description() {
    return this.getString(InvoiceItem.Fields.DESCRIPTION);
  }

  totalDisplay() {
    return this.getString(InvoiceItem.Fields.TOTAL_DISPLAY);
  }

  find() {
    var keys = this.keys();
    var found = new InvoiceItemMap(this.domain, {} );
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var data = this.domain.invoices().items().findById(key);
      var item = new InvoiceItem(this.domain, data);
      if (!item.isNew()) {
        found.add(item);
      }
    }
    return found.first();
  }
}