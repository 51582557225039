
import RcTableViewRow from "@/portals/shared/cell/common/RcTableViewRow.js";

import Vehicle from "@/domain/model/vehicle/Vehicle.js";

export default class VehicleRow extends RcTableViewRow {

  static Id = "VehicleRow";

  constructor() {
    super();
    this.put(RcTableViewRow.TypeId, VehicleRow.Id);
  }

  withVehicle(vehicle) {
    this.withObject(vehicle);
    this.withId(vehicle.id());
    this.withKey(vehicle.modelName());
    return this;
  }

  vehicle(domain) {
    return new Vehicle(domain, this.object());
  }
}