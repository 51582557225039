import RcObject from "@/domain/common/RcObject";
import Company from "@/domain/model/company/Company";

export default class ReportListFilter extends RcObject {
 
  static Fields = {
    PAGINATION: 'pagination',
    STATE_TYPE: 'state',
    COMPANY: Company.MODEL_NAME,
    SEARCH_TEXT: 'searchText',
    IDS: "ids",
  }

  constructor() {
    super({});
  }

  withSearchText(text) {
    this.put(ReportListFilter.Fields.SEARCH_TEXT, { 'text': text });
    return this;
  }

  withState(state) {
    this.put(ReportListFilter.Fields.STATE_TYPE, state);
    return this;
  }

  withIds(ids) {
    this.put(ReportListFilter.Fields.IDS, ids);
    return this;
  }

  withCompany(companyId) {
    this.put(ReportListFilter.Fields.COMPANY, companyId);
    return this;
  }

  withPagination(size, number) {
    this.put(ReportListFilter.Fields.PAGINATION, { size, number });
    return this;
  }

  done() {
    //
  }
}