
import RcObject from "@/domain/common/RcObject.js";
import Company from "@/domain/model/company/Company.js";

export default class InvoiceListFilter extends RcObject {
  
  static Fields = {
    PAGINATION: 'pagination',
    COMPANY: Company.MODEL_NAME,
    CREATED_DATE_RANGE: 'createDateRange',
    TOTAL_RANGE: 'totalRange',
    CONTRACT_START_RANGE: 'contractStartRange',
    PROMO_CODE: 'promoCode',
    CHARGE_STATUS: 'chargeStatus',
    AUTO_CHARGE: 'autoCharge',
    AUTO_EMAIL: 'autoEmail',
    SEARCH_TEXT: 'searchText',
  }
  
  constructor() {
    super({});
  }
  
  withCompany(companyId) {
    this.put(InvoiceListFilter.Fields.COMPANY, companyId);
    return this;
  }
  
  withPagination(size, number) {
    this.put(InvoiceListFilter.Fields.PAGINATION, { size, number });
    return this;
  }
  
  withDateRange(dateRange) {
    this.put(InvoiceListFilter.Fields.CREATED_DATE_RANGE, dateRange);
    return this;
  }
  
  withTotalRange(amount) {
    this.put(InvoiceListFilter.Fields.TOTAL_RANGE, amount);
    return this;
  }
  
  withContractStartRange(value) {
    this.put(InvoiceListFilter.Fields.CONTRACT_START_RANGE, value);
    return this;
  }
  
  withPromoCode(value) {
    this.put(InvoiceListFilter.Fields.PROMO_CODE, value);
    return this;
  }
  
  withChargeStatus(value) {
    this.put(InvoiceListFilter.Fields.CHARGE_STATUS, value);
    return this;
  }
  
  withAutoCharge(value) {
    this.put(InvoiceListFilter.Fields.AUTO_CHARGE, value);
    return this;
  }

  withAutoEmail(value) {
    this.put(InvoiceListFilter.Fields.AUTO_EMAIL, value);
    return this;
  }
 
  withSearchText(text) {
    this.put(InvoiceListFilter.Fields.SEARCH_TEXT, { 'text': text });
    return this;
  }

  done() {
    //
  }
}