<template>

    <div class="rc-row">
      <div class="rc-box-col-2 rc-text-value">
        {{ company.id() }}<br>
        {{ company.state() }} <br> 
      </div>
      <div class="rc-box-col-4 rc-text-value">
        {{ company.name() }} <br> 
        {{ summary() }}<br>
        <br>
        Integration ID {{ company.integrationReference() }} <br>
        Charge Invoice {{ company.autoChargeInvoice() }}<br>
        Email Invoice {{ company.autoEmailInvoice() }}<br>
        Admin Notes: {{ notes() }} <br>
      </div>
      <div class="rc-box-col-2 rc-text-value">
        {{ company.phone() }}
      </div>
      <div class="rc-box-col-3 rc-text-value">
        Created {{ company.createdDateV2().displayAs_YYYY_MM_DD() }}<br>
        Contract {{ company.contractStartDate().displayAs_YYYY_MM_DD() }}<br>
        Modified {{ company.modifiedDateV2().displayAs_YYYY_MM_DD() }}<br>
        Billing Interval {{ company.billingPeriod() }} <br>
      </div>
      <div class="rc-box-col-1 rc-text-value rc-text-centre">
        {{ company.creditDisplay() }}
      </div>
      <div class="rc-box-col-1 rc-text-value rc-text-centre">
        Discount {{ company.discount() }} <br>
      </div>
      <div class="rc-box-col-2 rc-text-value rc-text-centre">
       Promo {{ company.promo() }}<br>
      </div>
    </div>
    
</template>

<script>
import { mapGetters } from 'vuex';
import MC from "@/domain/session/MC.js";
import Company from "@/domain/model/company/Company.js";
import StringUtils from '@/utils/StringUtils.js';
import ContentUtils from '@/utils/ContentUtils.js';

export default {
  name: 'portals-admin-companys-row',
  components: {
  },
  props: {
    companyData: Object,
  },
  data() {
    return {
      MC: new MC(),
      StringUtils: StringUtils,
      ContentUtils: ContentUtils,
    };
  },
  watch: {
  },
  computed: {
    ...mapGetters([ 
                    'auth_client',
                    'domain'
                    ]),
    company() {
      if (this.companyData) {
        return new Company(this.domain, this.companyData);
      }
      return new Company(this.domain, {});
    },
  },
  
  mounted: function () {
  },
  
  methods: {
    isInContractPeriodWithDiscount: function() {
      var createdDate = this.company.contractStartDate();
      if (this.company.discount() == 1) {
        var now = this.domain.session().time().now();
        if (now.time() > createdDate.time()) {
          return true;
        }
      }
      return false;
    },
    notes() {
      var value = ContentUtils.decode(this.company.adminNotes());
      if (StringUtils.isEmpty(value)) {
        value = "-- empty --";
      }
      return value.substring(0, value.length > 50 ? 50 : value.length) + "...";
    },
    summary() {
      var summary = ContentUtils.decode(this.company.adminSummary());
      if (StringUtils.isEmpty(summary)) {
        summary = "-- empty --";
      }
      return summary;
    },
  },
}

</script>