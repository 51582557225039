<template>
  <Row v-if="ready">
    <div v-if="left" :class="leftPadding"> </div>
    <div :class="middle" ref="date">
      <Box>
        <Row v-if="showLabel">
          <Column :width="15">
            <div v-if="isTitleShowing" class="rc-font-small rc-text-label">
              {{ placeholderText() }}
            </div>
            <div v-else class="rc-font-small">
              <div v-if="placeholder != null" > 
                <div class="rc-font-small rc-text-label"> <br> </div>
              </div>
            </div>
          </Column>
        </Row>
        <Row v-if="showClear">
          <Column :width="14">
            <Box>
              <Row>
                <Column :width="15">
                  <input class="rc-input-field rc-input-width rc-text-value"
                    :placeholder="placeholderText()" 
                    :disable="disabled"
                    type="date"
                    v-model="value"
                    v-on:keyup.enter="enter"
                    v-on:click="click"
                    v-on:change="change"
                    v-on:input="input" 
                    style="height: 30px; padding-left: 20px; padding-right: 20px;"
                  />
                </Column>
              </Row>
            </Box>
          </Column>
          <Column :width="1" class="clickable">
            <i v-on:click="clear()" class="fa fa-close" />
          </Column>
        </Row>
        <Row v-else>
          <Column :width="15">
            <Box>
              <Row>
                <Column :width="15">
                  <input class="rc-input-field rc-input-width rc-text-value"
                  :placeholder="placeholderText()" 
                  :disable="disabled"
                  type="date" 
                  v-model="value"
                  v-on:keyup.enter="enter"
                  v-on:click="click"
                  v-on:change="change"
                  v-on:input="input" 
                  style="height: 30px; padding-left: 20px; padding-right: 20px;"
                  />
                </Column>
              </Row>
            </Box>
          </Column>
        </Row>
        <Row>
          <Column :width="15">
            <Box>
              <Row v-if="!shouldShowRequired && !Strings.isEmpty(hint)">
                <Column :width="15">
                  <div class="rc-font-small rc-text-value">{{ hint }} <br /></div>
                </Column>
              </Row>
              <Row v-if="shouldShowRequired">
                <Column :width="15">
                  <div v-if="shouldShowRequired" class="rc-font-small rc-background-required">A value for {{ MC.get(placeholder) }} is required</div>
                </Column>
              </Row>
            </Box>
          </Column>
        </Row>
      </Box>
    </div> <!-- end middle -->
    <div v-if="right" :class="rightPadding"> </div>
  </Row>
</template>

<script>
import { mapGetters } from 'vuex'

import Catalog from '@/domain/session/CanadianEnglish.js';
import ContentUtils from '@/utils/ContentUtils';
import StringUtils from '@/utils/StringUtils';

import Box from "@/portals/shared/library/box/Box.vue";
import Row from "@/portals/shared/library/box/Row.vue";
import Column from "@/portals/shared/library/box/Column.vue";

export default {
  name: 'key-date',
  components: {
    Box, Row, Column,
  },
  props: {
    title: String,
    defaultValue: { type: String, default: ""},
    placeholder: String,

    showLabel: { type: Boolean, default: true },
    hint: { type: String, default: ""},
    
    
    disabled: {type: Boolean, default: false},
    left: { type: Number, default: 0 },
    right: { type: Number, default: 0 },
    mandatory: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    showClear: { type: Boolean, default: true },
    clearValue: {type: Boolean, default: false},
    componentName: { type: String, default: "" },
  },

  data() {
    return {
      value: null,
      isTitleShowing: true,
      MC: new Catalog(),
      isOpen: false,
      ready: false,
      Strings: StringUtils,
    };
  },
  watch: {
    value() {
      this.validate();
    },

    clearValue() {
      if (this.clearValue) {
        this.value = null;
      }
    },

    required() {
      if (this.shouldShowRequired) {
        this.$refs.date.scrollIntoView();
      }
    },

    defaultValue() {
      this.value = this.defaultValue ? ContentUtils.decodeDate(this.defaultValue) : null;
    },
  },
  computed: {
    ...mapGetters([
                   'domain',
                 ]),
    shouldShowRequired: function() {
      if (this.required) {
        if (this.mandatory) {
          if (StringUtils.isEmpty(this.value) || this.value == "0" || this.value == 0) {
            return true;
          }
        }
      }
      return false;
    },
    leftPadding: function() {
      if (this.left > 0) {
        return "rc-box-col-" + this.left;
      }
      return "";
    },
    rightPadding: function() {
      if (this.right > 0) {
        return "rc-box-col-" + this.right;
      }
      return "";
    },
    middle: function() {
      var value = (15 - this.left - this.right);
      return "rc-box-col-" + value;
    },
  },
  mounted: function() {
    this.value = this.defaultValue ? ContentUtils.decodeDate(this.defaultValue) : null;
    this.validate();
    this.ready = true;
  },
  methods: {
    placeholderText: function() {
      if (this.mandatory) {
        return this.MC.get(this.placeholder) + "*";
      }
      return this.MC.get(this.placeholder);
    },
   
    emit: function() {
      clearInterval(this.timer);
      const value = ContentUtils.encodeDate(this.value);
      this.$emit('value', {'key': this.title, 'value': value});
    },
    validate: function() {
    },
    click: function(event) {
      if (!event) {
        return;
      }
      this.domain.logger().info("click");
    },
    change: function(event) {
      if (!event) {
        return;
      }
      this.domain.logger().info("change");
      this.emit();
    },
    input: function(event) {
      if (!event) {
        return;
      }
      this.domain.logger().info("input");
    },
    enter: function() {
      this.emit();
    },
    clear: function() {
      this.value = null;
      this.emit();
    }
  },

}
</script>
