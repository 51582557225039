<template>
  <Row v-if="ready" cmpt="StringUpdateCell" :tagValue="row.key()" :refValue="row.key()">
    <Column :width="15">
      <Box>
        <KeyDate :class="css" 
          :title="row.field()"
          :showLabel="row.showLabel()"
          :placeholder="row.name()" 
          :required="showMissingRequirements"
          :defaultValue="row.defaultValue()"
          :mandatory="row.mandatoryValue()"
          :hint="row.hint()" 
          v-on:value="withValue" 
          />
      </Box>
    </Column>
  </Row>
  
</template>

<script>

import { mapGetters } from 'vuex'

import DateUpdateRow from "./DateUpdateRow.js";
import KeyDate from '@/components/input/KeyDate.vue';
import Box from "@/portals/shared/library/box/Box.vue";
import Row from "@/portals/shared/library/box/Row.vue";
import Column from "@/portals/shared/library/box/Column.vue";

export default {
  name: 'date-update-cell',
  components: {
    KeyDate,
    Box, Row, Column,
  },
  props: {
    css:    { type: String, default: "" },
    showMissingRequirements: { type: Boolean, default: false },
    data: null,
  },
  data() {
    return {
      ready: false,
      rowData: new DateUpdateRow(),
    }
  },
  computed: {
    ...mapGetters([
                   'domain',
                 ]),
    row: function() {
      return this.rowData;
    },
  },
  mounted: function () {
    this.rowData.data = this.data;
    this.ready = true;
  },
  methods: {
    withValue: function(kvp) {
      if (kvp) {
        kvp['id'] = this.row.id();
        kvp['object'] = this.row.object();
        kvp['field'] = this.row.field();
        kvp['key'] = this.row.key();
        this.$emit('withValue', kvp);
      }
    }
  },
}
</script>