<template>

  <Row cmpt="DetailsRow">
    <Column :width="15">
      <Box>
        
        <Row>
          <Column :width="15">
            <Edit v-if="!isLoading"
              :isAdmin="isAdmin"
              v-on:companySaved="companySaved"
              />
          </Column>
        </Row>  

        <LoadingRow :showBorder="true" v-if="isLoading">{{loadingMessage}}</LoadingRow>
        <SpacerRow v-if="isLoading" />  
        
      </Box>
    </Column>
  </Row>

</template>

<script>

import { mapGetters, mapActions } from 'vuex';
import MC from "@/domain/session/MC.js";
import ConnectionUtils from '@/utils/ConnectionUtils.js';
import LoadingRow from "@/portals/shared/library/loading/LoadingRow.vue";  
import SpacerRow from "@/portals/shared/library/spacer/SpacerRow.vue";  

import Box from '@/portals/shared/library/box/Box.vue';
import Row from '@/portals/shared/library/box/Row.vue';
import Column from '@/portals/shared/library/box/Column.vue';

import Edit from '@/views/portal/companys/Edit.vue'

export default {
  name: "admin-company-details-row",
  components: {
    Edit, Box, Row, Column, LoadingRow, SpacerRow,
  },
  props: {
    isAdmin: { type: Boolean, default: false },
  },
  data() {
    return {
      tab: null,
      isLoading: true,
      loadingMessage: "",
      MC: new MC(),
    };
  },
  computed: {
    ...mapGetters([
                   'auth_connected',
                   'auth_socket_status',
                   'signin_event',
                   'auth_client',
                   'domain',
                 ]),
    companyId: function() {
      return this.$route.params['id'];
    },
  },
  watch: {
    auth_connected() {
      ConnectionUtils.sendSignInEvent(this);
    },
    auth_socket_status() {
      ConnectionUtils.displayStatus(this);
    },
    signin_event() {
      ConnectionUtils.createDomainSession(this);
    }
  },
  mounted: function () {
    ConnectionUtils.ensureConnection(this);
  },
  methods: {
    ...mapActions([
                   'AuthStorage_updateCompany',
                   'addListenerAction',
                   'removeListenerAction',
                   'updateDomain',
                   ]),

    start: function() {
      this.message = null;
      this.loadingMessage = "";
      ConnectionUtils.loadCompany(this);
    },
    callback: function(msg) {
      this.loadingMessage = msg;
    },
    startCompany: function() {
      //
    },
    companySaved: function(value) {
      if (value) {
        this.$emit('companySaved', value);
      }
    },
  }
}
</script>