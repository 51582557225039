
import BoxDisplayRow from "@/portals/shared/cell/box/BoxDisplayRow.js";
import RowDisplayRow from "@/portals/shared/cell/row/RowDisplayRow.js";
import TitleDisplayRow from "@/portals/shared/cell/title/TitleDisplayRow.js";
import SpacerDisplayRow from "@/portals/shared/cell/spacer/SpacerDisplayRow.js";
import ButtonDisplayRow from "@/portals/shared/cell/button/ButtonDisplayRow.js";

import ImageDisplayRow from "@/portals/shared/cell/image/ImageDisplayRow.js";
import StringDisplayRow from "@/portals/shared/cell/string/StringDisplayRow.js";
import StringUpdateRow from "@/portals/shared/cell/string/StringUpdateRow.js";
import PhoneNumberUpdateRow from "@/portals/shared/cell/phonenumber/PhoneNumberUpdateRow.js";
import StringListUpdateRow from "@/portals/shared/cell/string/list/StringListUpdateRow.js";
import CheckboxUpdateRow from "@/portals/shared/cell/checkbox/CheckboxUpdateRow.js";
import EmailUpdateRow from "@/portals/shared/cell/email/EmailUpdateRow.js";


import MC from "@/domain/session/MC.js";
import WebLogger from "@/domain/logger/WebLogger";
import ContentUtils from '@/utils/ContentUtils.js';
import RegisterViewModel from "./RegisterViewModel.js";

export default class RegisterViewModelUtils {

  static buildRowData(domain, model, tableData) {
    var au = new RegisterViewModelUtils(domain, model, tableData);
    au.start();
  }

  constructor(model, tableData) {
    this._model = model;
    this._panel = this._model.panel();
    this._tableData = tableData;
    this.MC = new MC();
    this._logger = new WebLogger(window.console, true);
  }

  panel() {
    return this._panel;
  }

  registration() {
    var panel = this.panel();
    var model = panel.model();
    return model.registration();
  }

  logger() {
    return this._logger;
  }

  start() {
    var model = this.panel().model();
    for (var spacers = 0; spacers < 1; spacers++) {
      this.addRow(new SpacerDisplayRow());
    }

    var roles = [
      { '@rid': 'owner', 'name': 'Owner/Operator', 'details': 'Your business is in great hands with ReadyChek!' },
      { '@rid': 'manager', 'name': 'Manager', 'details': 'Thanks for choosing ReadyChek we can\'t wait to meet you!' },
      { '@rid': 'driver', 'name': 'Driver', 'details': 'ReadyChek is designed for use by an entire business and not an individual driver. That being said, we would still like to speak to you and determine if we could start the conversation with your company.' },
      { '@rid': 'other', 'name': 'Other', 'details': 'Interesting, we will give you a call to find out more before we setup your account!' },
    ]

    var signInOrWelcomeRow = new StringDisplayRow().withValue("Create Account").withAlignLeft().withStyle("rc-font-extra-large");

    var mainKids = [];
    var moreRows = [];
    var hint = model.error().hint;
    if (model.isStateSending()) {
      mainKids = [
        new SpacerDisplayRow(),
        new SpacerDisplayRow(),
        new StringDisplayRow().withValue(model.status()).withStyle("rc-font-large"),
      ];
    } else if (model.isStateDone()) {
      signInOrWelcomeRow = new StringDisplayRow().withValue("Thank-you!").withAlignLeft().withStyle("rc-font-extra-large");
      mainKids = [
        new SpacerDisplayRow(),
        new SpacerDisplayRow(),
        new StringDisplayRow()
          .withValue("Thanks for registering, we are processing your request")
          .withStyle("rc-font-normal"),
        new SpacerDisplayRow(),
        new StringDisplayRow()
          .withValue("WE HAVE RECEIVED YOUR REGISTRATION!!")
          .withStyle("rc-font-regular-large"),
        new SpacerDisplayRow(),
        new StringDisplayRow()
          .withValue("We will send you a welcome email with information about ReadyChek in the next 24 hours!")
          .withStyle("rc-font-regular-large"),
        new RowDisplayRow()
          .withChildren([
            new BoxDisplayRow()
              .withWidth(5)
              .withChildren([
                new SpacerDisplayRow(),
              ]),
            new BoxDisplayRow()
              .withWidth(5)
              .withChildren([
                new ButtonDisplayRow()
                  .withWidth(5)
                  .withName("Back to Sign In")
                  .withField(RegisterViewModel.Fields.BackButton),
              ]),
            new BoxDisplayRow()
              .withWidth(5)
              .withChildren([
              ]),
          ]),
      ];

    } else {
      mainKids = [
        new StringDisplayRow().withWidth(15).withValue("ReadyChek is more than just an inspection app. It is designed to help owner/operators manage the pre-trip inspections and/or hours of service records for their drivers.").withAlignLeft(),
        new StringDisplayRow().withWidth(15).withValue("Please fill in the form below with your contact details and a little information about your company.").withAlignLeft(),
        new StringDisplayRow().withWidth(15).withValue("You will receive your welcome email containing your temporary password and set up instructions in the next 24 hours.").withAlignLeft().withStyle("rc-font-regular-large"),
        new RowDisplayRow()
          .withChildren([
          ]),
      ];
      
      var roleOther = [];
      if (this._model.showCompanyRoleOther()) {
        roleOther = [
                        new StringUpdateRow().withName("Please share about your Other role")
                          .withDefaultValue(this.registration().getString("companyRoleOtherDetails"))
                          .withMandatoryValue(true).withField("companyRoleOtherDetails"),
                        ];
      }
      var socialOther = [];
      if (this._model.showSocialOther()) {
        socialOther = [
                     new StringUpdateRow().withName("Who recommended us?")
                       .withDefaultValue(this.registration().getString("other"))
                       .withMandatoryValue(true).withField("other"),
                     ];
        
      }
      moreRows = [
        new RowDisplayRow()
          .withChildren([
            new BoxDisplayRow()
              .withWidth(1),
            new BoxDisplayRow()
              .withWidth(6)
              .withBorder(true)
              .withChildren([
                new TitleDisplayRow().withTitle("Your Information").withSubtitle(true).withWidth(15),
                new BoxDisplayRow()
                  .withWidth(15)
                  .withChildren([
                    new StringUpdateRow().withName("First Name").withMandatoryValue(true)
                      .withDefaultValue(this.registration().getString("userFirst"))
                      .withField("userFirst"),
                    new StringUpdateRow().withName("Last Name").withMandatoryValue(true)
                      .withDefaultValue(this.registration().getString("userLast"))
                      .withField("userLast"),
                    new EmailUpdateRow().withName("Email").withMandatoryValue(true)
                      .withDefaultValue(this.registration().getString("userEmail"))
                      .withField("userEmail"),
                    new PhoneNumberUpdateRow().withName("Phone").withMandatoryValue(true)
                      .withDefaultValue(this.registration().getString("userPhone"))
                      .withField("userPhone"),
                  ]),
              ]),
            new BoxDisplayRow()
              .withWidth(7)
              .withBorder(true)
              .withChildren([
                new TitleDisplayRow().withTitle("Company Information").withSubtitle(true).withWidth(15),
                new BoxDisplayRow()
                  .withWidth(15)
                  .withChildren([
                    new StringUpdateRow().withName("Company Name").withMandatoryValue(true).withField("companyName").withDefaultValue(this.registration().getString("companyName")),
                    new StringUpdateRow().withName("City").withMandatoryValue(true).withField("companyCity").withDefaultValue(this.registration().getString("companyCity")),
                    new StringListUpdateRow().withName("Province or State").withMandatoryValue(true)
                      .withField("companyProvince")
                      .withDefaultValue(this.registration().getString("companyProvince"))
                      .withOptions(ContentUtils.PROVINCES()),
                    new StringListUpdateRow().withName("Your role at the company").withMandatoryValue(true)
                      .withDefaultValue(this.registration().getString("companyRole"))
                      .withField("companyRole").withOptions(roles),
                    ...roleOther,
                    new StringListUpdateRow().withName("How many vehicles and trailers are in your fleet?").withMandatoryValue(true)
                      .withDefaultValue(this.registration().getString("fleetSize"))
                      .withField("fleetSize").withOptions(ContentUtils.FLEET_SIZE()),
                  ]),
              ]),
            new BoxDisplayRow()
              .withWidth(1),
          ]),
        new RowDisplayRow()
          .withChildren([
            new BoxDisplayRow()
              .withWidth(1)
              .withChildren([
                new SpacerDisplayRow(),
              ]),
            new BoxDisplayRow()
              .withWidth(6)
              .withBorder(true)
              .withChildren([
                new TitleDisplayRow().withTitle("Please let us know how you found ReadyChek").withSubtitle(true).withWidth(15),
                new StringListUpdateRow().withName("From a partner or social media?").withMandatoryValue(true)
                  .withDefaultValue(this.registration().getString("ads"))
                  .withField("ads").withOptions(ContentUtils.SOCIALS()).withDefaultValue(this.registration().getString("ads")),
                ...socialOther,
                new StringUpdateRow().withName("Do you have a Promo Code?")
                  .withDefaultValue(this.registration().getString("code"))
                  .withMandatoryValue(false).withField("code"),
              ]),
            new BoxDisplayRow()
              .withWidth(7)
              .withBorder(true)
              .withChildren([
                new TitleDisplayRow().withTitle("What would you like to use?").withSubtitle(true).withWidth(15),
                new BoxDisplayRow().withBorder(true).withChildren([
                  new CheckboxUpdateRow().withName("Commerical Vehicle Inspections").withMandatoryValue(true)
                    .withField("featureInspection")
                    .withDefaultValue(this.registration().getBoolean("featureInspection"))
                    .withHint("With this feature drivers have the option of recording daily pre-trip commercial vehicle inspections."),
                  ]),
                new BoxDisplayRow().withBorder(true).withChildren([
                  new CheckboxUpdateRow().withName("Hours of Service").withMandatoryValue(false)
                    .withField("featureHoursOfService")
                    .withDefaultValue(this.registration().getBoolean("featureHoursOfService"))
                    .withHint("With this feature drivers have the option of recording their duty status (Driving, Not Driving, Off Duty) each day."),
                ])
              ]),
            new BoxDisplayRow()
              .withWidth(1)
              .withChildren([
              ]),
          ]),
        new RowDisplayRow()
          .withChildren([
            new BoxDisplayRow()
              .withWidth(1)
              .withChildren([
                new SpacerDisplayRow(),
              ]),
            new BoxDisplayRow()
              .withWidth(13)
              .withBorder(true)
              .withChildren([
                new TitleDisplayRow().withTitle("Terms of Service").withSubtitle(true).withWidth(15),
                new CheckboxUpdateRow()
                  .withName("Click here to indicate that you have read and agree to the terms of the ReadyChek Software as a Service Agreement")
                  .withDefaultValue(this.registration().getBoolean("terms"))
                  .withHint("The agreement can be found by clicking here").withHintUrl("https://readychek.cloud:82/saas/2022/Software_Agreement_v1.2.pdf")
                  .withMandatoryValue(true).withField("terms"),
              ]),
            new BoxDisplayRow()
              .withWidth(1)
              .withChildren([
              ]),
          ]),
        new RowDisplayRow()
          .withChildren([
            new BoxDisplayRow()
              .withWidth(5)
              .withChildren([
              ]),
            new BoxDisplayRow()
              .withWidth(5)
              .withChildren([
                new StringDisplayRow().withValue("Yes I'd like to start my FREE 30 day trial!"),
                new ButtonDisplayRow().withWidth(5).withName("Send").withBackgroundPrimaryAction().withField(RegisterViewModel.Fields.SendButton),
                new StringDisplayRow().withValue("Credit card required after 30 days to continue").withStyle("rc-font-small"),
              ]),
            new BoxDisplayRow()
              .withWidth(2)
              .withChildren([
              ]),
            new BoxDisplayRow()
              .withWidth(2)
              .withChildren([
                new StringDisplayRow().withValue("No thanks!"),
                new ButtonDisplayRow().withWidth(5).withName("Back").withField(RegisterViewModel.Fields.BackButton),
              ]),
            new BoxDisplayRow()
              .withWidth(1)
              .withChildren([
              ]),
          ]),
        new SpacerDisplayRow(),
      ];
    }


    this.addRow(new RowDisplayRow()
      .withChildren([

        new BoxDisplayRow()
          .withWidth(2)
          .withChildren([]),
        new BoxDisplayRow()
          .withWidth(11)
          .withPrimaryBackgroundColour()
          .withBorder(true)
          .withBorderStyleLarge()
          .withChildren([
            new SpacerDisplayRow(),
            new RowDisplayRow()
              .withChildren([
                new BoxDisplayRow()
                  .withWidth(1)
                  .withChildren([]),
                new BoxDisplayRow()
                  .withWidth(13)
                  .withChildren([
                    new StringDisplayRow().withValue(hint + " ").withStyleEven().withStyle("rc-title-error-left rc-font-regular-large"),
                  ]),
                new BoxDisplayRow()
                  .withWidth(1)
                  .withChildren([]),
              ]),
            new RowDisplayRow()
              .withChildren([
                new BoxDisplayRow()
                  .withWidth(1)
                  .withChildren([]),
                new BoxDisplayRow()
                  .withWidth(7)
                  .withChildren([
                    new ImageDisplayRow().withImageUrl("@/assets/2019/circlechek_logo.png"),
                    signInOrWelcomeRow,
                  ]),
                new BoxDisplayRow()
                  .withWidth(6)
                  .withChildren(
                    mainKids,
                  ),
                new BoxDisplayRow()
                  .withWidth(1)
                  .withChildren([]),
              ]),
            ...moreRows,
          ]),
      ]));
  }

  addRow(row) {
    this._tableData.push(row);
  }
}