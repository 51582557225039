<template>
  <div class="rc-container">
  
    <Row v-if="isAdmin">
      <Column :width="15">
        <div class="rc-text-centre">
        Admin View - Dashboard
        </div>
      </Column>
    </Row>

    <ErrorRow :error="error" />

    <Row :showBorder="true" >
      <div class="rc-box-col-14">
        <Loading v-if="isLoadingToday || isLoadingMonthly || isLoadingThisMonth || isLoadingDailyStats || isLoading" imageSize="small"> {{ loadingMessage }} {{ loadingTodayMessage }} {{ loadingThisMonthMessage }} {{ loadingDailyMessage }} {{ loadingMonthlyMessage }}</Loading>
        <div v-else class="rc-text-value rc-text-left"> Last Update {{ domain ? domain.session().time().now().displayAs_YYYY_MM_DD_HH_MM_SS_A() : '' }} </div>
      </div>
      
      <div class="rc-box-col-1">
        <Button :disabled="isLoading" v-on:click="refreshStats">
        Refresh
        </Button>
      </div>
    </Row>
    
    <TitleRow>Invoices</TitleRow>
    <Row :showBorder="true">
      <div class="rc-box-col-7">
        <div class="rc-container">
          <TitleRow :subtitle="true">Invoices By Company Unpaid YTD</TitleRow>
          <StringValueRow label="Total" :value="Strings.toString(appStatsToday.invoicesUnpaidByCompanyYTD().size())" />
          <StringValueRow 
            label="Unpaid YTD" 
            :value="appStatsToday.invoiceYearUnpaidTotal()" 
            stylingValue="rc-text-right"
            labelWidth="rc-box-col-6" 
            valueWidth="rc-box-col-9" />
        </div>
        <div class="rc-container" style="overflow-y: scroll; height:250px;">
          <CompanyStatsRow 
            v-for="(companyStatsData, csIndex) in appStatsToday.invoicesUnpaidByCompanyYTD().sortByInvoiceAmountDescRaw()" 
            :companyStatsData="companyStatsData" 
            identifier="amount"
            :isMoney="true"
            :index="csIndex + 1"
            :key="csIndex+'_invoice_paid_ytd'"
            />
        </div>
      </div>
      
      <div class="rc-box-col-8">
        <div class="rc-container">
          <TitleRow :subtitle="true">Invoices By Company Unpaid LT</TitleRow>
          <StringValueRow label="Total" :value="Strings.toString(appStatsToday.invoicesUnpaidByCompanyLT().size())" />
          <StringValueRow 
            label="Unpaid LT" 
            :value="appStatsToday.invoiceLifeTimeUnpaidTotal()" 
            stylingValue="rc-text-right"
            labelWidth="rc-box-col-6" 
            valueWidth="rc-box-col-9" />
        </div>
        <div class="rc-container" style="overflow-y: scroll; height:250px;">
          <CompanyStatsRow 
            v-for="(companyStatsData, csIndex) in appStatsToday.invoicesUnpaidByCompanyLT().sortByInvoiceAmountDescRaw()" 
            :companyStatsData="companyStatsData" 
            identifier="amount"
            :isMoney="true"
            :index="csIndex + 1"
            :key="csIndex+'_invoice_unpaid_lt'"/>
        </div>
      </div>
    </Row>
    
    <Row :showBorder="true">
      <div class="rc-box-col-7">
        <div class="rc-container">
          <TitleRow :subtitle="true">Invoices By Company Paid YTD</TitleRow>
          <StringValueRow label="Total" :value="Strings.toString(appStatsToday.invoicesPaidByCompanyYTD().size())" />
          <StringValueRow 
            label="Total YTD" 
            :value="appStatsToday.invoiceYearTotal()" 
            stylingValue="rc-text-right"
            labelWidth="rc-box-col-6" 
            valueWidth="rc-box-col-9" />
        </div>
        <div class="rc-container" style="overflow-y: scroll; height:250px;">
          <CompanyStatsRow 
            v-for="(companyStatsData, csIndex) in appStatsToday.invoicesPaidByCompanyYTD().sortByInvoiceAmountDescRaw()" 
            :companyStatsData="companyStatsData" 
            identifier="amount"
            :isMoney="true"
            :index="csIndex + 1"
            :key="csIndex+'_invoice_paid_ytd'"
            />
        </div>
      </div>
      
      <div class="rc-box-col-8">
        <div class="rc-container">
          <TitleRow :subtitle="true">Invoices By Company Paid LT</TitleRow>
          <StringValueRow label="Total" :value="Strings.toString(appStatsToday.invoicesPaidByCompanyLT().size())" />
          <StringValueRow 
            label="Total LT" 
            :value="appStatsToday.invoiceLifeTimeTotal()" 
            stylingValue="rc-text-right"
            labelWidth="rc-box-col-6" 
            valueWidth="rc-box-col-9" />
        </div>
        <div class="rc-container" style="overflow-y: scroll; height:250px;">
          <CompanyStatsRow 
            v-for="(companyStatsData, csIndex) in appStatsToday.invoicesPaidByCompanyLT().sortByInvoiceAmountDescRaw()" 
            :companyStatsData="companyStatsData" 
            identifier="amount"
            :isMoney="true"
            :index="csIndex + 1"
            :key="csIndex+'_invoice_unpaid_lt'"/>
        </div>
      </div>
    </Row>
    
    <Row :showBorder="true">
      <div class="rc-box-col-7">
        <div class="rc-container">
          <TitleRow :subtitle="true">Invoices By Company Paid Last Month</TitleRow>
          <StringValueRow label="Total" :value="Strings.toString(appStatsToday.invoicesPaidByCompanyLM().size())" />
          <StringValueRow label="Paid" :value="appStatsToday.invoicesPaidTotalLM()"  stylingValue="rc-text-right"/>
        </div>
        <div class="rc-container" style="overflow-y: scroll; height:250px;">
          <CompanyStatsRow 
            v-for="(companyStatsData, csIndex) in appStatsToday.invoicesPaidByCompanyLM().sortByInvoiceAmountDescRaw()" 
            :companyStatsData="companyStatsData" 
            identifier="amount"
            :isMoney="true"
            :index="csIndex + 1"
            :key="csIndex+'_invoice_paid_tm'"
            />
        </div>
      </div>
      
      <div class="rc-box-col-8">
        <div class="rc-container">
          <TitleRow :subtitle="true">Invoices By Company Unpaid Last Month</TitleRow>
          <StringValueRow label="Total" :value="Strings.toString(appStatsToday.invoicesUnpaidByCompanyLM().size())" />
          <StringValueRow label="Unpaid" :value="appStatsToday.invoicesUnpaidTotalLM()" stylingValue="rc-text-right"/>
        </div>
        <div class="rc-container" style="overflow-y: scroll; height:250px;">
          <CompanyStatsRow 
            v-for="(companyStatsData, csIndex) in appStatsToday.invoicesUnpaidByCompanyLM().sortByInvoiceAmountDescRaw()" 
            :companyStatsData="companyStatsData" 
            identifier="amount"
            :isMoney="true"
            :index="csIndex + 1"
            :key="csIndex+'_invoice_unpaid_tm'"/>
        </div>
      </div>
    </Row>
    
    <SpacerRow />
    
    
    <Row :showBorder="true">
      <Column>
        <Box>
          <TitleRow :subtitle="false">Companies</TitleRow>
          <StringValueRow label="Total" :value="appStatsToday.companyTotal()" />
          <StringValueRow class="rowEven" label="Paying" :value="appStatsToday.companyPayingTotal()" />
          <StringValueRow label="New" :value="appStatsThisMonth.companyCountNew()" />
        </Box>
      </Column>
    </Row>
    
    <SpacerRow />
    
    <TitleRow :subtitle="false">Income</TitleRow>
    <Row :showBorder="true">
      <div class="rc-box-col-7">
        <div class="rc-container">
          <TitleRow :subtitle="true">Income from Today</TitleRow>
          <StringValueRow label="Inspections" :value="inspectionsIncomeToday" labelWidth="rc-box-col-12" valueWidth="rc-box-col-3" />
          <StringValueRow class="rowEven" label="Hours" :value="hoursIncomeToday" labelWidth="rc-box-col-12" valueWidth="rc-box-col-3" />
          <StringValueRow label="Check Ins" :value="checkInsIncomeToday" labelWidth="rc-box-col-12" valueWidth="rc-box-col-3" />
          <StringValueRow class="rowEven" label="Amount" :value="totalIncomeToday" labelWidth="rc-box-col-12" valueWidth="rc-box-col-3" />
        </div>
      </div>
      <div class="rc-box-col-8">
        <div class="rc-container">
          <TitleRow :subtitle="true">Records for Today</TitleRow>
          <StringValueRow label="Inspections" :value="appStatsToday.inspectionsTotal()" labelWidth="rc-box-col-12" valueWidth="rc-box-col-3" />
          <StringValueRow class="rowEven" label="Billable Inspections" :value="appStatsToday.inspectionsPaid()" labelWidth="rc-box-col-12" valueWidth="rc-box-col-3" />
          <StringValueRow label="Hours" :value="appStatsToday.timesheetsTotal()" labelWidth="rc-box-col-12" valueWidth="rc-box-col-3" />
          <StringValueRow class="rowEven" label="Billable Hours" :value="appStatsToday.timesheetsPaid()" labelWidth="rc-box-col-12" valueWidth="rc-box-col-3" />
          <StringValueRow label="Check Ins" :value="appStatsToday.checkInsTotal()" labelWidth="rc-box-col-12" valueWidth="rc-box-col-3" />
          <StringValueRow class="rowEven" label="Billable Check Ins" :value="appStatsToday.checkInsPaid()" labelWidth="rc-box-col-12" valueWidth="rc-box-col-3" />
        </div>
      </div>
    </Row>
    
    <SpacerRow />
    <Row :showBorder="true">
      <div class="rc-box-col-7">
        <div class="rc-container">
          <TitleRow :subtitle="true">Income This Month</TitleRow>
          <StringValueRow label="Vehicles" :value="vehicleIncome" labelWidth="rc-box-col-12" valueWidth="rc-box-col-3" />
          <StringValueRow class="rowEven" label="Inspections" :value="inspectionIncomeThisMonth" labelWidth="rc-box-col-12" valueWidth="rc-box-col-3" />
          <StringValueRow label="Hours" :value="timesheetIncomeThisMonth" labelWidth="rc-box-col-12" valueWidth="rc-box-col-3" />
          <StringValueRow label="Amount" :value="totalIncomeThisMonth" labelWidth="rc-box-col-12" valueWidth="rc-box-col-3" />
        </div>
      </div>
      <div class="rc-box-col-8">
        <div class="rc-container">
          <TitleRow :subtitle="true">Records This Month</TitleRow>
          <StringValueRow label="Vehicles" :value="appStatsThisMonth.vehiclesBillableDisplay()" labelWidth="rc-box-col-12" valueWidth="rc-box-col-3" />
          <StringValueRow class="rowEven" label="Inspections" :value="appStatsThisMonth.inspectionsTotal()" labelWidth="rc-box-col-12" valueWidth="rc-box-col-3" />
          <StringValueRow label="Billable Inspections" :value="appStatsThisMonth.inspectionsPaid()" labelWidth="rc-box-col-12" valueWidth="rc-box-col-3" />
          <StringValueRow label="Hours" :value="appStatsThisMonth.timesheetsTotal()" labelWidth="rc-box-col-12" valueWidth="rc-box-col-3" />
          <StringValueRow class="rowEven" label="Billable Hours" :value="appStatsThisMonth.timesheetsPaid()" labelWidth="rc-box-col-12" valueWidth="rc-box-col-3" />
        </div>
      </div>
    </Row>
    
    <SpacerRow />
    <Row :showBorder="true">
      <div class="rc-box-col-15">
        <div class="rc-container">
          <TitleRow :subtitle="true">Projections for This Month</TitleRow>
          <StringValueRow class="rowEven" label="Inspections" :value="projectedInspections()" labelWidth="rc-box-col-12" valueWidth="rc-box-col-3" />
          <StringValueRow label="Hours" :value="projectedHours()" labelWidth="rc-box-col-12" valueWidth="rc-box-col-3" />
          <StringValueRow class="rowEven" label="$/Day" :value="dailyAverage" labelWidth="rc-box-col-12" valueWidth="rc-box-col-3" />        
          <StringValueRow class="rowEven" label="Income" :value="projection" labelWidth="rc-box-col-12" valueWidth="rc-box-col-3" />        
        </div>
      </div>
    </Row>
    
    <SpacerRow />
    <TitleRow :subtitle="false">Inspections Overview</TitleRow>
    
    <StringValueRow label="Today" :value="appStatsToday.inspectionsTotal()" labelWidth="rc-box-col-3" valueWidth="rc-box-col-12" />
    <StringValueRow class="rowEven" label="Month" :value="appStatsThisMonth.inspectionsTotal()" labelWidth="rc-box-col-3" valueWidth="rc-box-col-12" />
    <StringValueRow label="Billable" :value="appStatsThisMonth.inspectionsPaid()" labelWidth="rc-box-col-3" valueWidth="rc-box-col-12" />
    <StringValueRow class="rowEven" label="Amount" :value="inspectionIncomeThisMonth" labelWidth="rc-box-col-3" valueWidth="rc-box-col-12" />

    <Row :showBorder="true">

      <div class="rc-box-col-7">
        <div class="rc-container">
          <TitleRow :subtitle="true">Inspections By Company Today</TitleRow>
          <StringValueRow label="Total" :value="Strings.toString(appStatsThisMonth.inspectionsByCompanyToday().size())" />
        </div>
        <div class="rc-container" style="overflow-y: scroll; height:250px;">
          <CompanyStatsRow 
            v-for="(companyStatsData, csIndex) in appStatsThisMonth.inspectionsByCompanyToday().sortByInspectionCountDescRaw()" 
            :companyStatsData="companyStatsData" 
            identifier="inspectionCount"
            :index="csIndex + 1"
            :key="csIndex+'_inspections_today'"
            />
        </div>
      </div>
      
      <div class="rc-box-col-8">
        <div class="rc-container">
          <TitleRow :subtitle="true">Inspections By Company This Month</TitleRow>
          <StringValueRow label="Total" :value="Strings.toString(appStatsThisMonth.inspectionsByCompanyThisMonth().size())" />
        </div>
        <div class="rc-container" style="overflow-y: scroll; height:250px;">
          <CompanyStatsRow 
            v-for="(companyStatsData, csIndex) in appStatsThisMonth.inspectionsByCompanyThisMonth().sortByInspectionCountDescRaw()" 
            :companyStatsData="companyStatsData" 
            identifier="inspectionCount"
            :index="csIndex + 1"
            v-bind:key="csIndex+'_inspections_month'"/>
        </div>
      </div>
        
    </Row>
    
    <SpacerRow />
    <TitleRow :subtitle="false">Hours of Service Overview</TitleRow>
    <StringValueRow label="Today" :value="appStatsToday.timesheetsTotal()" labelWidth="rc-box-col-3" valueWidth="rc-box-col-12" />
    <StringValueRow class="rowEven" label="Month" :value="appStatsThisMonth.timesheetsTotal()" labelWidth="rc-box-col-3" valueWidth="rc-box-col-12" />
    <StringValueRow label="Billable" :value="appStatsThisMonth.timesheetsPaid()" labelWidth="rc-box-col-3" valueWidth="rc-box-col-12" />
    <StringValueRow class="rowEven" label="Amount" :value="timesheetIncomeThisMonth" labelWidth="rc-box-col-3" valueWidth="rc-box-col-12" />
    
    <Row :showBorder="true">
    
      <div class="rc-box-col-7">
        <div class="rc-container">
          <TitleRow :subtitle="true">Hours of Service By Company Today</TitleRow>
          <StringValueRow label="Total" :value="Strings.toString(appStatsThisMonth.timesheetsByCompanyToday().size())" />
        </div>
        <div class="rc-container" style="overflow-y: scroll; height:250px;">
          <CompanyStatsRow 
            v-for="(companyStatsData, csIndex) in appStatsThisMonth.timesheetsByCompanyToday().sortByTimesheetCountDescRaw()" 
            :companyStatsData="companyStatsData" 
            identifier="timesheetCount"
            :index="csIndex + 1"
            v-bind:key="csIndex+'_timesheets_today'"
            />
        </div>
      </div>
      
      <div class="rc-box-col-8">
        <div class="rc-container">
          <TitleRow :subtitle="true">Hours of Service By Company This Month</TitleRow>
          <StringValueRow label="Total" :value="Strings.toString(appStatsThisMonth.timesheetsByCompanyThisMonth().size())" />
        </div>
        <div class="rc-container" style="overflow-y: scroll; height:250px;">
          <CompanyStatsRow 
            v-for="(companyStatsData, csIndex) in appStatsThisMonth.timesheetsByCompanyThisMonth().sortByTimesheetCountDescRaw()" 
            :companyStatsData="companyStatsData" 
            identifier="timesheetCount"
            :index="csIndex + 1"
            v-bind:key="csIndex+'_timesheets_month'"/>
        </div>
      </div>
        
    </Row>
    
    
    
    <SpacerRow />
    <TitleRow :subtitle="false"> Check-Ins Overview</TitleRow>
    <StringValueRow class="rowEven" label="Today" :value="appStatsToday.checkInsTotal()" labelWidth="rc-box-col-3" valueWidth="rc-box-col-12" />
    <StringValueRow label="Month" :value="appStatsThisMonth.checkInsTotal()" labelWidth="rc-box-col-3" valueWidth="rc-box-col-12" />
    <StringValueRow class="rowEven" label="Billable" :value="appStatsThisMonth.checkInsPaid()" labelWidth="rc-box-col-3" valueWidth="rc-box-col-12" />
    <StringValueRow label="Amount" :value="checkInIncomeThisMonth" labelWidth="rc-box-col-3" valueWidth="rc-box-col-12" />
    
    <Row :showBorder="true">
  
      <div class="rc-box-col-7">
        <div class="rc-container">
          <TitleRow :subtitle="true">Check-Ins By Company Today</TitleRow>
          <StringValueRow label="Total" :value="Strings.toString(appStatsThisMonth.checkinsByCompanyToday().size())" />
        </div>
        <div class="rc-container" style="overflow-y: scroll; height:250px;">
          <CompanyStatsRow 
            v-for="(companyStatsData, csIndex) in appStatsThisMonth.checkinsByCompanyToday().sortByCheckInCountDescRaw()" 
            :companyStatsData="companyStatsData" 
            identifier="checkInCount"
            :index="csIndex + 1"
            v-bind:key="csIndex+'_checkins_today'"
            />
        </div>
      </div>
      
      <div class="rc-box-col-8">
        <div class="rc-container">
          <TitleRow :subtitle="true">CheckIns By Company This Month</TitleRow>
          <StringValueRow label="Total" :value="Strings.toString(appStatsThisMonth.checkinsByCompanyThisMonth().size())" />
        </div>
        <div class="rc-container" style="overflow-y: scroll; height:250px;">
          <CompanyStatsRow 
            v-for="(companyStatsData, csIndex) in appStatsThisMonth.checkinsByCompanyThisMonth().sortByCheckInCountDescRaw()" 
            :companyStatsData="companyStatsData" 
            identifier="checkInCount"
            :index="csIndex + 1"
            v-bind:key="csIndex+'_checkins_month'"/>
        </div>
      </div>
        
    </Row>
    
    
    <!-- Trends -->
    <SpacerRow />
    <TitleRow :subtitle="false">Trends Last 15 Days</TitleRow>
    
    <Row :showBorder="true">
      <div class="rc-box-col-2 rc-text-label rc-text-right">Date</div>
      <div class="rc-box-col-13">
          <div class="rc-container">
            <div class="rc-row">
              <div v-for="(item, index) in statsListDaily" 
                  v-bind:key="item.id()" 
                  :class="Strings.isEven(index) ? 'rowEven rc-box-col-1' : 'rowOdd rc-box-col-1'" 
                  class=" rc-text-value rc-text-centre"> {{ item.isNew() ? item.createdDateV2().displayAs_MM_DD() : item.createdDateV2().day().previous().displayAs_MM_DD() }} 
              </div>
            </div>
          </div>
      </div>
    </Row>
    
    <Row :showBorder="true">
      <div class="rc-box-col-2 rc-text-label rc-text-right">Inspections</div>
      <div class="rc-box-col-13">
          <div class="rc-container">
            <div class="rc-row">
              <div v-for="(item, index) in statsListDaily" 
                  v-bind:key="item.id()" 
                  :class="Strings.isEven(index) ? 'rowEven rc-box-col-1' : 'rowOdd rc-box-col-1'" 
                  class=" rc-text-value rc-text-centre"> {{ item.inspectionsTotal() }} 
              </div>
            </div>
          </div>
      </div>
    </Row>
    <Row :showBorder="true">
      <div class="rc-box-col-2 rc-text-label rc-text-right">Billable Inspections</div>
      <div class="rc-box-col-13">
          <div class="rc-container">
            <div class="rc-row">
              <div v-for="(item, index) in statsListDaily" 
                  v-bind:key="item.id()" 
                  :class="Strings.isEven(index) ? 'rowEven rc-box-col-1' : 'rowOdd rc-box-col-1'" 
                  class=" rc-text-value rc-text-centre"> {{ item.inspectionsPaidCount() }} 
              </div>
            </div>
          </div>
      </div>
    </Row>
    <Row :showBorder="true">
      <div class="rc-box-col-2 rc-text-label rc-text-right">Timesheets</div>
      <div class="rc-box-col-13">
          <div class="rc-container">
            <div class="rc-row">
              <div v-for="(item, index) in statsListDaily" 
                  v-bind:key="item.id()" 
                  :class="Strings.isEven(index) ? 'rowEven rc-box-col-1' : 'rowOdd rc-box-col-1'" 
                  class=" rc-text-value rc-text-centre"> {{ item.timesheetsTotal() }} 
              </div>
            </div>
          </div>
      </div>
    </Row>
    <Row :showBorder="true">
      <div class="rc-box-col-2 rc-text-label rc-text-right">Billable Timesheets</div>
      <div class="rc-box-col-13">
          <div class="rc-container">
            <div class="rc-row">
              <div v-for="(item, index) in statsListDaily" 
                  v-bind:key="item.id()" 
                  :class="Strings.isEven(index) ? 'rowEven rc-box-col-1' : 'rowOdd rc-box-col-1'" 
                  class=" rc-text-value rc-text-centre"> {{ item.timesheetsPaidCount() }} 
              </div>
            </div>
          </div>
      </div>
    </Row>
    <Row :showBorder="true">
      <div class="rc-box-col-2 rc-text-label rc-text-right">Check-Ins</div>
      <div class="rc-box-col-13">
          <div class="rc-container">
            <div class="rc-row">
              <div v-for="(item, index) in statsListDaily" 
                  v-bind:key="item.id()" 
                  :class="Strings.isEven(index) ? 'rowEven rc-box-col-1' : 'rowOdd rc-box-col-1'" 
                  class=" rc-text-value rc-text-centre"> {{ item.checkInsTotal() }} 
              </div>
            </div>
          </div>
      </div>
    </Row>
    <Row :showBorder="true">
      <div class="rc-box-col-2 rc-text-label rc-text-right">Billable Check-Ins</div>
      <div class="rc-box-col-13">
          <div class="rc-container">
            <div class="rc-row">
              <div v-for="(item, index) in statsListDaily" 
                  v-bind:key="item.id()" 
                  :class="Strings.isEven(index) ? 'rowEven rc-box-col-1' : 'rowOdd rc-box-col-1'" 
                  class=" rc-text-value rc-text-centre"> {{ item.checkInsPaidCount() }} 
              </div>
            </div>
          </div>
      </div>
    </Row>
  
    <Row :showBorder="true">
      <div class="rc-box-col-2 rc-text-label rc-text-right">Users Active</div>
      <div class="rc-box-col-13">
          <div class="rc-container">
            <div class="rc-row">
              <div v-for="(item, index) in statsListDaily" 
                  v-bind:key="item.id()" 
                  :class="Strings.isEven(index) ? 'rowEven rc-box-col-1' : 'rowOdd rc-box-col-1'" 
                  class=" rc-text-value rc-text-centre"> {{ item.users() }} 
              </div>
            </div>
          </div>
      </div>
    </Row>
  
    <Row :showBorder="true">
      <div class="rc-box-col-2 rc-text-label rc-text-right">Users Invited</div>
      <div class="rc-box-col-13">
          <div class="rc-container">
            <div class="rc-row">
              <div v-for="(item, index) in statsListDaily" 
                  v-bind:key="item.id()" 
                  :class="Strings.isEven(index) ? 'rowEven rc-box-col-1' : 'rowOdd rc-box-col-1'" 
                  class=" rc-text-value rc-text-centre"> {{ item.usersInvited() }} 
              </div>
            </div>
          </div>
      </div>
    </Row>
  
    <Row :showBorder="true">
      <div class="rc-box-col-2 rc-text-label rc-text-right">Users Inactive</div>
      <div class="rc-box-col-13">
          <div class="rc-container">
            <div class="rc-row">
              <div v-for="(item, index) in statsListDaily" 
                  v-bind:key="item.id()" 
                  :class="Strings.isEven(index) ? 'rowEven rc-box-col-1' : 'rowOdd rc-box-col-1'" 
                  class=" rc-text-value rc-text-centre"> {{ item.usersInactive() }} 
              </div>
            </div>
          </div>
      </div>
    </Row>
    
    <Row :showBorder="true">
      <div class="rc-box-col-2 rc-text-label rc-text-right">Vehicles</div>
      <div class="rc-box-col-13">
          <div class="rc-container">
            <div class="rc-row">
              <div v-for="(item, index) in statsListDaily" 
                  v-bind:key="item.id()" 
                  :class="Strings.isEven(index) ? 'rowEven rc-box-col-1' : 'rowOdd rc-box-col-1'" 
                  class=" rc-text-value rc-text-centre"> {{ item.vehicles() }} 
              </div>
            </div>
          </div>
      </div>
    </Row>
    
    <Row :showBorder="true">
      <div class="rc-box-col-2 rc-text-label rc-text-right">Trailers</div>
      <div class="rc-box-col-13">
          <div class="rc-container">
            <div class="rc-row">
              <div v-for="(item, index) in statsListDaily" 
                  v-bind:key="item.id()" 
                  :class="Strings.isEven(index) ? 'rowEven rc-box-col-1' : 'rowOdd rc-box-col-1'" 
                  class=" rc-text-value rc-text-centre"> {{ item.trailers() }} 
              </div>
            </div>
          </div>
      </div>
    </Row>
    
    <Row :showBorder="true">
      <div class="rc-box-col-2 rc-text-label rc-text-right">Companys</div>
      <div class="rc-box-col-13">
          <div class="rc-container">
            <div class="rc-row">
              <div v-for="(item, index) in statsListDaily" 
                  v-bind:key="item.id()" 
                  :class="Strings.isEven(index) ? 'rowEven rc-box-col-1' : 'rowOdd rc-box-col-1'" 
                  class=" rc-text-value rc-text-centre"> {{ item.companyTotal() }} 
              </div>
            </div>
          </div>
      </div>
    </Row>
  
    <Row :showBorder="true">
      <div class="rc-box-col-2 rc-text-label rc-text-right">Paying Companys</div>
      <div class="rc-box-col-13">
          <div class="rc-container">
            <div class="rc-row">
              <div v-for="(item, index) in statsListDaily" 
                  v-bind:key="item.id()" 
                  :class="Strings.isEven(index) ? 'rowEven rc-box-col-1' : 'rowOdd rc-box-col-1'" 
                  class=" rc-text-value rc-text-centre"> {{ item.companyPayingTotal() }} 
              </div>
            </div>
          </div>
      </div>
    </Row>

    <Row :showBorder="true">
      <div class="rc-box-col-2 rc-text-label rc-text-right">New Companys</div>
      <div class="rc-box-col-13">
          <div class="rc-container">
            <div class="rc-row">
              <div v-for="(item, index) in statsListDaily" 
                  v-bind:key="item.id()" 
                  :class="Strings.isEven(index) ? 'rowEven rc-box-col-1' : 'rowOdd rc-box-col-1'" 
                  class=" rc-text-value rc-text-centre"> {{ item.companyCountNew() }} 
              </div>
            </div>
          </div>
      </div>
    </Row>

    <TitleRow :subtitle="false">Trends Last 15 Months</TitleRow>
    <div class="row rc-background-dark-top-bottom">
      <div class="rc-box-col-2 rc-text-label rc-text-right">Date</div>
      <div class="rc-box-col-13">
          <div class="rc-container">
            <div class="rc-row">
              <div v-for="(item, index) in statsListMonthly" 
                  v-bind:key="item.id()" 
                  :class="Strings.isEven(index) ? 'rowEven rc-box-col-1' : 'rowOdd rc-box-col-1'" 
                  class=" rc-text-value rc-text-centre"> {{ item.isNew() ? item.createdDateV2().month().name(true) : item.createdDateV2().month().previous().name(true) }} 
              </div>
            </div>
          </div>
      </div>
    </div>
    <div class="row rc-background-dark-top-bottom">
      <div class="rc-box-col-2 rc-text-label rc-text-right">Inspections</div>
      <div class="rc-box-col-13">
          <div class="rc-container">
            <div class="rc-row">
              <div v-for="(item, index) in statsListMonthly" 
                  v-bind:key="item.id()" 
                  :class="Strings.isEven(index) ? 'rowEven rc-box-col-1' : 'rowOdd rc-box-col-1'" 
                  class=" rc-text-value rc-text-centre"> {{ item.inspectionsTotal() }} 
              </div>
            </div>
          </div>
      </div>
    </div>
    <div class="row rc-background-dark-top-bottom">
      <div class="rc-box-col-2 rc-text-label rc-text-right">Billable Inspections</div>
      <div class="rc-box-col-13">
          <div class="rc-container">
            <div class="rc-row">
              <div v-for="(item, index) in statsListMonthly" 
                  v-bind:key="item.id()" 
                  :class="Strings.isEven(index) ? 'rowEven rc-box-col-1' : 'rowOdd rc-box-col-1'" 
                  class=" rc-text-value rc-text-centre"> {{ item.inspectionsPaid() }} 
              </div>
            </div>
          </div>
      </div>
    </div>
    <div class="row rc-background-dark-top-bottom">
      <div class="rc-box-col-2 rc-text-label rc-text-right">Timesheets</div>
      <div class="rc-box-col-13">
          <div class="rc-container">
            <div class="rc-row">
              <div v-for="(item, index) in statsListMonthly" 
                  v-bind:key="item.id()" 
                  :class="Strings.isEven(index) ? 'rowEven rc-box-col-1' : 'rowOdd rc-box-col-1'" 
                  class=" rc-text-value rc-text-centre"> {{ item.timesheetsTotal() }} 
              </div>
            </div>
          </div>
      </div>
    </div>
    <div class="row rc-background-dark-top-bottom">
      <div class="rc-box-col-2 rc-text-label rc-text-right">Billable Timesheets</div>
      <div class="rc-box-col-13">
          <div class="rc-container">
            <div class="rc-row">
              <div v-for="(item, index) in statsListMonthly" 
                  v-bind:key="item.id()" 
                  :class="Strings.isEven(index) ? 'rowEven rc-box-col-1' : 'rowOdd rc-box-col-1'" 
                  class=" rc-text-value rc-text-centre"> {{ item.timesheetsPaid() }} 
              </div>
            </div>
          </div>
      </div>
    </div>
    <div class="row rc-background-dark-top-bottom">
      <div class="rc-box-col-2 rc-text-label rc-text-right">Check-Ins</div>
      <div class="rc-box-col-13">
          <div class="rc-container">
            <div class="rc-row">
              <div v-for="(item, index) in statsListMonthly" 
                  v-bind:key="item.id()" 
                  :class="Strings.isEven(index) ? 'rowEven rc-box-col-1' : 'rowOdd rc-box-col-1'" 
                  class=" rc-text-value rc-text-centre"> {{ item.checkInsTotal() }} 
              </div>
            </div>
          </div>
      </div>
    </div>
    <div class="row rc-background-dark-top-bottom">
      <div class="rc-box-col-2 rc-text-label rc-text-right">Billable Check-Ins</div>
      <div class="rc-box-col-13">
          <div class="rc-container">
            <div class="rc-row">
              <div v-for="(item, index) in statsListMonthly" 
                  v-bind:key="item.id()" 
                  :class="Strings.isEven(index) ? 'rowEven rc-box-col-1' : 'rowOdd rc-box-col-1'" 
                  class=" rc-text-value rc-text-centre"> {{ item.checkInsPaid() }} 
              </div>
            </div>
          </div>
      </div>
    </div>
    <div class="row rc-background-dark-top-bottom">
      <div class="rc-box-col-2 rc-text-label rc-text-right">Users Active</div>
      <div class="rc-box-col-13">
          <div class="rc-container">
            <div class="rc-row">
              <div v-for="(item, index) in statsListMonthly" 
                  v-bind:key="item.id()" 
                  :class="Strings.isEven(index) ? 'rowEven rc-box-col-1' : 'rowOdd rc-box-col-1'" 
                  class=" rc-text-value rc-text-centre"> {{ item.users() }} 
              </div>
            </div>
          </div>
      </div>
    </div>
    <div class="row rc-background-dark-top-bottom">
      <div class="rc-box-col-2 rc-text-label rc-text-right">Users Invited</div>
      <div class="rc-box-col-13">
          <div class="rc-container">
            <div class="rc-row">
              <div v-for="(item, index) in statsListMonthly" 
                  v-bind:key="item.id()" 
                  :class="Strings.isEven(index) ? 'rowEven rc-box-col-1' : 'rowOdd rc-box-col-1'" 
                  class=" rc-text-value rc-text-centre"> {{ item.usersInvited() }} 
              </div>
            </div>
          </div>
      </div>
    </div>
    <div class="row rc-background-dark-top-bottom">
      <div class="rc-box-col-2 rc-text-label rc-text-right">Users Inactive</div>
      <div class="rc-box-col-13">
          <div class="rc-container">
            <div class="rc-row">
              <div v-for="(item, index) in statsListMonthly" 
                  v-bind:key="item.id()" 
                  :class="Strings.isEven(index) ? 'rowEven rc-box-col-1' : 'rowOdd rc-box-col-1'" 
                  class=" rc-text-value rc-text-centre"> {{ item.usersInactive() }} 
              </div>
            </div>
          </div>
      </div>
    </div>
    <div class="row rc-background-dark-top-bottom">
      <div class="rc-box-col-2 rc-text-label rc-text-right">Vehicles</div>
      <div class="rc-box-col-13">
          <div class="rc-container">
            <div class="rc-row">
              <div v-for="(item, index) in statsListMonthly" 
                  v-bind:key="item.id()" 
                  :class="Strings.isEven(index) ? 'rowEven rc-box-col-1' : 'rowOdd rc-box-col-1'" 
                  class=" rc-text-value rc-text-centre"> {{ item.vehicles() }} 
              </div>
            </div>
          </div>
      </div>
    </div>
    
    <div class="row rc-background-dark-top-bottom">
      <div class="rc-box-col-2 rc-text-label rc-text-right">Trailers</div>
      <div class="rc-box-col-13">
          <div class="rc-container">
            <div class="rc-row">
              <div v-for="(item, index) in statsListMonthly" 
                  v-bind:key="item.id()" 
                  :class="Strings.isEven(index) ? 'rowEven rc-box-col-1' : 'rowOdd rc-box-col-1'" 
                  class=" rc-text-value rc-text-centre"> {{ item.trailers() }} 
              </div>
            </div>
          </div>
      </div>
    </div>
  
    <div class="row rc-background-dark-top-bottom">
      <div class="rc-box-col-2 rc-text-label rc-text-right">Billable Vehicles</div>
      <div class="rc-box-col-13">
          <div class="rc-container">
            <div class="rc-row">
              <div v-for="(item, index) in statsListMonthly" 
                  v-bind:key="item.id()" 
                  :class="Strings.isEven(index) ? 'rowEven rc-box-col-1' : 'rowOdd rc-box-col-1'" 
                  class=" rc-text-value rc-text-centre"> {{ item.vehiclesBillableCount() }} 
              </div>
            </div>
          </div>
      </div>
    </div>
  
    <div class="row rc-background-dark-top-bottom">
      <div class="rc-box-col-2 rc-text-label rc-text-right">Companys</div>
      <div class="rc-box-col-13">
          <div class="rc-container">
            <div class="rc-row">
              <div v-for="(item, index) in statsListMonthly" 
                  v-bind:key="item.id()" 
                  :class="Strings.isEven(index) ? 'rowEven rc-box-col-1' : 'rowOdd rc-box-col-1'" 
                  class=" rc-text-value rc-text-centre"> {{ item.companyTotal() }} 
              </div>
            </div>
          </div>
      </div>
    </div>
  
    <div class="row rc-background-dark-top-bottom">
      <div class="rc-box-col-2 rc-text-label rc-text-right">Paying Companys</div>
      <div class="rc-box-col-13">
          <div class="rc-container">
            <div class="rc-row">
              <div v-for="(item, index) in statsListMonthly" 
                  v-bind:key="item.id()" 
                  :class="Strings.isEven(index) ? 'rowEven rc-box-col-1' : 'rowOdd rc-box-col-1'" 
                  class=" rc-text-value rc-text-centre"> {{ item.companyPayingTotal() }} 
              </div>
            </div>
          </div>
      </div>
    </div>
  
    <div class="row rc-background-dark-top-bottom">
      <div class="rc-box-col-2 rc-text-label rc-text-right">New Companys</div>
      <div class="rc-box-col-13">
          <div class="rc-container">
            <div class="rc-row">
              <div v-for="(item, index) in statsListMonthly" 
                  v-bind:key="item.id()" 
                  :class="Strings.isEven(index) ? 'rowEven rc-box-col-1' : 'rowOdd rc-box-col-1'" 
                  class=" rc-text-value rc-text-centre"> {{ item.companyCountNew() }} 
              </div>
            </div>
          </div>
      </div>
    </div>
  
    <div class="row rc-background-dark-top-bottom">
      <div class="rc-box-col-2 rc-text-label rc-text-right">Invoice Month Total</div>
      <div class="rc-box-col-13">
          <div class="rc-container">
            <div class="rc-row">
              <div v-for="(item, index) in statsListMonthly" 
                  v-bind:key="item.id()" 
                  :class="Strings.isEven(index) ? 'rowEven rc-box-col-1' : 'rowOdd rc-box-col-1'" 
                  class=" rc-text-value rc-text-centre"> {{ item.invoiceMonthTotal() }} 
              </div>
            </div>
          </div>
      </div>
    </div>
    
    <div class="row rc-background-dark-top-bottom">
      <div class="rc-box-col-2 rc-text-label rc-text-right">Invoice YTD Total</div>
      <div class="rc-box-col-13">
          <div class="rc-container" >
            <div class="rc-row">
              <div v-for="(item, index) in statsListMonthly" 
                  v-bind:key="item.id()" 
                  :class="Strings.isEven(index) ? 'rowEven rc-box-col-1' : 'rowOdd rc-box-col-1'" 
                  class=" rc-text-value rc-text-centre"> {{ item.invoiceYearTotal() }} 
              </div>
            </div>
          </div>
      </div>
    </div>
    <!-- /Trends -->
    
    <SpacerRow />
    <TitleRow :subtitle="false">Vehicles and Trailers</TitleRow>
    <div class="rc-row">
      <div class="rc-box-col-7">
        <div class="rc-container">
          <TitleRow :subtitle="true">Overview</TitleRow>
          <StringValueRow label="Vehicles" :value="appStatsToday.vehicles()" />
          <StringValueRow class="rowEven" label="Trailers" :value="appStatsToday.trailers()" />
          <StringValueRow label="Billable" :value="appStatsToday.vehiclesBillableDisplay()" />
          <StringValueRow class="rowEven" label="Amount" :value="vehicleIncome" />

        </div>
      </div>
      <div class="rc-box-col-8">
        <div class="rc-container" style="overflow-y: scroll; height:250px;">
          <TitleRow :subtitle="true">Vehicles By Company</TitleRow>
          <CompanyStatsRow 
            v-for="(companyStatsData, csIndex) in appStatsThisMonth.vehiclesByCompanyThisMonth().sortByVehicleCountDescRaw()" 
            :companyStatsData="companyStatsData" 
            identifier="vehicleCount"
            :index="csIndex + 1"
            v-bind:key="csIndex+'_vehicles_month'"/>
        </div>
      </div>
    </div>
    
    <SpacerRow />
    <TitleRow :subtitle="false">Employees</TitleRow>
    <div class="rc-row">
      <div class="rc-box-col-7">
        <div class="rc-container">
          <TitleRow :subtitle="true">Overview</TitleRow>
          <StringValueRow label="Total" :value="appStatsToday.users()" />
        </div>
      </div>
      <div class="rc-box-col-8">
        <div class="rc-container" style="overflow-y: scroll; height:250px;">
          <TitleRow :subtitle="true">Employees By Company</TitleRow>
          <CompanyStatsRow 
            v-for="(companyStatsData, csIndex) in appStatsThisMonth.employeesByCompanyThisMonth().sortByEmployeeCountDescRaw()" 
            :companyStatsData="companyStatsData" 
            identifier="employeeCount"
            :index="csIndex + 1"
            v-bind:key="csIndex+'_employees_month'"/>
        </div>
      </div>
    </div>

    <LoadingRow :showBorder="true" v-if="isLoading">{{ loadingMessage }} </LoadingRow>

  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ConnectionUtils from '@/utils/ConnectionUtils.js';
import MC from "@/domain/session/MC.js";

import ConstUtils from '@/utils/ConstUtils.js';
import StringUtils from '@/utils/StringUtils.js';
import DateUtils from '@/utils/DateUtils.js';

import ErrorRow from '@/components/row/ErrorRow.vue';
import StringValueRow from '@/components/row/StringValueRow.vue';

import CompanyStatsRow from '@/portals/shared/row/CompanyStatsRow.vue';

import AppStats from '@/domain/model/app/AppStats.js';
import AppStatsMap from "@/domain/model/app/AppStatsMap.js";

import AppStatsListFilter from "@/domain/model/app/AppStatsListFilter.js";
//import Company from "@/domain/model/company/Company.js";

import Box from "@/portals/shared/library/box/Box.vue";
import Row from "@/portals/shared/library/box/Row.vue";
import Column from "@/portals/shared/library/box/Column.vue";

import TitleRow from "@/portals/shared/library/title/TitleRow.vue";

import SpacerRow from "@/portals/shared/library/spacer/SpacerRow.vue";

import Button from "@/portals/shared/library/button/Button.vue";
import Loading from "@/portals/shared/library/loading/Loading.vue";  
import LoadingRow from "@/portals/shared/library/loading/LoadingRow.vue";


export default {
  name: "home",
  components: {
    TitleRow, Loading, LoadingRow,
    SpacerRow,
    ErrorRow,
    StringValueRow,
    CompanyStatsRow,
    Button,
    Box, Row, Column, 
  },
  data() {
    return {
      isLoading: false,
      loadingMessage: null,
      isAdmin: true,

      isLoadingToday: false,
      loadingTodayMessage: null,
      
      isLoadingThisMonth: false,
      loadingThisMonthMessage: null,
      
      isLoadingDaily: false,
      loadingDailyMessage: null,
      
      isLoadingMonthly: false,
      loadingMonthlyMessage: null,
      
      MC: new MC(),
      Consts: ConstUtils,
      
      isLoadingDailyStats: false,
      isLoadingMonthlyStats: false,
      error: null,
      count: 0,
      pageNumber: 0,
      pageSize: 14,
      statsListDaily: [],
      statsListMonthly: [],
      appStatsToday: new AppStats(null, {}),
      appStatsThisMonth: new AppStats(null, {}),
      Strings: StringUtils,
      
      showSessionTimeoutDialog: false,
    };
  },
  computed: {
    ...mapGetters([
        'auth_connected',
        'auth_socket_status',
        'signin_event',
        'auth_client',
        'domain',
    ]),
    appStats: function() {
      return this.appStatsToday;
    },
    countDown: function() {
      return this.count.toString();
    },
    inspectionIncome: function() {
      var value = this.appStatsToday.inspectionsPaidCount() * 0.50;
      return "$" + value.toFixed(2).toString();
    },
    inspectionIncomeThisMonth: function() {
      var value = this.appStatsThisMonth.inspectionsPaidCount() * 0.50;
      return "$" + value.toFixed(2).toString();
    },
    inspectionsIncomeToday: function() {
      var total = this.appStatsToday.inspectionsPaidCount();
      var inspections = total * 0.50;
      return "$" + inspections.toFixed(2).toString();
    },
    inspectionsToday: function() {
      var total = this.appStatsToday.inspectionsTotal();
      return total;
    },

    checkInsIncomeToday: function() {
      var total = this.appStatsToday.checkInsPaidCount();
      var checkIns = total * 0.15;
      return "$" + checkIns.toFixed(2).toString(); 
    },
    checkInsToday: function() {
      var total = this.appStatsToday.checkInsTotal();
      return total;
    },
    checkInIncomeThisMonth: function() {
      var total = this.appStatsThisMonth.checkInsPaidCount();
      var checkIns = total * 0.15;
      return "$" + checkIns.toFixed(2).toString(); 
    },
    hoursIncomeToday: function() {
      var total = this.appStatsToday.timesheetsPaidCount();
      var hours = total * 0.35;
      return "$" + hours.toFixed(2).toString(); 
    },
    timesheetIncomeThisMonth: function() {
      var total = this.appStatsThisMonth.timesheetsPaidCount();
      var hours = total * 0.35;
      return "$" + hours.toFixed(2).toString(); 
    },
    hoursToday: function() {
      var total = this.appStatsToday.timesheetsTotal();
      return total; 
    },
    totalIncomeToday() {
      var inspections = this.appStatsToday.inspectionsPaidCount() * 0.50; 
      var timesheets = this.appStatsToday.timesheetsPaidCount() * 0.35;
      var checkIns = this.appStatsToday.checkInsPaidCount() * 0.15;
      var total = inspections + checkIns + timesheets;
      return "$" + total.toFixed(2).toString();
    },
    totalToday() {
      var inspections = this.appStatsToday.inspectionsTotal() * 1; 
      var timesheets = this.appStatsToday.timesheetsTotal() * 1;
      var checkIns = this.appStatsToday.checkInsTotal() * 1;
      var total = inspections + checkIns + timesheets;
      return total + "";
    },
    checkInIncome: function() {
      var value = this.appStatsToday.checkInsPaidCount() * 0.15;
      return "$" + value.toFixed(2).toString();
    },
    timesheetIncome: function() {
      var value = this.appStatsToday.timesheetsPaidCount() * 0.35;
      return "$" + value.toFixed(2).toString();
    },
    vehicleIncome: function() {
      var value = this.appStatsToday.vehiclesBillableCount();
      return "$" + value.toFixed(2).toString();
    },
    userIncome: function() {
      var value = 0;//this.appStatsToday.usersBillableCount();
      return "$" + value.toFixed(2).toString();
    },
    totalIncomeThisMonth: function() {
      var v1 = this.appStatsThisMonth.vehiclesBillableCount();
      var v2 = this.appStatsThisMonth.timesheetsPaidCount() * 0.35;
      var v3 = this.appStatsThisMonth.checkInsPaidCount() * 0.15;
      var v4 = this.appStatsThisMonth.inspectionsPaidCount() * 0.50;
      var total = v1 + v2 + v3 + v4;
      return "$" + total.toFixed(2).toString();
    },
    projection: function() {
      var v1 = this.appStatsThisMonth.vehiclesBillableCount();
      var v2 = this.appStatsThisMonth.timesheetsPaidCount() * 0.35;
      var v3 = this.appStatsThisMonth.checkInsPaidCount() * 0.15;
      var v4 = this.appStatsThisMonth.inspectionsPaidCount() * 0.50;
      var total = v2 + v3 + v4;
      if (this.domain) {
        var day = this.domain.session().time().now().day();
        var dailyAverage = total / day.value();
        var monthlyTotal = dailyAverage * (DateUtils.daysInMonth(day.year().value(), day.month().value) + 1);
        total = monthlyTotal;
      }
      total = total + v1;
      return "$" + total.toFixed(2).toString();
    },
    dailyAverage: function() {
      var v1 = this.appStatsThisMonth.vehiclesBillableCount();
      var v2 = this.appStatsThisMonth.timesheetsPaidCount() * 0.35;
      var v3 = this.appStatsThisMonth.checkInsPaidCount() * 0.15;
      var v4 = this.appStatsThisMonth.inspectionsPaidCount() * 0.50;
      var total = v2 + v3 + v4;
      if (this.domain) {
        var day = this.domain.session().time().now().day();
        var dailyAverage = (total / day.value()) + (v1 / day.month().lastDay().value());
        total = dailyAverage;
      }
      return "$" + total.toFixed(2).toString();
    }
  },
  watch: {
    auth_connected() {
      ConnectionUtils.sendSignInEvent(this);
    },
    auth_socket_status() {
      ConnectionUtils.displayStatus(this);
    },
    signin_event() {
      ConnectionUtils.createDomainSession(this);
    }
  },
  mounted: function () {
    ConnectionUtils.ensureConnection(this);
  },
  methods: {
    ...mapActions([
                   'AuthStorage_updateCompany',
                   'addListenerAction',
                   'removeListenerAction',
                   'updateDomain',
                   ]),
    start: function() {
      this.isLoading = false;
      this.loadingMessage = null;
      ConnectionUtils.withAdminCompany(this);
      this.load();
    },
    callback: function(msg) {
      this.loadingMessage = msg;
    },
    load: function() {
      this.appStatsToday = new AppStats(this.domain,{});
      this.appStatsThisMonth = new AppStats(this.domain,{});
      this.statsListDaily = [];
      this.statsListMonthly = [];
      this.loadAppStatsToday();
    },

    loadAppStatsToday: function() {
      this.isLoadingToday = true;
      this.loadingTodayMessage = this.MC.Stats.Status.LoadingToday.value();

      var request = this.domain.events().app().statsDetails();
      request.send(this.loadAppStatsTodayListener);
    },
    
    loadAppStatsTodayListener: function(event) {
      if (event.error()) {
        this.error = event.error();
      } else {
        var payload = event.payload();
        if (payload) {
          this.appStatsToday = new AppStats(this.domain, payload.valuesFor(AppStats.MODEL_NAME));
        }
      }
      this.isLoadingToday = false;
      this.loadingTodayMessage = null;

      this.loadAppStatsThisMonth();
      this.loadAppStatsDaily();
      this.loadAppStatsMonthly();
    },

    loadAppStatsThisMonth: function() {
      this.isLoadingThisMonth = true;
      this.loadingThisMonthMessage = this.MC.Stats.Status.LoadingThisMonth.value();

      var request = this.domain.events().app().statsDetails('thisMonth');
      request.send(this.loadAppStatsThisMonthListener);
    },
    
    loadAppStatsThisMonthListener: function(event) {
      if (event.error()) {
        this.error = event.error();
      } else {
        var payload = event.payload();
        if (payload) {
          this.appStatsThisMonth = new AppStats(this.domain, payload.valuesFor(AppStats.MODEL_NAME));
        }
      }
      this.isLoadingThisMonth = false;
      this.loadingThisMonthMessage = null;
    },
    
    loadAppStatsDaily: function() {
      this.isLoadingDailyStats = true;
      this.loadingDailyMessage = this.MC.Stats.Status.LoadingDaily.value();

      var filter = new AppStatsListFilter();
      filter
        .withPagination(this.pageSize, this.pageNumber)
        .withTypeDaily()
        .done();
      var request = this.domain.events().app().statsList(filter);
      request.send(this.loadAppStatsDailyListener);
    },
    
    loadAppStatsDailyListener: function(event) {
      if (event.error()) {
        this.error = event.error();
      }
      var payload = event.payload();
      if (payload) {
        var statsMap = new AppStatsMap(this.domain, payload.valuesFor(AppStatsMap.MODEL_NAME));
        this.statsListDaily = statsMap.sortByCreatedDateDesc();
      }
      this.isLoadingDailyStats = false;
      this.loadingDailyMessage = null;
    },
    
    loadAppStatsMonthly: function() {
      this.isLoadingMonthlyStats = true;
      this.loadingMonthlyMessage = this.MC.Stats.Status.LoadingMonthly.value();

      var filter = new AppStatsListFilter();
      filter
        .withPagination(this.pageSize, this.pageNumber)
        .withTypeMonthly()
        .done();
      
      var request = this.domain.events().app().statsList(filter);
      request.send(this.loadAppStatsMonthlyListener);
    },
    
    loadAppStatsMonthlyListener: function(event) {
      if (event.error()) {
        this.error = event.error();
      }
      var payload = event.payload();
      if (payload) {
        var statsMap = new AppStatsMap(this.domain, payload.valuesFor(AppStatsMap.MODEL_NAME));
        this.statsListMonthly = statsMap.sortByCreatedDateDesc();
      }

      this.isLoadingMonthlyStats = false;
      this.loadingMonthlyMessage = null;
    },
    
    refreshStats: function() {
      this.load();
    },
    
    projectedInspections: function() {
      if (!this.domain) {
        return "0";
      }
      var now = this.domain.session().time().now();
      var nowValue = now.day().value();
      var month = now.month();
      var lastDay = month.lastDay().value();
      return ((this.appStatsThisMonth.inspectionsPaid() / (nowValue) ) * (lastDay)).toFixed(0) + "";
    },
    
    projectedHours: function() {
      if (!this.domain) {
        return "0";
      }
      var now = this.domain.session().time().now();
      var nowValue = now.day().value();
      var month = now.month();
      var lastDay = month.lastDay().value();
      return ((this.appStatsThisMonth.timesheetsPaid() / (nowValue) ) * (lastDay)).toFixed(0) + "";
    }
  },
  beforeDestroy() {
  },
}
</script>