
//import ConstUtils from '@/utils/ConstUtils.js';

export default class InvoiceUtils {

  static SortByTotal = function(a,b) {
    if (a.total() > b.total()) {
      return 1;
    }
    if (a.total() < b.total()) {
      return -1;
    }
    return 0;
  }
  
  static SortByTotalDesc = function(a,b) {
    if (a.total() < b.total()) {
      return 1;
    }
    if (a.total() > b.total()) {
      return -1;
    }
    return 0;
  }
  
  static SortByCreatedDate = function(a, b) {
    if (a.createdDate() < b.createdDate()) {
      return 1;
    }
    if (a.createdDate() > b.createdDate()) {
      return -1;
    }
    return 0;
  }

  static SortByModifiedDate = function(a, b) {
    if (a.modifiedDate() > b.modifiedDate()) {
      return -1;
    }
    if (a.modifiedDate() < b.modifiedDate()) {
      return 1;
    }
    return 0;
  }
  
  static SortByInvoiceNumberDesc = function(a,b) {
    if (a.number() < b.number()) {
      return 1;
    }
    if (a.number() > b.number()) {
      return -1;
    }
    return 0;
  }
  
  static SortByInvoiceNumber = function(a,b) {
    if (a.number() < b.number()) {
      return -1;
    }
    if (a.number() > b.number()) {
      return 1;
    }
    return 0;
  }
}