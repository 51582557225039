<template>
  <DisplayView :timesheetId="timesheetId" @click="withButtonPressed" @withButtonPressed="withButtonPressed" @withValue="withValue"></DisplayView>
</template>

<script>
import DisplayView from './UpdateTimesheetPdfDisplayView.vue';
export default {
  name: 'admin-views-timesheet-list-update-timesheet-display-view-row',
  components: {
    DisplayView
  },
  props: {
    timesheetId: { type: String, default: "" },
  },
  methods: {
    withValue: function(event) {
      this.$emit('withValue', event);
    },
    withButtonPressed: function(event) {
      this.$emit('withButtonPressed', event);
    },
  }
}
</script>
