import RcTableViewRow from "@/portals/shared/cell/common/RcTableViewRow.js";

import Checklist from "@/domain/model/checklist/CheckList.js";

export default class ChecklistRow extends RcTableViewRow {
    
    static Id = "ChecklistRow";
    
    constructor() {
        super();
        this.put(RcTableViewRow.TypeId, ChecklistRow.Id);
    }
    
    withChecklist(checklist) {
        this.withObject(checklist.data);
        this.withId(checklist.id());
        this.withField(checklist.modelName());
        this.withKey(checklist.modelName());
        return this;
    }
    
    checklist(domain) {
        return new Checklist(domain, this.object()); 
    }
}