
import RcTableViewRow from "@/portals/shared/cell/common/RcTableViewRow.js";

export default class LogoImageDisplayRow extends RcTableViewRow {

  static Id = "LogoImageDisplayRow";
  
  constructor() {
    super();
    this.put(RcTableViewRow.TypeId, LogoImageDisplayRow.Id);
  }
  
}