<template>
  <BillingUpdateView :settingsId="settingsId" @click="withButtonPressed" @withButtonPressed="withButtonPressed" @withValue="withValue"></BillingUpdateView>
</template>

<script>
import BillingUpdateView from './BillingUpdateView.vue';
export default {
  name: 'billing-update-view-row',
  components: {
    BillingUpdateView
  },
  props: {
    settingsId: { type: String, default: "" },
  },
  data() {
    return {
      isAdmin: false,
    };
  },
  methods: {
    withValue: function(event) {
      this.$emit('withValue', event);
    },
    withButtonPressed: function(event) {
      this.$emit('withButtonPressed', event);
    },
  }
}
</script>
