<template>
  <Row v-if="ready" borderStyle="rc-border-thin-even">
    <Column :width="15" :key="redraw">
      <Box :even="row.even()" :backgroundColour="row.even() ? 'rowEven' : 'rowOdd'">
        <Row>
          <Column :width="3">
            <Box>
              <Row>
                <Column>
                  <VehicleImageDisplayCell
                    v-on:click="withButtonPressed"
                    v-on:withValue="withValue" 
                    :data="this.vehicleImageData().data" />
                </Column>
              </Row>
              <Row v-if="!vehicle.isStateActive()">
                <Column :width="2"></Column>
                <Column :width="11">
                  <Box>
                    <StatusCell :data="this.vehicleStateRow().data"></StatusCell>
                  </Box>
                </Column>
                <Column :width="2"></Column>
              </Row>
            </Box>
          </Column>
          <Column :width="12">
            <Box>
              <Row>
                <Column :width="4">
                  <LabelValueBox :labelWidth="5">
                    <template slot="label">
                      Name
                    </template>
                    <template slot="value">
                      {{ this.Content.decodeText(vehicle.name(), "[Not Set]") }}
                    </template>
                  </LabelValueBox>
                </Column>
                <Column :width="4">
                  <LabelValueBox :labelWidth="5">
                    <template slot="label">
                      Plate
                    </template>
                    <template slot="value">
                      {{ this.vehicle.plate() }}
                    </template>
                  </LabelValueBox>
                </Column>
                <Column :width="4">
                  <Box v-if="vehicle.hasLastSafetyDateExpired()">
                    <StatusCell :data="this.vehicleSafetyRow(-1).data"></StatusCell>
                  </Box>
                  <Box v-else-if="vehicle.willLastSafetyDateExpireInWeeks(12)">
                    <StatusCell :data="this.vehicleSafetyRow(12).data"></StatusCell>
                  </Box>
                  <Box v-else-if="vehicle.willLastSafetyDateExpireInWeeks(1)">
                    <StatusCell :data="this.vehicleSafetyRow(1).data"></StatusCell>
                  </Box>
                  <LabelValueBox :labelWidth="5" v-else>
                    <template slot="label">
                      Safety Date
                    </template>
                    <template slot="value">
                      {{ this.vehicle.safetyDate().displayAs_YYYY_MM_DD() }}
                    </template>
                  </LabelValueBox>
                </Column>
                <Column :width="3">
                  <LabelValueBox :labelWidth="5">
                    <template slot="label">
                      Vehicle Type
                    </template>
                    <template slot="value">
                      {{ this.categoryName() }}
                    </template>
                  </LabelValueBox>
                </Column>
              </Row>
              
              <Row>
                <Column :width="4">
                    <Box>
                      <StatusCell :data="this.vehicleStatusRow().data"></StatusCell>
                    </Box>
                </Column>
                <Column :width="4">
                  <Box v-if="vehicle.hasPlateExpired()">
                    <StatusCell :data="this.vehiclePlateRow(-1).data"></StatusCell>
                  </Box>
                  <Box v-else-if="vehicle.willPlateExpireInWeeks(12)">
                    <StatusCell :data="this.vehiclePlateRow(12).data"></StatusCell>
                  </Box>
                  <Box v-else-if="vehicle.willPlateExpireInWeeks(1)">
                    <StatusCell :data="this.vehiclePlateRow(1).data"></StatusCell>
                  </Box>
                  <LabelValueBox v-else :labelWidth="5">
                    <template slot="label">
                      Plate Expiry Date
                    </template>
                    <template slot="value">
                      {{ this.vehicle.plateExpiryDateV2().displayAs_YYYY_MM_DD() }}
                    </template>
                  </LabelValueBox>
                </Column>
                <Column :width="4">
                  <LabelValueBox :labelWidth="5">
                    <template slot="label">
                      Safety Number
                    </template>
                    <template slot="value">
                      {{ this.vehicle.safetyNumber() }}
                    </template>
                  </LabelValueBox>
                </Column>
                <Column :width="3">
                  <LabelValueBox :labelWidth="5">
                    <template v-if="this.vehicle.isTrailer()" slot="label">
                      GVWR
                    </template>
                    <template v-else slot="label">
                      RGW
                    </template>
                    <template slot="value">
                      {{ this.vehicle.weight() }}
                    </template>
                  </LabelValueBox>
                </Column>
              </Row>

              <Row>
                <Column :width="4">
                  <LabelValueBox :labelWidth="5">
                    <template slot="label">
                      Odometer
                    </template>
                    <template slot="value">
                      {{ this.vehicle.odometer() }} {{ this.vehicle.odometerType() }}
                    </template>
                  </LabelValueBox>
                </Column>
                <Column :width="4">
                </Column>
                <Column :width="4">
                </Column>
                <Column :width="3">
                  <LabelValueBox :labelWidth="5">
                    <template slot="label">
                      Year
                    </template>
                    <template slot="value">
                      {{ this.vehicle.year() }}
                    </template>
                  </LabelValueBox>
                </Column>
              </Row>

              <Row>
                <Column :width="4">
                  <LabelValueBox :labelWidth="5">
                    <template slot="label">
                      Attributes
                    </template>
                    <template slot="value">
                      {{ this.vehicle.summary() }}
                    </template>
                  </LabelValueBox>
                </Column>
                <Column :width="4">
                  <LabelValueBox :labelWidth="5">
                    <template slot="label">
                      Plate Jurisdiction
                    </template>
                    <template slot="value">
                      {{ this.vehicle.jurisdiction() }}
                    </template>
                  </LabelValueBox>
                </Column>
                <Column :width="4"></Column>
                <Column :width="3">
                  <LabelValueBox :labelWidth="5">
                    <template slot="label">
                      Make and Model
                    </template>
                    <template slot="value">
                      {{ this.makeAndModel() }}
                    </template>
                  </LabelValueBox>
                </Column>
              </Row>

              <Row v-if="this.schedule.isNotNew()">
                <Column :width="10">
                  <LabelValueBox :labelWidth="2">
                    <template slot="label">
                      Schedule
                    </template>
                    <template slot="value">
                      {{ this.schedule.name() }}
                    </template>
                  </LabelValueBox>
                </Column>
              </Row>

              <Row v-if="this.checklists.isNotEmpty()">
                <Column :width="10">
                  <LabelValueBox :labelWidth="2">
                    <template slot="label">
                      Checklist
                    </template>
                    <template slot="value">
                      {{ this.checklists.first().name() }}
                    </template>
                  </LabelValueBox>
                </Column>
              </Row>

            </Box>
          </Column>
        </Row>

        <Row> <!-- User Buttons Row-->
          <Column :width="9"></Column>
          <Column :width="2">
            <Button v-on:click="withInspectionsButtonPressed">Inspections</Button>
          </Column>

          <Column v-if="vehicle.isStateActive()" :width="2">
            <Button v-on:click="withRemoveButtonPressed">Remove</Button>
          </Column>
          <Column v-if="vehicle.isStateDeleted()" :width="2">
            <Button v-on:click="withRestoreButtonPressed">Restore</Button>
          </Column>

          <Column :width="2">
            <Button v-on:click="withUpdateButtonPressed">Update</Button>
          </Column>
        </Row><!-- User Button Row-->
        
        <!-- Admin section -->
        <Row v-if="row.isAdmin()">
          <Column :width="15">
            <Box :even="row.even()">
              <Row>
                <Column>Admin Section</Column>
              </Row>
              <Row>
                <Column :width="5">
                  <LabelValueBox :labelWidth="5">
                    <template slot="label">
                      Company Name
                    </template>
                    <template slot="value">
                      {{ this.Content.decodeText(company.name(), "[Not Set]") }}
                    </template>
                  </LabelValueBox>
                </Column>
                <Column :width="5">
                  <LabelBox></LabelBox>
                </Column>
              </Row>

              <Row> <!-- Created and Modified By/On Row -->
                <Column :width="5">
                  <LabelValueBox :labelWidth="5">
                    <template slot="label">
                      Modified By/On
                    </template>
                    <template slot="value">
                      {{ vehicle.modifiedBy().find().fullName() }}
                      {{ vehicle.modifiedDateV2().displayAs_YYYY_MM_DD_HH_MM_SS_A() }}
                    </template>
                  </LabelValueBox>
                </Column>
                <Column :width="5">
                  <LabelValueBox :labelWidth="5">
                    <template slot="label">
                      Created By/On
                    </template>
                    <template slot="value">
                      {{ vehicle.createdBy().find().fullName() }}
                      {{ vehicle.createdDateV2().displayAs_YYYY_MM_DD_HH_MM_SS_A() }}
                    </template>
                  </LabelValueBox>
                </Column>
                <Column :width="5">
                  <LabelValueBox :labelWidth="5">
                    <template slot="label">
                      Vehicle Id
                    </template>
                    <template slot="value">
                      {{ vehicle.id() }}
                    </template>
                  </LabelValueBox>
                </Column>
              </Row>

              <Row> <!-- Button Row-->
                <Column :width="9"></Column>
                <Column :width="2">
                </Column>
                <Column :width="2">
                </Column>
                <Column :width="2">
                  <Button v-on:click="withSelectButtonPressed">Select</Button>
                </Column>
              </Row><!-- End Button Row-->
            </Box>
          </Column>
        </Row>
        <!-- Admin section -->

      </Box>
    </Column>
  </Row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
  
import ConnectionUtils from '@/utils/ConnectionUtils.js';
import ConstUtils from '@/utils/ConstUtils.js';
import ContentUtils from '@/utils/ContentUtils.js';
import StringUtils from '@/utils/StringUtils.js';
  
import MC from "@/domain/session/MC.js";

import Box from "@/portals/shared/library/box/Box.vue";
import Column from "@/portals/shared/library/box/Column.vue";
import Row from "@/portals/shared/library/box/Row.vue";

import Button from "@/portals/shared/library/button/Button.vue";
import LabelBox from "@/portals/shared/library/label/Label.vue";
import LabelValueBox from "@/portals/shared/library/labelvalue/LabelValue.vue";
// import ValueBox from "@/portals/shared/library/value/Value.vue";

import StatusCell from "@/portals/shared/cell/status/StatusDisplayCell";
import StatusDisplayRow from '@/portals/shared/cell/status/StatusDisplayRow';

import VehicleImageDisplayCell from '@/portals/shared/cell/vehicle/image/display/VehicleImageDisplayCell.vue';
import VehicleImageDisplayRow from '@/portals/shared/cell/vehicle/image/display/VehicleImageDisplayRow.js';

import VehicleRow from './VehicleRow';

export default {
  name: "vehicle-cell",
  components: {
    Box, Row, Column,
    StatusCell,
    // ValueBox, 
    LabelBox, LabelValueBox,
    Button,
    VehicleImageDisplayCell
  },
  props: {
    showMissingRequirements: { type: Boolean, default: false },
    isAdmin: {type: Boolean, default: true},
    data: {type: Object, default: null},
  },
  data() {
    return {
      MC: new MC(),
      Connections: ConnectionUtils,
      Consts: ConstUtils,
      Content: ContentUtils,
      Strings: StringUtils,

      row: new VehicleRow(),
      company: null,
      vehicle: null,
      schedule: null,
      checklists: null,
      ready: false,
      redraw: 1,
    }
  },
  computed: {
    ...mapGetters([
                    'auth_connected',
                    'auth_socket_status',
                    'signin_event',
                    'auth_client',
                    'domain',
                    'VehicleStorage_lastEvent',
                    ]),
  },
  watch: {
    VehicleStorage_lastEvent() {
      let vehicle = this.domain.vehicles().findById(this.vehicle.id());
      if (this.vehicle.modifiedDate() < vehicle.modifiedDate()) {
        this.row.withVehicle(vehicle);
        this.vehicle = this.row.vehicle(this.domain);
        this.company = this.vehicle.company().find();
        this.schedule = this.vehicle.schedule().find();
        this.checklists = this.vehicle.checklists().find();
        this.redraw++;
      }
    }
  },
  mounted: function () {
    this.row.data = this.data;
    
    this.vehicle = this.row.vehicle(this.domain);
    this.company = this.vehicle.company().find();
    this.schedule = this.vehicle.schedule().find();
    this.checklists = this.vehicle.checklists().find();
    this.ready = true;
  },
  methods: {
    ...mapActions([
                    'AuthStorage_updateCompany',
                    'addListenerAction',
                    'removeListenerAction',
                    'updateDomain',
                    ]),

    withEnterPressed(keyData) {
      this.$emit("withEnterPressed", keyData);
    },

    withValue(valueData) {
      this.$emit('withValue', valueData);
    },

    withSelectButtonPressed() {
      let data = {};
      data["field"] = "Update";
      data["id"] = this.company.id();
      this.withButtonPressed(data);
    },

    withUpdateButtonPressed() {
      let data = {};
      data["field"] = "Update";
      this.withButtonPressed(data);
    },

    withRestoreButtonPressed() {
      let data = {};
      data["field"] = "Restore";
      this.withButtonPressed(data);
    },

    withRemoveButtonPressed() {
      let data = {};
      data["field"] = "Remove";
      this.withButtonPressed(data);
    },

    withInspectionsButtonPressed() {
      let data = {};
      data["field"] = "Inspections";
      this.withButtonPressed(data);
    },

    withButtonPressed: function (buttonData) {
      if (StringUtils.isEmpty(buttonData["id"])) {
        buttonData["id"] = this.row.id();
      }
      buttonData["key"] = this.row.key();
      this.$emit('withButtonPressed', buttonData);
    },

    vehicleStateRow() {
      let row = new StatusDisplayRow();

      row.withLabelWidth(0);
      row.withValueWidth(15);
      row.withValue("Expired");
      row.withObject("Inactive");
      return row;
    },

    vehicleStatusRow() {
      let row = new StatusDisplayRow();
      let value = this.vehicle.defectType();
      if (StringUtils.isEmpty(value)) {
        value = "Good";
      }
      let message = null;

      row.withLabelWidth(5);
      row.withValueWidth(10);
      row.withLabel("Status")
      row.withValue(value);
      row.withObject(message);
      return row;
    },

    vehicleSafetyRow(countDown) {
      let safetyDateDisplay = this.vehicle.safetyDate().displayAs_YYYY_MM_DD();
      let row = new StatusDisplayRow();
      let value = "";
      let hint = "";
      if (countDown == -1) {
        value = "Expired";
        hint = "Safety has expired"
      }
      if (countDown == 12) {
        value = "ExpireSoon";
        hint = "Safety will expire soon"
      }
      if (countDown == 1) {
        value = "ExpireNext";
        hint = "Safety expiring this week"
      }
      row.withHint(hint);
      row.withLabelWidth(5);
      row.withValueWidth(10);
      row.withLabel("Safety Date")
      row.withValue(value);
      row.withObject(safetyDateDisplay);
      return row;
    },

    vehiclePlateRow(countDown) {
      let safetyDateDisplay = this.vehicle.plateExpiryDateV2().displayAs_YYYY_MM_DD();
      let row = new StatusDisplayRow();
      let value = "";
      let hint = "";
      if (countDown == -1) {
        value = "Expired";
        hint = "Plate has expired"
      }
      if (countDown == 12) {
        value = "ExpireSoon";
        hint = "Plate will expire soon"
      }
      if (countDown == 1) {
        value = "ExpireNext";
        hint = "Plate expiring this week"
      }
      row.withHint(hint);
      row.withLabelWidth(5);
      row.withValueWidth(10);
      row.withLabel("Plate Expiry Date")
      row.withValue(value);
      row.withObject(safetyDateDisplay);
      return row;
    },


    makeAndModel() {
      let vehicle = this.vehicle;
      let value = "";
      let makeSet = false;
      if (StringUtils.isNotEmpty(vehicle.make())) {
        value = vehicle.make();
        makeSet = true;
      }
      if (StringUtils.isNotEmpty(vehicle.model())) {
        if (makeSet) {
          value = value + " / " + vehicle.model();
        } else {
          value = "<Not Set>" + "/ " + vehicle.model();
        }
      } else {
        if (makeSet) {
          value = value + " / " + "<Not Set>"
        }
      }
      return value;
    },

    categoryName() {
      let category = this.vehicle.category().find();
      let list = ContentUtils.CATEGORIES();
      for (let i = 0; i < list.length; i++) {
        let item = list[i];
        if (item['@rid'] == category.id()) {
          return item['name'];
        }
      }
      return category.name();
    },

    vehicleImageData() {
      let value = this.vehicle.data;
      let row = new VehicleImageDisplayRow().withValue(value);
      return row;
    }
  }
}
</script>