<template>
  <DisplayView 
    :timesheetId="timesheetId" 
    :loadTimesheet="false"
    @click="withButtonPressed" 
    @withButtonPressed="withButtonPressed" 
    @withValue="withValue"></DisplayView>
</template>

<script>

import DisplayView from '../../../../timesheet/list/details/DetailsDisplayView.vue';

export default {
  name: 'compliance-list-timesheet-details-display-row',
  components: {
    DisplayView
  },
  props: {
    timesheetId: { type: String, default: "" },
  },
  methods: {
    withValue: function(event) {
      this.$emit('withValue', event);
    },
    withButtonPressed: function(event) {
      this.$emit('withButtonPressed', event);
    },
  }
}
</script>
