import RcObject from "@/domain/common/RcObject.js";

export default class ReportParams extends RcObject {

  static MODEL_NAME = "ReportParams";

  static Fields = {
    FILTER: "filter",
  }

  constructor() {
    super({});
  }

  with(reportMap) {
    this.put(reportMap.modelName(), reportMap);
    return this;
  }
  
  withFilter(filter) {
    this.put(ReportParams.Fields.FILTER, filter ? filter.data : null);
    return this;
  }
}
  