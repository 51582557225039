<template>
  <Row v-if="ready">
    <a v-if="rowData.isImageTransparent()">
      <img src="@/assets/2022/800x800transparent.png" 
           width="24px" />
    </a>
    <a v-else-if="rowData.isImageHosMissing()" :class="getClasses()" >
      <div :class="getImageClasses()"></div>
      <img alt="Hours of Service Missing" 
           src="@/assets/2022/HOS-Missing3.png" 
           width="24px"
           v-on:click="withValue({})"/>
    </a>
    <a v-else-if="rowData.isImageHosOffDutyDay()" :class="getClasses()" >
      <img src="@/assets/2022/HOS-OffDuty3.png" 
           width="24px" 
           v-on:click="withValue({})"/>
    </a>
    <a v-else-if="rowData.isImageHosCompleteDay()" :class="getClasses()" >
      <img src="@/assets/2022/HOS-Complete2.png" 
           width="24px" 
          v-on:click="withValue({})"/>
    </a>
    <a v-else-if="rowData.isImageHosInProgressDay()" :class="getClasses()" >
      <img alt="Hours of Service In Progress" 
           src="@/assets/2022/HOS-InProgress.png" 
           width="24px" 
           v-on:click="withValue({})"/>
    </a>
    <a v-else-if="rowData.isImageHosIncompleteDay()" :class="getClasses()" >
      <img alt="Hours of Service Incomplete" 
           src="@/assets/2022/HOS-Incomplete2.png" 
           width="24px" 
           v-on:click="withValue({})"/>
    </a>
    <a v-else-if="rowData.isImageInspectionCompleteGood()" :class="getClasses()">
      <img alt="Inspection Good" 
           src="@/assets/2022/InspectionCompleteGood.png" 
           width="24px" 
           v-on:click="withValue({})"/>
    </a>
    <a v-else-if="rowData.isImageInspectionCompleteMinor()" :class="getClasses()" >
      <img alt="Inspection Minor" 
           src="@/assets/2022/InspectionCompleteMinor.png" 
           width="24px" 
           v-on:click="withValue({})"/>
    </a>
    <a v-else-if="rowData.isImageInspectionCompleteMajor()" :class="getClasses()" >
      <img alt="Inspection Major Defects Reported" 
           src="@/assets/2022/InspectionCompleteMajor2.png" 
           width="24px" 
           v-on:click="withValue({})"/>
    </a>
    <div v-else :class="getClasses()">
      <img width="25%" src="@/assets/ReadyChekIcon.png" :class="getImageClasses()"/>
    </div>
  </Row>
  
</template>

<script>

import { mapGetters } from 'vuex'

import ContentUtils from '@/utils/ContentUtils.js';
import MC from "@/domain/session/MC.js";
import ImageDisplayRow from "./ImageDisplayRow.js";
import Row from '@/portals/shared/library/box/Row.vue';
export default {
  name: 'image-display-cell',
  components: {
    Row,
  },
  props: {
    data: null,
  },
  data() {
    return {
      ready: false,
      rowData: new ImageDisplayRow(),
      Contents: ContentUtils,
      MC: new MC(),
    }
  },
  computed: {
    ...mapGetters([
                   'domain',
                 ]),
    row: function() {
      return this.rowData;
    },
  },
  mounted: function () {
    this.rowData.data = this.data;
    this.ready = true;
  },
  methods: {
    withValue: function(kvp) {
      if (kvp) {
        kvp['id'] = this.row.id();
        kvp['object'] = this.row.object();
        kvp['field'] = this.row.field();
        kvp['key'] = this.row.key();
        this.$emit('click', kvp);
      }
    },
    getClasses() {
      var value = "";
      if (this.rowData.isClickable()) {
        value = "clickable "
      }
      return value;
    },
    getImageClasses() {
      var value = "";
      if (this.rowData.hasTooltip()) {
        value = value + "tooltip ";
      }
      return value;
    },
  },
}
</script>