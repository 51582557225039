<template>
    <Row v-if="ready" borderStyle="rc-border-thin-even"> 
        <Column :width="15" :key="redraw">
            <Box :even="row.even()" :backgroundColour="row.even() ? 'rowEven' : 'rowOdd'">
                <Row>
                    <Column :width="3">
                        <Box>
                            <StringDisplayCell :data="invoiceNumberRow().data" />
                        </Box>
                    </Column>
                    <Column :width="3">
                        <Box>
                            <StringDisplayCell :data="invoiceDateRow().data" />
                        </Box>
                    </Column>
                    <Column :width="2">
                        <Box>
                            <StringDisplayCell :data="invoiceTotalRow().data" />
                        </Box>
                    </Column>
                    <Column :width="3">
                        <Box>
                            <StringDisplayCell :data="invoiceDueDateRow().data" />
                        </Box>
                    </Column>
                    <Column :width="4">
                        <Box>
                            <StatusCell :data="invoiceStatusRow().data" />
                        </Box>
                    </Column>
                </Row>
                <Row>
                    <Column :width="9"></Column>
                    <Column :width="3">
                        <Button v-on:click="withDetailsButtonPressed">Details</Button>
                    </Column>
                    <Column :width="3">
                        <Button v-on:click="withPdfButtonPressed">Print/Download</Button>
                    </Column>
                </Row>
            </Box>
        </Column>
    </Row>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';

import ConnectionUtils from '@/utils/ConnectionUtils.js';
import ConstUtils from '@/utils/ConstUtils.js';
import ContentUtils from '@/utils/ContentUtils.js';
import StringUtils from '@/utils/StringUtils.js';

import MC from "@/domain/session/MC.js";

import Box from "@/portals/shared/library/box/Box.vue";
import Column from "@/portals/shared/library/box/Column.vue";
import Row from "@/portals/shared/library/box/Row.vue";

import Button from "@/portals/shared/library/button/Button.vue";

import StringDisplayCell from '@/portals/shared/cell/string/StringDisplayCell';
import StringDisplayRow from '@/portals/shared/cell/string/StringDisplayRow';

import StatusCell from '@/portals/shared/cell/status/StatusDisplayCell';
import StatusDisplayRow from '@/portals/shared/cell/status/StatusDisplayRow';
import BillingRow from './BillingRow';

export default {
    name: 'billing-cell',
    components: {
        Box, Column, Row,
        Button,
        StatusCell, 
        StringDisplayCell,
    },

    props: {
        showMissingRequirements: { type: Boolean, default: false },
        isAdmin: { type: Boolean, default: true },
        data: { type: Object, default: null },
    },

    data() {
        return {
            MC: new MC(),
            Connections: ConnectionUtils,
            Consts: ConstUtils,
            Content: ContentUtils,
            Strings: StringUtils,

            row: new BillingRow(),
            invoice: null,
            company: null,

            ready: false,
            redraw: 1,
        };
    },

    computed: {
    ...mapGetters([
                    'auth_connected',
                    'auth_socket_status',
                    'signin_event',
                    'auth_client',
                    'domain',
                    'BillingStorage_lastEvent',
                    ]),
    },

    watch: {
        BillingStorage_lastEvent() {
            let invoice = this.domain.invoices().findById(this.invoice.id());
            if (this.invoice.modifiedDate() < invoice.modifiedDate()) {
                this.row.withChecklist(invoice);
                this.invoice = this.row.invoice(this.domain);
                this.company = this.invoice.company().find();
                this.redraw++;
            }
        }
    },

    mounted() {
        this.row.data = this.data;
        this.invoice = this.row.invoice(this.domain);
        this.company = this.invoice.company().find();
        this.ready = true;
    },

    methods: {
    ...mapActions([
                'AuthStorage_updateCompany',
                'addListenerAction',
                'removeListenerAction',
                'updateDomain',
                ]),

    withEnterPressed(keyData) {
      this.$emit("withEnterPressed", keyData);
    },

    withValue(valueData) {
      this.$emit('withValue', valueData);
    },

    withDetailsButtonPressed() {
      let data = {};
      data["field"] = "Details";
      this.withButtonPressed(data);
    },

    withPdfButtonPressed() {
      let data = {};
      data["field"] = "Pdf";
      this.withButtonPressed(data);
    },

    withButtonPressed: function (buttonData) {
      if (StringUtils.isEmpty(buttonData["id"])) {
        buttonData["id"] = this.row.id();
      }
      buttonData["key"] = this.row.key();
      this.$emit('withButtonPressed', buttonData);
    },

    invoiceStatusRow() {
        let now = this.domain.time().now();
        let row = new StatusDisplayRow();
        let dueDate = this.invoice.dueDate();
        let paidValue = "notpaid";
        if (this.invoice.paid()) {
            paidValue = "paid";
        } else {
            if (now.time() > dueDate.time()) {
            paidValue = "pastdue"
            } else if (dueDate.minus(1).time() < now.time()) {
            paidValue = "notpaid1day";
            } else if (dueDate.minus(5).time() < now.time()) {
            paidValue = "notpaid1week";
            } else {
            paidValue = "notpaid";
            }
        }
        row.withWidth("4");
        row.withValue(paidValue);
        
        if (this.invoice.paid()) {
            row.withHint(this.invoice.paidDate().displayAs_YYYY_MM_DD());
        }
        return row;
    },

    invoiceNumberRow() {
        let row = new StringDisplayRow();
        row.withValue(this.invoice.number());
        return row;
    },

    invoiceDateRow() {
        let row = new StringDisplayRow();
        row.withValue(this.invoice.createdDateDisplay());
        return row;
    },

    invoiceTotalRow() {
        let row = new StringDisplayRow();
        row.withValue(this.invoice.totalWithTaxes().toFixed(2));
        return row;
    },

    invoiceDueDateRow() {
        let row = new StringDisplayRow();
        row.withValue(this.invoice.dueDateDisplay());
        return row;
    },
},
}

</script>