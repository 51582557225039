
//import StringUtils from "@/utils/StringUtils.js";

import RcMonth from "./RcMonth.js";
import RcDay from "./RcDay.js";
import RcDate from "./RcDate.js";

export default class RcTime {
  
  static Zone = {
    Names: {
      CANADA_TORONTO: "America/Toronto",
    }
  }
  
  static time() {
    return new RcTime();
  }
  
  constructor() {
    this._timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  }
  
  timeZone() {
    return this._timeZone;
  }
  
  at(time) {
    var date = new Date(time);
    if (time && time._date) {
      date = new Date(time._date);
      this._timeZone = time._timeZone;
    }
    return new RcDate(date, this._timeZone);
  }
  
  today() {
    return new RcDay(new Date(), this._timeZone);
  }
  
  now() {
    return new RcDate(new Date(), this._timeZone);
  }
  
  month(year, month) {
    return new RcMonth(new Date(year, month), this._timeZone);
  }
  
  day(year, month, day) {
    return new RcDay(new Date(year, month, day), this._timeZone);
  }
  
}