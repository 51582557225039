
import RcTableViewRow from "@/portals/shared/cell/common/RcTableViewRow.js";

export default class ChecklistUpdateRow extends RcTableViewRow {

  static Id = "ChecklistUpdateRow";
  
  constructor() {
    super();
    this.put(RcTableViewRow.TypeId, ChecklistUpdateRow.Id);
  }

  defaultValue() {
    var value = super.defaultValue();
    if (value && value["@rid"]) {
      value = value["@rid"];
    }
    return value;
  }

  withMandatoryValue(mandatory) {
    return super.withMandatoryValue(mandatory);
  }

  withShowClear(value) {
    this.put("showClear", value);
    return this;
  }

  showClear() {
    return this.getBoolean("showClear");
  }
}