import RcTableViewUtils from "@/portals/shared/table/RcTableViewUtils";

import BoxDisplayRow from "@/portals/shared/cell/box/BoxDisplayRow";
import RowDisplayRow from "@/portals/shared/cell/row/RowDisplayRow";
import StringDisplayRow from "@/portals/shared/cell//string/StringDisplayRow";
import StringUpdateRow from "@/portals/shared/cell/string/StringUpdateRow.js";
import StringListUpdateRow from "@/portals/shared/cell/string/list/StringListUpdateRow.js";
import LabelValueDisplayRow from "@/portals/shared/cell//label/LabelValueDisplayRow";
import TitleDisplayRow from "@/portals/shared/cell/title/TitleDisplayRow";
import ButtonDisplayRow from "@/portals/shared/cell/button/ButtonDisplayRow";
import SpacerDisplayRow from "@/portals/shared/cell/spacer/SpacerDisplayRow";

import MC from "@/domain/session/MC.js";
import ContentUtils from '@/utils/ContentUtils.js';
import StringUtils from '@/utils/StringUtils.js';
import LabelDisplayRow from "@/portals/shared/cell/label/LabelDisplayRow";
import ComplianceRow from "@/portals/shared/cell/compliance/ComplianceRow";

export default class ListViewUtils extends RcTableViewUtils {

  constructor(model, tableData) {
    super(model, tableData);
    this.MC = new MC();
    this.pages = [];
    this.Strings = new StringUtils();
  }

  start() {
    if (this.model().hasError()) {
      this.renderError();  
    }

    if (this.model().isStatePageLoading()) {
      super.start();
      return;
    }
    
    this.renderHeader(this.model().totalItems());

    if (this.model().isStateContentLoading()) {
      super.start();
      return;
    }
    
    if (this.model().isStateContentLoaded()) {
      this.renderBody();
    }

    this.renderFooter(this.model().totalItems());
  }

  renderBody() {
    let row = null;
    let even = true;
    let item = null;
    let list = this.model().itemList();

    let timesheetsList = this.model()._timesheetMap.list();
    let inspectionsList = this.model()._inspectionMap.list();
    let timesheetIds = timesheetsList.map(timesheet => timesheet.id());
    let inspectionIds = inspectionsList.map(inspection => inspection.id());

    for (var index = 0; index < list.length; index++) {
      item = list[index];
      let user = item.user().find();
      row = new ComplianceRow().withEmployee(item, user);
      row
        .withStartFilterDate(this.model()._startFilterDate)
        .withTimesheetIds(timesheetIds)
        .withInspectionIds(inspectionIds)
        .withIsAdmin(this.model().isAdmin())
        .withEven(even)
        .withId(user.id())
      this.addBody(row);
      even = !even;
    }
  }

  renderFooter(totalItems) {
    let query = this.model().panel().$route.query;
    this.pages = this.model().pages();
    this.addFooter(
        new BoxDisplayRow()
        .withWidth("15")
        .withChild(new RowDisplayRow()
          .withChild(new BoxDisplayRow()
            .withWidth("15")
            .withChild(new RowDisplayRow()
              .withChild(new LabelValueDisplayRow().withWidth("4").withLabel("Showing").withValue(this.createPageInfo(totalItems, query, "compliance")))
              .withChild(new StringDisplayRow().withWidth("7"))
              .withChild(new ButtonDisplayRow().withWidth("1").withName("<").withField("PreviousPage"))
              .withChild(new StringListUpdateRow().withWidth("2")
                                                  .withShowLabel(false)
                                                  .withName("Jump to Page")
                                                  .withField("PageIndex")
                                                  .withDefaultValue(query["page"] ? query["page"] : "1")
                                                  .withOptions(this.pages))
              .withChild(new ButtonDisplayRow().withWidth("1").withName(">").withField("NextPage"))
            )
          )
        )
    );
  }

  renderHeader(totalItems) {
    let query = this.model().panel().$route.query;
    this.addHeader(
      new RowDisplayRow()
      .withChild(
        new BoxDisplayRow()
        .withWidth("15")
        .withChild(new RowDisplayRow()
          .withChild(new LabelDisplayRow().withWidth("2").withLabel("Compliance"))
          .withChild(new SpacerDisplayRow().withWidth("9"))
          .withChild(new ButtonDisplayRow().withWidth("2").withName("Show Labels").withField("Labels").withSelected(query["labels"] == true))
          .withChild(new ButtonDisplayRow().withWidth("2").withName("Refresh").withField("Refresh"))
        )
        .withChild(new RowDisplayRow()
          .withChild(new BoxDisplayRow()
            .withBorder(true)
            .withWidth(15)
            .withChild(new LabelDisplayRow().withLabel("Filters"))
            .withChild(new RowDisplayRow()
                  .withChild(new LabelValueDisplayRow().withWidth("1").withLabel("Type"))
                  .withChild(new ButtonDisplayRow().withWidth("1").withName("All").withKey("all").withField("Type").withDefaultValue("all").withSelected(query["type"] === "all"))
                  .withChild(new ButtonDisplayRow().withWidth("1").withName("Included").withKey("included").withField("Type").withDefaultValue("included").withSelected(query["type"] === "included" || !query["type"]))
                  .withChild(new ButtonDisplayRow().withWidth("1").withName("Hidden").withKey("excluded").withField("Type").withDefaultValue("excluded").withSelected(query["type"] === "excluded"))
                  .withChild(new SpacerDisplayRow().withWidth("11"))
                )
            .withChild(new RowDisplayRow()
                .withChild(new LabelValueDisplayRow().withWidth("1").withLabel("Search"))
                .withChild(new StringUpdateRow().withWidth("5")
                    .withShowLabel(false).withField("Search")
                    .withKey("Search").withName("Search")
                    .withDefaultValue(query["search"] ? query["search"] : ""))
                .withChild(new ButtonDisplayRow().withWidth("1").withName("Go").withKey("Search").withField("Search").withSelected(false))
                .withChild(new ButtonDisplayRow().withWidth("1").withName("Clear").withKey("Search").withField("Clear").withSelected(false))
                .withChild(new SpacerDisplayRow().withWidth("1"))
                .withChild(new LabelValueDisplayRow().withWidth("1").withLabel("Range"))
                .withChild(new ButtonDisplayRow().withWidth("1").withName("<").withKey("Range").withField("Increase").withSelected(false))
                .withChild(new StringDisplayRow().withWidth("2").withValue(this.rangeDisplay()))
                .withChild(new ButtonDisplayRow().withWidth("1").withName(">").withKey("Range").withField("Decrease").withSelected(false))
                .withChild(new ButtonDisplayRow().withWidth("1").withName("Reset").withKey("Reset").withField("Reset").withSelected(false))
              )
          )
        )
        .withChild(new RowDisplayRow()
          .withChild(new BoxDisplayRow()
            .withWidth("15")
            .withBorder(true)
            .withChild(new RowDisplayRow()
              .withChild(new LabelValueDisplayRow().withWidth("4").withLabelWidth("5").withLabel("Showing").withValue(this.createPageInfo(totalItems, query, "compliance")))
              .withChild(new StringDisplayRow().withWidth("4"))
              .withChild(new StringListUpdateRow().withWidth("3")
                                                  .withName("Items/Page")
                                                  .withField("PageSize")
                                                  .withShowLabel(false)
                                                  .withDefaultValue(this.model().pageSize(query, "compliance"))
                                                  .withOptions(ContentUtils.PAGE_SIZES()))
              .withChild(new ButtonDisplayRow().withWidth("1").withName("<").withField("PreviousPage"))
              .withChild(new StringListUpdateRow().withWidth("2")
                                                  .withShowLabel(false)
                                                  .withName("Jump to Page")
                                                  .withField("PageIndex")
                                                  .withDefaultValue(query["page"] ? query["page"] : "1")
                                                  .withOptions(ContentUtils.PAGE_INDEXES(totalItems, this.model().pageSize(query, "compliance"))))
              .withChild(new ButtonDisplayRow().withWidth("1").withName(">").withField("NextPage"))
            )
          )
        )
      )
    );
    let box = new BoxDisplayRow();
    box
      .withChild(new RowDisplayRow()
        .withBackgroundTitle()
        .withChild(new TitleDisplayRow().withAlign("centre").withWidth(15).withTitle("Compliance"))
      );
    this.addHeader(box);
  }

  rangeDisplay() {
    let start = this.model()._startFilterDate.displayAs_MM_DD(true);
    let end = this.model()._startFilterDate.minus(14).displayAs_MM_DD(true);
    return StringUtils.format("{0} to {1}", [start, end]);
  }
}