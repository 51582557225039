
import RcTableViewRow from "@/portals/shared/cell/common/RcTableViewRow.js";
import StringUtils  from '@/utils/StringUtils.js';

export default class RowDisplayRow extends RcTableViewRow {

  static Id = "RowDisplayRow";
  static Border = "border";
  static Fixed = "fixed";
  
  constructor() {
    super();
    this.put(RcTableViewRow.TypeId, RowDisplayRow.Id);
    this.withChildren([]);
  }

  withBackgroundTitle() {
    this.put("background", "title")
    return this;
  }

  withBackgroundSubtitle() {
    this.put("background", "subtitle")
    return this;
  }

  isBackgroundTitle() {
    return StringUtils.eic(this.getString("background"), "title");
  }

  isBackgroundSubtitle() {
    return StringUtils.eic(this.getString("background"), "subtitle");
  }

  withBorder() {
    this.withBorderStyleThin();
    return this;
  }
  
  withFixedPosition(fixed) {
    this.put(RowDisplayRow.Fixed, fixed);
    return this;
  }

  isFixedPosition() {
    return this.getBoolean(RowDisplayRow.Fixed);
  }
  
  border() {
    return this.get(RowDisplayRow.Border);
  }
  
}