<template>
  <Row>
    <Column :width="15" :key="redraw">
      <div id="detailsModal" class="rc-modal-dialog">
        <!-- Modal content -->
        <div class="rc-modal-content-dialog-inner-wide">
          
          <div class="rc-modal-header-dialog">
            <Box >
              <Row v-for="(row, rowIndex) in viewHeaderData" 
                :key="rowIndex" >
                  <CellFactory 
                    :rowData="row.data"
                    :showMissingRequirements="showMissingRequirements"
                    v-on:withValue="withFieldValue"
                    v-on:withButtonPressed="withButtonPressed"
                    />
              </Row>
            </Box>
          </div>

          <div class="rc-modal-body-dialog ">
            <Box class="rc-modal-body-scroll rc-border-thin">
              <Row>
                <Column :width="15">
                  <UserListView :usedFor="'selection'"
                      v-on:withValue="withFieldValue"
                      v-on:withButtonPressed="withButtonPressed"
                    ></UserListView>
                </Column>
              </Row>
            </Box>
          </div>

          <div class="rc-modal-footer-dialog">
            <Box :key="redrawFooter">
              <Row 
                v-for="(row, rowIndex) in viewFooterData" 
                :key="rowIndex" >
                  <CellFactory 
                    :rowData="row.data"
                    :showMissingRequirements="showMissingRequirements"
                    v-on:withValue="withFieldValue"
                    v-on:withButtonPressed="withButtonPressed"
                    />
              </Row>
            </Box>
          </div>

        </div>
      </div>
    </Column>
  </Row>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';

import ConnectionUtils from '@/utils/ConnectionUtils.js';
import ConstUtils from '@/utils/ConstUtils.js';
import ContentUtils from '@/utils/ContentUtils.js';
import StringUtils from '@/utils/StringUtils.js';

import MC from "@/domain/session/MC.js";

import CellFactory from "@/portals/shared/cell/CellFactory.vue";
import Box from "@/portals/shared/library/box/Box.vue";
import Column from "@/portals/shared/library/box/Column.vue";
import Row from "@/portals/shared/library/box/Row.vue";

import ValueDisplayRow from "@/portals/shared/cell/value/ValueDisplayRow";
import BoxDisplayRow from "@/portals/shared/cell/box/BoxDisplayRow";
import ButtonDisplayRow from "@/portals/shared/cell/button/ButtonDisplayRow";
import ErrorDisplayRow from "@/portals/shared/cell/error/ErrorDisplayRow";
import RowDisplayRow from "@/portals/shared/cell/row/RowDisplayRow";
import SpacerDisplayRow from '@/portals/shared/cell/spacer/SpacerDisplayRow';
import StringDisplayRow from "@/portals/shared/cell/string/StringDisplayRow";
import StringUpdateRow from "@/portals/shared/cell/string/StringUpdateRow";
import TitleDisplayRow from "@/portals/shared/cell/title/TitleDisplayRow";
import UserListView from "@/portals/customer/operator/views/employee/list/ListView";

import UserMap from "@/domain/model/user/UserMap.js";

export default {
  name: "report-list-add-users-view",
  components: {
    Column, Row, 
    Box,
    CellFactory,
    UserListView,
  },
  props: {
    itemId: { type: String, default: "" },
  },
  data() {
    return {
      redraw: 1,
      redrawFooter: 10000,
      
      MC: new MC(),
      Strings: StringUtils,
      Contents: ContentUtils,
      Consts: ConstUtils,

      error: null,
      showMissingRequirements: false,
      viewHeaderData: [],
      viewBodyData: [],
      viewFooterData: [],

      kvps: {},
      users: null,
      userList: [],
      StringUpdateRow: StringUpdateRow
    };
  },
  computed: {
    ...mapGetters([
                   'auth_connected',
                   'auth_socket_status',
                   'signin_event',
                   'auth_client',
                   'domain',
                   ]),
  },
  watch: {
    auth_connected() {
      ConnectionUtils.sendSignInEvent(this);
    },
    auth_socket_status() {
      ConnectionUtils.displayStatus(this);
    },
    signin_event() {
      ConnectionUtils.createDomainSession(this);
    }
  },
  mounted: function () {
    ConnectionUtils.ensureConnection(this);
  },
  methods: {
    ...mapActions([
                   'addListenerAction',
                   'removeListenerAction',
                   'updateDomain',
                   ]),

    start: function() {
      this.setup();
      this.users = new UserMap(this.domain);
      this.mainScreen();
    },

    setup: function() {
      window.onclick = this.onClick;
    },

    mainScreen() {
      this.viewHeaderData = [];
      this.viewBodyData = [];
      this.viewFooterData = [];

      this.viewHeaderData.push(new TitleDisplayRow().withTitle(StringUtils.format("Select Users")));
      this.viewHeaderData.push(new ValueDisplayRow().withValue("Find and select the users for this report."));

      var errorBox = new BoxDisplayRow().withBorder(true)
                          .withChild(new RowDisplayRow()
                            .withChild(new SpacerDisplayRow().withWidth("1"))
                            .withChild(new ErrorDisplayRow().withWidth("13").withError(this.error))
                            .withChild(new SpacerDisplayRow().withWidth("1"))
                          );
      if (this.error) {
        this.viewBodyData.push(errorBox);
      }

      this.mainScreenFooter();
    },

    mainScreenFooter() {
      this.viewFooterData.length = 0;

      let bodyRow = new BoxDisplayRow().withBorder();
      bodyRow.withChild(new TitleDisplayRow().withTitle("Selected Users").withSubtitle(true))

      if (this.userList.length == 0) {
        bodyRow.withChild(new SpacerDisplayRow());
        bodyRow.withChild(new ValueDisplayRow().withValue("No Users Selected"));
        bodyRow.withChild(new SpacerDisplayRow());
      } else {
        for (let index = 0; index < this.userList.length; index++) {
          let user = this.userList[index];
          let userRow = new RowDisplayRow();
          userRow
            .withChild(new ValueDisplayRow().withWidth(13).withValue((index + 1) + " " + user.fullName()))
            .withChild(new ButtonDisplayRow().withWidth(2).withName("Remove").withId(user.id()).withField("Remove"));
          bodyRow.withChild(userRow);
        }
      }

      this.viewFooterData.push(bodyRow);

      let footerRow = new BoxDisplayRow()
                  .withBorder(true)
                  .withChild(
                    new RowDisplayRow()
                      .withChild(new ButtonDisplayRow().withWidth("2").withName("Cancel").withField("PopupClose"))
                      .withChild(new StringDisplayRow().withWidth("11"))
                      .withChild(new ButtonDisplayRow().withWidth("2").withName("Done").withField("PopupDone").withBackgroundPrimaryAction())
                  );
      this.viewFooterData.push(footerRow);
    },

    sendingScreen() {
      this.viewBodyData = [];
      this.viewFooterData = [];
      this.viewHeaderData = [];

      let bodyBox = new BoxDisplayRow().withChild(new RowDisplayRow()
                              .withChild(new SpacerDisplayRow().withWidth("2"))
                              .withChild(new BoxDisplayRow()
                                    .withWidth("11")
                                    .withChild(new StringDisplayRow()
                                                  .withValue("Sending"))
                                  )
                              .withChild(new SpacerDisplayRow().withWidth("2"))
                            );
      this.viewBodyData.push(bodyBox);
      this.paint();
    },

    doneScreen() {
      this.viewHeaderData = [];
      this.viewBodyData = [];
      this.viewFooterData = [];

      let bodyBox = new BoxDisplayRow().withChild(new RowDisplayRow()
                              .withChild(new SpacerDisplayRow().withWidth("2"))
                              .withChild(new BoxDisplayRow()
                                    .withWidth("11")
                                    .withChild(new StringDisplayRow()
                                                  .withMandatoryValue(true)
                                                  .withValue("Done!"))
                                  )
                              .withChild(new SpacerDisplayRow().withWidth("2"))
                            );

      this.viewBodyData.push(bodyBox);
      this.paint();
    },

    paint: function() {
      this.redraw++;
    },

    paintFooter: function() {
      this.redrawFooter++;
    },

    onClick: function(event) {
      var modal = document.getElementById("detailsModal");
      if (event.target == modal) {
        this.goBack();
      }
    },
    
    log() {
      return this.domain.logger();
    },

    resetMissingValues() {
      if (this.showMissingRequirements) {
        this.showMissingRequirements = false;
      }
    },

    withFieldValue: function(valueData) {
      this.resetMissingValues();
      this.kvps = valueData;
      this.log().info("Popup Value {0}", [JSON.stringify(this.kvps, null, 2)]);
    },
    
    withButtonPressed: function(buttonData) {
      this.resetMissingValues();

      this.log().info("Popup Button {0}", [JSON.stringify(buttonData, null, 2)]);
      if (buttonData["field"] === "PopupClose") {
        this.$emit("withButtonPressed", buttonData);

      } else if (buttonData["field"] === "PopupDone") {
        buttonData["users"] = this.users.data;
        buttonData["object"] = "User"
        this.$emit("withButtonPressed", buttonData);

      } else if (buttonData["field"] === "Pick") {
        let id = buttonData["id"];
        let user = this.domain.users().findById(id);
        this.users.add(user);
        this.userList = this.users.sortByName();
        this.log().info("Employees {0}", [JSON.stringify(this.users.data, null, 2)]);

        this.mainScreenFooter();
        this.paintFooter();

      } else if (buttonData["field"] === "Remove") {
        let id = buttonData["id"];
        let user = this.domain.users().findById(id);
        this.users.remove(user);
        this.userList = this.users.sortByName();
        this.log().info("Employees {0}", [JSON.stringify(this.users.data, null, 2)]);

        this.mainScreenFooter();
        this.paintFooter();

      } else {
        this.$emit("withButtonPressed", buttonData)
      }
    },

    doSendAction() {
      //
    },

    displayProgress: function(msg) {
      this.callback(msg);
    },

    callback: function(msg) {
      if (msg) {
        //
      }
    },

    goBack: function() {
      this.$router.back();
    },

    areRequirementsMet: function() {
      var met = true;
      
      if (met && StringUtils.isEmpty("")) {
        met = false;
      }

      return met;
    },

    saveReport() {
      //
    },

    saveAndRunReport() {
      //
    },

    savedReportListener() {
      //
    },

  },
}
</script>