
import RcTableViewModel from "../../../../../shared/table/RcTableViewModel";

import ListViewUtils from "./ListViewUtils";
import ContentUtils from '@/utils/ContentUtils.js';
import StringUtils from '@/utils/StringUtils.js';

import ScheduleMap from '@/domain/model/schedule/ScheduleMap.js';


export default class ListViewModel extends RcTableViewModel {
    
  constructor(panel) {
    super(panel);
    this._companyId = ContentUtils.baseDecode(panel.$route.params.operatorId);
    this._userId = null;
    this._total = 0;
    this._error = null;
    this._itemList = [];
    this._itemMap = null;
    this._pages = [];
    this.SU = StringUtils;
    this.CU = ContentUtils;
  }

  start() {
    super.start();
  }

  company() {
    return this.domain().companys().findById(this._companyId);
  }
  
  isAdmin() {
    return super.isAdmin();
  }

  withValue(valueData) {
    super.withValue(valueData, "schedule");
  }

  withButtonPressed(buttonData) {
    super.withButtonPressed(buttonData);
    if (!buttonData) {
      return;
    }
  }

  buildRowData(tableData) {
    var utils = new ListViewUtils(this, tableData);
    utils.start();
    return this;
  }

  loadItems() {
    super.loadItems();
    //var query = this.panel().$route.query;
    this.panel().displayProgress("Loading Items");
    
    var context = {};
    context["model"] = this;
    context["startTime"] = new Date();
    context["pageName"] = "schedule";
    var event = this.domain().events().schedules().list();
    event.send(this.loadedItemsListener, context);
  }

  loadedItemsListener(responseEvent, context) {
    var model = context.model;
    context["loadTime"] = new Date();
    if (responseEvent.error()) {
      model.withError(responseEvent.error());
    } else {
      var payload = responseEvent.payload();
      model._total = payload.count();
      var values = payload.valuesFor(ScheduleMap.MODEL_NAME);
      model._itemMap = new ScheduleMap(this.domain, values);
      model._itemList = model._itemMap.sort();
      context["sortTime"] = new Date();
    }
    var query = model.panel().$route.query;
    model._pages = ContentUtils.PAGE_INDEXES(model._total, model.pageSize(query, "schedule"))
    model
      .withStateContentLoaded()
      .clearAndBuildTableData()
      .done();
    model.panel().showDialogIfRequired();

    delete context.model;
    context["renderTime"] = new Date();
    model.log().info("Client Performance {0}", [JSON.stringify(context, null, 2)]);
  }

  items() {
    return this._itemList;
  }

  totalItems() {
    return this._total;
  }

  pages() {
    return this._pages;
  }

  error() {
    return this._error;
  }

}