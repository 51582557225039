<template>
  <Row>
    <Column :width="15" :key="redraw">
      <div id="detailsModal" class="rc-modal-dialog">
        <!-- Modal content -->
        <div class="rc-modal-content-dialog-small">
          <div class="rc-modal-header-dialog">
            <Box >
              <Row 
                v-for="(row, rowIndex) in viewHeaderData" 
                :key="rowIndex" >
                  <CellFactory 
                    :rowData="row.data"
                    :showMissingRequirements="showMissingRequirements"
                    v-on:withValue="withValue"
                    v-on:withButtonPressed="withButtonPressed"
                    />
              </Row>
            </Box>
          </div>

          <div class="rc-modal-body-dialog">
            <div class="rc-modal-body-inner-dialog rc-border-thin">
              <Box>
                <Row 
                  v-for="(row, rowIndex) in viewBodyData" 
                  :key="rowIndex" >
                    <CellFactory 
                      :rowData="row.data"
                      :showMissingRequirements="showMissingRequirements"
                      v-on:withValue="withValue"
                      v-on:withButtonPressed="withButtonPressed"
                      />
                </Row>
              </Box>
            </div>
          </div>

          <div class="rc-modal-footer-dialog">
            <Box>
              <Row 
                v-for="(row, rowIndex) in viewFooterData" 
                :key="rowIndex" >
                  <CellFactory 
                    :rowData="row.data"
                    :showMissingRequirements="showMissingRequirements"
                    v-on:withValue="withValue"
                    v-on:withButtonPressed="withButtonPressed"
                    />
              </Row>
            </Box>
          </div>
        </div>
      </div>
    </Column>
  </Row>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';

import ConnectionUtils from '@/utils/ConnectionUtils.js';
import ConstUtils from '@/utils/ConstUtils.js';
import ContentUtils from '@/utils/ContentUtils.js';
import StringUtils from '@/utils/StringUtils.js';

import MC from "@/domain/session/MC.js";

import Column from "@/portals/shared/library/box/Column.vue";
import Row from "@/portals/shared/library/box/Row.vue";
import Box from "@/portals/shared/library/box/Box.vue";
import CellFactory from "@/portals/shared/cell/CellFactory.vue";

import TitleDisplayRow from "@/portals/shared/cell/title/TitleDisplayRow";
import ButtonDisplayRow from "@/portals/shared/cell/button/ButtonDisplayRow";
import BoxDisplayRow from "@/portals/shared/cell/box/BoxDisplayRow";
import RowDisplayRow from "@/portals/shared/cell/row/RowDisplayRow";
import StringDisplayRow from "@/portals/shared/cell/string/StringDisplayRow";
import SpacerDisplayRow from '@/portals/shared/cell/spacer/SpacerDisplayRow';
import ErrorDisplayRow from "@/portals/shared/cell/error/ErrorDisplayRow";

export default {
  name: "inspection-rebuild-view",
  components: {
    Column, Row, 
    Box,
    CellFactory,
  },
  props: {
    inspectionId: { type: String, default: "" },
  },
  data() {
    return {
      redraw: 1,
      
      MC: new MC(),
      Strings: StringUtils,
      Contents: ContentUtils,
      Consts: ConstUtils,

      showMissingRequirements: false,
      viewHeaderData: [],
      viewBodyData: [],
      viewFooterData: [],

      kvps: null,
      error: null,
    };
  },
  computed: {
    ...mapGetters([
                   'auth_connected',
                   'auth_socket_status',
                   'signin_event',
                   'auth_client',
                   'domain',
                   ]),
  },
  watch: {
    auth_connected() {
      ConnectionUtils.sendSignInEvent(this);
    },
    auth_socket_status() {
      ConnectionUtils.displayStatus(this);
    },
    signin_event() {
      ConnectionUtils.createDomainSession(this);
    }
  },
  mounted: function () {
    ConnectionUtils.ensureConnection(this);
  },
  methods: {
    ...mapActions([
                   'AuthStorage_updateCompany',
                   'addListenerAction',
                   'removeListenerAction',
                   'updateDomain',
                   ]),

    start: function() {
      this.setup();
    },

    setup: function() {
      // window.onclick = this.onClick;

      this.viewHeaderData = [];
      this.viewBodyData = [];
      this.viewFooterData = [];

      this.viewHeaderData.push(new TitleDisplayRow().withTitle("Rebuild Inspection Pdf"));

      if (this.error) {
        var box = new BoxDisplayRow().withBorder(true);
        box
          .withChild(new RowDisplayRow()
            .withChild(new SpacerDisplayRow().withWidth("1"))
            .withChild(new ErrorDisplayRow().withWidth("13").withError(this.error))
            .withChild(new SpacerDisplayRow().withWidth("1"))
          );
        this.viewBodyData.push(box);
      }

      this.viewBodyData.push(new BoxDisplayRow().withChild(new RowDisplayRow()
                              .withChild(new SpacerDisplayRow().withWidth("2"))
                              .withChild(new BoxDisplayRow()
                                    .withWidth("11")
                                    .withChild(new StringDisplayRow().withValue("This will rebuild the inspection Pdf."))
                                    .withChild(new StringDisplayRow()
                                                  .withValue("Please note that it will take a few minutes to generated, previous version of Pdf will be lost"))
                                  )
                              .withChild(new SpacerDisplayRow().withWidth("2"))
                            )
                          );

      this.viewFooterData.push(new BoxDisplayRow()
                  .withBorder(true)
                  .withChild(
                    new RowDisplayRow()
                      .withChild(new ButtonDisplayRow().withWidth("3").withName("Cancel").withField("DetailsClose"))
                      .withChild(new StringDisplayRow().withWidth("9"))
                      .withChild(new ButtonDisplayRow().withWidth("3").withName("Rebuild").withField("Send").withBackgroundPrimaryAction())
                  )
              );
    },

    sendingScreen() {
      this.viewBodyData = [];
      this.viewBodyData.push(new BoxDisplayRow().withChild(new RowDisplayRow()
                              .withChild(new SpacerDisplayRow().withWidth("2"))
                              .withChild(new BoxDisplayRow()
                                    .withWidth("11")
                                    .withChild(new StringDisplayRow()
                                                  .withMandatoryValue(true)
                                                  .withValue("Rebuilding, this may take a moment"))
                                  )
                              .withChild(new SpacerDisplayRow().withWidth("2"))
                            )
                          )
      this.viewFooterData = [];
      this.paint();
    },

    doneScreen() {
      this.viewBodyData = [];
      this.viewBodyData.push(new BoxDisplayRow().withChild(new RowDisplayRow()
                              .withChild(new SpacerDisplayRow().withWidth("2"))
                              .withChild(new BoxDisplayRow()
                                    .withWidth("11")
                                    .withChild(new StringDisplayRow()
                                                  .withMandatoryValue(true)
                                                  .withValue("Done!"))
                                  )
                              .withChild(new SpacerDisplayRow().withWidth("2"))
                            )
                          )
      this.viewFooterData = [];
      this.paint();
    },

    paint: function() {
      this.redraw++;
    },

    onClick: function(event) {
      var modal = document.getElementById("detailsModal");
      if (event.target == modal) {
        this.goBack();
      }
    },
    
    withButtonPressed: function(buttonData) {
      if (buttonData["field"] === "DetailsClose") {
        this.goBack();
      }
      if (buttonData["field"] === "Send") {
        this.send();
      }
    },

    withValue: function(valueData) {
      //this.$emit("withValue", valueData);
      this.kvps = valueData;
    },

    displayProgress: function(msg) {
      this.callback(msg);
    },

    callback: function(msg) {
      if (msg) {
        //
      }
    },

    areRequirementsMet: function() {
      this.showMissingRequirements = false;
      var met = true;
      return met;
    },

    send: function() {
      if (!this.areRequirementsMet()) {
        return false;
      }
      
      var id = ContentUtils.baseDecode(this.inspectionId);
      this.sendingScreen();

      var event = this.domain
          .events()
          .inspections()
          .rebuild(id);
      event.send(this.listener);
      return true;
    },
    
    listener: function(event) {
      if (event.error()) {
        this.error = event.error();
        this.setup();
        this.paint();
      } else {
        this.doneScreen();
        let data = {};
        data["field"] = "Close";
        data["refresh"] = false;
        this.$emit("withButtonPressed", data);
      }
      this.isSending = false;
    },

    goBack: function() {
      this.$router.back();
    }
  },
}
</script>