<template>
    <Row v-if="ready" borderStyle="rc-border-thin-even"> 
        <Column :width="15" :key="redraw">
            <Box :even="row.even()" :backgroundColour="row.even() ? 'rowEven' : 'rowOdd'">
              <Row>
                <Column :width="5">
                  <LabelValueBox :labelWidth="4">
                    <template slot="label">
                      Name
                    </template>
                    <template slot="value">
                      {{ this.Content.decodeText(checklist.name(), "")  }}
                    </template>
                  </LabelValueBox>
                </Column>
                <Column :width="5">
                  <LabelValueBox :labelWidth="4">
                    <template slot="label">
                      Description
                    </template>
                    <template slot="value">
                      {{ this.Content.decodeText(checklist.description(), "")  }}
                    </template>
                  </LabelValueBox>
                </Column>
                <Column :width="5">
                  <LabelValueBox :labelWidth="4">
                    <template slot="label">
                      Questions
                    </template>
                    <template slot="value">
                      {{ this.Content.decodeText(checklist.items().size(), "")  }}
                    </template>
                  </LabelValueBox>
                </Column>
              </Row>
              <Row>
                <Column :width="5">
                  <Box>
                    <StatusCell :data="this.checklistStatusRow().data"></StatusCell>
                  </Box>
                </Column>
              </Row>
              <Row>
                <Column :width="9"></Column>
                <Column :width="2">
                  <Button v-on:click="withDetailsButtonPressed">Details</Button>
                </Column>
                <Column v-if="checklist.isStateActive()" :width="2">
                  <Button v-on:click="withRemoveButtonPressed">Remove</Button>
                </Column>
                <Column v-if="!checklist.isStateActive()" :width="2">
                  <Button v-on:click="withRestoreButtonPressed">Restore</Button>
                </Column>
                <Column :width="2">
                  <Button v-on:click="withUpdateButtonPressed">Update</Button>
                </Column>
              </Row>
               <!-- Admin section -->
              <Row v-if="row.isAdmin()">
                <Column :width="15">
                  <Box :even="row.even()">
                    <Row>
                      <Column :width="15">
                        <LabelValueBox :labelWidth="5">
                          <template slot="label">
                            Admin Section
                          </template>
                          <template slot="value">
                            {{ "" }}
                          </template>
                        </LabelValueBox>
                      </Column>
                    </Row>
                    <Row>
                      <Column :width="5">
                        <LabelValueBox :labelWidth="5">
                          <template slot="label">
                            Company Name
                          </template>
                          <template slot="value">
                            {{ this.Content.decodeText(company.name(), "[Not Set]") }} {{ company.id() }}
                          </template>
                        </LabelValueBox>
                      </Column>
                    </Row>

                    <Row> <!-- Created and Modified By/On Row -->
                      <Column :width="5">
                        <LabelValueBox :labelWidth="5">
                          <template slot="label">
                            Modified By/On
                          </template>
                          <template slot="value">
                            {{ checklist.modifiedBy().find().fullName() }}
                            {{ checklist.modifiedDateV2().displayAs_YYYY_MM_DD_HH_MM_SS_A() }}
                          </template>
                        </LabelValueBox>
                      </Column>
                      <Column :width="5">
                        <LabelValueBox :labelWidth="5">
                          <template slot="label">
                            Created By/On
                          </template>
                          <template slot="value">
                            {{ checklist.createdBy().find().fullName() }}
                            {{ checklist.createdDateV2().displayAs_YYYY_MM_DD_HH_MM_SS_A() }}
                          </template>
                        </LabelValueBox>
                      </Column>
                      <Column :width="5">
                        <LabelValueBox :labelWidth="5">
                          <template slot="label">
                            Checklist Id
                          </template>
                          <template slot="value">
                            {{ checklist.id() }}
                          </template>
                        </LabelValueBox>
                      </Column>
                    </Row>

                    <Row> <!-- Button Row-->
                      <Column :width="9"></Column>
                      <Column :width="2">
                      </Column>
                      <Column :width="2">
                      </Column>
                      <Column :width="2">
                        <Button v-on:click="withSelectButtonPressed">Select</Button>
                      </Column>
                    </Row><!-- End Button Row-->
                  </Box>
                </Column>
              </Row>
              <!-- Admin section -->
            </Box>
        </Column>
    </Row>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';

import ConnectionUtils from '@/utils/ConnectionUtils.js';
import ConstUtils from '@/utils/ConstUtils.js';
import ContentUtils from '@/utils/ContentUtils.js';
import StringUtils from '@/utils/StringUtils.js';

import MC from "@/domain/session/MC.js";

import Box from "@/portals/shared/library/box/Box.vue";
import Column from "@/portals/shared/library/box/Column.vue";
import Row from "@/portals/shared/library/box/Row.vue";

import LabelValueBox from "@/portals/shared/library/labelvalue/LabelValue.vue";
// import LabelDisplay from "@/portals/shared/library/label/LabelDisplayRow.vue";
import Button from "@/portals/shared/library/button/Button.vue";

import StatusCell from '@/portals/shared/cell/status/StatusDisplayCell';
import StatusDisplayRow from '@/portals/shared/cell/status/StatusDisplayRow';
import ChecklistRow from './ChecklistRow';

export default {
    name: 'checklist-cell',
    components: {
        Box, Column, Row,
        LabelValueBox, Button,
        StatusCell,
    },

    props: {
        showMissingRequirements: { type: Boolean, default: false },
        isAdmin: { type: Boolean, default: true },
        data: { type: Object, default: null },
    },

    data() {
        return {
            MC: new MC(),
            Connections: ConnectionUtils,
            Consts: ConstUtils,
            Content: ContentUtils,
            Strings: StringUtils,

            row: new ChecklistRow(),
            checklist: null,
            company: null,

            ready: false,
            redraw: 1,
        };
    },

    computed: {
    ...mapGetters([
                    'auth_connected',
                    'auth_socket_status',
                    'signin_event',
                    'auth_client',
                    'domain',
                    'ChecklistStorage_lastEvent',
                    ]),
    },

    watch: {
        ChecklistStorage_lastEvent() {
            let checklist = this.domain.checklists().findById(this.checklist.id());
            if (this.checklist.modifiedDate() < checklist.modifiedDate()) {
                this.row.withChecklist(checklist);
                this.checklist = this.row.checklist(this.domain);
                this.company = this.checklist.company().find();
                this.redraw++;
            }
        }
    },

    mounted() {
        this.row.data = this.data;
        this.checklist = this.row.checklist(this.domain);
        this.company = this.checklist.company().find();
        this.ready = true;
    },

    methods: {
    ...mapActions([
                'AuthStorage_updateCompany',
                'addListenerAction',
                'removeListenerAction',
                'updateDomain',
                ]),

    withEnterPressed(keyData) {
      this.$emit("withEnterPressed", keyData);
    },

    withValue(valueData) {
      this.$emit('withValue', valueData);
    },

    withUpdateButtonPressed() {
      let data = {};
      data["field"] = "Update";
      this.withButtonPressed(data);
    },

    withRestoreButtonPressed() {
      let data = {};
      data["field"] = "Restore";
      this.withButtonPressed(data);
    },

    withRemoveButtonPressed() {
      let data = {};
      data["field"] = "Remove";
      this.withButtonPressed(data);
    },

    withDetailsButtonPressed() {
      let data = {};
      data["field"] = "Details";
      this.withButtonPressed(data);
    },

    withSelectButtonPressed() {
      let data = {};
      data["field"] = "Select";
      data["id"] = this.company.id();
      this.withButtonPressed(data);
    },

    withButtonPressed: function (buttonData) {
      if (StringUtils.isEmpty(buttonData["id"])) {
        buttonData["id"] = this.row.id();
      }
      buttonData["key"] = this.row.key();
      this.$emit('withButtonPressed', buttonData);
    },

    checklistStatusRow() {
      let row = new StatusDisplayRow();
      let value = this.checklist.state();
      row.withValueWidth(12);
      row.withLabelWidth(4);
      row.withLabel("State");
      row.withValue(value);
      row.withObject(StringUtils.titleCase(value));
      return row;
    }
    },
}

</script>