import RcTableViewModel from "../../../../../shared/table/RcTableViewModel";
import ListViewUtils from "./ListViewUtils";

import InspectionListFilter from "@/domain/model/inspection/InspectionListFilter.js";

import InspectionMap from '@/domain/model/inspection/InspectionMap.js';
// import Utils from '@/views/portal/inspections/Utils.js';
import ContentUtils from '@/utils/ContentUtils.js';
import StringUtils from '@/utils/StringUtils.js';
import RcObject from "@/domain/common/RcObject.js";

export default class ListViewModel extends RcTableViewModel {
    
  constructor(panel) {
    super(panel);
    this._companyId = ContentUtils.baseDecode(panel.$route.params.operatorId);
    this._userId = null;
    this._vehicleId = null;
    this._total = 0;
    this._inspections = [];
    this._pages = [];
  }

  start() {
    super.start();
  }

  company( ) {
    return this.domain().companys().findById(this._companyId);
  }

  isAdmin() {
    return super.isAdmin();
  }

  withValue(valueData) {
    super.withValue(valueData, "inspection");
  }

  withButtonPressed(buttonData) {
    super.withButtonPressed(buttonData);
    if (!buttonData) {
      return;
    }
    if (buttonData["field"] == "Print") {
      var inspectionId = buttonData["id"];
      var inspection = this.domain().inspections().findById(inspectionId);
      this.printInspection(inspection);
    }
  }

  buildRowData(tableData) {
    var utils = new ListViewUtils(this, tableData);
    utils.start();
    return this;
  }

  loadInspections() {
    super.loadItems();
    var query = this.panel().$route.query;
    this.panel().displayProgress("Loading Inspections");
    
    var filter = new InspectionListFilter();
    var time = query["time"];
    if (!isNaN(time)) {
      time = "(" + time + ")";
    } 
    if (StringUtils.isEmpty(time)) {
      time = "last30Days";
    }
    
    var pageNumber = query["page"] ? (query["page"] - 1) : 0;
    filter
      .withDateRange(time)
      .withDefectType(query["status"])
      .withPagination(this.pageSize(query, "inspection"), pageNumber)
      .done();
    
    var user = this.user();
    if (!user.isNew()) {
      var employeeMap = this.domain().employees().findByUserId(user.id());
      var employee = employeeMap.findByCompanyId(this._companyId);
      if (this.isAdmin()) {
        employee = employeeMap.first();
      }
      if (!employee.isNew()) {
        filter
          .withEmployee(employee)
          .done();
      }
    }

    var vehicle = this.vehicle();
    if (!vehicle.isNew()) {
      filter
        .withVehicle(vehicle)
        .done();
    }

    filter
      .withCompany(this._companyId)
      .done();

    filter.remove(RcObject.ID);

    var event = this.domain().events()
        .inspections()
        .list(filter, true, true);

    event.with("populate", true);
    var context = {};
    context["model"] = this;
    context["startTime"] = new Date();
    event.send(this.loadedInspectionsListener, context);
  }

  loadedInspectionsListener(responseEvent, context) {
    context["loadTime"] = new Date();
    var model = context.model;
    if (responseEvent.error()) {
      model.withError(responseEvent.error());
    } else {
      var payload = responseEvent.payload();
      model._total = payload.count();
      var payloadMap = payload.valuesFor(InspectionMap.MODEL_NAME);
      var map = new InspectionMap(model.domain(), payloadMap);
      model._inspections = map.sort();
      context["sortTime"] = new Date();
    }
    var query = model.panel().$route.query;
    model._pages = ContentUtils.PAGE_INDEXES(model._total, model.pageSize(query, "inspection"));
    model.delayedClearAndBuildTable(context);
  }

  delayedClearAndBuildTable(context) {
    let model = context["model"];
    setTimeout(model.loadedInspectionsFinished, 250, context);
  }

  loadedInspectionsFinished(context) {
    let model = context["model"];
    model
    .withStateContentLoaded()
    .clearAndBuildTableData()
    .done();
    delete context.model;
    context["renderTime"] = new Date();
    model.log().info("Client Performance {0}", [JSON.stringify(context, null, 2)]);

    model.panel().showDialogIfRequired();
  }

  user() {
    this._userId = ContentUtils.baseDecode(this.panel().$route.query.userId);
    var user = this.domain().users().findById(this._userId);
    return user;
  }
  
  vehicle() {
    this._vehicleId = ContentUtils.baseDecode(this.panel().$route.query.vehicleId);
    var user = this.domain().vehicles().findById(this._vehicleId);
    return user;
  }

  inspections() {
    return this._inspections;
  }

  printInspection(inspection) {
    var url = ContentUtils.escape(inspection.pdfUrl());
    url = url.replace("#", "%23");
    window.open(url, '_blank');
  }
  
  totalItems() {
    return this._total;
  }

  pages() {
    return this._pages;
  }

}